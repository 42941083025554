import { useStyleEdit } from 'utils';
import Section from '../../components/Section/Section';
import ColorPicker from '../../components/ColorPicker/ColorPicker';
import NumberInput from '../../components/NumberInput/NumberInput';
import TextTransform from '../../components/TextTransform';
import TextStyle from '../../components/TextStyle';
import AlignmentPicker from '../../components/AlignmentPicker/AlignmentPicker';
import s from "./Milestone.module.css";


export default function TitleAppearance({styles, editAtom}) {
  const getter = (style) => style.title;
  const setter = (key, val, { id }) => ({ id, title: { [key]: val } });
  const [
    p, valueChange, toggleChange, colorChange
  ] = useStyleEdit({ editAtom, styles, getter, setter });

  return (
    <Section
      title="Title"
      onToggle={toggleChange("show")}
      toggle={p.show?.value}
      toggleMixed={p.show?.mixed}
    >
      {(p.show.value || p.show.mixed) && (
        <div className={s.wrapper}>
          <div className={s.row}>
            <ColorPicker
              title={"Color"}
              onChange={colorChange("color")}
              id={`ms-title-color`}
              color={p.color.value ?? {h: 0, s: 0, v: 0, a: 1}}
              mixed={p.color.mixed}
            />
            <NumberInput
              title={"Font size"}
              id={`ms-title-font-size`}
              value={p.fontSize.value ?? ""}
              mixed={p.fontSize.mixed}
              onChange={valueChange("fontSize")}
              min={6}
              max={60}
            />
          </div>
          <div className={s.row}>
            <TextStyle
              bold={p.bold.value && !p.bold.mixed}
              italic={p.italic.value && !p.italic.mixed}
              underline={p.underline.value && !p.underline.mixed}
              toggleBold={toggleChange("bold")}
              toggleItalic={toggleChange("italic")}
              toggleUnderline={toggleChange("underline")}
            />
            <TextTransform
              value={p.textTransform.mixed ? null : p.textTransform.value}
              onChange={valueChange("textTransform")} />
          </div>
          <div className={s.alignment}>
            <AlignmentPicker
              value={p.textAlign.mixed ? null : p.textAlign.value}
              onChange={valueChange("textAlign")}
              prefix={"ms-title-alignment"}
              min
            />
          </div>
        </div>
      )}
    </Section>
  )
}