import { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import Router from 'router/Router';
import { getCookie } from 'utils';

import './App.css';


export default function App () {
  useLayoutEffect(() => {
    const cookie = getCookie("cookiesConsent");
    if (cookie?.marketing) {
      window.fbq('consent', 'grant');
    }
  }, [])

  return (
    <>
      <Helmet titleTemplate="%s | Deltaplan" />
      <Router />
    </>
  )
}