import { Amplify } from "aws-amplify";
import config from "amplifyconfiguration.json";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import SignUp from "pages/authorization/SignUp";
import RouteError from "pages/RouteError/RouteError";
import SignIn, { loader as signinLoader } from "pages/authorization/SignIn";
import VerifyEmail from "pages/authorization/VerifyEmail";
import ResetPassword from "pages/authorization/ResetPassword";
import Workspace, { loader as workspaceLoader } from "pages/Workspace/Workspace";
import Project, { loader as projectLoader } from "pages/Project/Project";
import Templates, { loader as templatesLoader } from "pages/Workspace/Templates/Templates";
import Timelines from "pages/Workspace/Timelines/Timelines";
import Settings, { loader as settingsLoader } from "pages/Workspace/Settings/Settings";
import General from "pages/Workspace/Settings/General/General";
import Preferences from "pages/Workspace/Settings/Preferences/Preferences";
import Billing from "pages/Workspace/Settings/Billing/Billing";
import Checkout, { checkoutLoader } from "pages/Checkout/Checkout";
import Upgrade from "pages/CallToUpgrade/CallToUpgrade";
import Unsubscribe from "pages/Unsubscribe/Unsubscribe";

Amplify.configure(config);

const router = createBrowserRouter([{
    id: "root",
    errorElement: <RouteError />,
    children: [
      {
        path: "/",
        element: <Workspace />,
        loader: workspaceLoader,
        shouldRevalidate: () => true,
        children: [
          {
            index: true,
            element: <Timelines />,
          }, {
            path: "upgrade",
            element: <Upgrade />,
          }, {
            path: "templates",
            element: <Templates />,
            loader: templatesLoader,
          }, {
            path: "settings",
            element: <Settings />,
            loader: settingsLoader,
            children: [
              {
                index: true,
                element: <General />,
              }, {
                path: "preferences",
                element: <Preferences />,
              }, {
                path: "billing",
                element: <Billing />,
              }
            ]
          }
        ],
      }, {
        path: "/timeline/:timelineId",
        element: <Project />,
        loader: projectLoader,
      }, {
        path: "/signup",
        element: <SignUp />,
        loader: signinLoader,
      }, {
        path: "/login",
        element: <SignIn />,
        loader: signinLoader,
      }, {
        path: "/verify-email",
        element: <VerifyEmail />,
      }, {
        path: "/reset-password",
        element: <ResetPassword />,
      }, {
        path: "/checkout",
        element: <Checkout />,
        loader: checkoutLoader,
        children: [{
          path: "/checkout/:productId",
          element: <Checkout />,
          loader: checkoutLoader
        }]
      }, {
        path: "/unsubscribe/:userId",
        element: <Unsubscribe />
      }
    ]
  }]);

export default function Router() {
  return (
    <RouterProvider router={router} />
  )
}