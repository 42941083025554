import { useEffect } from 'react';
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { deleteRowAtom, deleteTaskAtom, deleteGroupAtom, editingAtom, historyAtom, isShowTempTooltipAtom, mapAtom, redoAtom, rowStylesAtom, selectedAtom, selectedTypeAtom, selectNextAtom, selectPrevAtom, snappingAtom, toolAtom, groupStylesAtom, taskStylesAtom, undoAtom, updateRowAtom, updateTaskAtom, updateGroupAtom, milestoneStylesAtom, updateMilestoneAtom, deleteMilestoneAtom, optionKeyAtom, SELECTABLE, TOOLS, DAY_LENGHT } from "store";
import { useTimeoutState } from 'utils';


function Hotkeys() {
  const [timeoutTooltip, setTimeoutTooltip] = useTimeoutState(2000);
  const setShowTempTooltip = useSetAtom(isShowTempTooltipAtom);

  const undo = useSetAtom(undoAtom);
  const redo = useSetAtom(redoAtom);
  const setHistory = useSetAtom(historyAtom);

  const map = useAtomValue(mapAtom);

  const groupStyles = useAtomValue(groupStylesAtom);
  const updateGroup = useSetAtom(updateGroupAtom);
  const deleteGroup = useSetAtom(deleteGroupAtom);

  const rowStyles = useAtomValue(rowStylesAtom);
  const updateRow = useSetAtom(updateRowAtom);
  const deleteRow = useSetAtom(deleteRowAtom);
  
  const taskStyles = useAtomValue(taskStylesAtom);
  const updateTask = useSetAtom(updateTaskAtom);
  const deleteTask = useSetAtom(deleteTaskAtom);

  const milestoneStyles = useAtomValue(milestoneStylesAtom);
  const updateMilestone = useSetAtom(updateMilestoneAtom);
  const deleteMilestone = useSetAtom(deleteMilestoneAtom);

  const [selected, setSelected] = useAtom(selectedAtom);
  const [selectedType, setSelectedType] = useAtom(selectedTypeAtom);
  const selectNext = useSetAtom(selectNextAtom);
  const selectPrev = useSetAtom(selectPrevAtom);

  const [editing, setEditing] = useAtom(editingAtom);
  const [snapping, setSnapping] = useAtom(snappingAtom);
  const setTool = useSetAtom(toolAtom);

  const setOption = useSetAtom(optionKeyAtom);

  const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator?.platform);

  useEffect(() => {
    setShowTempTooltip(Boolean(timeoutTooltip));
  }, [timeoutTooltip, setShowTempTooltip]);


  // Set hotkey listeners
  useEffect(() => {
    const shiftDates = (isBack, isNudge, isBoth) => {
      if ( ![SELECTABLE.TASK, SELECTABLE.MILESTONE].includes(selectedType) ) return;
      const delta = DAY_LENGHT
      * (isBack ? -1 : 1)
      * (isNudge ? 7 : 1);
      selected.map(
        id => map.get(id)
      ).forEach(
        ({id, type, start, end, date}) => {
          if (type === SELECTABLE.TASK) {
            const newStart = isBoth ? (start + delta) : start;
            const newEnd = Math.max(end + delta, newStart + DAY_LENGHT);
            updateTask({ id, start: newStart, end: newEnd});
          } else if (type === SELECTABLE.MILESTONE) {
            const newDate = date + delta;
            updateMilestone({ id, date: newDate});
          }
        }
      )
      const id = selected.join("-");
      setHistory(`KeyTaskEdit-${id}`);
      setTimeoutTooltip();
    }

    const deleteSelected = () => {
      if (selected.length === 0) return;
      selected.forEach(id => {
        deleteGroup(id);
        deleteRow(id);
        deleteTask(id);
        deleteMilestone(id);
      });
      setHistory();
    }
    const releaseListener = (e) => {
      if (e.key === "Alt") {
        setOption(false);
      }
    }
    const blurListener = () => {
      setOption(false);
    }
    const keyListener = (e) => {
      const {code, key, ctrlKey, altKey, metaKey, shiftKey} = e;
      const meta = isMac ? (metaKey && !ctrlKey) : ctrlKey;

      if (key === "Alt") {
        setOption(true);
      }
      if (
        ["KeyK", "KeyS", "KeyN", "KeyM", "KeyV", "KeyZ", "KeyY", "Enter", "Escape", "Backspace", "Delete"].includes(code) ||
        code.startsWith("Digit") || code.startsWith("Arrow")
      ) {
        e.preventDefault();
      }

      // Arrow editing
      switch (code) {
        case "ArrowRight" : 
          shiftDates(false, shiftKey, true);
          break;
        case "ArrowLeft" : 
          shiftDates(true, shiftKey, true);
          break;
        case "ArrowUp" : 
          shiftDates(false, shiftKey);
          break;
        case "ArrowDown" : 
          shiftDates(true, shiftKey);
          break;
        default:
          break;
      }

      // Undo/Redo
      if (meta && !altKey) {
        if (code === "KeyZ" && !shiftKey) {
          undo();
        } else if ((code === "KeyZ" && shiftKey) || (code === "KeyY" && !shiftKey)) {
          redo();
        }
      }
      // Select lower/higher
      if (code === "KeyK" && !altKey && meta) {
        document.getElementById("AImput")?.focus();
      }
      // Select lower/higher
      if (code === "KeyA" && !altKey && meta) {
        e.preventDefault();
        const selectableMap = [SELECTABLE.TASK, SELECTABLE.ROW, SELECTABLE.GROUP];
        if ( !selectableMap.includes( selectedType )) return;
        const pos = selectableMap.indexOf(selectedType);
        const newPos = shiftKey ? Math.min(selectableMap.length - 1, pos + 1) : Math.max(0, pos - 1);
        setSelectedType( selectableMap[newPos] );
      }
      // Changing styles
      if (code.startsWith("Digit") && meta && !altKey && !shiftKey) {
        if ( !selected ) return;

        let styles, update;
        switch ( selectedType ) {
          case SELECTABLE.GROUP :
            styles = groupStyles;
            update = updateGroup;
            break;
          case SELECTABLE.ROW :
            styles = rowStyles;
            update = updateRow;
            break;
          case SELECTABLE.TASK :
            styles = taskStyles;
            update = updateTask;
            break;
          case SELECTABLE.MILESTONE :
            styles = milestoneStyles;
            update = updateMilestone;
            break;
          default : return;
        }
        
        const styleIndex = (parseInt(code.slice(-1)) + 9) % 10;
        const style = styles[styleIndex]?.id;
        const allStyles = selected.map( id => map.get(id).style );
        const selectedStyle = allStyles.reduce(
          (original, current) => current === original ? original : false, allStyles[0]
        );
        if ( !style || selectedStyle === style ) return;
        selected.forEach( id => update({ id, style }));
        setHistory(`${selected.join("-")}-style-switch`);
      }

      // Tabbing
      if (code === "Tab" && !altKey && !ctrlKey && !metaKey) {
        e.preventDefault();
        shiftKey ? selectPrev() : selectNext();
      }
      // Snapping, Editing, Selection, Removing
      if (!shiftKey && !ctrlKey && !metaKey) {
        switch (code) {
          case "KeyS":
            setSnapping(!snapping);
            break;
          case "KeyN":
            setTool(TOOLS.TASK);
            break;
          case "KeyV":
            setTool(TOOLS.SELECT);
            break;
          case "KeyM":
            setTool(TOOLS.MILESTONE);
            break;
          case "Backspace":
          case "Delete":
            deleteSelected();
            break;
          case "Enter":
            if (selected.length !== 0) {
              setEditing(true);
            }
            break;
          case "Escape":
            if (editing) {
              setEditing(false);
            } else {
              document.activeElement.blur();
              setSelected(null);
            }
            break;
          default:
            break;
        }
      }
    }

    document.addEventListener("keydown", keyListener);
    window.addEventListener("blur", blurListener);
    document.addEventListener("keyup", releaseListener);
    return () => {
      document.removeEventListener("keydown", keyListener);
      window.removeEventListener("blur", blurListener);
      document.removeEventListener("keyup", releaseListener);
    }
  });

  return null;
}

export default Hotkeys;