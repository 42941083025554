import { useRef, useState } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { historyAtom, minDateAtom, timeScaleAtom, updateTaskAtom, snappedDatesAtom, editingAtom, selectedAtom, taskStylesMapAtom, isShowTempTooltipAtom, moveTaskAtom, lastUsedTaskStyleAtom, dragColumnsAtom, lockedAtom } from "store";
import { vxDate, useSnap, useSetAtomLater } from "utils";
import useDragAndDrop from "./dragHook";
import Knob from "./Knob";
import Bar from "./Bar";
import RangeTooltip from "./RangeTooltip";
import Label from "./Label";

import s from "./Task.module.css";

function Task(props) {
  const {id, title, start, end, styleId, temp, workgroup, overrides} = props;
  const ref = useRef();
  const capture = useRef(false);
  const [deltaStart, setDeltaStart] = useState(0);
  const [deltaEnd, setDeltaEnd] = useState(0);
  const [deltaY, setDeltaY] = useState(0);
  const [isEditing, setEditing] = useState(false);

  const timeoutTooltip = useAtomValue(isShowTempTooltipAtom);
  const isContentEditing = useAtomValue(editingAtom);
  const minDate = useAtomValue(minDateAtom);
  const timeScale = useAtomValue(timeScaleAtom);
  const taskStyles = useAtomValue(taskStylesMapAtom);
  const lastStyle = useAtomValue(lastUsedTaskStyleAtom);
  const columnDrag = useAtomValue( dragColumnsAtom );
  const selected = useAtomValue(selectedAtom);
  const updateTask = useSetAtom(updateTaskAtom);
  const moveTask = useSetAtom(moveTaskAtom);
  const setHistory = useSetAtom(historyAtom);
  const setSnappedDates = useSetAtomLater(snappedDatesAtom);
  const snap = useSnap(start, end);
  const getTargetRow = useDragAndDrop(ref, isEditing, deltaY);
  const isLocked = useAtomValue(lockedAtom);

  const onChangeStart = () => {
    setEditing(true);
    capture.current = true;
  }
  const onChangeEnd = () => {
    applyChanges();
    resetChanges();
  }
  const resetChanges = () => {
    setDeltaStart(0);
    setDeltaEnd(0);
    setDeltaY(0);
    setEditing(false);
    setSnappedDates(null);
    capture.current = false;
  }

  const onStartChange = (delta) => {
    setDeltaStart(delta * timeScale);
  }
  const onEndChange = (delta) => {
    setDeltaEnd(delta * timeScale);
  }
  const onMove = (deltaX, deltaY) => {
    setDeltaStart(deltaX * timeScale);
    setDeltaEnd(deltaX * timeScale);
    setDeltaY(deltaY);
  }
  
  const applyChanges = () => {
    const [ start, end ] = vxDate.sort(startDate, endDate);
    const newTask = { id, start, end };

    const isChanged = Object.keys(newTask).some(key => newTask[key] !== props[key]);
    if (isChanged) updateTask(newTask);

    const row = getTargetRow();
    const isRowChanged = row && row !== props.row;
    if ( isRowChanged ) moveTask({id, row});

    if ( !isChanged && !isRowChanged ) return;
    setHistory();
  };

  // Calc dates
  let startDate = start + deltaStart;
  let endDate = end + deltaEnd;
  // Snapping dates
  const snapStart = snap(startDate);
  const snapEnd = snap(endDate);
  if (capture.current) {
    if (deltaStart && deltaEnd) {
      let delta = 0;
      if (snapStart.snapped && snapEnd.snapped) {
        if (Math.abs(snapStart.delta) <= Math.abs(snapEnd.delta)) {
          delta = snapStart.delta;
        } else {
          delta = snapEnd.delta;
        }
      } else if (snapStart.snapped) {
        delta = snapStart.delta;
      } else if (snapEnd.snapped) {
        delta = snapEnd.delta;
      }
      startDate += delta;
      endDate += delta;
      if ((snapStart.snapped !== snapEnd.snapped)) {
        setSnappedDates(snapStart.snapped ? startDate : endDate);
      } else {
        setSnappedDates([startDate, endDate]);
      }
    } else if (deltaStart) {
      startDate = snapStart.val;
      setSnappedDates(startDate);
    } else if (deltaEnd) {
      endDate = snapEnd.val;
      setSnappedDates(endDate);
    }
  }


  // Style
  const width = Math.abs(endDate - startDate) / timeScale;
  const deltaX = (Math.min(startDate, endDate) - minDate) / timeScale;
  const transform = `translate(${deltaX}px, ${deltaY}px)`;
  const style = { width, transform };
  
  // ClassName
  const isSelected = selected.includes(id);
  const noTransitions = !ref.current || isEditing || temp;
  const immediateTooltip = (timeoutTooltip && isSelected) || deltaStart;
  const disabled = isLocked && !temp;

  const className = [
    `override-${id}`,
    s.taskWrapper,
    styleId ?? lastStyle,
    temp              && s.temp,
    isSelected        && s.selected,
    noTransitions     && s.noTransitions,
    isEditing         && s.editing,
    immediateTooltip  && s.immediateTooltip,
    columnDrag        && s.columnDrag,
    disabled          && s.disabled,
  ].filter(Boolean).join(" ");

  const isShowTooltip = temp 
    || (timeoutTooltip && isSelected)
    || (isEditing && (!deltaY || deltaStart || deltaEnd));
  
  const taskStyle = taskStyles[styleId] ?? taskStyles[lastStyle];
  const labelProps = { id, title, startDate, endDate, workgroup };

  const labels = taskStyle.labels.map(
    label => ({
      ...label,
      ...overrides?.labels?.[label.id]
    })
  )
  
  return (
    <div {...{ref, id, className, style}}>
      <Bar
        className={s.task}
        id={id}
        onChangeStart={onChangeStart}
        onChangeEnd={onChangeEnd}
        onChange={onMove}
      >
        {!temp && labels.map( (l, index) => (
          l.show && (
            <Label
              key={index}
              className={`${s.label} ${s[l.alignment]} ${l.id ?? `${taskStyle.id}-label-${index}`}`}
              field={l.field}
              format={l.format}
              { ...labelProps }
            />
          )))}
        {isContentEditing ? null : (
          <>
          <Knob
            className={s.startKnob}
            onChangeStart={onChangeStart}
            onChangeEnd={onChangeEnd}
            onChange={onStartChange}
          />
          <Knob
            className={s.endKnob}
            onChangeStart={onChangeStart}
            onChangeEnd={onChangeEnd}
            onChange={onEndChange}
          />
          </>
        )}
      </Bar>
      <RangeTooltip
        show={isShowTooltip}
        start={startDate}
        end={endDate}
      />
    </div>
  )
}

export default Task;