import { useAtomValue } from "jotai";
import { Helmet } from "react-helmet";
import { tasksAtom, rowsAtom, rowStylesAtom, rowStylesMapAtom, groupStylesAtom, taskStylesLabelsMapAtom, taskStylesAtom, backgroundAtom, pageFontAtom, borderColorAtom, borderWidthAtom, groupBorderColorAtom, groupBorderWidthAtom, labelColumnsAtom, pagePaddingAtom, ptAtom, cellIndentAtom, groupsAtom, groupStylesMapAtom, columnsAtom, backgroundShowAtom, groupBorderShowAtom, borderShowAtom, milestonesAtom, milestoneStylesAtom, milestoneStylesMapAtom, workareaWidthAtom, LABEL_ALIGNMENT, FIELDS, timelinePaddingAtom } from "store";
import { hsvaToHexCss } from "utils";
import { filter, map, merge } from "lodash";


function Styles() {
  const isReady = useAtomValue(workareaWidthAtom) !== 1;
  const groupStyles = useAtomValue(groupStylesAtom);
  const groupStylesMap = useAtomValue(groupStylesMapAtom);
  const rowStyles = useAtomValue(rowStylesAtom);
  const rowStylesMap = useAtomValue(rowStylesMapAtom);
  const taskStyles = useAtomValue(taskStylesAtom);
  const taskStylesMap = useAtomValue(taskStylesLabelsMapAtom);
  const milestoneStyles = useAtomValue(milestoneStylesAtom);
  const milestoneStylesMap = useAtomValue(milestoneStylesMapAtom);
  const groups = useAtomValue(groupsAtom);
  const rows = useAtomValue(rowsAtom);
  const tasks = useAtomValue(tasksAtom);
  const milestones = useAtomValue(milestonesAtom);
  const background = useAtomValue(backgroundAtom);
  const backgroundShow = useAtomValue(backgroundShowAtom);
  const pageFont = useAtomValue(pageFontAtom);
  const borderWidth = useAtomValue(borderWidthAtom);
  const borderColor = useAtomValue(borderColorAtom);
  const borderShow = useAtomValue(borderShowAtom);
  const groupBorderWidth = useAtomValue(groupBorderWidthAtom);
  const groupBorderColor = useAtomValue(groupBorderColorAtom);
  const groupBorderShow = useAtomValue(groupBorderShowAtom);
  const labelColumns = useAtomValue(labelColumnsAtom);
  const [leftPadding, rightPadding] = useAtomValue(pagePaddingAtom);
  const [leftTimelinePadding, rightTimelinePadding] = useAtomValue(timelinePaddingAtom);
  const cellIndent = useAtomValue(cellIndentAtom);
  const pt = useAtomValue(ptAtom);
  const columns = useAtomValue(columnsAtom);

  if (!isReady) return null;

  const overrideTasks = getOverrides(tasks, taskStylesMap);
  const overrideMilestones = getOverrides(milestones, milestoneStylesMap);
  const overrideRows = getOverrides(rows, rowStylesMap);
  const overrideGroups = getOverrides(groups, groupStylesMap);

  const columnTemplate = columns.map(
    col => col.field === FIELDS.TASKS ? `1fr` : `${col.width * pt}px`
  ).join(" ");

  const styles = [
    `:root {
      --page-padding-left: ${leftPadding * pt}px;
      --page-padding-right: ${rightPadding * pt}px;
      --timeline-padding-left: ${leftTimelinePadding * pt}px;
      --timeline-padding-right: ${rightTimelinePadding * pt}px;
      --pt: ${pt}px;
      --indent: ${pt * cellIndent}px;
      --columns: ${columnTemplate};
    }`,
    ...groupStyles.map(s => 
      `.${s.id} {
        --background: ${hsvaToHexCss(s.background, s.backgroundShow)};
        --bar-border-color: ${hsvaToHexCss(s.bar.borderColor, s.bar.borderShow)};
        --bar-border-width: ${s.bar.borderWidth * pt}px;
        --border-radius: ${s.bar.borderRadius * pt}px;
        --gap-opacity: ${s.bar.gapOpacity / 100};
        --bar-color: ${hsvaToHexCss(s.bar.background, s.bar.backgroundShow)};
        --height: ${s.bar.height * pt}px;
        --row-padding: ${s.padding * pt}px;
      }`
    ),
    ...overrideGroups.map(s => 
      `.${s.id} {
        --background: ${hsvaToHexCss(s.background, s.backgroundShow)};
        --bar-border-color: ${hsvaToHexCss(s.bar.borderColor, s.bar.borderShow)};
        --bar-border-width: ${s.bar.borderWidth * pt}px;
        --border-radius: ${s.bar.borderRadius * pt}px;
        --gap-opacity: ${s.bar.gapOpacity / 100};
        --bar-color: ${hsvaToHexCss(s.bar.background, s.bar.backgroundShow)};
        --height: ${s.bar.height * pt}px;
        --row-padding: ${s.padding * pt}px;
      }`
    ), 
    ...groupStyles.map(s => 
      labelColumns.map( ({id, width}) => 
      `.${s.id}-${id} {
        --width: ${width * pt}px;
        --color: ${hsvaToHexCss(s.columns[id]?.color)};
        --font-size: ${s.columns[id]?.fontSize * pt}px;
        --text-align: ${s.columns[id]?.textAlign};
        --justify-content: ${s.columns[id]?.textAlign === "left" ? "flex-start" : s.columns[id]?.textAlign === "right" ? "flex-end" : s.columns[id]?.textAlign};
        --font-weight: ${s.columns[id]?.bold ? 600 : 400};
        --font-style: ${s.columns[id]?.italic ? "italic" : "none"};
        --text-decoration: ${s.columns[id]?.underline ? "underline" : "none"};
        --text-transform: ${s.columns[id]?.textTransform};
      }`).join('\n')
    ),
    ...overrideGroups.map(s => 
      labelColumns.map( ({id, width}) => 
      `.${s.id}-${id} {
        --width: ${width * pt}px;
        --color: ${hsvaToHexCss(s.columns[id]?.color)};
        --font-size: ${s.columns[id]?.fontSize * pt}px;
        --text-align: ${s.columns[id]?.textAlign};
        --justify-content: ${s.columns[id]?.textAlign === "left" ? "flex-start" : s.columns[id]?.textAlign === "right" ? "flex-end" : s.columns[id]?.textAlign};
        --font-weight: ${s.columns[id]?.bold ? 600 : 400};
        --font-style: ${s.columns[id]?.italic ? "italic" : "none"};
        --text-decoration: ${s.columns[id]?.underline ? "underline" : "none"};
        --text-transform: ${s.columns[id]?.textTransform};
      }`).join('\n')
    ),
    ...rowStyles.map(s => 
    `.${s.id} {
      --background: ${hsvaToHexCss(s.background, s.backgroundShow)};
      --row-padding: ${s.padding * pt}px;
    }`),
    ...overrideRows.map(s => 
    `.${s.id} {
      --background: ${hsvaToHexCss(s.background, s.backgroundShow)};
      --row-padding: ${s.padding * pt}px;
    }`),
    ...rowStyles.map(s => 
      labelColumns.map( ({id, width}) => 
      `.${s.id}-${id} {
        --width: ${width * pt}px;
        --color: ${hsvaToHexCss(s.columns[id]?.color)};
        --font-size: ${s.columns[id]?.fontSize * pt}px;
        --text-align: ${s.columns[id]?.textAlign};
        --justify-content: ${s.columns[id]?.textAlign === "left" ? "flex-start" : s.columns[id]?.textAlign === "right" ? "flex-end" : s.columns[id]?.textAlign};
        --font-weight: ${s.columns[id]?.bold ? 600 : 400};
        --font-style: ${s.columns[id]?.italic ? "italic" : "none"};
        --text-decoration: ${s.columns[id]?.underline ? "underline" : "none"};
        --text-transform: ${s.columns[id]?.textTransform};
      }`).join('\n')
    ),
    ...overrideRows.map(s => 
      labelColumns.map( ({id, width}) => 
      `.${s.id}-${id} {
        --width: ${width * pt}px;
        --color: ${hsvaToHexCss(s.columns[id]?.color)};
        --font-size: ${s.columns[id]?.fontSize * pt}px;
        --text-align: ${s.columns[id]?.textAlign};
        --justify-content: ${s.columns[id]?.textAlign === "left" ? "flex-start" : s.columns[id]?.textAlign === "right" ? "flex-end" : s.columns[id]?.textAlign};
        --font-weight: ${s.columns[id]?.bold ? 600 : 400};
        --font-style: ${s.columns[id]?.italic ? "italic" : "none"};
        --text-decoration: ${s.columns[id]?.underline ? "underline" : "none"};
        --text-transform: ${s.columns[id]?.textTransform};
      }`).join('\n')
    ),
    ...taskStyles.map(s =>
    `.${s.id} {
      --height: ${s.height * pt}px;
      --background: ${hsvaToHexCss(s.background, s.backgroundShow)};
      --border-radius: ${s.borderRadius * pt}px;
      --border-color: ${hsvaToHexCss(s.borderColor, s.borderShow)};
      --border-width: ${s.borderWidth * pt}px;
    }`),
    ...overrideTasks.map(s =>
    `.${s.id} {
      --height: ${s.height * pt}px;
      --background: ${hsvaToHexCss(s.background, s.backgroundShow)};
      --border-radius: ${s.borderRadius * pt}px;
      --border-color: ${hsvaToHexCss(s.borderColor, s.borderShow)};
      --border-width: ${s.borderWidth * pt}px;
    }`),
    ...taskStyles.map(s =>
      s.labels.map( l => 
      `${`.${l.id}`} {
        --color: ${hsvaToHexCss(l.color)};
        --font-size: ${l.fontSize * pt}px;
        --text-align: ${textAlign[l.alignment]};
        --justify-content: ${justifyContent[l.alignment]};
        --font-weight: ${l.bold ? 600 : 400};
        --font-style: ${l.italic ? "italic" : "none"};
        --text-decoration: ${l.underline ? "underline" : "none"};
        --text-transform: ${l.textTransform};
      }`).join('\n')
    ),
    ...overrideTasks.map(s =>
      map(s.labels, l => 
      `${`.${s.id} .${l.id}`} {
        --color: ${hsvaToHexCss(l.color)};
        --font-size: ${l.fontSize * pt}px;
        --text-align: ${textAlign[l.alignment]};
        --justify-content: ${justifyContent[l.alignment]};
        --font-weight: ${l.bold ? 600 : 400};
        --font-style: ${l.italic ? "italic" : "none"};
        --text-decoration: ${l.underline ? "underline" : "none"};
        --text-transform: ${l.textTransform};
      }`).join('\n')
    ),
    ...milestoneStyles.map(s => 
      `.${s.id} {
        --background: ${hsvaToHexCss(s.background, s.backgroundShow)};
        --border-color: ${hsvaToHexCss(s.borderColor, s.borderShow)};
        --border-width: ${s.borderWidth * pt}px;
        --width: ${s.size * pt}px;
        --baseline: ${s.baseline * pt * -1}px;

        --title-color: ${hsvaToHexCss(s.title.color)};
        --title-font-size: ${s.title.fontSize * pt}px;
        --title-font-weight: ${s.title.bold ? 600 : 400};
        --title-font-style: ${s.title.italic ? "italic" : "none"};
        --title-text-decoration: ${s.title.underline ? "underline" : "none"};
        --title-text-transform: ${s.title.textTransform};

        --date-color: ${hsvaToHexCss(s.date.color)};
        --date-font-size: ${s.date.fontSize * pt}px;
        --date-font-weight: ${s.date.bold ? 600 : 400};
        --date-font-style: ${s.date.italic ? "italic" : "none"};
        --date-text-decoration: ${s.date.underline ? "underline" : "none"};
        --date-text-transform: ${s.date.textTransform};
      }`
    ),
    ...overrideMilestones.map(s => 
      `.${s.id} {
        --background: ${hsvaToHexCss(s.background, s.backgroundShow)};
        --border-color: ${hsvaToHexCss(s.borderColor, s.borderShow)};
        --border-width: ${s.borderWidth * pt}px;
        --width: ${s.size * pt}px;
        --baseline: ${s.baseline * pt * -1}px;

        --title-color: ${hsvaToHexCss(s.title.color)};
        --title-font-size: ${s.title.fontSize * pt}px;
        --title-font-weight: ${s.title.bold ? 600 : 400};
        --title-font-style: ${s.title.italic ? "italic" : "none"};
        --title-text-decoration: ${s.title.underline ? "underline" : "none"};
        --title-text-transform: ${s.title.textTransform};

        --date-color: ${hsvaToHexCss(s.date.color)};
        --date-font-size: ${s.date.fontSize * pt}px;
        --date-font-weight: ${s.date.bold ? 600 : 400};
        --date-font-style: ${s.date.italic ? "italic" : "none"};
        --date-text-decoration: ${s.date.underline ? "underline" : "none"};
        --date-text-transform: ${s.date.textTransform};
      }`
    ),
    `.planner {
      --background: ${hsvaToHexCss(background, backgroundShow)};
      --font-family: ${pageFont};
      --border-color: ${hsvaToHexCss(borderColor)};
      --border-width: ${borderWidth * pt * borderShow}px;
      --group-border-color: ${hsvaToHexCss(groupBorderColor)};
      --group-border-width: ${groupBorderWidth * pt * groupBorderShow}px;
    }`
  ].join('\n');
  return (
    <Helmet>
      <style>{styles}</style>
    </Helmet>
  )
}

const textAlign = {
  [LABEL_ALIGNMENT.INSIDE_LEFT]: "left",
  [LABEL_ALIGNMENT.INSIDE_RIGHT]: "right",
  [LABEL_ALIGNMENT.INSIDE_CENTER]: "center",
  [LABEL_ALIGNMENT.ABOVE_LEFT]: "left",
  [LABEL_ALIGNMENT.ABOVE_RIGHT]: "right",
  [LABEL_ALIGNMENT.ABOVE_CENTER]: "center",
  [LABEL_ALIGNMENT.BELOW_LEFT]: "left",
  [LABEL_ALIGNMENT.BELOW_RIGHT]: "right",
  [LABEL_ALIGNMENT.BELOW_CENTER]: "center",
  [LABEL_ALIGNMENT.OUTSIDE_LEFT]: "right",
  [LABEL_ALIGNMENT.OUTSIDE_RIGHT]: "left",
}
export const justifyContent = {
  [LABEL_ALIGNMENT.INSIDE_LEFT]: "flex-start",
  [LABEL_ALIGNMENT.INSIDE_RIGHT]: "flex-end",
  [LABEL_ALIGNMENT.INSIDE_CENTER]: "center",
  [LABEL_ALIGNMENT.ABOVE_LEFT]: "flex-start",
  [LABEL_ALIGNMENT.ABOVE_RIGHT]: "flex-end",
  [LABEL_ALIGNMENT.ABOVE_CENTER]: "center",
  [LABEL_ALIGNMENT.BELOW_LEFT]: "flex-start",
  [LABEL_ALIGNMENT.BELOW_RIGHT]: "flex-end",
  [LABEL_ALIGNMENT.BELOW_CENTER]: "center",
  [LABEL_ALIGNMENT.OUTSIDE_LEFT]: "flex-end",
  [LABEL_ALIGNMENT.OUTSIDE_RIGHT]: "flex-start",
  left: "flex-start",
  right: "flex-end",
  center: "center",
}

function getOverrides(items, styles) {
  return filter(items, "overrides").map(
    item => merge(
      {},
      styles[item.style],
      item.overrides,
      { id: `override-${item.id}` },
    )
  )
}

export default Styles;