import { atom } from "jotai";
import { splitAtom } from "jotai/utils";
import { v4 as uid } from "uuid";
import { getFormat } from "utils";
import { FIELDS, MIN_COLUMN_WIDTH, PAGE_WIDTH } from "./constants";
import { pagePaddingAtom } from "./planner";
import { addGroupColumnStyleAtom, editGroupStyleAtom, removeGroupColumnStyleAtom, groupStylesAtom, addRowColumnStyleAtom, editRowStyleAtom, removeRowColumnStyleAtom, rowStylesAtom } from "./styles";
import { addHeadingColumnAtom, editHeadingAtom, footerAtom, headerAtom, removeHeadingColumnAtom } from "./heading";


export const columnsAtom = atom([]);
export const columnAtomsAtom = splitAtom(columnsAtom, (el) => el.id);
export const labelColumnsAtom = atom(
  get => get(columnsAtom).filter(c => c.field !== FIELDS.TASKS)
);

export const timelineColumnIndexAtom = atom(
  get => get(columnsAtom).findIndex(col => col.field === FIELDS.TASKS)
);

export const timelineColumnWidthAtom = atom(
  get => (
    PAGE_WIDTH - get(labelColumnsAtom)
      .map(el => el.width)
      .concat(get(pagePaddingAtom))
      .reduce((acc, val) => acc + val, 0)
  )
);

const TIMLINE_MIN_WIDTH = 300;

export const availableWidthAtom = atom(
  get => Math.max(get(timelineColumnWidthAtom) - TIMLINE_MIN_WIDTH, 0)
)

export const addColumnAtom = atom(null,
  (get, set, field) => {
    const id = uid();
    const format = getFormat(field);
    const width = Math.min(100, get(availableWidthAtom));
    const column = { id, field, format, width };
    set(columnAtomsAtom, { type: "insert", value: column });
    set(addRowColumnStyleAtom, id);
    set(addGroupColumnStyleAtom, id);
    set(addHeadingColumnAtom, id);
  }
)

export const removeColumnAtom = atom(null,
  (get, set, atom) => {
    const { id } = get(atom);
    set(columnAtomsAtom, { type: "remove", atom });
    set(removeRowColumnStyleAtom, id);
    set(removeGroupColumnStyleAtom, id);
    set(removeHeadingColumnAtom, id);
  }
)

export const editColumnAtom = atom(null,
  (get, set, { atom, ...upd }) => {
    const original = get(atom);
    const item = { ...original, ...upd };
    if (upd.field) {
      item.format = getFormat(upd.field, item.format);
    }
    if (upd.width !== undefined) {
      item.width = Math.max(MIN_COLUMN_WIDTH, Math.min(upd.width, original.width + get(availableWidthAtom)));
    }
    set(atom, item);
  }
)

export const dragColumnsAtom = atom(null);
export const columnEdgeGuideAtom = atom(null);

export const editColumnAlignAtom = atom(null,
  (get, set, { columnId, textAlign }) => {
    const rowStyles = get(rowStylesAtom);
    const groupStyles = get(groupStylesAtom);
    const upd = {
      columns: {
        [columnId]: { textAlign }
      }
    };
    rowStyles.forEach(({ id }) => set(editRowStyleAtom, { id, ...upd }));
    groupStyles.forEach(({ id }) => set(editGroupStyleAtom, { id, ...upd }));
    [headerAtom, footerAtom].forEach((atom) => set(editHeadingAtom, { atom, ...upd }));
  }
)