import s from "./Input.module.css";


export default function Input({title, id, value, onChange, disabled, placeholder, mixed, autoComplete="off", autoFocus, ...rest}) {

  const onInput = (e) => {
    onChange(e.currentTarget.value);
  }
  const onKeyDown = (e) => {
    if (e.key === "Enter" || e.key === "Escape") {
      e.currentTarget.blur();
    }
  }

  return (
    <div className={s.wrapper} {...rest}>
      {title && <label className={s.label} htmlFor={id}>{title}</label>}
      <input
        type={"text"}
        id={id}
        className={s.input}
        value={mixed ? "" : value}
        onChange={onInput}
        onKeyDown={onKeyDown}
        disabled={disabled}
        placeholder={mixed ? "Mixed" : placeholder}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
      />
    </div>
  )
}