import { useState } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { teamAtom, preferencesAtom, FIELDS, teamModalAtom } from "store";
import { fieldValue, isEqualAll, uniqueValues } from "utils";
import { Button } from "components";
import AssignMember from "./AssignMember";
import MemberRow from "./MemberRow";

import {ReactComponent as Arrow} from "img/icon/fold-arrow.svg";
import {ReactComponent as Icon} from "img/icon/team.svg";
import s from "./Workgroup.module.css";


export default function Workgroup({ tasks }) {
  const setTeamModal = useSetAtom(teamModalAtom);
  const team = useAtomValue(teamAtom);

  const openModal = () => setTeamModal(true);

  const workgroups = tasks.map( task  => (
    Object.fromEntries(
      task.workgroup.map( member => [member.id, member] )
    )
  ))

  const members = tasks
  .map( task => (
    task.workgroup.map( member => member.id )
  ))
  .flat()
  .filter(uniqueValues)
  .map( id => ({
    id,
    ...team.find( member => member.id === id),
    mixed: !workgroups.every( workgroup => workgroup[id] ),
    tasks: tasks.filter( task => task.workgroup.includes( member => member.id === id ) ),
    allocation: {
      value: workgroups.find( workgroup => workgroup[id] )[id].allocation,
      mixed: !isEqualAll(
        ...workgroups
        .filter( workgroup => workgroup[id] )
        .map( workgroup => workgroup[id].allocation )
      )
    }
  }))
  .sort( (a, b) => a.mixed - b.mixed )
  ;

  const selected = members.filter( member => !member.mixed ).map( member => member.id );


  return (
    <div className={s.wrapper}>
      <AssigneeTable tasks={tasks} members={members} />
      <div className={s.toolbar}>
        <AssignMember exclude={selected} />
        <Button secondary iconOnly label="Edit team" icon={Icon} onClick={openModal} tooltip={"Edit team"} tooltipAlign={"right"} />
      </div>
      <Total tasks={tasks} />
    </div>
  )
}


function AssigneeTable({ tasks, members }) {
  const [showMixed, setShowMixed] = useState(false);
  const toggleMixed = () => setShowMixed(!showMixed);

  const nonMixed = members.filter( el => !el.mixed );
  const mixed = members.filter( el => el.mixed );

  return ( members.length ) ? (
    <div className={s.tableWrapper}>
      <div className={s.th}>
        <span className={s.thName}>Assignee</span>
        <span className={s.thRate}>Rate/d</span>
        <span className={s.thAllocation}>Allocation</span>
        <span className={s.thRemove} />
      </div>
      {nonMixed.map( el => (
        <MemberRow key={el.id} {...el} tasks={tasks} />
      ))}
      { !!mixed.length && (
        <>
        <div className={s.spoiler} onClick={toggleMixed}>
          <span>{mixed.length} mixed values</span>
          <Arrow className={`${[s.mixedArrow, showMixed && s.opened].join(" ")}`} />
        </div>
        {showMixed && mixed.map( el => (
          <MemberRow key={el.id} {...el} tasks={tasks} />
        ))}
        </>
      )}
    </div>
  ) : (
    <div className={s.empty}>Assignee</div>
  )
}


function Total({ tasks=[] }) {
  const team = useAtomValue( teamAtom );
  const preferences = useAtomValue( preferencesAtom );

  if ( !tasks.some( task => task.workgroup.length )) {
    return null;
  }

  const result = fieldValue(tasks, FIELDS.COST, null, team, preferences);

  return (
    <div className={s.total}>
      Total cost: {result}
    </div>
  )
}