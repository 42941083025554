import s from "./ViewToggle.module.css";
import { ReactComponent as Mixed } from "img/icon/show-mixed.svg";
import { ReactComponent as Visible } from "img/icon/show.svg";
import { ReactComponent as Hidden } from "img/icon/hide.svg";

export default function ViewToggle({ value=true, onChange, disabled, mixed }) {
  const onClick = (e) => {
    onChange();
    e.currentTarget.blur();
  }
  const onKeyDown = (e) => {
    e.currentTarget.blur();
  }
  return (
    <button className={s.button} onClick={onClick} onKeyDown={onKeyDown} disabled={disabled}>
      { mixed ? 
        <Mixed className={s.icon} /> :
        value ?
        <Visible className={s.icon} /> :
        <Hidden className={s.icon} />
      }
    </button>
  )
}