import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from 'react-router-dom';
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { isLifetimeUserAtom, userAtom } from "store";
import { currencyFormat, hasLifetime, useToggle, writeFileToBrowser } from "utils";
import dateFormat from "dateformat";
import { initializePaddle } from "@paddle/paddle-js";
import { fetchUserAttributes } from "aws-amplify/auth";
import { generateClient, get, post } from 'aws-amplify/api';
import { transactionsByOwner } from "graphql/queries";
import { Button, Modal } from "components";
import { ModalButton, ModalFooter } from "components/Modal";
import { ReactComponent as Download } from "img/icon/download.svg";
import s from "./Billing.module.css";

export default function Billing() {
  const user = useAtomValue(userAtom);
  const subscription = useMemo(() => JSON.parse(user["custom:premium"] || null), [user]);
  const customer = useMemo(() => JSON.parse(user["custom:billing"] || null), [user]);
  const [paddle, setPaddle] = useState();
  const [price, setPrice] = useState();
  const [billingMethod, setBillingMethod] = useState();

  // Init Paddle
  useEffect(() => {
    initializePaddle({
      environment: process.env.REACT_APP_ENV === "dev" ? "sandbox" : "production",
      token: process.env.REACT_APP_PADDLE_TOKEN,
    })
      .then(setPaddle)
      .catch(console.log);
  }, []);

  // Fetch price
  useEffect(() => {
    if (!paddle || !subscription || !customer) return;
    paddle.TransactionPreview({
      items: subscription.items,
      customerId: customer.customerId,
      addressId: customer.addressId,
      businessId: customer.businessId,
    })
    .then(setPrice);
  }, [paddle, subscription, customer])

  return (
    <>
      <Helmet title="Billing" />
      <div className={s.section}>
        <h1>Your subscription</h1>
        {!subscription || subscription.status === "canceled" ? (
          <NoSubscription />
        ) : !price ? (
          <LoadingSubscription />
        ) : (
          <Subscription
            subscription={subscription}
            price={price}
            billingMethod={billingMethod}
          />
        )}
      </div>
      <BillingHistory setBillingMethod={setBillingMethod} />
    </>
  );
}

// Subscription

function NoSubscription() {
  const isLifetime = useAtomValue(isLifetimeUserAtom);
  return (
    <div className={s.billingWrapper}>
      <div className={s.billingRow}>
        <span className={s.key}>Your plan</span>
        <div className={s.value}>
          <span>{isLifetime ? "Deltaplan Lifetime" : "Deltaplan Starter"}</span>
          <Button el={Link} to="/upgrade" label={"Upgrade"} />
        </div>
      </div>
    </div>
  )
}

function Subscription({ subscription, price, billingMethod }) {
  const [canceling, open, close] = useToggle(false);
  const [busy, setBusy] = useState(false);
  const navigate = useNavigate();

  const nextBilled = subscription?.nextBilledAt ? dateFormat(subscription.nextBilledAt, "d mmm yyyy") : null;

  const name = price.data.items[0].price.name;
  const currency = price.data.details.totals.currencyCode;
  const total = currencyFormat(price.data.details.totals.total, currency);
  const {type, card} = billingMethod ?? {};

  const paymentUpdate = () => {
    setBusy(true);
    get({
      apiName: "paddleApi",
      path: "/update-payment-method"
    }).response
    .then(res => res.body.text())
    .then(url => {
      const relLink = url.replace(/^.*\/\/[^/]+/, '');
      navigate(relLink);
    })
    .catch(console.log)
    .finally(() => setBusy(false));
  }

  const paymentTypes = {
    apple_pay: "Apple Pay",
    google_pay: "Google Pay",
    paypal: "PayPal",
  };
  return (
    <>
      <CancelationModal
        isOpen={canceling}
        open={open}
        close={close}
        date={nextBilled}
        subscription={subscription}
      />
      <div className={s.billingWrapper}>
        <div className={s.billingRow}>
          <span className={s.key}>Your plan</span>
          <div className={s.value}>
            <span>{name}</span>
          </div>
        </div>
        {nextBilled ? (<>
          <div className={s.billingRow}>
            <span className={s.key}>Next billing</span>
            <div className={s.value}>
              <span>{nextBilled}</span>
              <span>{total}</span>
            </div>
          </div>
          {type === "card" ? (
            <div className={s.billingRow}>
              <span className={s.key}>Payment method</span>
              <div className={s.value}>
                <div className={`${s.card} ${s[card.type]}`} />
                <span>•••• {card.last4}</span>
                <span>Expires {card.expiryMonth}/{card.expiryYear % 100}</span>
              </div>
            </div>
          ) : (
            <div className={s.billingRow}>
              <p className={s.key}>Payment method</p>
              <div className={s.value}>
                <div className={`${s.card} ${s[type]}`} />
                <span>{paymentTypes[type]}</span>
              </div>
            </div>
          )}
          <div className={s.billingRow} >
            <span />
            <div className={s.value}>
              <Button disabled={busy} onClick={paymentUpdate} label="Manage billing" />
              <Button disabled={busy} className={s.alertButton} onClick={open} label="Cancel subscription" />
            </div>
          </div>
        </>) : (
          <CancelationNote subscription={subscription} />
        )}
      </div>
    </>
  )
}

function LoadingSubscription() {
  return (
    <div className={`${s.billingWrapper} ${s.loading}`}>
      <div className={s.billingRow} />
      <div className={s.billingRow} />
      <div className={s.billingRow} />
      <div className={s.billingRow} />
    </div>
  )
}


// Billing history

function BillingHistory({ setBillingMethod }) {
  const [transactions, setTransactions] = useState();
  const user = useAtomValue(userAtom);

  // Fetch transactions
  useEffect(() => {
    const client = generateClient();
    async function fetchTransactions(nextToken) {
      const variables = {
        owner: user.sub,
        sortDirection: "DESC",
        nextToken
      };
      const res = await client.graphql({ query: transactionsByOwner, variables });
      const { items, nextToken: token } = res.data.transactionsByOwner;
      const result = [...items];
      if (token) {
        const nextPage = await fetchTransactions(token);
        result.push(...nextPage);
      }
      return result;
    }
    fetchTransactions()
      .then((items) => {
        const invoicableTransactions = items.filter(tr => !tr.paymentMethodChange);
        setTransactions(invoicableTransactions);
        const lastTransaction = items[0];
        if (lastTransaction) {
          setBillingMethod({
            type: lastTransaction.type,
            card: JSON.parse(lastTransaction.card ?? null)
          })
        }
      });
  }, [user, setBillingMethod]);
  
  return (
    <div className={s.section}>
      <h1>Billing history</h1>
      <p className={s.comment}>You can view and download all your previous invoices here. Please note that if you've recently made a payment, it may take some time to show up.</p>
      {(transactions && transactions.length) ? (
        <div className={s.transactionsList}>
          {transactions.map((t, index) => <Transaction key={t.id} index={index} {...t} />)}
        </div>
      ) : transactions ? (
        <NoTransactions />
        ) : (
        <LoadingTransactions />
      )}
    </div>
  )
}

function Transaction(props) {
  const date = dateFormat(props.date, "dd mmm yyyy");
  const total = currencyFormat(props.total, props.currency);
  const [busy, setBusy] = useState(false);

  const onClick = () => {
    if (busy) return;
    setBusy(true);
    get({
      apiName: "paddleApi",
      path: `/invoice/${props.id}`,
    }).response
      .then(res => res.body.text())
      .then(url => writeFileToBrowser("invoice.pdf", url))
      .catch(console.log)
      .finally(() => setBusy(false));
  }

  return (
    <div className={s.transaction} style={{animationDelay: `${props.index * 0.1}s`}}>
      <span>{date}</span>
      <span>{total}</span>
      <Button id={props.id} icon={Download} onClick={onClick} disabled={busy} label={busy ? "Loading..." : "Invoice"} />
    </div>
  )
}

function NoTransactions() {
  return (
    <div className={s.noTransactions}>No transactions found</div>
  )
}
function LoadingTransactions() {
  return (
    <div className={s.loadingTransactions}/>
  )
}

// Cancelation

function CancelationNote({ subscription }) {
  const [busy, setBusy] = useState(false);
  const setUser = useSetAtom(userAtom);

  const change = subscription.scheduledChange;
  if (!change) return null;
  const date = dateFormat(change.effectiveAt, "dd mmm yyyy");

  const onClick = () => {
    setBusy(true);
    post({
      apiName: "paddleApi",
      path: "/subscription/resume"
    }).response
      .then(() => waitForUserUpdate(subscription))
      .then(setUser)
      .catch(console.log)
      .finally(() => setBusy(false));
  }

  return (
    <div className={s.cancelationNote}>
      <p>Your subscription is scheduled to be<br/> canceled on {date}</p>
      <Button className={s.dontCancel} disabled={busy} onClick={onClick} label="Don't cancel" />
    </div>
  )
}

function CancelationModal({ isOpen, close, date, subscription }) {
  const [busy, setBusy] = useState(false);
  const [user, setUser] = useAtom(userAtom);

  const cancel = () => {
    setBusy(true);
    post({
      apiName: "paddleApi",
      path: "/subscription/cancel"
    }).response
    .then(() => waitForUserUpdate(subscription))
    .then(setUser)
    .then(close)
    .catch(console.log)
    .finally(() => setBusy(false));
  }
  
  return (
    <Modal
      isOpen={isOpen}
      title="Cancel subscription?"
      close={close}
      subtitle={() => (
        <>
          <p>You will keep using Deltaplan until {date}.</p>
          {hasLifetime(user) ? (
            <p>After that, you'll lose monthly AI credits and will be restricted to the Lifetime plan features.</p>
          ) : (
            <p>After that, the limits of the Starter plan will be applied. Documents outside of these limits will be unavailable.</p>
          )}
        </>
      )}
    >
      <ModalFooter>
        <ModalButton disabled={busy} onClick={close}>Keep plan</ModalButton>
        <ModalButton disabled={busy} onClick={cancel} alert>Cancel subscribtion</ModalButton>
      </ModalFooter>
    </Modal>
  )
}

function waitForUserUpdate(subscription) {
  return new Promise((resolve) => {
    const original = JSON.stringify(subscription);
    const fetchUpdate = async () => {
      const user = await fetchUserAttributes();
      if (user["custom:premium"] === original) {
        setTimeout(fetchUpdate, 3000);
      } else {
        resolve(user);
      }
    }
    setTimeout(fetchUpdate, 2000);
  })
}