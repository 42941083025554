import { useEffect, useState } from "react";
import { signIn, signOut, updatePassword } from "aws-amplify/auth";
import { Modal, Input, ModalMessage, Button } from "components";
import { ModalButton, ModalFooter } from "components/Modal";
import s from "./General.module.css";
import { useToggle } from "utils";


export default function PasswordInput({ email, setMessage }) {
  const [modal, openModal, closeModal] = useToggle(false);

  return (
    <>
      <PasswordModal isOpen={modal} close={closeModal} email={email} setMessage={setMessage} />
      <div className={s.row}>
        <Input
          className={s.input}
          label={"Password"}
          type={"text"}
          defaultValue={"••••••••"}
          disabled
          readOnly
        />
        <Button className={s.button} label={"Edit..."} onClick={openModal} disabled={modal} />
      </div>
    </>
  )
}


function PasswordModal({ isOpen, close, email, setMessage }) {
  const [error, setError] = useState();
  const [isBusy, setBusy] = useState(false);

  useEffect(() => {
    setError(null);
  }, [isOpen]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const oldPassword = data.get("current-password");
    const newPassword = data.get("new-password");

    if (!oldPassword) {
      setError("NoPassword");
      return;
    } else if (oldPassword.length < 6) {
      setError("ShortPassword");
      return;
    } else if (!newPassword) {
      setError("NoNewPassword");
      return;
    } else if (newPassword.length < 6) {
      setError("ShortNewPassword");
      return;
    }

    setBusy(true);
    try {
      await updatePassword({ oldPassword, newPassword });
      await signOut({ global: true }); /* Signout others */
      await new Promise((resolve) => setTimeout(resolve, 2000)); /* Wait for Chrome */
      await signIn({
        username: email,
        password: newPassword,
      }); /* Get new tokens */
      close();
      setMessage({ message: "Password changed" });
    } catch (error) {
      setError(error.name);
    } finally {
      setBusy(false);
    }
  }

  return (
    <Modal isOpen={isOpen} close={close} title="Change Password">
      <form onSubmit={onSubmit}>
        <input
          hidden
          defaultValue={email}
          type="email"
          name="email"
          autoComplete="username"
        />
        <Input
          className={s.modalInput}
          id="current-pasword"
          label="Current Password"
          name="current-password"
          autoComplete="current-password"
          type="password"
          autoFocus
        />
        <Input
          className={s.modalInput}
          id="new-password"
          label="New Password"
          name="new-password"
          autoComplete="new-password"
          type="password"
        />
        <ModalMessage value={error} options={options} />
        <ModalFooter>
          <ModalButton disabled={isBusy} onClick={close}>Cancel</ModalButton>
          <ModalButton disabled={isBusy}>Change Password</ModalButton>
        </ModalFooter>
      </form>
    </Modal>
  )
}

const options = {
  NotAuthorizedException: "Wrong password. Please try again.",
  NoPassword: "Password is required.",
  NoNewPassword: "New password is required.",
  ShortPassword: "Password has to be at least 6 characters long.",
  ShortNewPassword: "New password has to be at least 6 characters long.",
  InvalidPasswordException: "Password has to be at least 6 characters long.",
  LimitExceededException: "Attempt limit exceeded. Please try after some time.",
  default: "We are sorry, but it seems there is a problem on our side. Please try again later."
}