import { useEffect, useRef, useState } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { isEqual } from "lodash";
import { columnAtomsAtom, pagePaddingAtom, ptAtom, dragColumnsAtom, historyAtom, columnEdgeGuideAtom, timelineColumnIndexAtom, editColumnAtom, FIELDS, timelinePaddingAtom, optionKeyAtom } from "store";
import { ReactComponent as Icon } from "../../../../../img/icon/padding-control.svg";

import s from "./ColumnControls.module.css";


export default function ColumnControls() {
  const [columns, dispatch] = useAtom(columnAtomsAtom);
  const [padding, setPadding] = useAtom(pagePaddingAtom);
  const pt = useAtomValue(ptAtom);
  const optionKey = useAtomValue(optionKeyAtom);
  const drag = useAtomValue(dragColumnsAtom);
  const onChangePre = upd => setPadding(optionKey ? [upd, upd] : [upd, padding[1]]);
  const onChangePost = upd => setPadding(optionKey ? [upd, upd] : [padding[0], upd]);

  const cn = [
    s.wrapper,
    drag && s.drag
  ].filter(Boolean).join(" ");

  return (
    <div className={cn} id="column-controls">
      <div className={s.padding} style={{ width: padding[0] * pt }}>
        <Resizer
          reverse
          index={-1}
          width={padding[0]}
          onChange={onChangePre}
        />
      </div>
      <div className={s.columnsWrapper}>
        { columns.map( (atom, index) => (
        <Column
          key={`${atom}`}
          atom={atom}
          index={index}
          move={ (to) => dispatch({ type: 'move', atom, before: columns[to] })}
        />
        )) }
      </div>  
      <div className={s.padding} style={{ width: padding[1] * pt }}>
        <Resizer
          index={columns.length}
          width={padding[1]}
          onChange={onChangePost}
        />
      </div>
    </div>
  )
}

function Resizer({ index, width, onChange, padding, reverse }) {
  const optionKey = useAtomValue(optionKeyAtom);
  const pt = useAtomValue(ptAtom);
  const setHistory = useSetAtom(historyAtom);
  const setColumnEdgeGuide = useSetAtom(columnEdgeGuideAtom)
  const [start, setStart] = useState();
  const [delta, setDelta] = useState(0);
  const capture = useRef();
  const prevChanges = useRef({ delta, optionKey });

  useEffect(() => {
    const changes = { delta, optionKey };
    if (isEqual(prevChanges.current, changes) || !start) return;

    const result = start.width - delta;
    onChange(result);
    prevChanges.current = changes;

  }, [optionKey, start, delta, onChange])
  
  const onPointerDown = (e) => {
    e.stopPropagation();
    capture.current = true;
    e.currentTarget.setPointerCapture(e.pointerId);
    setStart({ width, pos: e.clientX });
    setDelta(0);
    prevChanges.current = { delta, optionKey };
    document.body.classList.toggle("no-transition", true);
  }
  const onPointerUp = (e) => {
    if (!capture.current) return;
    capture.current = false;
    const suffix = [
      index,
      padding && "padding",
      optionKey && "both"
    ].filter(Boolean).join("-");
    setHistory(`column-padding-resize-${suffix}`);
    setStart(null);
    document.body.classList.toggle("no-transition", false);
  }
  const onPointerMove = (e) => {
    if (!capture.current) return;
    const factor = reverse ? -1 : 1;
    const delta = Math.round((e.clientX - start.pos) / pt * factor);
    setDelta(delta);
  }
  const onMouseEnter = () => setColumnEdgeGuide({ index, reverse, padding });
  const onMouseLeave = () => setColumnEdgeGuide( null );

  const className = [
    s.resizer,
    reverse && s.reverse,
    padding && s.paddingControl
  ].filter(Boolean).join(" ");

  return (
    <div
      className={className}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      onLostPointerCapture={onPointerUp}
      onPointerMove={onPointerMove}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {padding && (
        <div className={s.icon} >
          <Icon />
        </div>
    )}
    </div>
  )
}

function Column({ atom, move, index }) {
  const { width, field, id } = useAtomValue( atom );
  const [ drag, setDrag ] = useAtom(dragColumnsAtom);
  const timelineColumnIndex = useAtomValue(timelineColumnIndexAtom);
  const pt = useAtomValue( ptAtom );
  const editColumn = useSetAtom(editColumnAtom);
  const setHistory = useSetAtom(historyAtom);
  const [ start, setStart ] = useState();
  const [ x, setX ] = useState();
  const capture = useRef(false);
  const dragTargets = useRef();

  const dragStart = (e) => {
    const target = e.currentTarget;
    const siblings = [...target.parentElement.parentElement.children];
    const { width, left } = target.getBoundingClientRect();
    const offset = document.getElementById("column-controls").getBoundingClientRect().left;
    dragTargets.current = siblings
    .filter( el => el.children[0] !== target )
    .map( el => el.getBoundingClientRect() )
    .map( rect => rect.left + rect.width / 2 - left )
    .map( (pos, i) => i >= index ? pos - width : pos );
    setDrag({ width, id, left: left - offset, from: index, to: index, delta: 0 });
  }

  const onPointerDown = (e) => {
    capture.current = true;
    e.currentTarget.setPointerCapture(e.pointerId);
    setStart({
      time: Date.now(),
      pos: e.clientX,
    })
    dragStart(e);
  }
  const onPointerUp = () => {
    if (!capture.current) return;
    capture.current = false;
    setStart(null);
    setX(0);
    setDrag(null);
    if ( drag.to !== index ) {
      move( drag.to > index ? drag.to + 1 : drag.to );
      setHistory("move-column");
    }
  }
  const onPointerMove = (e) => {
    if (!capture.current) return;
    const pos = e.clientX - start.pos
    setX( pos );
    const index = dragTargets.current.findLastIndex( el => el < pos) + 1;
    setDrag({...drag, to: index, delta: pos});
  }

  const onWidthChange = width => editColumn({ atom, width });

  const isMain = field === FIELDS.TASKS;
  const style = isMain ? {
    flexGrow: 1,
  } : {
    width: width * pt,
  };
  if (x) {
    style.transform = `translateX(${x}px)`;
  } else if (drag) {
    if (drag.from < index && drag.to >= index) {
      style.transform = `translateX(${-drag.width}px)`;
    }
    if (drag.from > index && drag.to <= index) {
      style.transform = `translateX(${drag.width}px)`;
    }
  }

  const className = [
    s.column,
    drag && !start  && s.transition,
  ].filter(Boolean).join(" ")

  return (
    <div className={className} style={style}>
      {isMain ? (
        <TimelineGrabber
          index={index}
          onPointerDown={onPointerDown}
          onPointerMove={onPointerMove}
          onPointerUp={onPointerUp}
          onLostPointerCapture={onPointerUp}
        />
      ) : (<>
        <div
          className={s.grabber}
          onPointerDown={onPointerDown}
          onPointerMove={onPointerMove}
          onPointerUp={onPointerUp}
          onLostPointerCapture={onPointerUp}
        />
        <Resizer
          reverse={index < timelineColumnIndex}
          index={index}
          width={width}
          onChange={onWidthChange}
        />
      </>)}
    </div>
  )
}

function TimelineGrabber({index, ...props}) {
  const [padding, setPadding] = useAtom(timelinePaddingAtom);
  const pt = useAtomValue(ptAtom);
  const optionKey = useAtomValue(optionKeyAtom);

  const onChangePre = upd => setPadding(optionKey ? [upd, upd] : [upd, padding[1]]);
  const onChangePost = upd => setPadding(optionKey ? [upd, upd] : [padding[0], upd]);

  return (
    <div className={s.timelineGrabberWrapper} {...props} >
      <div className={s.padding} style={{width: padding[0] * pt}}>
        <Resizer index={index} reverse padding width={padding[0]} onChange={onChangePre} />
      </div>
      <div className={s.grabber} style={{ flexGrow: 1 }} />
      <div className={s.padding} style={{ width: padding[1] * pt }} >
        <Resizer index={index} padding width={padding[1]} onChange={onChangePost} />
      </div>
    </div>
  )
}