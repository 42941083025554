import { useEffect, useLayoutEffect, useRef, useState } from "react";
import Button from "./Button";
import { ReactComponent as Cross } from "img/icon/cross.svg";
import s from "./Modal.module.css";

export default function Modal({ className, dialogClassName, isOpen, children, close, title, tabIndex, subtitle: sub, lightClose = true, wide, fullscreen, closeButton }) {
  const [ open, setOpen ] = useState(isOpen);
  const ref = useRef();

  useLayoutEffect( () => {
    if (isOpen) {
      setOpen(true);
    } else {
      const modal = ref.current;
      if (!modal) {
        setOpen(false);
        return;
      }
      async function awaitAnimation() {
        modal.close();
        const animationsComplete = () => (
          Promise.allSettled(
            ref.current.getAnimations().map(animation => 
              animation.finished
            )
          )
        );
        await animationsComplete();
        setOpen(false);
      }
      awaitAnimation();
    }
  }, [isOpen])

  useEffect(() => {
    if (!open) return;
    ref.current.showModal();
  }, [open])

  useEffect(() => {
    if (!open) return;
    const modal = ref.current;
    modal.addEventListener("close", close);
    return () => {
      modal.removeEventListener("close", close);
    }
  }, [open, close])

  const onKeyDown = (e) => {
    e.stopPropagation();
    if (e.key === "Escape" && !lightClose) e.preventDefault();
  }
  const onClick = (e) => {
    const isClickOnShadow = e.target.nodeName === 'DIALOG';
    if (!isClickOnShadow || !lightClose) return;
    close();
  }
  
  let Subtitle = () => null;
  if ( typeof(sub) === "function" ) {
    Subtitle = sub;
  } else if ( typeof(sub) === "string" ) {
    Subtitle = () => <p>{ sub }</p>
  }

  const _dialogClassName = [
    s.dialog,
    dialogClassName,
    wide && s.wide,
    fullscreen && s.fullscreen,
  ].filter(Boolean).join(" ");

  const wrapperClassName = [
    s.wrapper, className
  ].filter(Boolean).join(" ");

  return open ? (
    <dialog ref={ref} className={_dialogClassName} onClick={onClick} onKeyDown={onKeyDown}>
      <div tabIndex={tabIndex} className={wrapperClassName}>
        {closeButton && <button
          type="button"
          className={s.closeModal}
          onClick={close}
        >
          <Cross />
        </button>}
        <div className={s.header}>
          {title && <h1>{title}</h1>}
          <Subtitle />
        </div>
        {children}
      </div>
    </dialog>
  ) : null;
}

export function ModalFooter({ children }) {
  return (
    <footer className={`${s.footer} ${s.modalFooter}`}>
      { children }
    </footer>
  )
}

export function FormFooter({ children }) {
  return (
    <footer className={`${s.footer} ${s.formFooter}`}>
      { children }
    </footer>
  )
}

export function ModalButton({ children: label, onClick, alert, primary, ...rest }) {
  const isPrimary = !onClick || alert || primary;
  const className = isPrimary ? alert ? s.alertButton : s.primaryButton : null;
  const type = isPrimary ? "submit" : "button";

  const props = { onClick, className, type, label, ...rest };

  return (
    <Button {...props} />
  )
}