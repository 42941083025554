import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useAsyncError, useRouteError } from "react-router-dom";
import { post } from "aws-amplify/api";
import ls from "localstorage-slim";
import { ReactComponent as Icon} from "img/icon/go-to.svg";
import s from "./RouteError.module.css";


export default function RouteError() {
  const error = useRouteError();

  useEffect(() => {
    const location = document.location.toString();
    const message = error.message || error.data;
    const stack = error.stack;
    // console.log(error.errors[0]);
    // console.log("Location:", location);
    // console.log("Message:", message);
    // console.log("Stack:", stack);

    if (
      (process.env.REACT_APP_ENV === "dev") ||
      (error.status === 403) ||
      (ls.get("error") === location)
    ) {
      return;
    };

    post({
      apiName: "notifier",
      path: "/error",
      options: {
        body: {
          location,
          message,
          stack,
        }
      }
    })
    ls.set("error", location, { ttl: 300 });
  }, [error])

  return <ErrorSwitch error={error} />
}

export function RouteAsyncError() {
  const error = useAsyncError();
  return <ErrorSwitch error={error} />
}

function ErrorSwitch({error = {}}) {
  if (error.status === 404) {
    return (
      <ErrorPage title="The page is missing" seoTitle="Page not found">
        <p>We can't find the page you are looking for.</p>
        <p>Please <a href="https://deltaplan.io/contact/" target="_blank" rel="noreferrer">send us a message</a> if you believe there's an error.</p>
      </ErrorPage>
    );
  } else if (error.status === 403) {
    return (
      <ErrorPage title="Access denied" seoTitle="Access denied">
        <p>It looks like you don't have the necessary permissions to access this page. Double-check your login credentials and try again.</p>
        <p>If you think there's an error, please <a href="https://deltaplan.io/contact/" target="_blank" rel="noreferrer">send us a message</a>.</p>
      </ErrorPage>
    );
  } else {
    return (
      <ErrorPage title="It shouldn't be like this" seoTitle="App error">
        <p>We have been notified of the problem and are working to fix it.</p>
        <p>In the meantime, feel free to <a href="https://deltaplan.io/contact/" target="_blank" rel="noreferrer">contact us</a> if you have any questions or extra details that may help us resolve this.</p>
      </ErrorPage>
    )
  }
}

function ErrorPage({title, children, seoTitle}) {
  return (
    <div className={s.wrapper}>
      <Helmet title={seoTitle ?? "App error"} />
      <h1 className={s.title}>{title}</h1>
      <div className={s.description}>{children}</div>
      <Link className={s.action} to="/">
        <span>To the worksapce</span>
        <Icon className={s.icon} />
      </Link>
    </div>
  )
}