import { milestoneStylesMapAtom, updateMilestoneAtom, editMilestoneStyleAtom, overrideMilestoneAtom, SELECTABLE } from "store";
import { useGetStyles, useStyleEdit } from "utils";
import StylesList from "../../components/StylesList/StylesList";
import Section from "../../components/Section/Section";
import Input from "../../components/Input/Input";
import EditMode from "../../components/EditMode/EditMode";
import DatePicker from "../../components/DatePicker/DatePicker";
import MilestoneAppearance from "./MilestoneAppearance";
import TitleAppearance from "./TitleAppearance";
import DateAppearance from "./DateAppearance";
import Header from "../../components/Header/Header";


export default function Milestone() {
  const [items, styles, isOverriding] = useGetStyles(milestoneStylesMapAtom);
  const editAtom = isOverriding ? overrideMilestoneAtom : editMilestoneStyleAtom;

  return (
    <>
      <div>
        <Header title="Milestone" />
        <MainDetails items={items} />
        <StylesList title="Style" type={SELECTABLE.MILESTONE} />
      </div>
      <div>
        <EditMode />
        <MilestoneAppearance
          styles={styles}
          editAtom={editAtom}
        />
        <TitleAppearance
          styles={styles}
          editAtom={editAtom}
        />
        <DateAppearance
          styles={styles}
          editAtom={editAtom}
        />
      </div>
    </>
  )
}

function MainDetails({ items }) {
  const [
    s, valueChange
  ] = useStyleEdit({
    editAtom: updateMilestoneAtom,
    styles: items,
  });

  return (
    <Section title="Details">
      <div>
        <Input
          title="Title"
          id="milestone-data-title"
          value={s.title.value}
          mixed={s.title.mixed}
          onChange={valueChange("title")}
        />
      </div>
      <div>
        <DatePicker
          title="Date"
          id="milestone-date"
          value={s.date.value}
          mixed={s.date.mixed}
          onChange={valueChange("date")}
        />
      </div>
    </Section>
  )
}