import { useAtomValue } from "jotai"
import { dragColumnsAtom, FIELDS } from "store";
import RowTimeline from "./RowTimeline";
import RowTitle from "./RowTitle";
import RowLabel from "./RowLabel";
import s from "./Row.module.css";


export default function Cell({ atom, columnIndex, rowId, groupStyle, rowStyle, styleId, title, items, temp, innerRef, selected }) {
  const column = useAtomValue( atom );
  const drag = useAtomValue( dragColumnsAtom );

  const isOverlapped = groupStyle?.columns?.[column.id]?.show;
  if (isOverlapped) return null;
  const show = rowStyle.columns?.[column.id]?.show;
  
  const dragTarget = drag && drag.from === columnIndex;
  const style = {gridColumn: `${columnIndex + 1}/${columnIndex + 2}`};
  if (drag) {
    if (dragTarget) {
      style.transform = `translateX(${drag.delta}px)`;
    } else if (drag.from < columnIndex && drag.to >= columnIndex) {
      style.transform = `translateX(${-drag.width}px)`;
    } else if (drag.from > columnIndex && drag.to <= columnIndex) {
      style.transform = `translateX(${drag.width}px)`;
    }
  }
  const isTasks = column.field === FIELDS.TASKS;
  const isTransition = drag && !dragTarget;
  const className = [
    `${styleId}-${column.id}`,
    `override-${rowId}-${column.id}`,
    s.cell,
    isTasks      && s.tasksCell,
    dragTarget   && s.dragCell,
    isTransition && s.cellTransition,
  ].filter(Boolean).join(" ");

  const wrapperProps = { style, className };

  switch (column.field) {
    case FIELDS.TASKS :
      return (
        <div {...wrapperProps} ref={innerRef}>
          <div className={s.cellContent}>
            <RowTimeline id={rowId} items={items} temp={temp} />
          </div>
        </div>
      )
    case FIELDS.TITLE :
      return (
        <div {...wrapperProps}>
          <div className={s.cellContent}>
            {show && <RowTitle id={rowId} title={title} selected={selected} />}
          </div>
        </div>
      )
    default :
      return (
        <div {...wrapperProps}>
          <div className={s.cellContent}>
            {show && <RowLabel items={items} {...column} />}
          </div>
        </div>
      )
  }
}