import { atom } from "jotai";
import { atomWithStorage } from 'jotai/utils';
import { milestonesAtom, minDateAtom, tasksAtom, timeScaleAtom, timelineIndentAtom } from ".";

export const snapDatesAtom = atom( get => (
  [
    ...get(tasksAtom).map( el => [el.start, el.end] ).flat(),
    ...get(milestonesAtom).map( el => el.date )
  ]
));

export const snappedDatesAtom = atom(
  (get) => get(_snappedDatesAtom),
  (get, set, input) => {
    const val = Array.isArray(input) ? input : [input];
    const points = get(snapDatesAtom);
    const result = val.filter(el => points.includes(el));
    set(_snappedDatesAtom, result);
  }
);
const _snappedDatesAtom = atom([]);

export const snappedGuidesAtom = atom( get => {
  const points = get(snappedDatesAtom);
  const indent = get(timelineIndentAtom);
  const start = get(minDateAtom);
  const timeScale = get(timeScaleAtom);
  return points.map(el => indent + (el - start) / timeScale);
});
    
export const snapThresholdAtom = atom( get => get(timeScaleAtom) * 5 );
export const snappingAtom = atomWithStorage("snapping", true);