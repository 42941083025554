import s from "./Group.module.css";
import { ReactComponent as Icon } from "img/icon/fold-arrow.svg";

export default function Toggle({onClick, unfolded, ...rest}) {
  const onPointerDown = e => e.stopPropagation();
  const className = [
    s.button,
    unfolded && s.unfoldedIcon
  ].filter(Boolean).join(" ");
  return (
    <button onPointerDown={onPointerDown} className={className} onClick={onClick} tabIndex={-1} {...rest}>
      <Icon className={s.icon} />
    </button>
  )
}