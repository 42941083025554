import { useState } from 'react';
import { post } from 'aws-amplify/api';
import { countries, regions, taxNumberName, vatRegexMap, zipRegexMap } from './constants';
import { Button, Input, ModalMessage, Toggle } from 'components';
import { MainSelect } from 'components/Select';
import FormRow from 'components/FormRow';
import s from "./Checkout.module.css";


export default function AddressStep({ txnData, onChange = () => { }, onSubmit = () => { } }) {
  const address = txnData?.customer?.address;
  const business = txnData?.customer?.business;
  const [country, setCountry] = useState(address?.country_code ?? "");
  const [zip, setZip] = useState(address?.postal_code ?? "");
  const [region, setRegion] = useState(address?.region ?? "");
  const [addVat, setAddVat] = useState(!!business);
  const [vat, setVat] = useState(business?.tax_identifier ?? "");
  const [company, setCompany] = useState(business?.name ?? "");
  const [street, setStreet] = useState(address?.first_line ?? "");
  const [city, setCity] = useState(address?.city ?? "");
  const [isBusy, setBusy] = useState(false);
  const [error, setError] = useState();

  const zipRegex = zipRegexMap[country];
  const vatRegex = vatRegexMap[country];
  const askRegion = country === "AE";
  const askZip = !!zipRegex;
  const askVat = !!vatRegex;

  // Submit address
  const handleSubmit = (e) => {
    e.preventDefault();

    let error = "";
    if (!country) {
      error = "NoCountry";
    } else if (askZip && !zip) {
      error = "NoZip";
    } else if (askZip && !zipRegex.test(zip)) {
      error = "WrongZip";
    } else if (askRegion && !region) {
      error = "NoRegion";
    } else if (addVat && !vat) {
      error = "NoVAT";
    } else if (addVat && !vatRegex.test(vat)) {
      error = "WrongVAT";
    } else if (addVat && !company) {
      error = "NoCompany";
    } else if (addVat && !street) {
      error = "NoStreet";
    } else if (addVat && !city) {
      error = "NoCity";
    } else if (addVat && !region) {
      error = "NoRegion";
    }
    if (error) {
      setError(error);
      return;
    }

    setBusy(true);
    const body = { country };
    if (askZip) body.zip = zip;
    if (askRegion) body.region = region;
    if (addVat) Object.assign(body, {
      vat, company, street, city, region
    });
    post({
      apiName: "paddleApi",
      path: "/billing",
      options: { body },
    }).response
      .then(res => res.body.json())
      .then(onSubmit)
      .catch(console.log)
      .finally(() => setBusy(false));
  }

  // Edits
  const updateAddress = (country, zip) => {
    onChange({
      countryCode: country,
      postalCode: zip
    })
  }
  const onCountryChange = (val) => {
    if (val === country) return;
    setError("");
    setZip("");
    setRegion("");
    setAddVat(false);
    setVat("");
    setCompany("");
    setStreet("");
    setCity("");
    setCountry(val);
    updateAddress(val, "");
  }
  const onZipBlur = (e) => {
    updateAddress(country, e.target.value);
  }
  const onZipChange = (e) => {
    setZip(e.target.value.toUpperCase());
    if (error === "NoZip" || error === "WrongZip") setError(null);
  }
  const onRegionSelect = (val) => {
    setRegion(val);
    if (error === "NoRegion") setError(null);
  }
  const onAddVatChange = () => {
    setAddVat(!addVat);
    if (["NoVAT", "WrongVAT", "NoCompany", "NoStreet", "NoCity", "NoRegion"].includes(error)) setError(null);
  }
  const onVatChange = (e) => {
    setVat(e.target.value.toUpperCase());
    if (["NoVAT", "WrongVAT"].includes(error)) setError(null);
  }
  const onCompanyChange = (e) => {
    setCompany(e.target.value);
    if (error === "NoCompany") setError(null);
  }
  const onStreetChange = (e) => {
    setStreet(e.target.value);
    if (error === "NoStreet") setError(null);
  }
  const onCityChange = (e) => {
    setCity(e.target.value);
    if (error === "NoCity") setError(null);
  }
  const onRegionChange = (e) => {
    setRegion(e.target.value);
    if (error === "NoRegion") setError(null);
  }


  const errorsMap = {
    NoCountry: "Select Country",
    NoZip: "Enter your ZIP/Postcode",
    WrongZip: "Enter a valid ZIP/Postcode",
    NoVAT: () => `${taxNumberName(country)} is required`,
    WrongVAT: () => `Enter a valid ${taxNumberName(country)}`,
    NoCompany: "Enter Company name",
    NoStreet: "Enter Street",
    NoCity: "Enter City",
    NoRegion: "Enter State/Region",
  };

  return (
    <form className={s.addressStep} onSubmit={handleSubmit}>
      <p className={s.comment}>Please enter your details. This information helps us prevent fraud and keep your payments secure.</p>
      <MainSelect
        title="Country"
        placeholder=""
        value={country}
        onChange={onCountryChange}
        options={countries}
        isSearchable={true}
      />
      {askZip && <Input
        className={s.modalInput}
        value={zip}
        onChange={onZipChange}
        onBlur={onZipBlur}
        id="zip"
        label="ZIP/Postcode"
        name="zip"
        autoComplete="zip"
      />}
      {askRegion && <MainSelect
        title="Region"
        placeholder=""
        selected={region}
        onChange={onRegionSelect}
        options={regions}
        isSearchable={true}
      />}
      {askVat && (
        <FormRow>
          <Toggle
            checked={addVat}
            onChange={onAddVatChange}
            title={`Add ${taxNumberName(country)} ${country !== "JP" ? "(for companies)" : ""}`}
            primary
          />
        </FormRow>
      )}
      {(askVat && addVat) && (<>
        <Input
          className={s.modalInput}
          value={vat}
          onChange={onVatChange}
          label={taxNumberName(country)}
          id="vat"
          name="vat"
        />
        <Input
          className={s.modalInput}
          value={company}
          onChange={onCompanyChange}
          label="Company name"
          id="company"
          name="company"
        />
        <Input
          className={s.modalInput}
          value={street}
          onChange={onStreetChange}
          label="Street"
          id="street"
          name="street"
        />
        <Input
          className={s.modalInput}
          value={city}
          onChange={onCityChange}
          label="City"
          id="city"
          name="city"
        />
        {!askRegion && (
          <Input
            className={s.modalInput}
            value={region}
            onChange={onRegionChange}
            label="State/Region"
            id="state"
            name="state"
          />
        )}
      </>)}
      <ModalMessage value={error} options={errorsMap} />
      <Button className={s.submit} disabled={isBusy} label={"To Payment"} />
    </form>
  )
};