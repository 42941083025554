import { useRef, useState } from "react";
import merge from "lodash/merge";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { addSelectedAtom, editingAtom, historyAtom, mapAtom, moveGroupAtom, rowDragAtom, selectedAtom, groupDragAtom, groupsAtom, updateGroupAtom, columnAtomsAtom, groupStylesMapAtom, ptAtom, groupBorderWidthAtom, groupBorderShowAtom, lockedAtom, GROUP_TYPES } from "store";
import Row from "../Row/Row";
import Toggle from "./Toggle";
import Cell from "./Cell";

import s from "./Group.module.css";

function Group( props ) {
  const { children=[], title, unfolded, styleId, index, id, overrides } = props;

  const dropTargets = useRef();
  const [ startY, setStartY ] = useState();
  const [ deltaY, setDeltaY ] = useState(0);

  const isLocked = useAtomValue(lockedAtom);
  const map = useAtomValue(mapAtom);
  const rowDrag = useAtomValue(rowDragAtom);
  const columns = useAtomValue(columnAtomsAtom);
  const pt = useAtomValue(ptAtom);
  const groupBorderWidth = useAtomValue(groupBorderWidthAtom);
  const groupBorderShow = useAtomValue(groupBorderShowAtom);
  const addSelected = useSetAtom(addSelectedAtom);
  const updateGroup = useSetAtom(updateGroupAtom);
  const moveGroup = useSetAtom(moveGroupAtom);
  const setHistory = useSetAtom(historyAtom);
  const groupStyles = useAtomValue(groupStylesMapAtom);
  const [ groupDrag, setGroupDrag ] = useAtom(groupDragAtom);
  const [ selected, setSelected ] = useAtom(selectedAtom);
  const [ isEditing, setEditing ] = useAtom(editingAtom);

  const style = merge({}, groupStyles[styleId], overrides);
 
  const isRoot = id === "root";
  const isSwimlane = style.type === GROUP_TYPES.SWIMLANE && unfolded && !isRoot;

  const select = (e) => {
    e.stopPropagation();
    setEditing(false);
    if (e.shiftKey || e.metaKey) {
      addSelected(id);
    } else if (!selected.includes(id)) {
      setSelected(id);
    }
  }

  const toggle = () => {
    updateGroup({id, unfolded: !unfolded});
    setHistory(`group-toggle-${id}`);
  }

  // Drag'n'drop
  
  const groups = useAtomValue(groupsAtom);
  const onPointerDown = (e) => {
    if (isLocked) return;
    select(e);
    e.target.setPointerCapture(e.pointerId);

    setStartY(e.clientY);

    const targetSet = document.getElementById(id).getBoundingClientRect();
    dropTargets.current = groups.reduce( (acc, {id}, i) => {
      if ( id === props.id || id === "root" ) return acc;

      const { top, height } = document.getElementById(id).getBoundingClientRect();
      let pos = top + height / 2 - targetSet.top;
      if (i > index) pos -= targetSet.height;
      acc.push(pos);
      return acc;
    }, []);

    setGroupDrag({
      id,
      fromIndex: index,
      toIndex: index,
      height: targetSet.height + groupBorderWidth * groupBorderShow * pt,
    })
  }

  const onPointerMove = (e) => {
    if (!dropTargets.current) return;

    const y = e.clientY - startY;
    const toIndex = dropTargets.current.findLastIndex( pos => pos < y ) + 2;
    setGroupDrag({ ...groupDrag, toIndex });
    setDeltaY(y);
  }

  const onPointerUp = () => {
    if (!dropTargets.current) return;

    setGroupDrag(null);
    setStartY(null);
    setDeltaY(0);
    dropTargets.current = null;
    applyChanges();
  }
  

  const applyChanges = () => {
    const {toIndex} = groupDrag;
    if ( toIndex === index ) return;
    
    moveGroup({id, index: toIndex});
    setHistory();
  }

  // Styles
  const headerStyle = {};
  const borderStyle = {};

  if ( rowDrag ) {

    const { from, to, delta } = rowDrag;
    
    // Header resize
    if (isSwimlane) {
      let bottom = 0;
      if ( from.groupIndex === index ) {
        bottom += delta;
      }
      if ( to.groupIndex === index ) {
        bottom -= delta;
      }
      headerStyle.bottom = `${bottom}px`;
    }
    
    // Heading translate
    let translateY = 0;
    if ( from.groupIndex < index ) {
      translateY -= delta;
    }
    if ( to.groupIndex < index ) {
      translateY += delta;
    }
    headerStyle.transform = `translateY(${translateY}px)`;

    // Border translate
    let borderTranslate = 0;
    if (from.groupIndex <= index) {
      borderTranslate -= delta;
    }
    if (to.groupIndex <= index) {
      borderTranslate += delta;
    }
    borderStyle.transform = `translateY(${borderTranslate}px)`;

  }

  // Drag style
  const groupStyle = {};

  if ( groupDrag && id !== "root" ) {
      let translateY = 0;

      if (groupDrag.id !== id) {
        const { fromIndex, toIndex, height } = groupDrag;
        const index = fromIndex < props.index ? props.index - 1 : props.index;
        if ( fromIndex <= index) translateY -= height;
        if ( toIndex <= index ) translateY += height;
      } else {
        translateY = deltaY;
      }

      groupStyle.transform = `translateY(${translateY}px)`;
  }

  // Classes
  const isSelected = !isEditing && selected.includes(id);
  const hasTransitions = groupDrag && groupDrag.id !== id;
  const className = [
    s.group,
    styleId,
    `override-${id}`,
    !unfolded ? s.folded : isSwimlane ? s.swimlane : s.heading,
    rowDrag         && s.rowDrag,
    deltaY          && s.drag,
    hasTransitions  && s.dragTransitions,
    !children.length && s.empty,
  ].filter(Boolean).join(" ");


  const rows = children.map(id => map.get(id)).filter(Boolean);
  const items = rows.map( row => row.children ).flat().map( id => map.get(id));

  return(
    <div id={id} className={className} style={groupStyle}>
      {!isRoot && (
        <>
        <div
          className={s.header}
          style={headerStyle}
          onPointerDown={onPointerDown}
          onPointerMove={onPointerMove}
          onPointerUp={onPointerUp}
          onLostPointerCapture={onPointerUp}
          onDoubleClick={toggle}
        >
          <div className={s.cellsWrapper}>
            {columns.map( (atom, columnId) => (
              <Cell
                key={`${atom}`}
                atom={atom}
                columnId={columnId}
                groupId={id}
                styleId={styleId}
                style={style}
                title={title}
                items={items}
                selected={isSelected}
                swimlane={isSwimlane}
              />
            ))}
          </div>
        </div>
        <Toggle style={headerStyle} onClick={toggle} unfolded={unfolded} />
        </>
      )}
      {(unfolded || isRoot) && (
        <div className={s.rowsWrapper}>
          {rows.map(( { style: styleId, children, ...row }, i ) => 
            <Row
              key={row.id}
              index={i}
              groupId={id}
              groupStyle={isSwimlane ? style : null}
              groupIndex={index}
              childItems={children}
              styleId={styleId}
              {...row}
            />
          )}
        </div>
      )}
      <div className={s.border} style={borderStyle} />
      {isSelected && <div className={s.selectFrame} />}
    </div>
  )
}

export default Group;