import ReactDatePicker from "react-datepicker";
import { flip } from "@floating-ui/dom";
import { TIMELINE_UNITS } from "store";
import s from "./DatePicker.module.css";
import "./DatePicker.css";

export default function DatePicker({ title, id, value, onChange, minDate: propMinDate, maxDate: propMaxDate, isLast, format, mixed, ...rest }) {
  
    let dateFormat;
    let shiftDate = () => {};
    let roundDate = () => {};
    switch (format) {
      case TIMELINE_UNITS.YEARS :
        dateFormat = "y";
        roundDate = (d) => d.setUTCMonth(0, 1);
        shiftDate = (d) => d.setUTCMonth(12, 1);
        break;
      case TIMELINE_UNITS.QUARTERS :
        dateFormat = "QQQ y";
        roundDate = (d) => d.setUTCMonth(Math.floor(d.getUTCMonth() / 3) * 3, 1);
        shiftDate = (d) => d.setUTCMonth(d.getUTCMonth() + 3);
        break;
      case TIMELINE_UNITS.MONTHS :
        dateFormat = "MMM y";
        roundDate = (d) => d.setUTCDate(1);
        shiftDate = (d) => d.setUTCMonth(d.getUTCMonth() + 1);
        break;
      default :
        dateFormat = "d MMM y";
        roundDate = (d) => d.setUTCHours(0,0,0,0);
        shiftDate = (d) => d.setUTCHours(24);
        break;
    }

  const onInput = (val) => {
    if (!val) return;
    const d = convertLocalToUTC(val);
    if (isLast) { shiftDate(d) }
    const result = d.valueOf();
    onChange(result);
  }

  let selected = new Date(value);
  let minDate = new Date(propMinDate || (Date.now() - 36500 * 24 * 60 * 60 * 1000));
  let maxDate = new Date(propMaxDate || (Date.now() + 36500 * 24 * 60 * 60 * 1000));
  if (isLast) {
    selected.setUTCHours(-24);
    minDate.setUTCHours(-24);
    maxDate.setUTCHours(-24);
  }
  roundDate(selected);
  roundDate(minDate);
  roundDate(maxDate);
  selected = convertUTCToLocal(selected);
  minDate = convertUTCToLocal(minDate);
  maxDate = convertUTCToLocal(maxDate);

  return (
    <div className={s.wrapper} {...rest}>
      <label className={s.label} htmlFor={id}>{title}</label>
      <ReactDatePicker
        id={id}
        className={s.input}
        selected={mixed ? null : selected}
        placeholderText={mixed ? "Mixed" : ""}
        autoComplete="off"
        dateFormat={dateFormat}
        onChange={onInput}
        minDate={minDate}
        maxDate={maxDate}
        showPopperArrow={false}
        calendarStartDay={1}
        strictParsing
        showMonthYearPicker={format === TIMELINE_UNITS.MONTHS}
        showQuarterYearPicker={format === TIMELINE_UNITS.QUARTERS}
        showYearPicker={format === TIMELINE_UNITS.YEARS}
        popperProps={{
          placement: "bottom-start",
          middleware: [flip()]
        }}
      />
    </div>
  )
}

function convertUTCToLocal(date) {
  if (!date) {
    return date;
  }
  const temp = new Date(date);
  const result = new Date(temp.getUTCFullYear(), temp.getUTCMonth(), temp.getUTCDate());
  return result;
}

function convertLocalToUTC(date) {
  if (!date) {
    return date;
  }
  const temp = new Date(date);
  const result = new Date(Date.UTC(temp.getFullYear(), temp.getMonth(), temp.getDate()));
  return result;
}