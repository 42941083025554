import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { hexToHsv, hsvToHex, hsvaToHexCss } from "utils";
import SketchPicker from "@uiw/react-color-sketch";
import Input from "../Input/Input";
import ViewToggle from "../ViewToggle/ViewToggle";
import s from "./ColorPicker.module.css";


function ColorPicker({ title = "", id, disabled, mixedDisabled, color, onChange, hexInput = true, onToggle, alwaysOn, mixed, ...rest }) {
  const picker = useRef();
  const button = useRef();
  const [tempHex, setTempHex] = useState(mixed ? "" : hsvToHex(color));
  const [isShow, setShow] = useState(false);
  const [isClickInsde, setClickInside] = useState(false);
  const [coord, setCoord] = useState([0,0]);

  
  // Fit in screen
  useLayoutEffect(() => {
    if (!isShow) {
      button.current.blur();
      return;
    };
    const p = picker.current.getBoundingClientRect();
    const b = button.current.getBoundingClientRect();
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const pagePadding = 8;

    const xMax = screenWidth - p.width - pagePadding;
    const yMax = screenHeight - p.height - pagePadding;
    const yMin = pagePadding;

    const below = b.bottom + 2;
    const above = b.top - p.height - 2;

    const x = Math.min(b.left - 1, xMax);
    const y = (below < yMax) ? below : (above > yMin) ? above : yMax;
    setCoord([x, y]);
  }, [isShow])

  // Close listener
  useEffect(() => {
    const handler = (e) => {
      if (isClickInsde) {
        setClickInside(false);
        document.addEventListener("click", handler, { once: true, capture: true });
      } else {
        e.preventDefault();
        e.stopPropagation();
        setShow(false);
      }
    }
    if (isShow) {
      document.addEventListener("click", handler, { once: true, capture: true });
    }
    return () => {document.removeEventListener("click", handler, true)}
  }, [isShow, isClickInsde])

  // Update hex values
  useEffect(() => setTempHex(mixed ? "" : hsvToHex(color)), [color, mixed])

  // Open
  const onClick = () => {
    if (disabled) return;
    setShow(!isShow);
  }
  // Prevent document listener if on input
  const onPointerDown = () => {
    isShow && setClickInside(true);
  }

  // Hex input handler
  const onHexBlur = () => {
    const hsva = hexToHsv(tempHex);
    if (!hsva) {
      setTempHex(mixed ? "" : hsvToHex(color));
      return;
    }
    const originalHex = hsvToHex(color);
    const newHex = hsvToHex(hsva);
    if (originalHex !== newHex || mixed ) {
      hsva.a = color.a;
      onChange({ hsva });
    }
  }

  // On key close picker
  const onKeyDown = (e) => {
    if (e.key !== "Escape") return;
    if (isShow) {
      setShow(false);
    }
  }

  const toggle = onToggle || alwaysOn;

  const className = [
    s.wrapper,
    toggle    && s.wide,
    hexInput  && s.extended,
    disabled  && s.disabled,
    isShow    && s.opened,
    mixed     && s.mixed,
  ].filter(Boolean).join(" ");

  const swatch = hsvaToHexCss(color);

  return (
    <div className={className} {...rest}>
      <label className={s.label} htmlFor={id}>{title}</label>
      <div className={s.row}>
        { toggle && <ViewToggle value={!disabled} mixed={mixedDisabled} onChange={onToggle} disabled={alwaysOn} />}
        <div className={s.controlsWrapper}>
          <button ref={button} className={s.toggle} type="button" disabled={disabled} onClick={onClick} onPointerDown={onPointerDown} onKeyDown={onKeyDown} >
            <div className={s.swatch} style={{"--swatch": swatch}} />
          </button>
          { hexInput && (
            <Input
              id={id}
              className={s.hex}
              value={tempHex}
              placeholder={mixed ? "Mixed" : ""}
              onChange={setTempHex}
              onBlur={onHexBlur}
              disabled={disabled}
            />
          )}
        </div>
      </div>
      {isShow ? (
        createPortal((
          <div tabIndex={0} onKeyDown={onKeyDown} ref={picker} className={s.picker} onPointerDown={onPointerDown} style={{left: `${coord[0]}px`, top: `${coord[1]}px`}}>
            <SketchPicker
              prefixCls={s.colorPicker}
              presetColors={[]}
              onChange={onChange}
              color={color}
            />
          </div>
        ),
          document.body,
        )
      ) : null}
    </div>
  )
}

export default ColorPicker;