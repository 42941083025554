import { NavLink } from "react-router-dom";
import s from "./Nav.module.css";

export default function Nav() {
  const className = ({isActive}) => [
    isActive && s.active,
    s.link
  ].filter(Boolean).join(" ");
  return (
    <nav className={s.wrapper}>
      <NavLink className={className} to={"/settings"} end>General</NavLink>
      <NavLink className={className} to={"/settings/preferences"}>Preferences</NavLink>
      <NavLink className={className} to={"/settings/billing"}>Billing</NavLink>
    </nav>
  )
}