import { useEffect, useMemo, useRef, useState } from "react";
import { fetchUserAttributes, updateUserAttribute } from "aws-amplify/auth";
import { useAtom } from "jotai";
import { userAtom } from "store";
import { getUserPrefs } from "utils";
import { isEqual } from "lodash";
import { Helmet } from "react-helmet";
import { Input, Segmented, CheckboxGroup } from "components";
import s from "./Preferences.module.css";


export default function Preferences() {
  const [user, setUser] = useAtom(userAtom);
  const value = useMemo(() => getUserPrefs(user), [user]);

  const [workdays, setWorkdays] = useState(value.workdays);
  const [placement, setPlacement] = useState(value.currencyPlacement);
  const [sign, setSign] = useState(value.currencySign);
  const [busy, setBusy] = useState(false);
  const timeout = useRef();

  useEffect(() => {
    setBusy(true);
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    const result = {
      workdays: workdays,
      currencyPlacement: placement,
      currencySign: sign
    }
    if (isEqual(result, value)) {
      setBusy(false);
      return;
    }
    timeout.current = setTimeout(
      () => {
        updateUserAttribute({
          userAttribute: {
            attributeKey: "custom:preferences",
            value: JSON.stringify(result)
          }
        })
          .then(fetchUserAttributes)
          .then(setUser)
          .catch(console.log)
          .finally(() => setBusy(false));
      }, 1000
    )
  }, [value, workdays, placement, sign, setUser]);


  return (
    <div id="preferences">
      <Helmet title="Preferences" />
      <div className={s.header}>
        <h1>Preferences</h1>
        {busy && <p className={s.busyMarker}>Saving...</p>}
      </div>
      <CurrencyInput
        placement={placement}
        onPlacementChange={setPlacement}
        currency={sign}
        onCurrencyChange={setSign}
      />
      <WorkdaysInput value={workdays} onChange={setWorkdays} />
    </div>
  )
}

function WorkdaysInput({ value, onChange }) {
  const handleChange = (val) => {
    const result = val.map(op => op.value);
    result.unshift(result.pop());
    onChange(result);
  };
  const options = [
    {
      value: value[1],
      label: "Monday"
    }, {
      value: value[2],
      label: "Tuesday"
    }, {
      value: value[3],
      label: "Wednesday"
    }, {
      value: value[4],
      label: "Thursday"
    }, {
      value: value[5],
      label: "Friday"
    }, {
      value: value[6],
      label: "Saturday"
    }, {
      value: value[0],
      label: "Sunday"
    }
  ];

  return (
    <CheckboxGroup
      label="Working days"
      options={options}
      onChange={handleChange}
    />
  )
}

function CurrencyInput({ placement, onPlacementChange, currency, onCurrencyChange }) {
  const handlePlacement = (val) => {
    onPlacementChange(val);
  }
  const handleCurrency = (e) => {
    const pos = e.target.selectionStart;
    onCurrencyChange(e.target.value.slice(pos - 1, pos));
  }
  return (
    <>
      <p className={s.label}>Currency sign</p>
      <div className={s.currencyInput}>
        <Segmented
          onChange={handlePlacement}
          value={placement}
          showLabels
          className={s.segmented}
          options={[
            { value: "prefix", label: "Prefix" },
            { value: "suffix", label: "Suffix" }
          ]}
        />
        <Input
          value={currency}
          onChange={handleCurrency}
        />
      </div>
    </>
  )
}