import { vxDate } from "utils";
import s from "./Task.module.css";

export default function RangeTooltip({start, end, show}) {

  const startRounded = vxDate.start(start, end);
  const endRounded = vxDate.end(start, end);

  const startLabel = vxDate.format(startRounded, "ddd, dd mmm yyyy");
  const endLabel = vxDate.format(endRounded, "ddd, dd mmm yyyy", true);

  return show ? (
    <div className={s.rangeHelper}>
      {startLabel} – {endLabel}
    </div>
  ) : null;
}