import s from "./Toggle.module.css";

export default function Toggle({ className, title, checked, mixed, primary, ...rest }) {
  const cn = [
    className, s.wrapper,
    mixed && s.mixed,
    primary && s.primary
  ].filter(Boolean).join(" ");
  const onClick = (e) => e.target.blur();
  return (
    <label className={cn}>
      <input onClick={onClick} className={s.input} type="checkbox" checked={checked && !mixed} {...rest} />
      <div className={s.toggle} />
      {title && <span className={s.title}>{title}</span>}
    </label>
  )
}