import { atom } from "jotai";
import { find } from "lodash";
import { deleteRowAtom, mapAtom, rowsAtom, milestoneStylesAtom, newItemsAllowedAtom, callToUpgradeModalAtom, cleanupRowsAtom } from ".";
import { v4 as uid } from "uuid";


export const milestonesAtom = atom([]);

const _lastUsedMilestoneStyleAtom = atom();
export const lastUsedMilestoneStyleAtom = atom(
  ( get ) => {
    return get(_lastUsedMilestoneStyleAtom) ?? get(milestoneStylesAtom)[0].id;
  },
  ( get, set, upd ) => {
    set(_lastUsedMilestoneStyleAtom, upd);
  }
);

// Milestone manipulations
export const createMilestoneAtom = atom(null, (get, set, props = {}) => {
  if (!get(newItemsAllowedAtom)) {
    set(cleanupRowsAtom);
    set(callToUpgradeModalAtom, true);
    return;
  }
  const defaultProps = {
    id: uid(),
    type: "milestone",
    title: "Milestone",
    style: get(lastUsedMilestoneStyleAtom),
  }
  const { row, ...providedProps } = props;
  const milestone = {...defaultProps, ...providedProps};
  set(milestonesAtom, [...get(milestonesAtom), milestone]);
  set(attachMilestoneAtom, { id: milestone.id, row });
  return milestone;
})

export const updateMilestoneAtom = atom(null, (get, set, props) => {
  const target = get(mapAtom).get(props.id);
  if (!target || target.type !== "milestone") return false;

  const milestone = {...target, ...props};
  const newMilestones = get(milestonesAtom).map( el => el === target ? milestone : el );
  set(milestonesAtom, newMilestones);

  if (props.style) {
    set( lastUsedMilestoneStyleAtom, props.style );
  }

  return milestone;
})


export const deleteMilestoneAtom = atom(null, (get, set, id) => {
  set(detachMilestoneAtom, id);
  set(milestonesAtom, get(milestonesAtom).filter( el => el.id !== id ));
})

export const detachMilestoneAtom = atom(null, (get, set, id) => {
  const rows = get(rowsAtom);
  const target = rows.find( el => el.children.includes(id));
  if (!target) return false;

  const row = { ...target };
  row.children = target.children.filter(el => el !== id);

  set(rowsAtom, rows.map( el => el === target ? row : el ));
  if (!row.children.length) set(deleteRowAtom, target.id);
  return true;
})

export const attachMilestoneAtom = atom(null, (get, set, props) => {
  const rows = get(rowsAtom);
  const target = find(rows, { id: props.row });
  if (!target) return false;
  const row = { ...target };
  row.children = [ ...target.children, props.id ];

  const newRows = rows.map( el => el === target ? row : el );
  set(rowsAtom, newRows);
})

export const moveMilestoneAtom = atom(null, ( get, set, props) => {
  set(detachMilestoneAtom, props.id);
  set(attachMilestoneAtom, props)
})