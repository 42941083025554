import { updateTaskAtom } from 'store';
import { useStyleEdit, vxDate } from 'utils';
import Section from '../../components/Section/Section';
import Input from '../../components/Input/Input';
import DatePicker from '../../components/DatePicker/DatePicker';
import Workgroup from './Workgroup/Workgroup';


export default function MainData({ items }) {
  const setter = (key, value, { id, start, end }) => {
    const date1 = key === "start" ? value : start;
    const date2 = key === "end" ? value : end;
    return {
      id,
      [key]: value,
      start: vxDate.start(date1, date2),
      end: vxDate.end(date1, date2)
    };
  }
  const [p, valueChange] = useStyleEdit({
    editAtom: updateTaskAtom,
    styles: items,
    setter
  });

  return (
    <Section title="Details">
      <div>
        <Input
          title="Title"
          id="task-data-title"
          value={p.title.value}
          mixed={p.title.mixed}
          onChange={valueChange("title")}
        />
      </div>
      <div>
        <DatePicker
          title="Start"
          id="task-data-start"
          value={p.start.value}
          mixed={p.start.mixed}
          onChange={valueChange("start")}
        />
        <DatePicker
          title="End"
          id="task-data-end"
          value={p.end.value}
          mixed={p.end.mixed}
          onChange={valueChange("end")}
          isLast={true}
        />
      </div>
      <Workgroup tasks={items} />
    </Section>
  )
}