import { useStyleEdit } from 'utils';
import Section from '../../components/Section/Section';
import ColorPicker from '../../components/ColorPicker/ColorPicker';
import NumberInput from '../../components/NumberInput/NumberInput';
import SliderInput from '../../components/Slider/Slider';
import { ReactComponent as Height } from "img/icon/height.svg";
import { ReactComponent as LineWidth } from "img/icon/line-width.svg";
import { ReactComponent as Roundness } from "img/icon/roundness.svg";


function BarAppearance({ styles, editAtom }) {
  const [
    p, valueChange, toggleChange, colorChange, opacityChange
  ] = useStyleEdit({ editAtom, styles });

  return p ? (
    <Section title="Appearance">
      <div>
        <ColorPicker
          title={"Fill"}
          id={"task-fill"}
          color={p.background.value ?? { h: 0, s: 0, v: 0, a: 1 }}
          mixed={p.background.mixed}
          disabled={!p.backgroundShow.mixed && !p.backgroundShow.value}
          mixedDisabled={p.backgroundShow.mixed}
          onChange={colorChange("background")}
          onToggle={toggleChange("backgroundShow")}
        />
        <NumberInput
          title={"Opacity"}
          id={"task-fill-alpha"}
          value={Math.floor(p.background.value.a * 100)}
          mixed={p.background.mixedAlpha}
          disabled={!p.backgroundShow.mixed && !p.backgroundShow.value}
          onChange={opacityChange("background")}
          min={0}
          max={100}
          unit={"%"}
        />
      </div>
      <div>
        <ColorPicker
          title={"Border"}
          id={"task-border"}
          color={p.borderColor.value ?? { h: 0, s: 0, v: 0, a: 1 }}
          mixed={p.borderColor.mixed}
          disabled={!p.borderShow.mixed && !p.borderShow.value}
          mixedDisabled={p.borderShow.mixed}
          onChange={colorChange("borderColor")}
          onToggle={toggleChange("borderShow")}
        />
        <NumberInput
          title={"Line width"}
          id={"task-line-width"}
          value={p.borderWidth.value ?? ""}
          mixed={p.borderWidth.mixed}
          disabled={!p.borderShow.mixed && !p.borderShow.value}
          onChange={valueChange("borderWidth")}
          min={0}
          max={10}
          step={0.5}
          icon={LineWidth}
        />
      </div>
      <div>
        <SliderInput
          title={"Height"}
          id={"task-height"}
          value={p.height.value ?? ""}
          mixed={p.height.mixed}
          onChange={valueChange("height")}
          min={4}
          sliderMax={40}
          max={160}
          icon={Height}
        />
      </div>
      <div>
        <SliderInput
          title={"Roundness"}
          id={"task-roundness"}
          value={p.borderRadius.value ?? ""}
          mixed={p.borderRadius.mixed}
          onChange={valueChange("borderRadius")}
          min={0}
          sliderMax={20}
          max={80}
          icon={Roundness}
        />
      </div>
    </Section>
  ) : null
}

export default BarAppearance;