import { useSetAtom } from "jotai";
import { historyAtom, removeTaskLabelStyleAtom, TASK_FIELD_OPTIONS } from "store";
import { useStyleEdit } from "utils";
import Section, { Icon } from '../../components/Section/Section';
import ColorPicker from '../../components/ColorPicker/ColorPicker';
import NumberInput from "../../components/NumberInput/NumberInput";
import AlignmentPicker from "../../components/AlignmentPicker/AlignmentPicker";
import FormatPicker from "../../components/FormatPicker/FormatPicker";
import TextStyle from "../../components/TextStyle";
import TextTransform from "../../components/TextTransform";
import { ReactComponent as cross } from "img/icon/cross.svg";
import s from "./LabelAppearance.module.css";


export default function LabelAppearance({ styles, editAtom }) {
  const removeTaskLabel = useSetAtom(removeTaskLabelStyleAtom);
  const setHistory = useSetAtom(historyAtom);
  const setter = (key, val, { id, labelId }) => ({
    id, labels: { [labelId]: { [key]: val } }
  });
  const [
    p, valueChange, toggleChange, colorChange
  ] = useStyleEdit({ editAtom, styles, setter });

  const prefix = styles.map(l => l.labelId).join("-");

  const remove = () => {
    styles.forEach(
      ({ labelId }) => removeTaskLabel(labelId)
    )
    setHistory();
  }

  const toolbar = <>
    <FormatPicker
      value={p.format.mixed ? null : p.format.value}
      field={p.field.value}
      onChange={valueChange("format")}
    />
    <Icon icon={cross} onClick={remove} />
  </>;

  return (
    <Section
      title={p.field.value}
      options={TASK_FIELD_OPTIONS}
      toggle={p.show.value}
      toggleMixed={p.show.mixed}
      onChange={valueChange("field")}
      onToggle={toggleChange("show")}
      toolbar={toolbar}
    >
      {(p.show.value || p.show.mixed) && (
        <div className={s.wrapper}>
          <div className={s.row}>
            <ColorPicker
              title="Color"
              id={`${prefix}-color`}
              color={p.color.value ?? {h: 0, s: 0, v: 0, a: 1}}
              mixed={p.color.mixed}
              onChange={colorChange("color")}
            />
            <NumberInput
              title="Font size"
              id={`${prefix}-font-size`}
              value={p.fontSize.value ?? ""}
              mixed={p.fontSize.mixed}
              onChange={valueChange("fontSize")}
              min={6}
              max={60}
            />
          </div>
          <div className={s.row}>
            <TextStyle
              bold={p.bold.value && !p.bold.mixed}
              italic={p.italic.value && !p.italic.mixed}
              underline={p.underline.value && !p.underline.mixed}
              toggleBold={toggleChange("bold")}
              toggleItalic={toggleChange("italic")}
              toggleUnderline={toggleChange("underline")}
            />
            <TextTransform
              value={p.textTransform.mixed ? null : p.textTransform.value}
              onChange={valueChange("textTransform")}
            />
          </div>
          <div className={s.alignment}>
            <AlignmentPicker
              value={p.alignment.mixed ? null : p.alignment.value}
              onChange={valueChange("alignment")}
              prefix={prefix}
            />
          </div>
        </div>
      )}
    </Section>
  )
}