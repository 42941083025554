import { atom } from "jotai";

// Toggle tooltip
const isShowTooltipAtom = atom(
  get => get(_isShowTooltipAtom) && !get(isShowTempTooltipAtom),
  (_, set, val) => set(_isShowTooltipAtom, val),
);
const _isShowTooltipAtom = atom(true);
const isShowTempTooltipAtom = atom(false);

export {
  isShowTooltipAtom,
  isShowTempTooltipAtom,
}