import { SHAPES } from 'store';
import { useStyleEdit } from 'utils';
import Section from '../../components/Section/Section';
import ColorPicker from '../../components/ColorPicker/ColorPicker';
import NumberInput from '../../components/NumberInput/NumberInput';
import SliderInput from '../../components/Slider/Slider';
import { Segmented } from 'components';
import { ReactComponent as LineWidth } from "img/icon/line-width.svg";
import { ReactComponent as Height } from "img/icon/height.svg";
import { ReactComponent as Baseline } from "img/icon/baseline.svg";
import { ReactComponent as Flag } from "img/icon/milestone/flag.svg";
import { ReactComponent as Pin } from "img/icon/milestone/pin.svg";
import { ReactComponent as Diamond } from "img/icon/milestone/diamond.svg";
import { ReactComponent as Star } from "img/icon/milestone/star.svg";
import { ReactComponent as Star8 } from "img/icon/milestone/star8.svg";
import { ReactComponent as Up } from "img/icon/milestone/up.svg";
import { ReactComponent as Down } from "img/icon/milestone/down.svg";

export default function MilestoneAppearance({ styles, editAtom }) {
  const [
    p, valueChange, toggleChange, colorChange, opacityChange
  ] = useStyleEdit({ editAtom, styles });

  return (
    <Section title="Appearance">
      <div>
        <Segmented
          label={"Shape"}
          value={p.shape.mixed || p.shape.value}
          onChange={valueChange("shape")}
          secondary
          options={[
            {
              label: "Flag",
              value: SHAPES.FLAG,
              icon: Flag,
            }, {
              label: "Pin",
              value: SHAPES.PIN,
              icon: Pin,
            }, {
              label: "Diamond",
              value: SHAPES.DIAMOND,
              icon: Diamond,
            }, {
              label: "Star",
              value: SHAPES.STAR,
              icon: Star,
            }, {
              label: "Star 8 point",
              value: SHAPES.STAR8,
              icon: Star8,
            }, {
              label: "Down",
              value: SHAPES.ARROW_DOWN,
              icon: Down,
            }, {
              label: "Up",
              value: SHAPES.ARROW_UP,
              icon: Up,
            },
          ]}
        />
      </div>
      <div>
        <ColorPicker
          title={"Background"}
          id={"milestone-fill"}
          color={p.background.value ?? { h: 0, s: 0, v: 0, a: 1 }}
          mixed={p.background.mixed}
          disabled={!p.backgroundShow.mixed && !p.backgroundShow.value}
          mixedDisabled={p.backgroundShow.mixed}
          onChange={colorChange("background")}
          onToggle={toggleChange("backgroundShow")}
        />
        <NumberInput
          title="Opacity"
          id={"milestone-fill-alpha"}
          value={Math.floor(p.background.value.a * 100)}
          mixed={p.background.mixedAlpha}
          disabled={!p.backgroundShow.mixed && !p.backgroundShow.value}
          onChange={opacityChange("background")}
          min={0}
          max={100}
          unit="%"
        />
      </div>
      <div>
        <ColorPicker
          title={"Border"}
          id={"task-border"}
          color={p.borderColor.value ?? { h: 0, s: 0, v: 0, a: 1 }}
          mixed={p.borderColor.mixed}
          disabled={!p.borderShow.mixed && !p.borderShow.value}
          mixedDisabled={p.borderShow.mixed}
          onChange={colorChange("borderColor")}
          onToggle={toggleChange("borderShow")}
        />
        <NumberInput
          title={"Line width"}
          id={"task-line-width"}
          value={p.borderWidth.value}
          mixed={p.borderWidth.mixed}
          disabled={!p.borderShow.mixed && !p.borderShow.value}
          onChange={valueChange("borderWidth")}
          min={0}
          max={10}
          step={0.5}
          icon={LineWidth}
        />
      </div>
      <div>
        <SliderInput
          title={"Size"}
          id={"milestone-size"}
          value={p.size.value}
          mixed={p.size.mixed}
          onChange={valueChange("size")}
          min={1}
          sliderMax={40}
          max={100}
          icon={Height}
        />
      </div>
      <div>
        <SliderInput
          title={"Baseline"}
          id={"milestone-baseline"}
          value={p.baseline.value}
          mixed={p.baseline.mixed}
          onChange={valueChange("baseline")}
          min={-40}
          max={40}
          icon={Baseline}
          fromCenter={true}
        />
      </div>
    </Section>
  )
}