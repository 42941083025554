import { useRef, useState } from "react";
import { useSetAtom } from "jotai";
import { historyAtom } from "store";
import { Select } from "components";
import { ReactComponent as More } from "img/icon/more.svg";
import s from "./StylesList.module.css";



export default function StyleItem({
  atoms, id, name, index, checked, prefix, onChange, delta,
  onRemove, isRename, setRename,
  isHotkey = true, isMenu = true, sortable = true, removeable = true,
  onDragStart = () => {},
  onDrag = () => {},
  onDragEnd = () => {},
}) {
  const setHistory = useSetAtom(historyAtom);
  const editStyle = useSetAtom(atoms.editStyle);
  const [isFocus, setFocus] = useState(false);
  const [start, setStart] = useState(null);
  const [distance, setDistance] = useState(0);
  const draggedRef = useRef();
  const startRef = useRef();
  startRef.current = start;
  
  const className = [
    s.row, id, checked && s.selected, start && s.drag, isFocus && s.focus
  ].filter(Boolean).join(" ");

  const rename = () => setRename(id);

  const onRename = (e) => {
    const name = e.target.value;
    editStyle({ id, name });
    setHistory( `${id}-style-rename` );
  }

  const onKeyDown = (e) => {
    e.stopPropagation();
    if (e.key !== "Enter" && e.key !== "Escape") return;
    setRename(null);
  }
  const onFocus = (e) => {
    e.target.select();
  }
  const onBlur = () => {
    setRename(null);
  }

  const onPointerDown = (e) => {
    e.stopPropagation();
    e.currentTarget.setPointerCapture(e.pointerId);
    setStart({
      time: Date.now(),
      pos: e.clientY
    });
    onDragStart(id);
  };
  
  const onClick = (e) => {
    if (!draggedRef.current) return;
    e.preventDefault();
    draggedRef.current = false;
  }
  const onPointerUp = (e) => {
    if (!startRef.current) return;
    e.preventDefault();
    e.stopPropagation();
    setStart(null);
    setDistance(0);
    onDragEnd();
    startRef.current = null;
    const deltaTime = Date.now() - start.time;
    if ( Math.abs(distance) > 10 || deltaTime > 500 ) {
      draggedRef.current = true;
    }
  }

  const onPointerMove = (e) => {
    if (!startRef.current) return;
    e.preventDefault();
    e.stopPropagation();
    const distance = e.clientY - start.pos;
    setDistance(distance);
    onDrag( distance );
  }

  const style = delta ? {
    transform: `translateY(${delta}px)`,
  } : distance ? {
    transform: `translateY(${distance}px)`,
    zIndex: 10,
  } : {};


  return isRename ? (
    <li className={className}>
      <div className={s.label}>
        <span className={s.preview} />
        <input
          type="text"
          autoFocus={true}
          className={s.nameInput}
          defaultValue={name}
          onChange={onRename}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
    </li>
  ) : (
    <li className={className} style={style}>
      <input
        id={`${prefix}-${id}`}
        className={s.input}
        checked={checked}
        type="radio"
        name={`${prefix}-style`}
        value={id}
        onChange={onChange}
      />
      <label
        className={s.label}
        id={id}
        htmlFor={`${prefix}-${id}`}
        onClick={onClick}
        onPointerDown={sortable ? onPointerDown : null}
        onPointerUp={sortable ? onPointerUp : null}
        onPointerMove={sortable ? onPointerMove : null}
        onLostPointerCapture={sortable ? onPointerUp : null}
      >
        <span className={s.preview} />
        <span className={s.name}>{name}</span>
        {isHotkey && <Hotkey index={index} />}
      </label>
      {isMenu && <StyleOptions setFocus={setFocus} rename={rename} remove={removeable ? onRemove : null} />}
    </li>
  )
}

function StyleOptions({ setFocus, rename, remove }) {

  const preventDefault = e => e.preventDefault();
  const options = [
    {label: "Rename", value: "rename", action: rename},
    {label: "Delete", value: "delete", action: remove, isDisabled: !remove},
  ]

  function onChange(e) {
    options.find(
      i => i.value === e
    ).action();
  }

  const onMenuOpen = () => setFocus(true);
  const onMenuClose = () => setFocus(false);

  const classNames = {
    control: s.control
  }

  return (
    <div className={s.selectWrapper} onClick={preventDefault}>
      <Select
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        className={s.action}
        classNames={classNames}
        options={options}
        onChange={onChange}
        value={null}
        icon={More}
        rightMenu
        iconControl
      />
    </div>
  )
}

const Hotkey = ({index}) => {
  const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator?.platform);
  const meta = isMac ? "⌘" : "Ctrl";
  return (index < 10) ? (
    <span className={s.hotkey}>
      <span className={s.key}>{meta}</span>
      <span className={s.key}>{(index + 1) % 10}</span>
    </span>
  ) : null;
}