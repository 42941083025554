import { useAtomValue } from "jotai";
import { rowStylesMapAtom, columnAtomsAtom, updateRowAtom, editRowStyleAtom, overrideRowAtom, SELECTABLE } from "store";
import { useGetStyles, useStyleEdit } from "utils";
import { AddColumn } from "../../components/AddItem/AddItem";
import LvlSwitch from "../../components/LvlSwitch/LvlSwitch";
import StylesList from "../../components/StylesList/StylesList";
import Section from "../../components/Section/Section";
import Input from "../../components/Input/Input";
import EditMode from "../../components/EditMode/EditMode";
import Column from "../../components/ColumnLabelAppearance";
import RowAppearance from "./RowAppearance";
import { Button } from "components";
import {ReactComponent as linked} from "../../../../../img/icon/linked.svg";
import {ReactComponent as unlinked} from "../../../../../img/icon/unlinked.svg";

import s from "./Row.module.css";


export default function Row() {
  const columns = useAtomValue(columnAtomsAtom);
  const [items, styles, isOverriding] = useGetStyles(rowStylesMapAtom);
  const editAtom = isOverriding ? overrideRowAtom : editRowStyleAtom;

  return (
    <>
      <div>
        <LvlSwitch />
        <MainDetails items={items} />
        <StylesList title="Style" type={SELECTABLE.ROW} />
      </div>
      <div>
        <EditMode />
        <RowAppearance
          styles={styles}
          editAtom={editAtom}
        />
        {columns.map((atom) => (
          <Column
            key={`${atom}`}
            atom={atom}
            styles={styles}
            editAtom={editAtom}
          />
        ))}
        <Section>
          <AddColumn />
        </Section>
      </div>
    </>
  )
}

function MainDetails({ items }) {
  const [ref, valueChange, toggleChange] = useStyleEdit({
    editAtom: updateRowAtom,
    styles: items,
  });

  return (
    <Section title="Details">
      <div className={s.inputWrapper}>
        <Input
          title="Title"
          id="row-data-title"
          value={ref.title.value}
          mixed={ref.title.mixed}
          onChange={valueChange("title")}
        />
        <Button
          iconOnly
          secondary
          label={"First child title"}
          icon={!ref.isLinkedTitle?.mixed && ref.isLinkedTitle?.value ? linked : unlinked}
          onClick={toggleChange("isLinkedTitle")}
          tooltip={"First child title"}
          tooltipAlign={"right"}
          reverseTooltip
        />
      </div>
    </Section>
  )
}