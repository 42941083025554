import { vxDate } from "utils";

export const DAY_LENGHT = 24 * 60 * 60 * 1000;

export const PAGE_WIDTH = 960;
export const MIN_COLUMN_WIDTH = 20;
export const MAX_COLUMN_WIDTH = 800;

export const GUEST_USER = "guest-user";

export const SAVE_STATUS = {
  REQUIRED: "required",
  QUEUED: "queued",
  SAVING: "saving",
  SAVED: "saved",
}

export const EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const SELECTABLE = {
  DOCUMENT: "document",
  HEADER: "header",
  FOOTER: "footer",
  MILESTONE: "milestone",
  TASK: "task",
  ROW: "row",
  GROUP: "group",
}

export const TOOLS = {
  SELECT: "select",
  TASK: "task",
  MILESTONE: "milestone",
}

export const SHAPES = {
  FLAG: "flag",
  PIN: "pin",
  DIAMOND: "diamond",
  STAR: "star",
  STAR8: "star8",
  ARROW_DOWN: "arrow-down",
  ARROW_UP: "arrow-up",
}

export const LABEL_ALIGNMENT = {
  INSIDE_LEFT: "inside-left",
  INSIDE_RIGHT: "inside-right",
  INSIDE_CENTER: "inside-center",
  ABOVE_LEFT: "above-left",
  ABOVE_RIGHT: "above-right",
  ABOVE_CENTER: "above-center",
  BELOW_LEFT: "below-left",
  BELOW_RIGHT: "below-right",
  BELOW_CENTER: "below-center",
  OUTSIDE_LEFT: "outside-left",
  OUTSIDE_RIGHT: "outside-right",
}

export const MILESTONE_LABEL_ALIGNMENT = {
  ABOVE: "above",
  BELOW: "below",
  BEFORE: "before",
  AFTER: "after",
}

export const EDIT_MODES = {
  GLOBAL: "global",
  STYLE: "style",
  ELEMENT: "element",
}

export const GROUP_TYPES = {
  HEADING: "heading",
  SWIMLANE: "swimlane",
}

// DOCUMENT SETTINGS
export const FONT_OPTIONS = [
  {
    label: "Arial",
    value: "Arial",
  }, {
    label: "Georgia",
    value: "Georgia",
  }, {
    label: "Tahoma",
    value: "Tahoma",
  }, {
    label: "Times New Roman",
    value: "Times New Roman",
  }, {
    label: "Trebuchet MS",
    value: "Trebuchet MS",
  }, {
    label: "Verdana",
    value: "Verdana",
  }
];

export const DEFAULT_WORKDAYS = [
  false, true, true, true, true, true, false
];

export const DEFAULT_CURRENCY_PLACEMENT = "suffix";
export const DEFAULT_CURRENCY_SIGN = "$";

export const DEFAULT_EXCEL_CONFIG = {
  types: "both",
  separateSheets: true,
  headingRow: true,
  fields: ["group", "row", "title", "startDate", "endDate", "duration", "assignee", "rate", "allocation", "cost"]
};


// ROWS, COLUMNS and TASK LABELS
export const FIELDS = {
  TITLE: "title",
  START: "start",
  END: "end",
  RANGE: "range",
  DURATION: "duration",
  COST: "cost",
  TASKS: "tasks",
  TIMELINE: "timeline",
  FTE: "fte",
  ASSIGNEE: "assignee",
}

export const COLUMN_OPTIONS = [
  {
    label: "Title",
    value: FIELDS.TITLE,
  }, {
    label: "Cost",
    value: FIELDS.COST,
  }, {
    label: "Duration",
    value: FIELDS.DURATION,
  }, {
    label: "Date range",
    value: FIELDS.RANGE,
  }, {
    label: "Start date",
    value: FIELDS.START,
  }, {
    label: "End date",
    value: FIELDS.END,
  }, {
    label: "FTE",
    value: FIELDS.FTE,
  }, {
    label: "Assignee",
    value: FIELDS.ASSIGNEE,
  }
]
export const TIER_OPTIONS = [
  {
    label: "Timescale",
    value: FIELDS.TIMELINE
  }, {
    label: "Cost breakdown",
    value: FIELDS.COST
  }, {
    label: "FTE",
    value: FIELDS.FTE
  }
]
export const TASK_FIELD_OPTIONS = [
  {
    label: "Title",
    value: FIELDS.TITLE,
  }, {
    label: "Cost",
    value: FIELDS.COST,
  }, {
    label: "Duration",
    value: FIELDS.DURATION,
  }, {
    label: "Start date",
    value: FIELDS.START,
  }, {
    label: "End date",
    value: FIELDS.END,
  }, {
    label: "Date range",
    value: FIELDS.RANGE,
  }, {
    label: "FTE",
    value: FIELDS.FTE,
  }, {
    label: "Assignee",
    value: FIELDS.ASSIGNEE,
  }
]

export const IMPORTABLE = {
  GROUP: "group",
  TITLE: "title",
  START: "start",
  END: "end",
  ASSIGNEE_NAME: "assignee-name",
  ASSIGNEE_ROLE: "assignee-role",
  ASSIGNEE_RATE: "assignee-rate",
  ASSIGNEE_ALLOCATION: "assignee-allocation",
}
export const IMPORT_COLUMN_OPTIONS = [
  {
    label: "Unmapped",
    value: null,
  }, {
    label: "Group",
    value: IMPORTABLE.GROUP,
  }, {
    label: "Title",
    value: IMPORTABLE.TITLE,
  }, {
    label: "Start date",
    value: IMPORTABLE.START,
  }, {
    label: "End date",
    value: IMPORTABLE.END,
  }, {
    label: "Name (assignee)",
    value: IMPORTABLE.ASSIGNEE_NAME,
  }, {
    label: "Role (assignee)",
    value: IMPORTABLE.ASSIGNEE_ROLE,
  }, {
    label: "Rate",
    value: IMPORTABLE.ASSIGNEE_RATE,
  }, {
    label: "Allocation",
    value: IMPORTABLE.ASSIGNEE_ALLOCATION,
  }
]


// TIMELINE
export const TIMELINE_UNITS = {
  AUTO: "auto",
  DAYS: "days",
  MONTHS: "months",
  QUARTERS: "quarters",
  YEARS: "years",
}
export const TIMELINE_SCALE_OPTIONS = [
  {
    label: "Auto",
    value: TIMELINE_UNITS.AUTO,
  }, {
    label: "Months",
    value: TIMELINE_UNITS.MONTHS,
  }, {
    label: "Quarters",
    value: TIMELINE_UNITS.QUARTERS,
  }, {
    label: "Years",
    value: TIMELINE_UNITS.YEARS,
  }
]
export const TIMELINE_ROUND_OPTIONS = [
  {
    label: "Days",
    value: TIMELINE_UNITS.DAYS,
  }, {
    label: "Months",
    value: TIMELINE_UNITS.MONTHS,
  }, {
    label: "Quarters",
    value: TIMELINE_UNITS.QUARTERS,
  }, {
    label: "Years",
    value: TIMELINE_UNITS.YEARS,
  }
]
export const TIMELINE_ROUND_SHORT_OPTIONS = [
  {
    label: "D",
    value: TIMELINE_UNITS.DAYS,
  }, {
    label: "M",
    value: TIMELINE_UNITS.MONTHS,
  }, {
    label: "Q",
    value: TIMELINE_UNITS.QUARTERS,
  }, {
    label: "Y",
    value: TIMELINE_UNITS.YEARS,
  }
]


// FORMATS
const today = Date.now();
export const DATE_FORMATS = [
  "dd.mm",
  "dd/mm",
  "dd mmm",
  "dd mmmm",
  "dd.mm.yy",
  "dd/mm/yy",
  "dd mmm 'yy",
  "dd mmm yyyy",
  "dd mmmm 'yy",
  "dd mmmm yyyy",
  "ddd, dd mmm",
  "dddd, dd mmm",
  "ddd, dd mmmm",
  "dddd, dd mmmm",
]
export const DURATION_FORMATS = [
  "d",
  "days"
];
export const TIMELINE_FORMATS = [
  "mmm",
  "mmmm",
  "mm",
]
export const ASSIGNEE_FORMATS = {
  AUTO: "auto",
  ROLE: "role",
  NAME: "name",
}

export const FORMAT_ASSIGNEE_OPTIONS = Object.values(ASSIGNEE_FORMATS).map( format => ({
  label: format.charAt(0).toUpperCase() + format.slice(1),
  value: format,
}))

export const FORMAT_DATE_OPTIONS = DATE_FORMATS.map( format => ({
  label: vxDate.format(today, format),
  value: format,
}))
export const FORMAT_DURATION_OPTIONS = DURATION_FORMATS.map( format => ({
  label: format,
  value: format
}))
export const FORMAT_TIMELINE_OPTIONS = TIMELINE_FORMATS.map( format => ({
  label: vxDate.format(today, format),
  value: format
}))

export const FORMAT_OPTIONS = {
  [FIELDS.START] : FORMAT_DATE_OPTIONS,
  [FIELDS.END] : FORMAT_DATE_OPTIONS,
  [FIELDS.RANGE] : FORMAT_DATE_OPTIONS,
  [FIELDS.DURATION] : FORMAT_DURATION_OPTIONS,
  [FIELDS.ASSIGNEE] : FORMAT_ASSIGNEE_OPTIONS,
  [`${FIELDS.TIMELINE}-${TIMELINE_UNITS.MONTHS}`] : FORMAT_TIMELINE_OPTIONS,
  [`${FIELDS.TIMELINE}-${TIMELINE_UNITS.AUTO}`] : FORMAT_TIMELINE_OPTIONS,
}

export const FREE_ELEMENTS_LIMIT = 10;