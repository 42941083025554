import { useAtom, useSetAtom } from "jotai";
import { TOOLS, toolAtom, snappingAtom, lockedAtom, teamModalAtom } from "store";
import { Button } from "components";
import s from "./Tools.module.css";

import {ReactComponent as select} from "img/icon/select.svg";
import {ReactComponent as task} from "img/icon/task.svg";
import {ReactComponent as milestone} from "img/icon/milestone.svg";
import {ReactComponent as snap} from "img/icon/snap.svg";
import {ReactComponent as lock} from "img/icon/lock.svg";
import {ReactComponent as unlock} from "img/icon/unlock.svg";
import {ReactComponent as team} from "img/icon/team.svg";

const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator?.platform);
const altKey = isMac ? "⌥" : "Alt";

export default function Tools() {
  const [tool, setTool] = useAtom(toolAtom);
  const [locked, setLocked] = useAtom(lockedAtom);
  const [snapping, setSnapping] = useAtom(snappingAtom);
  const setTeamModal = useSetAtom(teamModalAtom);
  const openTeamModal = () => setTeamModal(true);
  const onSnap = () => setSnapping(!snapping);
  const onLock = () => setLocked(!locked);

  return (
    <div className={s.wrapper}>
      <Button
        iconOnly
        onClick={() => setTool(TOOLS.SELECT)}
        active={tool === TOOLS.SELECT}
        icon={select}
        tooltip={"Select"}
        hotkey={["V"]}
        reverseTooltip
      />
      <Button
        iconOnly
        onClick={() => setTool(TOOLS.TASK)}
        active={tool === TOOLS.TASK}
        icon={task}
        tooltip={"Task"}
        hotkey={["N"]}
        reverseTooltip
      />
      <Button
        iconOnly
        onClick={() => setTool(TOOLS.MILESTONE)}
        active={tool === TOOLS.MILESTONE}
        icon={milestone}
        tooltip={"Milestone"}
        hotkey={["M"]}
        reverseTooltip
      />
      <Separator />
      <Button
        iconOnly
        onClick={onSnap}
        active={snapping}
        icon={snap}
        tooltip={"Snapping"}
        hotkey={["S"]}
        reverseTooltip
      />
      <Button
        iconOnly
        onClick={onLock}
        active={locked}
        icon={locked ? lock : unlock}
        tooltip={"Lock"}
        hotkey={[`${altKey} (Hold)`]}
        reverseTooltip
      />
      <Button
        iconOnly
        onClick={openTeamModal}
        icon={team}
        tooltip={"Team"}
        reverseTooltip
      />
    </div>
  )
}

const Separator = () => <div className={s.sr} />