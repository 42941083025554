import ReactSlider from "react-slider";
import NumberInput from "../NumberInput/NumberInput";
import s from "./Slider.module.css";


function Slider({fromCenter, ...props}) {
  const onBeforeChange = () => {
    document.body.classList.toggle("no-transition", true);
  }
  const onAfterChange = () => {
    document.body.classList.toggle("no-transition", false);
  }
  const className = [
    s.slider,
    fromCenter && s.fromCenter,
    fromCenter && props.value < 0 && s.inverted
  ].filter(Boolean).join(" ");
  return (
    <ReactSlider
      className={className}
      trackClassName={s.track}
      thumbClassName={s.thumb}
      onBeforeChange={onBeforeChange}
      onAfterChange={onAfterChange}
      {...props}
    />
  )
}

const Thumb = ({ key, ...props }) => {
  return <div key={key} {...props} tabIndex={undefined} />
};

export default function SliderInput({title, value, min, max, sliderMin, sliderMax, onChange, mixed, invert, fromCenter, ...rest}) {
  return (
    <div className={s.wrapper}>
      <div className={s.sliderWrapper}>
        <div className={s.sliderInput}>
        <label className={s.label}>{title}</label>
        <Slider
          value={mixed ? fromCenter ? 0 : min : value}
          min={sliderMin ?? min}
          max={sliderMax ?? max}
          onChange={onChange}
          renderThumb={Thumb}
          fromCenter={fromCenter}
        />
        </div>
        <NumberInput
          className={s.numberInput}
          value={value}
          min={min}
          max={max}
          onChange={onChange}
          mixed={mixed}
          {...rest}
        />
      </div>
    </div>
  )
}