import { useEffect, useRef, useState } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { 
  workareaWidthAtom,
  mouseToWorkareaAtom,
  isShowTooltipAtom,
  groupsAtom,
  editingAtom,
  selectedAtom,
  headerAtom,
  footerAtom,
  SELECTABLE,
} from "store";
import ColumnControls   from "./components/ColumnControls/ColumnControls";
import Heading          from "./components/Heading/Heading";
import Group            from "./components/Group/Group";
import TaskCreator      from "./components/TaskCreator/TaskCreator";
import SnapGuide        from "./components/SnapGuide/SnapGuide";
import ColumnGuide      from "./components/ColumnGuide/ColumnGuide";
import ColumnEdgeGuide  from "./components/ColumnEdgeGuide/ColumnEdgeGuide";
import Tooltip          from "./components/Tooltip/Tooltip";
import s from "./Planner.module.css";

export default function Planner() {
  const ref = useRef(null);
  const [isRendered, setRendered] = useState(false);
  const [workarea, setWorkarea] = useAtom(workareaWidthAtom);
  const isReady = workarea !== 1;
  // const groups = useAtomValue(groupsAtom);

  // OnResize Workarea scaling 
  useEffect(() => {
    const setWidth = () => {
      if (!ref.current) return;
      const {width} = ref.current.getBoundingClientRect();
      setWorkarea(width - 2); /* minus borders */
    };
    setWidth();
    window.addEventListener("resize", setWidth);
    return () => window.removeEventListener("resize", setWidth);
  }, [setWorkarea])

  useEffect(() => {
    if (!isReady) {
      document.body.classList.toggle("no-transition", true);
    } else {
      setRendered(true);
    }
    if (isRendered) {
      setTimeout(
        () => document.body.classList.toggle("no-transition", false),
        500
      );
    }
  }, [isReady, isRendered])


  // Track mouse
  const setMouseToWorkarea = useSetAtom(mouseToWorkareaAtom);
  const setMouseCoord = (e) => {
    const {left, top} = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - left;
    const y = e.clientY - top;
    setMouseToWorkarea([x,y]);
  }

  // Toggle the Tooltip visibility
  const setShowTooltip = useSetAtom(isShowTooltipAtom);
  const onMouseEnter = () => setShowTooltip(true);
  const onMouseLeave = () => setShowTooltip(false);

  // Deselect
  const setEditing = useSetAtom(editingAtom);
  const setSelected = useSetAtom(selectedAtom);
  const onPointerDown = () => {
    setEditing(false);
    setSelected(null);
  }

  // Classes
  const classNames = [
    "planner",
    s.plannerWrapper,
    !isRendered && s.loading
  ].filter(Boolean).join(" ");

  return (
    <>
      <Tooltip />
      <main className={classNames} onPointerDown={onPointerDown}>
        <div className={s.planner}>
          <ColumnControls />
          <div className={s.pagesWrapper} ref={ref} onMouseMove={setMouseCoord} onPointerDownCapture={setMouseCoord}>
            {isReady ? (
              <div
                className={s.page}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                id="planner"
              >
                <Heading id={SELECTABLE.HEADER} atom={headerAtom} />
                <TasksArea />
                <TaskCreator />
                <Heading id={SELECTABLE.FOOTER} atom={footerAtom} />
                <SnapGuide />
                <ColumnGuide />
                <ColumnEdgeGuide />
              </div>
            ) : null}
          </div>
        </div>
      </main>
    </>
  )
}

function TasksArea() {
  const groups = useAtomValue(groupsAtom);

  return (
    <div>
      {groups ? groups.map(({style, ...rest}, index) => 
        <Group key={rest.id} index={index} styleId={style} {...rest} />
      ) : null}
    </div>
  )
}

