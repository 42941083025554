import { useEffect, useRef, useState } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { devError, devLog, isPaidUser } from "utils";
import { isEqual } from "lodash";
import { userAtom, gptGetSnapshotAtom, gptSetSnapshotAtom, tokensAtom, historyAtom, gptRequestTypeAtom, gptCancelRequestAtom, callToUpgradeModalAtom } from "store";
import { useRive, useStateMachineInput } from "@rive-app/react-canvas";
import { fetchAuthSession } from "aws-amplify/auth";
import { Tooltip } from "components/Button";
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as Send } from "img/icon/send.svg";
import riveSparklesIcon from "img/icon/sparkles.riv";
import s from "./Gpt.module.css";

const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator?.platform);
const meta = isMac ? "⌘" : "Ctrl";

export default function Gpt() {
  const [value, setValue] = useState("");
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [focus, setFocus] = useState(false);
  const [type, setType] = useAtom(gptRequestTypeAtom);
  const user = useAtomValue(userAtom)
  const getSnapshot = useSetAtom(gptGetSnapshotAtom);
  const setSnapshot = useSetAtom(gptSetSnapshotAtom);
  const setHistory = useSetAtom(historyAtom);
  const setTokens = useSetAtom(tokensAtom);
  const cancel = useSetAtom(gptCancelRequestAtom);
  const callToUpgrade = useSetAtom(callToUpgradeModalAtom);
  const cancelRef = useRef();

  useEffect(() => {
    if (!showError) return;
    const timer = setTimeout(() => {
      if (showError) {
        setShowError(false);
      }
    }, 5000);
    return () => {
      clearTimeout(timer);
    }
  }, [showError])

  const onChange = (e) => setValue(e.target.value);
  const onKeyDown = (e) => {
    e.stopPropagation();
    const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator?.platform);
    const meta = isMac ? (e.metaKey && !e.ctrlKey) : e.ctrlKey;
    if (e.key === "Escape") {
      e.target.blur();
    } else if (e.key === "Enter" && meta ) {
      onSubmit();
    }
  }
  const sendRequest = async (requestType, value) => {
    if (type) return;
    if (requestType === "general" && !value) return;
    
    const snapshot = getSnapshot();
    setShowError(false);
    setFocus(false);
    setValue("");
    setType(requestType);

    try {
      if (!isPaidUser(user)) {
        throw new Error("Subscription required");
      }
      const response = await gptRequest(requestType, snapshot, value);
      const reader = response.body.getReader();
      const decoder = new TextDecoder(); // Decodes binary to text if necessary
      
      const cancelPromise = new Promise((_, reject) => {
        cancelRef.current = () => reject(new Error('Request cancelled'));
      });

      let result = "";
      let edits = [];
      let done = false;

      while (!done) {
        const { value, done: iterationDone } = await Promise.race([
          reader.read(), // Get the next chunk
          cancelPromise  // Or cancel the operation
        ]);

        if (iterationDone || value === undefined) {
          done = true; // Break loop if done or cancelled
        } else {
          // Process the chunk (if not cancelled)
          const textChunk = decoder.decode(value);
          result += textChunk;

          const newEdits = extractFinishedEdits(result);
          if (!isEqual(edits, newEdits)) {
            devLog(edits);
            edits = newEdits
            setSnapshot({ edits });
          }
        }
      }

      // Process the final result
      const parsed = JSON.parse(result);
      if (parsed.statusCode && parsed.error) {
        throw new Error(parsed.error);
      }
      devLog(parsed);
      setSnapshot(parsed);
      setTokens(parsed.tokens);
      setHistory();
    } catch (err) {
      if (err.message === 'Request cancelled') {
        devLog('Request was cancelled.');
      } else {
        devError('Request failed:', err.message);
        switch (err.message) {
          case ("Subscription required") : {
            callToUpgrade(true);
            break;
          }
          case ("No tokens left"): {
            setError("You are out of AI Credits this month.");
            setShowError(true);
            setTokens(0);
            break;
          }
          default: {
            setError("AI can't process your request. Please try again later.");
            setShowError(true);
          }
        }
      }
    } finally {
      setType(null);
    }
  }
  const onSubmit = async () => {
    sendRequest('general', value);
  }
  const onSpecify = () => {
    sendRequest('specify');
  }
  const onGroup = () => {
    sendRequest('group');
  }
  const onTeam = () => {
    sendRequest('team');
  }
  const onAssign = () => {
    sendRequest('assign');
  }
  const onCancel = () => {
    cancel();
    if (cancelRef.current) {
      cancelRef.current();
      cancelRef.current = null;
    }
  }
  const errorClick = () => {
    setShowError(false);
  }
  const onFocus = () => setFocus(true);
  const onBlur = () => setFocus(false);

  const cn = [
    s.main,
    focus && s.focus,
    !!type && s.busy,
    !!value && s.filled
  ].filter(Boolean).join(" ");

  return (
    <div className={cn}>
      <div className={s.glow} />
      <div className={s.wrapper}>
        <div className={s.innerGlow} />
        <div className={s.innerWrapper}>
          <label className={s.inputWrapper}>
            <span className={s.sizer}>{value} </span>
            <div className={s.iconWrapper}>
              <Tooltip hotkey={[meta, "K"]} align={"center"} reverse>AI Prompt</Tooltip>
              <RiveIcon className={s.icon} src={riveSparklesIcon} state={!!type} />
            </div>
            <textarea
              id={"AImput"}
              className={s.input}
              value={value}
              placeholder={!!type ? "Generating..." : "Write or select prompt:"}
              rows={1}
              onKeyDown={onKeyDown}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              disabled={!!type}
            />
          </label>
          {!!type ? (
            <button
              onClick={onCancel}
              className={s.cancel}
              type="button"
              aria-label="Cancel"
            >
              Cancel
            </button>
          ) : value ? (
            <button
              onClick={onSubmit}
              className={s.submit}
              type="button"
              aria-label="Send"
            >
              <Send className={s.sendIcon} />
            </button>
          ) : (
            <div className={s.actionsWrapper}>
              <Action onClick={onSpecify} tooltip="Break down task into subtasks">Specify</Action>
              <Action onClick={onGroup} tooltip="Organize tasks into groups">Group</Action>
              <Action onClick={onTeam} tooltip="Create required team members">Create team</Action>
              <Action onClick={onAssign} tooltip="Assign people to selected tasks">Assign</Action>
            </div>
          )}
        </div>
      </div>
      <CSSTransition
        in={showError}
        timeout={200}
        classNames={s}
        unmountOnExit
      >
        <div className={s.errorWrapper} onClick={errorClick}>
          {error}
        </div>
      </CSSTransition>
    </div>
  )
}

function Action({ children, onClick, tooltip }) {
  const onKeyDown = (e) => {
    e.stopPropagation();
    if (e.key === "Escape") {
      e.target.blur();
    }
  }
  return (
    <div className={s.actionWrapper}>
      <button type="button" className={s.action} onKeyDown={onKeyDown} onClick={onClick}>
        <Tooltip className={s.tooltip} align={"center"} reverse>{tooltip}</Tooltip>
        {children}
      </button>
    </div>
  )
}

function RiveIcon({className, src, state}) {
  const { rive, RiveComponent } = useRive({
    src: src,
    stateMachines: "Main",
    autoplay: true,
  });
  const stateInput = useStateMachineInput(rive, "Main", "Active", false);
  if (stateInput) {
    stateInput.value = state;
  }
  return <RiveComponent className={className} />
}


async function gptRequest(type, plan, message) {
  const url = process.env.REACT_APP_GPT_LAMBDA_URL;
  const { idToken } = (await fetchAuthSession()).tokens;
  return fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify({
      plan, message, type,
    })
  });
}


function extractFinishedEdits(incompleteJson) {
  let depth = 0;  // Track the depth of braces
  let lastValidIndex = -1;  // Last valid closing position
  let isInsideQuotes = false;  // Track if we're inside a string
  
  for (let i = 0; i < incompleteJson.length; i++) {
      const char = incompleteJson[i];

      // Toggle quote tracking
      if (char === '"' && incompleteJson[i - 1] !== '\\') {
          isInsideQuotes = !isInsideQuotes;
      }

      // If we're inside quotes, skip further checks
      if (isInsideQuotes) continue;

      // Track brace depth only if outside quotes
      if (char === '{') {
          depth++;
      } else if (char === '}') {
          depth--;
      }

      // If we are back to depth 1 and see a `},` or just `}`, it's a valid object or array ending
      if (depth === 1 && char === '}' && (incompleteJson[i + 1] === ',' || incompleteJson[i + 1] === ']')) {
          lastValidIndex = i + 1;
      }
  }

  // If a valid end was found, close the JSON properly
  if (lastValidIndex !== -1) {
      const validJson = incompleteJson.slice(0, lastValidIndex) + ']}';
      try {
          const parsedData = JSON.parse(validJson);
          return parsedData.edits;
      } catch (e) {
          devError("Failed to parse JSON:", e);
          return [];
      }
  } else {
    return [];
  }
}