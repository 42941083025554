import { useAtomValue } from "jotai";
import { userAtom, GUEST_USER } from "store";
import { getUser } from "utils";
import { Outlet, redirect } from "react-router-dom";
import Nav from "./Nav/Nav";
import s from "./Settings.module.css";


export default function Settings() {
  const user = useAtomValue(userAtom);
  return user && (
    <>
      <Nav />
      <div className={s.wrapper}>
        <Outlet />
      </div>
    </>
  )
}

export async function loader() {
  const user = await getUser();
  if (user === GUEST_USER) {
    return redirect("/login");
  } else {
    return null;
  }
}