import { useEffect, useRef } from 'react';
import { subscription } from './constants';
import s from "./Checkout.module.css";


export default function PaymentStep({ paddle, ids, plan, txn }) {
  const init = useRef();
  useEffect(() => {
    if (!paddle) return;
    const props = txn ? {
      transactionId: txn
    } : {
      items: [subscription[plan]], customer: {
        id: ids.customerId,
        address: { id: ids.addressId || null },
        business: { id: ids.businessId || null },
      },
    };
    if (!init.current) {
      init.current = true;
      const frameStyle = [
        "width: 100%;",
        "min-width: 312px;",
        "background-color: transparent;",
        "border: none;"
      ].join(" ");
      paddle.Checkout.open({
        ...props,
        settings: {
          displayMode: "inline",
          frameTarget: "checkoutFrame",
          frameInitialHeight: "450",
          frameStyle: frameStyle,
          locale: "en",
          allowLogout: false,
          showAddDiscounts: true,
          showAddTaxId: false,
        },
      });
    } else {
      paddle.Checkout.updateItems([subscription[plan]]);
    }
  }, [paddle, ids, plan, txn]);

  return (
    <div className={s.paymentStep}>
      <div className="checkoutFrame" />
    </div>
  )
}