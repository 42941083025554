
import Checkbox from "./Checkbox";
import s from "./CheckboxGroup.module.css";

export default function CheckboxGroup({ label, options, onChange, secondary, noFrame, columns=1 }) {
  const handleChange = (val) => {
    const result = options.map(
      op => (op === val) ? {...op, value: !op.value} : op
    );
    onChange(result);
  }
  const className = [
    s.wrapper, 
    secondary && s.secondary,
    noFrame && s.noFrame
  ].filter(Boolean).join(" ");

  const style = {
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    gridTemplateRows: `repeat(${Math.ceil(options.length / columns)}, auto)`
  }

  return (
    <div className={className}>
      { label && <p className={s.label}>{label}</p> }
      <div className={s.checkboxWrapper} style={style}>
        {options.map( (op) => (
          <Checkbox
            secondary={secondary}
            key={op.label}
            value={op.value}
            disabled={op.disabled}
            onChange={() => handleChange(op)}
          >
            {op.label}
          </Checkbox>
        ))}
      </div>
    </div>
  )
}