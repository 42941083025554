import { useRef, useState } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import {
  SELECTABLE,
  historyAtom, mapAtom, selectedAtom,
  milestoneStylesAtom, editMilestoneStyleAtom, createMilestoneStyleAtom, removeMilestoneStyleAtom,updateMilestoneAtom, milestonesAtom, applyMilestoneOverridesAtom, clearMilestoneOverridesAtom,
  taskStylesAtom, editTaskStyleAtom, createTaskStyleAtom, removeTaskStyleAtom, updateTaskAtom, tasksAtom, clearTaskOverridesAtom, applyTaskOverridesAtom,
  rowStylesAtom, editRowStyleAtom, createRowStyleAtom, removeRowStyleAtom, updateRowAtom, rowsAtom, clearRowOverridesAtom, applyRowOverridesAtom,
  groupStylesAtom, editGroupStyleAtom, createGroupStyleAtom, removeGroupStyleAtom, updateGroupAtom, groupsAtom,
  clearGroupOverridesAtom, applyGroupOverridesAtom,
} from "store";
import { chain } from "lodash";
import RemoveStyleModal from "./RemoveStyleModal";
import StyleItem from "./StyleItem";
import { Button } from "components";
import { ReactComponent as plusIcon } from "img/icon/plus.svg";
import { ReactComponent as updateIcon } from "img/icon/upload.svg";
import { ReactComponent as resetIcon } from "img/icon/reset.svg";
import s from "./StylesList.module.css";


export default function StylesList({title, type}) {
  const atoms = atomsMap[type];
  const ref = useRef();
  const dropTargets = useRef();
  const [ drag, setDrag ] = useState();
  const [ removeModal, setRemoveModal ] = useState();
  const [ rename, setRename ] = useState(false);
  const map = useAtomValue(mapAtom);
  const selected = useAtomValue(selectedAtom);
  const styles = useAtomValue(atoms.styles);
  const updateItem = useSetAtom(atoms.updateItem);
  const createStyle = useSetAtom(atoms.createStyle);
  const updateStyle = useSetAtom(atoms.editStyle);
  const clearOverrides = useSetAtom(atoms.clearOverrides);
  const applyOverrides = useSetAtom(atoms.applyOverrides);
  const setHistory = useSetAtom(historyAtom);

  const onChange = (e) => {
    const style = e.target.value;
    selected.forEach( id => updateItem({ id, style }));
    setHistory(`${type}-${selected.join("-")}-style-change`);
  }

  const value = chain(selected)
    .map((id) => map.get(id).style)
    .reduce((result, current) => (current === result ? result : null))
    .value();

  const add = () => {
    const style = createStyle(value);
    setHistory(`create-style-${style.id}`);
    setRename(style.id);
  }
  const reset = () => {
    selected.forEach(id => clearOverrides(id));
    setHistory(`clear-overrides-${selected.join("-")}`);
  }
  const apply = () => {
    selected.forEach(id => applyOverrides(id));
    setHistory(`apply-overrides-${selected.join("-")}`);
  }

  const onDragStart = ( id ) => {
    const nodes = ref.current.getElementsByClassName(s.label);
    const items = [...nodes].map( i => ({
      id: i.id,
      rect: i.getBoundingClientRect(),
    }) );
    const index = items.findIndex( i => i.id === id );
    const target = items[index].rect;

    dropTargets.current = items.filter( i => i.id !== id ).map( (item, i) => {
      const { top, height } = item.rect;
      const delta = (i >= index) ? target.height : 0;
      const pos = top + height / 2 - target.top - delta;
      return pos;
    }, []);

    setDrag({ from: index, to: index, delta: target.height, id });
  }
  const onDrag = ( delta ) => {
    if ( !dropTargets.current || !drag ) return;
    const index = dropTargets.current.findLastIndex( el => el < delta ) + 1;
    if ( index !== drag.to ) {
      setDrag({ ...drag, to: index });
    }
  }
  const onDragEnd = () => {
    setDrag( null );
    const { from, to, id } = drag;
    if ( from === to ) return;
    updateStyle({ id, index: to });
    setHistory(`${type}-styles-sort`);
  }

  const calcDelta = (index) => {
    if ( !drag || index === drag.from ) {
      return 0;
    } else if ( index < drag.from && index >= drag.to ) {
      return drag.delta;
    } else if ( index > drag.from && index <= drag.to ) {
      return -drag.delta;
    }
  } 

  const className = [
    s.list, drag && s.dragWrapper
  ].filter( Boolean ).join(" ");

  const closeModal = () => setRemoveModal(null);

  const hasOverrides = selected.some(id => !!map.get(id).overrides);

  return (
    <>
      <div className={s.wrapper}>
        <div className={s.toolbar}>
          <h2 className={s.title}>{title}</h2>
        </div>
        <ul className={className} ref={ref}>
          {styles.map(({id, name}, index) => (
            <StyleItem
              key={id}
              atoms={atoms}
              checked={value === id}
              onRemove={() => setRemoveModal(id)}
              removeable={styles.length > 1}
              prefix={type}
              isRename={rename === id}
              delta={calcDelta(index)}
              {...{index, id, name, onChange, setRename, onDragStart, onDrag, onDragEnd}}
            />
          ))}
        </ul>
        <div className={s.actions}>
          <Button
            secondary
            iconOnly
            tooltip="Create style"
            icon={plusIcon}
            onClick={add}
          />
          <Button
            secondary
            iconOnly
            tooltip="Update style"
            icon={updateIcon}
            onClick={apply}
            disabled={!hasOverrides}
          />
          <Button
            secondary
            iconOnly
            tooltip="Reset changes"
            icon={resetIcon}
            onClick={reset}
            disabled={!hasOverrides}
          />
        </div>
      </div>
      <RemoveStyleModal atoms={atoms} id={removeModal} styles={styles} closeModal={closeModal} />
    </>
  )
}


const atomsMap = {
  [SELECTABLE.MILESTONE]: {
    items: milestonesAtom,
    updateItem: updateMilestoneAtom,
    styles: milestoneStylesAtom,
    createStyle: createMilestoneStyleAtom,
    editStyle: editMilestoneStyleAtom,
    removeStyle: removeMilestoneStyleAtom,
    clearOverrides: clearMilestoneOverridesAtom,
    applyOverrides: applyMilestoneOverridesAtom,
  },
  [SELECTABLE.TASK]: {
    items: tasksAtom,
    updateItem: updateTaskAtom,
    styles: taskStylesAtom,
    createStyle: createTaskStyleAtom,
    editStyle: editTaskStyleAtom,
    removeStyle: removeTaskStyleAtom,
    clearOverrides: clearTaskOverridesAtom,
    applyOverrides: applyTaskOverridesAtom,
  },
  [SELECTABLE.ROW]: {
    items: rowsAtom,
    updateItem: updateRowAtom,
    styles: rowStylesAtom,
    createStyle: createRowStyleAtom,
    editStyle: editRowStyleAtom,
    removeStyle: removeRowStyleAtom,
    clearOverrides: clearRowOverridesAtom,
    applyOverrides: applyRowOverridesAtom,
  },
  [SELECTABLE.GROUP]: {
    items: groupsAtom,
    updateItem: updateGroupAtom,
    styles: groupStylesAtom,
    createStyle: createGroupStyleAtom,
    editStyle: editGroupStyleAtom,
    removeStyle: removeGroupStyleAtom,
    clearOverrides: clearGroupOverridesAtom,
    applyOverrides: applyGroupOverridesAtom,
  }
}