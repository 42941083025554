import { useEffect, useRef } from "react";
import { useAtom } from "jotai";
import { editingAtom, selectedAtom } from "store";
import s from "./TitleInput.module.css";

export default function TitleInput({value, id, onChange, className}) {
  const [editing, setEditing] = useAtom(editingAtom);
  const [selected, setSelected] = useAtom(selectedAtom);
  const ref = useRef();
  const prevEditing = useRef();

  useEffect(() => {
    if ( prevEditing.current === editing ) return;
    prevEditing.current = editing;
    if (selected.includes(id) && editing) ref.current.select();
  }, [selected, editing, id]);

  const onPointerDown = (e) => {
    e.stopPropagation();
    e.currentTarget.setPointerCapture(e.pointerId);
  }
  const stopPropagation = (e) => {
    e.stopPropagation();
  }

  const onKeyDown = (e) => {
    e.stopPropagation();
    if(e.key === "Enter" || e.key === "Escape") {
      setEditing(false);
    }
  }
  const onInput = (e) => {
    onChange(e.target.value);
  }
  const onFocus = () => {
    setSelected(id);
  }

  const cn = [
    s.wrapper,
    className && className,
    editing && s.editing
  ].filter(Boolean).join(" ");

  return (
    <div className={cn}>
      {editing ? <input
        ref= {ref}
        type="text"
        className={s.input}
        value={value}
        onInput={onInput}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onPointerDown={onPointerDown}
        onClick={stopPropagation}
      /> : null}
      <div className={s.value}>{value || "\u00A0"}</div>
    </div>
  )
}