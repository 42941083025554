import { useAtomValue } from "jotai";
import { columnEdgeGuideAtom, columnsAtom, ptAtom, pagePaddingAtom, PAGE_WIDTH, timelinePaddingAtom } from "store";
import s from "./ColumnEdgeGuide.module.css";


export default function ColumnEdgeGuide() {
  const guide = useAtomValue(columnEdgeGuideAtom);
  const columns = useAtomValue(columnsAtom);
  const pagePadding = useAtomValue(pagePaddingAtom);
  const timelinePadding = useAtomValue(timelinePaddingAtom);
  const pt = useAtomValue(ptAtom);
  if (guide === null) return null;

  let left;
  if (guide.reverse) {
    left = columns.slice(0, guide.index + 1).reduce((acc, el) => acc + el.width, 0);
    left += pagePadding[0];
    left += guide.padding ? timelinePadding[0] : 0;
  } else {
    left = columns.slice(guide.index).reduce((acc, el) => acc - el.width, PAGE_WIDTH);
    left -= pagePadding[1];
    left -= guide.padding ? timelinePadding[1] : 0;
  }
  left *= pt;
  
  return (
    <div className={s.guide} style={{left}} />
  )
}