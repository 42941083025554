import { useState } from "react"
import { Helmet } from "react-helmet";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { signUp } from "aws-amplify/auth";
import { EMAIL_REGEXP } from "store";
import { Button, Input, Checkbox, ModalMessage } from "components";
import { Wrapper } from "./SignIn";

import s from "./authorization.module.css";


export default function SignUp() {
  const locationState = useLocation().state;
  const navigate = useNavigate();
  const [startTime, setTime] = useState(Date.now());
  const [sending, setSending] = useState(false);
  const [name, setName] = useState(locationState?.name ?? "");
  const [email, setEmail] = useState(locationState?.email ?? "");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [consent, setConsent] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [error, setError] = useState("");
  const state = { name, email, from: "/signup" };

  const handleName = e => setName(e.target.value);
  const handleEmail = e => setEmail(e.target.value);
  const handleAddress = e => setAddress(e.target.value);
  const handlePassword = e => setPassword(e.target.value);
  const handleConsent = () => setConsent(!consent);
  const handleMarketing = () => setMarketing(!marketing);

  const handleSubmit = e => {
    e.preventDefault();
    if (sending) return;
    const time = Math.floor((Date.now() - startTime) / 1000);
    const _name = name.trim();
    if (!_name) {
      setError("NoName");
      return;
    } else if (_name.length < 2) {
      setError("ShortName");
      return;
    } else if (_name.length > 50) {
      setError("LongName");
      return;
    } else if (!/^[\p{L}\p{M}\s]+$/u.test(_name)) {
      setError("NameSpecialCharacters");
      return;
    } else if (!email) {
      setError("NoEmail");
      return;
    } else if (!email.match(EMAIL_REGEXP)) {
      setError("NotEmail");
      return;
    } else if (!password) {
      setError("NoPassword");
      return;
    } else if (password.length < 6) {
      setError("ShortPassword");
      return;
    } else if (!consent) {
      setError("NoConsent");
      return;
    }
    setSending(true);
    setTime(Date.now());
    signUp({
        username: email,
        password: password,
        options: {
          userAttributes: {
            name: _name,
            address: address,
            nickname: "",
            "custom:subscription": JSON.stringify(marketing),
          },
          validationData: {
            time: `${time}`
          },
          autoSignIn: true,
        },
    }).then(
      () => {
        window.gtag('event', 'conversion', {
          'send_to': 'AW-16673445972/86pQCIjy_soZENSwwo4-',
        });
        window.fbq('track', 'CompleteRegistration');
        navigate("/verify-email", { state });
      }
    ).catch(
      error => {
        console.log(error.name);
        setSending(false);
        setError(error.name);
      }
    );
  }

  const options = {
    NoName : "Name is required.",
    LongName : "Name has to be no longer than 50 characters.",
    ShortName: "Name has to be at least 2 characters long.",
    NameSpecialCharacters: "Special characters are not allowed in the name",
    NoEmail : "Email is required.",
    NotEmail : "Email seems to be incorrect. Please check for typos.",
    NoPassword : "Password is required.",
    ShortPassword : "Password has to be at least 6 characters long.",
    InvalidPasswordException : "Password has to be at least 6 characters long.",
    UsernameExistsException : () => <p>Account already exists. Try to <Link to="/login" className={s.link} state={state}>log in instead</Link>.</p>,
    TooManyRequestsException : "Sorry, we have received too many requests from your IP address, please wait a few minutes before trying again.",
    NoConsent : "You must agree to the Terms of use",
    default: "We are sorry, but it seems there is a problem on our side. Please try again later.",
  }

  return (
    <Wrapper>
      <Helmet title="Sign up" />
      <h1 className={s.title}>Create a new account</h1>
      <form className={s.form} onSubmit={handleSubmit}>
        <div>
          <Input
            id={"sign-up-name"}
            label={"Name"}
            type={"text"}
            value={name}
            autoComplete={"name"}
            required
            maxLength={50}
            onChange={handleName}
          />
        </div>
        <div>
          <Input
            id={"sign-up-email"}
            label={"Email"}
            type={"email"}
            value={email}
            autoComplete={"email"}
            required
            onChange={handleEmail}
          />
        </div>
        <div>
          <Input
            id={"sign-up-address"}
            label={"Address"}
            type={"text"}
            value={address}
            autoComplete={"off"}
            className={s.help}
            onChange={handleAddress}
          />
        </div>
        <div>
          <Input
            id={"sign-up-password"}
            label={"Password"}
            type={"password"}
            value={password}
            autoComplete={"new-password"}
            required
            minLength={6}
            onChange={handlePassword}
          />
        </div>
        <div>
          <Checkbox
            id={"sign-up-consent"}
            required
            value={consent}
            onChange={handleConsent}
          >
            I agree to <a href="https://deltaplan.io/terms/" className={s.link} target="_blank" rel="noreferrer">Terms of use</a>
          </Checkbox>
          <Checkbox
            id={"sign-up-marketing"}
            value={marketing}
            onChange={handleMarketing}
          >
            Subscribe to product tips and updates 
          </Checkbox>
        </div>
        <ModalMessage value={error} options={options} />
        <Button className={s.submit} disabled={sending} label="Create account" />
        <div className={s.footer}>
          <p className={s.footerRow}>Have an account? <Link to="/login" className={s.link} state={state}>Log in</Link></p>
        </div>
      </form>
    </Wrapper>
  )
}