import { post } from "aws-amplify/api";
import { useState } from "react";
import { useParams } from "react-router-dom";
import s from "./Unsubscribe.module.css";


export default function Unsubscribe() {
  const STATES = {
    SUBSCRIBED: "SUBSCRIBED",
    UNSUBSCRIBED: "UNSUBSCRIBED",
    MISSED: "MISSED",
    ERROR: "ERROR"
  };
  const { userId } = useParams();
  const [busy, setBusy] = useState(false);
  const [state, setState] = useState(STATES.SUBSCRIBED);

  const onClick = async () => {
    if (busy) return;
    setBusy(true);
    const isSubscribed = state === STATES.SUBSCRIBED;
    let path, targetState;
    if (isSubscribed) {
      path = `/unsubscribe/${userId}`;
      targetState = STATES.UNSUBSCRIBED;
    } else {
      path = `/subscribe/${userId}`;
      targetState = STATES.SUBSCRIBED;
    }
    try {
      const response = await post({
        apiName: "unsubscribe",
        path: path,
      }).response;
      const body = await response.body.json();
      if (body.$metadata.httpStatusCode !== 200) {
        throw new Error(body.$metadata.httpStatusCode);
      }
      setState(targetState);
    } catch (err) {
      if (err.message === "400") {
        setState(STATES.MISSED);
      } else {
        setState(STATES.ERROR);
      }
    } finally {
      setBusy(false);
    }
  }


  switch (state) {
    case STATES.SUBSCRIBED: {
      return <Subscribed onClick={onClick} busy={busy} />
    }
    case STATES.UNSUBSCRIBED: {
      return <Unsubscribed onClick={onClick} busy={busy} />
    }
    case STATES.MISSED: {
      return <Missed />
    }
    default: {
      return <Unexpected />
    }
  }
}

function Subscribed({ onClick, busy }) {
  return (
    <div className={s.wrapper}>
      <h1 className={s.title}>Unsubscribe</h1>
      <div className={s.description}>
        <p>We’re sad to see you go. If you confirm, you’ll no longer receive our emails, including updates, tips, and special offers.</p>
        <p>Changed your mind? No worries, you can simply close this page to stay subscribed. If you’re certain, click the button below to confirm:</p>
      </div>
      <button className={s.action} disabled={busy} onClick={onClick}>
        <span>Unsubscribe</span>
        <span className={s.spinner} />
      </button>
    </div>
  )
}

function Unsubscribed({ onClick, busy }) {
  return (
    <div className={s.wrapper}>
      <h1 className={s.title}>You are unsubscribed</h1>
      <div className={s.description}>
        <p>While you’re here, we’d love to know why you’re leaving so we can improve: <a href="https://deltaplan.io/contact">Send feedback</a></p>
        <p>If this was a mistake or you’d like to rejoin, press the button again.</p>
      </div>
      <button className={s.action} disabled={busy} onClick={onClick}>
        <span>Subscribe again</span>
        <span className={s.spinner} />
      </button>
    </div>
  )
}

function Missed() {
  return (
    <div className={s.wrapper}>
      <h1 className={s.title}>User not found</h1>
      <div className={s.description}>
        <p>We can't identify you to unsubscribe you from the mailing list. Please send us a message so we can resolve the problem.</p>
      </div>
      <a className={s.action} href='mailto:"Deltaplan support" <support@deltaplan.io>?subject=Unsubscribe&body=Any%20deatails%20would%20be%20helpful'>Contact support</a>
    </div>
  )
}

function Unexpected() {
  return (
    <div className={s.wrapper}>
      <h1 className={s.title}>Error occurred</h1>
      <div className={s.description}>
        <p>Something went wrong. Please send us a message so we can resolve the problem.</p>
      </div>
      <a className={s.action} href='mailto:"Deltaplan support" <support@deltaplan.io>?subject=Unsubscribe error&body=Any%20deatails%20would%20be%20helpful'>Contact support</a>
    </div>
  )
}