import { FORMAT_OPTIONS } from "store";
import { Select } from "components";
import { ReactComponent as edit } from "img/icon/edit.svg";
import s from "./FormatPicker.module.css";


export default function FormatPicker({ field, ...props }) {

  const classNames = { control: s.control };
  const options = FORMAT_OPTIONS[field]; 
  
  return (
    <Select
      isDisabled={!options}
      classNames={classNames}
      options={options}
      icon={edit}
      rightMenu
      iconControl
      {...props}
    />
  )
}