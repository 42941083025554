import { useEffect, useState } from 'react';
import { currencyFormat } from 'utils';
import { Link } from 'react-router-dom';
import { subscription } from './constants';
import { ReactComponent as Logo } from "img/logo.svg";
import { ReactComponent as Check } from "img/icon/milestone/check.svg";
import { ReactComponent as Arrow } from "img/icon/arrow-back.svg";
import s from "./Pricing.module.css";
import { useAtomValue } from 'jotai';
import { isLifetimeUserAtom, isSubscribedUserAtom } from 'store';


export default function Pricing({ paddle, address, isPayment, txnData, plan, setPlan }) {
  const [price, setPrice] = useState();
  useEffect(() => {
    if (isPayment) {
      // Read paddle checkout data
      if (!txnData) return;
      const totals = txnData.totals;
      const currency = txnData.currency_code;
      const { subtotal, tax, total, discount } = totals;
      const name = txnData.items[0].price_name;
      const id = txnData.items[0].price_id;
      const multiplyer = ["JPY", "KRW"].includes(currency) ? 1 : 100;
      setPrice({
        id,
        name,
        currency,
        subtotal: subtotal * multiplyer,
        discount: discount * multiplyer,
        tax: tax * multiplyer,
        total: total * multiplyer,
      })
    } else {
      // Request transaction preview
      if (!paddle) return;
      const request = { items: [subscription[plan]] };
      if (address) {
        request.address = address;
      } else if (txnData?.customer) {
        request.customerId = txnData.customer.id;
        request.addressId = txnData.customer.address.id;
      }
      paddle.TransactionPreview(request)
        .then(res => {
          const totals = res.data.details.totals;
          const { subtotal, tax, total } = totals;
          const name = res.data.items[0].price.name;
          const id = res.data.items[0].price.id
          setPrice({
            id,
            name,
            currency: totals.currencyCode,
            subtotal: Number(subtotal),
            tax: Number(tax),
            total: Number(total),
          })
        })
        .catch(console.log);
    }
  }, [paddle, address, txnData, isPayment, plan]);

  if (!price) return (
    <div className={s.wrapper}>
      <div>
        <LinkBack />
      </div>
    </div>
  );
  const isBusy = price.id !== subscription[plan].priceId;

  const subtotal = currencyFormat(price.subtotal, price.currency);
  const tax = currencyFormat(price.tax, price.currency);
  const discount = currencyFormat(price.discount, price.currency);
  const total = currencyFormat(price.total, price.currency);
  const name =  price.name;
  const hasDiscount = !!price.discount;
  const hasTotal = !!price.total || hasDiscount;

  let title, comment, totalLabel;
  if (plan === "update") {
    title = "Payment method update";
    totalLabel = "Total due today:";
    if (hasTotal) {
      comment = "Update payment method to pay your outstanding subscription and ensure continued access to our services.";
    } else {
      comment = "Keep your information current and ensure uninterrupted access to our services.";
    }
  } else {
    title = "Checkout";
    if (price.id === subscription.lifetime.priceId) {
      totalLabel = "Total:";
    } else {
      totalLabel = "Total due today:";
    }
  }

  const pricesCn = [
    s.pricesWrapper,
    isBusy && s.busy
  ].filter(Boolean).join(" ");

  return (
    <div>
      <LinkBack />
      <h1 className={s.title}>{title}</h1>
      <p className={s.comment}>{comment}</p>
      {hasTotal ? (
        <div className={pricesCn}>
          <PlanControl onChange={setPlan} value={plan} />
          <div className={s.pricingRow}>
            <span>{name}:</span>
            <span>{subtotal}</span>
          </div>
          {hasDiscount && (
            <div className={s.pricingRow}>
              <span>Discount:</span>
              <span>-{discount}</span>
            </div>
          )}
          <div className={s.pricingRow}>
            <span>Taxes:</span>
            <span>{tax}</span>
          </div>
          <div className={`${s.pricingRow} ${s.pricingTotal}`}>
            <span>{totalLabel}</span>
            <span>{total}</span>
          </div>
        </div>
      ) : (
        <div className={s.benefits}>
          <Benefit>You won't be charged until the next billing date.</Benefit>
        </div>
      )}
      {plan !== "update" && (
        <div className={s.benefits}>
          {plan === "lifetime" ? (
            <Benefit>One-time payment</Benefit>
          ) : (
            <Benefit>Cancel anytime</Benefit>
          )}
          <Benefit>14 Days Money Back Guarantee</Benefit>
          <Benefit>Safe and Secure Payments</Benefit>
        </div>
      )}
    </div>
  )
}

function LinkBack() {
  return (
    <Link className={s.backLink} to="/settings/billing">
      <Arrow className={s.backArrow} />
      <Logo className={s.logo} />
    </Link>
  )
}

function Benefit({children}) {
  return (
    <p><Check />{children}</p>
  )
}

function PlanControl({ value, onChange }) {
  const handleChange = (e) => {
    onChange(e.target.value);
  };
  const isSubscribed = useAtomValue(isSubscribedUserAtom);
  const isLifetime = useAtomValue(isLifetimeUserAtom);

  if (isSubscribed) return null;

  const options = [
    {
      label: "Monthly",
      value: "monthly"
    }, {
      label: "Annual",
      value: "annual"
    }
  ];
  if (!isLifetime) {
    options.push({
      label: "Lifetime",
      value: "lifetime"
    });
  }

  if (!options.find(op => op.value === value)) {
    return null;
  }
  return (
    <div className={s.planSwitchWrapper}>
      {options.map(op => (
        <PlanOption key={op.value} value={op.value} label={op.label} active={op.value === value} onChange={handleChange} />
      ))}
    </div>
  )
}
function PlanOption({ label, value, active, onChange }) {
  const cn = [
    s.planSwitchOption,
    active && s.planSwitchActiveOption
  ].filter(Boolean).join(" ");
  return (
    <label className={cn}>
      <input type='radio' name="plan" value={value} onChange={onChange} checked={active} />
      <span>{label}</span>
    </label>
  )
}