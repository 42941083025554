import { useRef } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { historyAtom, mapAtom, selectedAtom, teamAtom, updateTaskAtom, tempMemberAtom, createTeamMemberAtom, currencyPlacementAtom, currencySignAtom } from "store";
import { Select } from "components";
import { createFilter } from "react-select";

import s from "./AssignMember.module.css";


export default function AssignMember({ exclude }) {
  const ref = useRef();
  const placement = useAtomValue(currencyPlacementAtom);
  const sign = useAtomValue(currencySignAtom);
  const selected = useAtomValue(selectedAtom);
  const team = useAtomValue(teamAtom);
  const map = useAtomValue(mapAtom);
  const updateTask = useSetAtom(updateTaskAtom);
  const setTempMember = useSetAtom(tempMemberAtom);
  const createTeamMember = useSetAtom(createTeamMemberAtom);
  const setHistory = useSetAtom(historyAtom);


  const options = team
  .filter( el => !exclude.includes( el.id ) )
  .map( el => ({
    value: el.id,
    role: el.role,
    name: el.name,
    rate: el.rate,
  }))

  const onChange = ( value ) => {
    selected.forEach( id => {
      const { workgroup } = map.get(id);
      if (workgroup.some( member => member.id === value )) {
        return;
      }
      updateTask({
        id,
        workgroup: [ ...workgroup, { id: value, allocation: 100 } ]
      });
    })
    setHistory();
  }

  const onKeyDown = ( e ) => {
    if (e.key === "Enter" && !ref.current.getFocusableOptions().length)
    ref.current.blur();
    if (e.key === "Escape")
    ref.current.blur();
  }

  const filterOption = createFilter({
    stringify: el => `${el.data.role} ${el.data.name}`,
  })

  const prefix = placement === "prefix" ? sign : "";
  const suffix = placement === "suffix" ? sign : "";
  const formatOptionLabel = ({ value, role, name, rate }) => (
    (role || name) ? (
    <>
      <div className={s.optionNameWrapper}>
        <span className={s.optionRole}>{role}</span>
        <span className={s.optionName}>{name}</span>
      </div>
      <span className={s.optionRate}>{prefix}{rate}{suffix}</span>
    </>
    ) : (
      <div>Create <b>{value}</b></div>
    )
  )

  const onCreateOption = ( role ) => {
    const { id } = createTeamMember({ role });
    setTempMember( id );
    onChange( id );
  }

  return (
    <Select
      innerRef={ref}
      classNames={{
        control: s.control,
        placeholder: s.placeholder,
        option: s.optionWrapper
      }}
      options={options}
      filterOption={filterOption}
      formatOptionLabel={formatOptionLabel}
      isSearchable={true}
      menuPlacement={"bottom"}
      placeholder={"Assign member"}
      controlShouldRenderValue={false}
      noOptionsMessage={NoOptions}
      blurInputOnSelect={false}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onCreateOption={onCreateOption}
      icon={null}
    />
  )
}


function NoOptions() {
  return (
    <div className={s.noOptionsMessage}>No members found</div>
  )
}