import { useAtom, useAtomValue } from "jotai";
import { mapAtom, selectedAtom, selectedTypeAtom, SELECTABLE } from "store";
import { Segmented } from "components";

import s from "./LvlSwitch.module.css";


export default function LvlSwitch() {
  const [selectedType, setSelectedType] = useAtom(selectedTypeAtom);
  const selected = useAtomValue(selectedAtom);
  const map = useAtomValue(mapAtom);


  const checkParent = (id) => {
    return map.get("root").children.reduce(
      (result, el) => (el === id || map.get(el).children.includes(id)) || result, false
    )
  }
  const isRootParent = selected.reduce(
    (result, id) => checkParent(id) && result, true
  )

  const onChange = (val) => {
    setSelectedType(val);
  }

  return (
    <div className={s.wrapper}>
      <Segmented
        className={s.switch}
        optionClassName={s.option}
        showLabels={true}
        onChange={onChange}
        value={selectedType}
        options={[
          {
            label: "Group",
            value: SELECTABLE.GROUP,
            disabled: isRootParent,
          },
          {
            label: "Row",
            value: SELECTABLE.ROW,
          },
          {
            label: "Task",
            value: SELECTABLE.TASK,
          },
        ]}
      />
    </div>
  )
}