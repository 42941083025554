import {v4 as uid} from "uuid";
import { atom } from "jotai";
import { cloneDeep } from "lodash";
import { isEqualStrings } from "utils";
import { mapAtom } from "./planner";
import { groupStylesAtom } from ".";


export const groupsAtom = atom([]);

export const createGroupAtom = atom(null, (get, set, props = {}) => {
  const groups = get(groupsAtom);
  const defaultProps = {
    id: uid(),
    type: "group",
    title: "",
    style: get(groupStylesAtom)[0].id,
    unfolded: true,
    children: [],
  };
  const { index, ...providedProps } = props;
  const result = { ...defaultProps, ...providedProps };
  const i = index ?? groups.length;
  const newGroups = [
    ...groups.slice(0, i),
    result,
    ...groups.slice(i)
  ];
  set(groupsAtom, newGroups);
  return result;
})

export const splitGroupAtom = atom(null, (get, set, rowId) => {
  // Find group
  const groups = get(groupsAtom);
  const groupIndex = groups.findIndex(el => el.children.includes(rowId));
  const target = groups[groupIndex];

  // Split children
  const splitIndex = target.children.findIndex(el => el === rowId);
  const childrenLeft = target.children.slice(0, splitIndex);
  const childrenMoved = target.children.slice(splitIndex);

  // Update group
  set(updateGroupAtom, {
    id: target.id,
    children: childrenLeft
  });

  // Create new group
  const newGroup = Object.assign(
    cloneDeep(target),
    {
      index: groupIndex + 1,
      id: uid(),
      unfolded: true,
      children: childrenMoved,
    }
  );
  set(createGroupAtom, newGroup);

  return newGroup;
})

export const updateGroupAtom = atom(null, (get, set, props) => {
  const target = get(mapAtom).get(props.id);
  if (!target || target.type !== "group") return false;

  const group = Object.assign(cloneDeep(target), props);
  const result = get(groupsAtom).map( el => el === target ? group : el );
  set(groupsAtom, result);
  return group;
})

export const moveGroupAtom = atom(null , (get, set, {id, index}) => {
  const groups = get(groupsAtom);
  const fromIndex = groups.findIndex( el => el.id === id );
  const upd = [...groups];
  const el = upd.splice(fromIndex, 1)[0];
  upd.splice(index, 0, el);
  set(groupsAtom, upd);
});

export const deleteGroupAtom = atom(null, (get, set, id) => {
  const groups = get(groupsAtom);
  const index = groups.findIndex( el => el.id === id);

  const target = groups[index];
  const previous = groups[index - 1];

  if (!target || target.type !== "group" || target.id === "root") return;

  const updated = {...previous, children: [...previous.children, ...target.children]};
 
  const newGroups = groups
    .map( el => el === previous ? updated : el)
    .filter( el => el !== target )
  ;
  set(groupsAtom, newGroups);
})

export const groupDragAtom = atom();

export const groupFindOrCreateAtom = atom(null, (get, set, props) => {
  const groups = get(groupsAtom);

  if (!props?.title) {
    return groups.find(
      group => group.id === "root"
    );
  }

  return groups.find(
    group => isEqualStrings(group.title, props.title)
  ) || set(
    createGroupAtom, props
  );
})