import { useEffect, useRef, useState } from "react";
import Input from "rc-input-number";

import { ReactComponent as down } from "img/icon/input-down.svg"
import { ReactComponent as up } from "img/icon/input-up.svg"

import s from "./NumberInput.module.css";


export default function NumberInput({title, id, value, min = -Infinity, max = Infinity, step = 1, disabled, onChange, className, inputClassName, unit = "", instant, precision, icon: Icon, mixed, ...rest}) {
  const instantChange = useRef();
  const abort = useRef(false);
  const [tempVal, setTempVal] = useState(value);

  useEffect(() => setTempVal(value), [value]);

  const onInput = (value) => {
    if (instantChange.current || instant) {
      apply(value);
    } else {
      setTempVal(value);
    }
  }

  const onKeyDown = (e) => {
    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      instantChange.current = true;
    } else if (e.key === "Escape") {
      abort.current = true;
      e.target.blur();
    }
  }
  const onKeyUp = (e) => {
    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      instantChange.current = false;
    }
  }

  const onPointerDown = () => instantChange.current = true;
  const onPointerUp = () => instantChange.current = false;

  const onBlur = (e) => {
    const regexp = /-?\d+(?:[.]\d+)?/;
    const result = e.target.value.replaceAll(",", ".").match(regexp)?.[0];
    if (abort.current || !result) {
      abort.current = false;
      setTempVal(value);
    } else {
      apply(Number(result));
    }
  }
  const onEnter = (e) => e.target.blur();

  const clamp = (val) => Math.min(Math.max(Number(val), min), max);
  const apply = (val) => isNaN(val) ? false : onChange(clamp(val));

  const cn = [
    s.wrapper, className, disabled && s.disabled
  ].filter(Boolean).join(" ");
  const inputCn = [
    s.inputWrapper, inputClassName
  ].filter(Boolean).join(" ");

  return (
    <div className={cn} {...rest}>
      {title && <label className={s.label} htmlFor={id}>{title}</label>}
      <div className={inputCn}>
        <Input
          id={id}
          className={s.input}
          value={mixed ? "" : tempVal}
          placeholder={mixed ? "Mixed" : ""}
          onChange={onInput}
          onBlur={onBlur}
          onPressEnter={onEnter}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          disabled={disabled}
          min={min}
          max={max}
          step={step}
          precision={precision}
          upHandler={
            <Handler
            icon={up}
            alt="More"
            onPointerDown={onPointerDown}
            onPointerUp={onPointerUp}
            onPointerOut={onPointerUp}
            />
          }
          downHandler={
            <Handler
            icon={down}
            alt="Less"
            onPointerDown={onPointerDown}
            onPointerUp={onPointerUp}
            onPointerOut={onPointerUp}
            />
          }
        />
        <div className={s.unit}>{unit}</div>
        {Icon && <Icon className={s.icon} />}
      </div>
    </div>
  )
}

const Handler = ({icon: Icon, alt, ...props}) => (
  <div {...props} className={s.arrowWrapper}>
    <Icon className={s.arrow} />
  </div>
)