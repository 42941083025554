import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { tasksAtom } from "./tasks";
import { milestonesAtom } from "./milestones";
import { FREE_ELEMENTS_LIMIT } from "./constants";
import { hasLifetime, hasSubscription, isPaidUser } from "utils";

export const userAtom = atom();

export const isPaidUserAtom = atom((get) => {
  const user = get(userAtom);
  return (isPaidUser(user));
});
export const isLifetimeUserAtom = atom((get) => {
  const user = get(userAtom);
  return (hasLifetime(user));
});
export const isSubscribedUserAtom = atom((get) => {
  const user = get(userAtom);
  return (hasSubscription(user));
});

export const callToUpgradeModalAtom = atom(false);

const _copySampleDataAtom = atomWithStorage("copySampleData", true);
export const copySampleDataAtom = atom(
  (get) => {
    const sub = get(isPaidUserAtom);
    const val = get(_copySampleDataAtom);
    return sub && val;
  },
  (_, set, val) => {
    set(_copySampleDataAtom, val);
  } 
)

export const newItemsAllowedAtom = atom((get) => {
  const hasSubscription = get(isPaidUserAtom);
  if (hasSubscription) return true;
  const tasks = get(tasksAtom);
  const milestones = get(milestonesAtom);
  const count = tasks.length + milestones.length;
  return count < FREE_ELEMENTS_LIMIT;
})