import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { userAtom, historyAtom, workdaysAtom, currencyPlacementAtom, currencySignAtom, GUEST_USER } from "store";

import Section from "../../components/Section/Section";
import Input from "../../components/Input/Input";
import { Segmented } from "components";
import CheckboxGroup from "components/CheckboxGroup";
import { Link } from "react-router-dom";

import s from "./Style.module.css";


export default function Preferences() {
  const user = useAtomValue(userAtom) !== GUEST_USER;
  const toolbar = user ? (
    <Link target="_blank" className={s.toolbarLink} to="/settings/preferences">Edit defaults</Link>
  ): null;

  return (
    <Section title="Preferences" toolbar={toolbar}>
      <Currency />
      <Workdays />
    </Section>
  )
}

function Currency() {
  const [placement, setPlacement] = useAtom(currencyPlacementAtom);
  const [sign, setSign] = useAtom(currencySignAtom);
  const setHistory = useSetAtom(historyAtom);

  const onPlacementChange = (val) => {
    setPlacement(val);
    setHistory("currency-change");
  }
  const onSignChange = (val) => {
    setSign(val.slice(-1));
    setHistory("currency-change");
  }

  return (
    <div className={s.currencyInput}>
      <Segmented
        label="Currency sign"
        onChange={onPlacementChange}
        value={placement}
        secondary
        showLabels
        options={[
          { value: "prefix", label: "Prefix" },
          { value: "suffix", label: "Suffix" }
        ]}
      />
      <Input
        value={sign}
        onChange={onSignChange}
        id={"currency-sign"}
      />
    </div>
  )
}

function Workdays() {
  const [workdays, setWorkdays] = useAtom(workdaysAtom);
  const setHistory = useSetAtom(historyAtom);
  const handleChange = (val) => {
    const result = val.map(op => op.value);
    result.unshift(result.pop());
    setWorkdays(result);
    setHistory("workdays-change");
  }
  const options = [
    {
      value: workdays[1],
      label: "Monday"
    }, {
      value: workdays[2],
      label: "Tuesday"
    }, {
      value: workdays[3],
      label: "Wednesday"
    }, {
      value: workdays[4],
      label: "Thursday"
    }, {
      value: workdays[5],
      label: "Friday"
    }, {
      value: workdays[6],
      label: "Saturday"
    }, {
      value: workdays[0],
      label: "Sunday"
    }
  ];
  return (
    <div>
      <CheckboxGroup
        label="Working days"
        secondary
        options={options}
        onChange={handleChange}
      />
    </div>
  )
}