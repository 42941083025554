import { useAtomValue, useSetAtom } from "jotai";
import { isEqual, map, merge } from "lodash";
import { isColor, isEqualAll, uniqueValues } from "utils";
import { mapAtom, selectedAtom, editModeAtom, historyAtom, EDIT_MODES } from "store";

export function useStyleEdit({
  editAtom,
  styles,
  getter = (style) => style,
  ...props
}) {
  const setHistory = useSetAtom(historyAtom);
  const edit = useSetAtom(editAtom);

  const suffix = props.suffix || map(styles, "id").join("-");

  const setter = props.setter || ((key, val, { id }) => ({ id, [key]: val }));

  const refStyle = mixObjects(styles, getter);

  const onChange = (key, val) => {
    if (
      !refStyle[key]?.mixed &&
      isEqual(val === refStyle[key]?.value)
    ) return;

    styles.forEach(style => (
      edit(setter(key, val, style))
    ));
    setHistory(`${key}-change-${suffix}`);
  }

  const valueChange = (key) => {
    return (val) => onChange(key, val);
  }
  const opacityChange = (key) => {
    return (val) => {
      if (
        !refStyle[key]?.mixedAlpha &&
        isEqual(val === refStyle[key]?.value.a)
      ) return;

      styles.forEach(style => {
        const { a, ...newVal } = getter(style)[key];
        newVal.a = val / 100;
        edit(setter(key, newVal, style));
      })
      setHistory(`${key}-change-${suffix}`);
    };
  }
  const toggleChange = (key) => {
    return () => onChange(key, (refStyle[key]?.mixed ?? true) || (!refStyle[key]?.value ?? true));
  }
  const colorChange = (key) => {
    return ({ hsva }) => {
      const { a: refA, ...refColor } = refStyle[key].value;
      const { a: newA, ...newColor } = hsva;

      const isColChanged = !isEqual(refColor, newColor);
      const isAlphaChanged = !isEqual(refA, newA);
      if (!isColChanged && !isAlphaChanged) return;

      styles.forEach(style => {
        const { a: styleA, ...styleColor } = getter(style)[key];
        const result = isColChanged ? newColor : styleColor;
        result.a = isAlphaChanged ? newA : styleA;
        edit(setter(key, result, style));
      });
      setHistory(`${key}-change-${suffix}`);
    };
  }

  return [refStyle, valueChange, toggleChange, colorChange, opacityChange];
}

export function mixObjects(
  items,
  getter = (style) => style
) {

  if (!items.length) return null;
  const style = getter(items[0]);
  if (!style) return [];
  const refStyle = Object.entries(style).reduce(
    (result, [key, value]) => {
      const mixed = !isEqualAll(...items.map(s => getter(s)[key]));
      result[key] = { value, mixed };
      if (isColor(value)) {
        result[key].mixedAlpha = !isEqualAll(...items.map(s => getter(s)[key].a));
      }
      return result;
    }, {}
  );
  return refStyle;
}

export function useGetStyles(stylesMapAtom) {
  const map = useAtomValue(mapAtom);
  const selected = useAtomValue(selectedAtom);
  const stylesMap = useAtomValue(stylesMapAtom);
  const editMode = useAtomValue(editModeAtom);
  const items = selected.map(id => map.get(id));
  const isOverriding = editMode === EDIT_MODES.ELEMENT;

  let styles;
  switch (editMode) {
    case EDIT_MODES.GLOBAL: {
      styles = Object.values(stylesMap);
      break;
    }
    case EDIT_MODES.ELEMENT: {
      styles = (
        items.map(
          ({ id, style, overrides }) => merge(
            {},
            stylesMap[style],
            overrides,
            { id, hasOverrides: !!overrides }
          )
        )
      )
      break;
    }
    case EDIT_MODES.STYLE:
    default: {
      styles = (
        items
          .map(item => stylesMap[item.style])
          .filter(uniqueValues)
      )
    }
  }

  return [items, styles, isOverriding];
}