import { useStyleEdit } from 'utils';
import Section from '../../components/Section/Section';
import ColorPicker from '../../components/ColorPicker/ColorPicker';
import NumberInput from '../../components/NumberInput/NumberInput';
import SliderInput from '../../components/Slider/Slider';
import { ReactComponent as Spacing } from "img/icon/spacing.svg";

export default function RowAppearance({ styles, editAtom }) {
  const [
    p, valueChange, toggleChange, colorChange, opacityChange
  ] = useStyleEdit({ editAtom, styles });

  return p ? (
    <Section title="Appearance">
      <div>
        <ColorPicker
          title={"Background"}
          id={"row-fill"}
          color={p.background.value ?? { h: 0, s: 0, v: 0, a: 1 }}
          mixed={p.background.mixed}
          disabled={!p.backgroundShow.mixed && !p.backgroundShow.value}
          mixedDisabled={p.backgroundShow.mixed}
          onChange={colorChange("background")}
          onToggle={toggleChange("backgroundShow")}
        />
        <NumberInput
          title="Opacity"
          id={"row-fill-alpha"}
          value={Math.floor(p.background.value.a * 100)}
          mixed={p.background.mixedAlpha}
          disabled={!p.backgroundShow.mixed && !p.backgroundShow.value}
          onChange={opacityChange("background")}
          min={0}
          max={100}
          unit="%"
        />
      </div>
      <div>
        <SliderInput
          title={"Spacing"}
          id={"row-padding"}
          value={p.padding.value ?? ""}
          mixed={p.padding.mixed}
          onChange={valueChange("padding")}
          min={0}
          sliderMax={32}
          max={160}
          icon={Spacing}
        />
      </div>
    </Section>
  ) : null
}