import { atom } from "jotai";
import { v4 as uid } from "uuid";
import { isEqualStrings } from "utils";
import { tasksAtom, updateTaskAtom } from "./tasks";

export const teamAtom = atom([]);

export const tempMemberAtom = atom();

export const createTeamMemberAtom = atom(null, 
  (get, set, upd) => {
    const team = get( teamAtom );
    const defaultMember = {
      id: uid(),
      name: "",
      role: "",
      rate: 0,
    }
    const member = { ...defaultMember, ...upd };
    set( teamAtom, [ ...team, member ] );
    return member;
  }
)

export const updateTeamMemberAtom = atom(null,
  (get, set, upd) => {
    if (!upd) return false;
    const team = get( teamAtom );
    const target = team.find( el => el.id === upd.id );
    if ( !target ) return false;
    const member = { ...target, ...upd };
    const result = team.map( el => el === target ? member : el );
    set( teamAtom, result );
    return member;
  }
)

export const removeTeamMemberAtom = atom(null,
  (get, set, id) => {
    const team = get(teamAtom).filter( member => member.id !== id);
    set(teamAtom, team);
    get(tasksAtom).forEach( task => {
      const workgroup = task.workgroup.filter( member => member.id !== id );
      set(updateTaskAtom, { id: task.id, workgroup });
    })
  }
)

export const changeTeamMemberIndexAtom = atom(null,
  (get, set, {id, index}) => {
    const team = [...get(teamAtom)];
    const targetIndex = team.findIndex( member => member.id === id );
    const target = team.splice( targetIndex, 1)[0];
    team.splice( index, 0, target );
    set(teamAtom, team);
  }
)


export const teamMemberFindOrCreateAtom = atom(null, (get, set, assignee) => {
  // Handle empty 
  if (!assignee || !(assignee.name || assignee.role)) {
    return null;
  }

  // Get team
  const team = get(teamAtom);

  // Search by name
  if (assignee.name) {
    const match = team.find(
      person => isEqualStrings(person.name, assignee.name)
    );
    console.log(assignee, match);
    if (match) return match;
  }

  // Search by role
  if (assignee.role) {
    const match = team.find(
      person => (!assignee.name || !person.name) && isEqualStrings(person.role, assignee.role)
    )
    if (match) return match;
  }

  // Create new member
  return set(createTeamMemberAtom, assignee);
})

export const mapWorkgroupToTeamAtom = atom(null, (_, set, workgroup) => {
  console.log(workgroup);
  return workgroup
    .map(
      assignee => {
        const { allocation, ...props } = assignee;
        // Find team member
        const id = set(teamMemberFindOrCreateAtom, props)?.id;
        if (!id) return null;
        // Prepare assignee record
        return {
          id, allocation: allocation * 100
        }
      }
    )
    // Remove nulls
    .filter(Boolean)
    // Remove duplicates
    .filter((current, index, arr) => {
      return arr.findIndex(assignee => assignee.id === current.id) === index;
    });
})