import { useRef } from "react";
import ReactSelect from "react-select";
import Creatable from "react-select/creatable";
import { ReactComponent as arrow } from "img/icon/arrow-down.svg";
import s from "./Select.module.css";

export default function Select({
  classNames = {},
  styles = defaultStyles,
  onChange = () => { },
  onKeyDown = () => { },
  icon = arrow,
  title, id, wrapperClass, className, innerRef,
  components, onCreateOption, value, iconControl, rightMenu, ...rest
}) {
  const handleChange = (e) => onChange(e.value);
  const componentRef = useRef();
  const ref = innerRef ?? componentRef;

  const handleKeyDown = (e) => {
    onKeyDown(e);
    if (e.code === "Escape") {
      ref.current.blur();
    }
  }

  const cn = [
    s.select,
    className,
  ].filter(Boolean).join(" ");

  const staticClassNames = [
    "container", "valueContainer", "input", "placeholder", "singleValue", "menu", "menuList", "noOptionsMessage"
  ].reduce((acc, name) => {
    acc[name] = () => [
      s[name],
      classNames[name]
    ].filter(Boolean).join(" ");
    return acc;
  }, {});
  const _classNames = [
    "control", "option",
  ].reduce((acc, name) => {
    acc[name] = ({ isFocused, isDisabled }) => [
      isFocused && s.focused,
      isDisabled && s.disabled,
      s[name],
      classNames[name]
    ].filter(Boolean).join(" ");
    return acc;
  }, staticClassNames);

  const wrapperClassName = [
    s.wrapper,
    wrapperClass,
    rightMenu && s.floatRight,
    iconControl && s.iconControl,
  ].filter(Boolean).join(" ");

  const IndicatorsContainer = () => <Indicators icon={icon} />
  const Component = onCreateOption ? Creatable : ReactSelect;

  const selected = rest.options?.find( option => option.value === value );

  const props = {
    unstyled: true,
    isSearchable: false,
    tabSelectsValue: false,
    captureMenuScroll: false,
    menuPlacement: "auto",
    blurInputOnSelect: true,
    menuShouldScrollIntoView: true,
  };
  Object.assign(props, rest)

  return (
    <div className={wrapperClassName}>
      {title && <label className={s.label} htmlFor={id}>{title}</label>}
      <Component
        inputId={id}
        ref={ref}
        styles={styles}
        className={cn}
        value={selected}
        classNames={_classNames}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        components={{ IndicatorsContainer, ...components }}
        onCreateOption={onCreateOption}
        {...props}
      />
    </div>
  )
}

const defaultStyles = {
  container: () => { },
  control: () => { },
  option: () => { },
}

function Indicators({ icon: Icon }) {
  return Icon ? (
    <div className={s.icon}>
      <Icon className={s.iconImg} draggable={false} />
    </div>
  ) : null
}

export function MainSelect({ wrapperClass, ...props }) {
  const className = [
    s.mainSelect,
    wrapperClass
  ].filter(Boolean).join(" ");
  return (
    <Select wrapperClass={className} {...props} />
  )
}