import { useEffect, useState } from 'react';
import { initializePaddle } from '@paddle/paddle-js';
import { redirect, useLoaderData } from 'react-router-dom';
import { createStore, Provider } from 'jotai';
import { GUEST_USER, userAtom } from 'store';
// import { get } from 'aws-amplify/api';
import { getUser, hasLifetime, hasSubscription } from 'utils';
import { Helmet } from "react-helmet";
import Pricing from './Pricing';
import AddressStep from './AddressStep';
import PaymentStep from './PaymentStep';
import Success from './Success';
import { ReactComponent as Arrow } from 'img/icon/go-to.svg';
import imageSrc from "img/checkout-success.riv";
import s from './Checkout.module.css';

export default function Checkout() {
  const { user, store, txn, transactionType } = useLoaderData();
  const [paddle, setPaddle] = useState();
  const [txnData, setTxnData] = useState();
  const [ids, setIds] = useState(JSON.parse(user["custom:billing"] || null));
  const [plan, setPlan] = useState(transactionType);
  const [address, setAddress] = useState();
  const [step, setStep] = useState((ids || txn) ? "payment" : "address");
  const [image, setImage] = useState();

  // Initialize Paddle
  useEffect(() => {
    window.fbq('track', 'InitiateCheckout');
    initializePaddle({
      environment: process.env.REACT_APP_ENV === "dev" ? "sandbox" : "production",
      token: process.env.REACT_APP_PADDLE_TOKEN,
      eventCallback: (e) => {
        if (!e.name) return;
        if (e.name === "checkout.completed") {
          setStep("success"); /* Success message on transaction complete */
        } else {
          setTxnData(e.data); /* Read transaction updates */
        }
      },
    })
      .then(setPaddle)
      .catch(console.log);
  }, []);

  // Loading rive animation
  useEffect(() => {
    fetch(imageSrc)
    .then(res => res.arrayBuffer())
    .then(setImage)
    .catch(console.log);
  },[]);

  const onAddressSubmit = (ids) => {
    setIds(ids);
    setTxnData(null);
    setStep("payment");
  }
  const onAddressChange = (address) => {
    setAddress(address);
    setTxnData(null);
  }

  return step === "success" ? (
    <Success img={image} isUpdate={!!txn} />
  ) : (
    <Provider store={store}>
      <div className={s.pageWrapper}>
        <Helmet title="Upgrade" />
        <div className={s.columnWrapper}>
          <Pricing
            paddle={paddle}
            isPayment={step === "payment"}
            txnData={txnData}
            address={address}
            plan={plan}
            setPlan={setPlan}
          />
        </div>
        <div className={s.columnWrapper}>
          <div>
            {!txn && <Breadcrumbs step={step} setStep={setStep} />}
            {step === "address" ? (
              <AddressStep txnData={txnData} onChange={onAddressChange} onSubmit={onAddressSubmit} />
            ) : step === "payment" ? (
              <PaymentStep paddle={paddle} txn={txn} ids={ids} plan={plan} />
            ) : null}
          </div>
        </div>
      </div>
    </Provider>
  )
}


function Breadcrumbs({ step, setStep }) {
  const steps = ["address", "payment"];
  const pos = steps.indexOf(step);
  const onClick = (index) => {
    if (index >= pos) return;
    setStep(steps[index]);
  }

  return (
    <div className={s.stepsWrapper}>
      {
        ["Information", "Payment"].map((label, index) => (
          <Step
            key={label}
            onClick={() => onClick(index)}
            active={pos > index}
            current={pos === index}
            index={index}
          >
            {label}
          </Step>
        ))
      }
    </div>
  );
}

function Step({ children, active, current, onClick, index }) {
  const className = [
    s.step,
    active && s.active,
    current && s.current,
  ].filter(Boolean).join(" ");

  return (
    <div className={className}>
      {(index > 0) && <Arrow />}
      <div onClick={onClick}>{children}</div>
    </div>
  )
}


export async function checkoutLoader({ request, params }) {
  try {
    const store = createStore();
    const user = await getUser();
    if (user === GUEST_USER) {
      return redirect("/signup");
    }
    store.set(userAtom, user);
    const url = new URL(request.url);
    const txn = url.searchParams.get('_ptxn');

    let transactionType = params.productId || "monthly";
    if (hasLifetime(user) && hasSubscription(user) && !txn) {
      return redirect("/");
    } else if (txn) {
      transactionType = "update";
    } else if (hasLifetime(user) && transactionType === "lifetime") {
      transactionType = "monthly";
    } else if (hasSubscription(user) && (transactionType === "monthly" || transactionType === "annual")) {
      transactionType = "lifetime";
    }
    return { store, user, txn, transactionType };
  } catch {
    return redirect("/signup");
  }
}