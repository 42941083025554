import { ButtonGroup } from "components";
import { ReactComponent as boldIcon } from "img/icon/text-bold.svg";
import { ReactComponent as italicIcon } from "img/icon/text-italic.svg";
import { ReactComponent as underlineIcon } from "img/icon/text-underline.svg";


export default function TextStyle({ bold, italic, underline, toggleBold, toggleItalic, toggleUnderline }) {
  return (
    <ButtonGroup options={[
      {
        label: "Bold",
        active: bold,
        icon: boldIcon,
        toggle: toggleBold,
      }, {
        label: "Italic",
        active: italic,
        icon: italicIcon,
        toggle: toggleItalic,
      }, {
        label: "Underline",
        active: underline,
        icon: underlineIcon,
        toggle: toggleUnderline,
      },
    ]} />
  )
}