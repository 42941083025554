import { useRef, useState } from "react";

const Knob = ({className, onChangeStart, onChangeEnd, onChange, ...rest}) => {
  const [startPos, setStartPos] = useState(null);
  const capture = useRef(false);

  const onPointerDown = (e) => {
    capture.current = true;
    e.stopPropagation();
    e.currentTarget.setPointerCapture(e.pointerId);
    setStartPos(e.clientX);
    onChangeStart();
  };

  const onPointerUp = (e) => {
    if (!capture.current) return;
    capture.current = false;
    e.preventDefault();
    e.stopPropagation();
    setStartPos(null);
    onChangeEnd();
  };
  
  const onPointerMove = (e) => {
    if (!capture.current) return;
    e.preventDefault();
    e.stopPropagation();
    onChange(e.clientX - startPos);
  }

  return (
    <div
      className={className}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      onPointerMove={onPointerMove}
      onLostPointerCapture={onPointerUp}
      {...rest}
    />
  )
}

export default Knob;