import { useEffect, useState } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { tempMemberAtom, historyAtom, updateTaskAtom, currencyPlacementAtom, currencySignAtom } from "store";
import NumberInput from "../../../components/NumberInput/NumberInput";
import MemberModal from "./MemberModal";
import { Button } from "components";

import { ReactComponent as Cross } from "img/icon/cross.svg";
import s from "./Workgroup.module.css";

export default function MemberRow({ id, role, name, rate, allocation, tasks }) {
  const placement = useAtomValue(currencyPlacementAtom);
  const sign = useAtomValue(currencySignAtom);
  const [tempMember, setTempMember] = useAtom(tempMemberAtom);
  const [showModal, setShowModal] = useState(false);
  const updateTask = useSetAtom(updateTaskAtom);
  const setHistory = useSetAtom(historyAtom);
  const historySuffix = tasks.map( task => task.id ).join("-");

  const prefix = placement === "prefix" ? sign : "";
  const suffix = placement === "suffix" ? sign : "";

  useEffect( () => {
    if (tempMember === id) {
      setShowModal(true);
      setTempMember(null);
    }
  }, [id, tempMember, setTempMember])

  const onClick = () => setShowModal( true );
  const onCloseModal = () => setShowModal( false );
  const stopPropagation = (e) => e.stopPropagation();

  const onAllocationChange = (val) => {
    if (!allocation.mixed && allocation.value === val) return;
    tasks.forEach( task => {
      const workgroup = [...task.workgroup];
      const targetMember = workgroup.find( member => member.id === id );
      if (!targetMember) return;
      const newMember = { ...targetMember, allocation: val };
      const newWorkgroup = workgroup.map( el => el === targetMember ? newMember : el );
      updateTask({
        id: task.id,
        workgroup: newWorkgroup
      });
    })
    setHistory(`allocation-change-${historySuffix}-${id}`);
  }

  const onRemove = () => {
    tasks.forEach( task => {
      const workgroup = task.workgroup.filter( member => member.id !== id );
      updateTask({ id: task.id, workgroup });
    })
    setHistory();
  }

  const className = [
    s.row,
    showModal && s.focus
  ].filter( Boolean ).join( " " );

  return (
    <div className={className} id={id} onClick={onClick} >
      <div className={`${s.cell} ${s.nameCell}`}>
        { (role && name) ? (
          <div>
            <p className={s.title}>{role}</p>
            <p className={s.subtitle}>{name}</p>
          </div>
        ) : (
          <span className={s.title}>{role || name}</span>
        )}
      </div>
      <div className={`${s.cell} ${s.rateCell}`}>
        <span>{prefix}{rate || 0}{suffix}</span>
      </div>
      <div className={`${s.cell} ${s.allocationCell}`}>
        <NumberInput
          className={s.allocation}
          inputClassName={s.allocationInput}
          value={allocation.value}
          mixed={allocation.mixed}
          min={0}
          max={100}
          step={10}
          onChange={onAllocationChange}
          onClick={stopPropagation}
          unit={"%"}
        />
      </div>
      <div className={`${s.cell} ${s.removeCell}`}>
        <Button onClick={onRemove} icon={Cross} iconOnly secondary />
      </div>
      { showModal && (
        <MemberModal value={{ id, role, name, rate }} onClose={onCloseModal} />
      )}
    </div>
  )
}