import s from "./ButtonGroup.module.css";

const ButtonGroup = ({options}) => (
  <div className={s.wrapper}>
    {options.map((el, id) => (
      <Button key={id} {...el} />
    ))}
  </div>
)

const Button = ({label, icon : Icon, active, toggle, disabled}) => {
  const onClick = (e) => {
    toggle();
    e.currentTarget.blur();
  }
  return (
    <button onClick={onClick} disabled={disabled} className={`${s.button} ${active ? s.active : ""}`}>
      {Icon && <Icon className={s.icon} />}
      <span className={s.label}>{label}</span>
    </button>
  )
}

export default ButtonGroup;