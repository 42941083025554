import cc from "color-convert";

export function parseHex(val) {
  const str = val.replace(/[^a-fA-F0-9]/g, '');
  let hex;
  switch (str.length) {
    case 0:
      break;
    case 1:
      hex = str.repeat(6);
      break;
    case 2:
      hex = str.repeat(3);
      break;
    case 3:
    case 4:
    case 5:
      hex = str[0] + str[0] + str[1] + str[1] + str[2] + str[2];
      break;
    default:
      hex = str.substring(0, 6);
  }
  return hex;
}

export function hexToHsv(str) {
  const hex = parseHex(str);
  const hsv = cc.hex.hsv.raw(hex);
  if (!hsv) return null;
  return { h: hsv[0], s: hsv[1], v: hsv[2] }
}

export function hsvToHex( color = fallbackColor ) {
  return cc.hsv.hex(color.h, color.s, color.v);
}

export function hexAlpha(a) {
  return ( "0" + (a * 255).toString(16).split(".")[0] ).slice(-2).toUpperCase();
}

export function hsvaToHexCss( color = fallbackColor, show = true ) {
  return "#" + hsvToHex(color) + hexAlpha(color.a * show);
}

const fallbackColor = {
  h:0, s:0, v:0, a:0
}