import { useAtom, useSetAtom } from "jotai";
import { editingAtom, updateGroupAtom, historyAtom, selectedAtom } from "store";
import TitleInput from "../TitleInput/TitleInput";
import s from "./Title.module.css";


export default function Title({id, title, selected}) {
  const [editing, setEditing] = useAtom(editingAtom);
  const setUpdateGroup = useSetAtom(updateGroupAtom);
  const setHistory = useSetAtom(historyAtom);
  const setSelected = useSetAtom(selectedAtom);

  const onDoubleClick = (e) => {
    e.stopPropagation();
    setSelected(id);
    setEditing(true);
  }

  const onChange = (val) => {
    setUpdateGroup({id, title: val});
    setHistory(`title-edit-${id}`);
  }

  const className = [
    s.wrapper,
    !editing && s.placeholder,
    selected && !title && s.selected,
  ].filter(Boolean).join(" ");

  return (
    <div
      className={className}
      onDoubleClick={onDoubleClick}
    >
      <TitleInput
        id={id}
        value={title}
        onChange={onChange}
      />
    </div>
  )
}