import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { editingAtom, isShowTooltipAtom, mouseTimestampAtom, toolAtom, TOOLS } from "store";
import { vxDate } from "utils";
import s from "./Tooltip.module.css";
import taskCreatorStylesheet from "../TaskCreator/TaskCreator.module.css";
import rowStylesheet from "../Row/Row.module.css";
import taskStylesheet from "../Task/Task.module.css";
import milestoneStylesheet from "../Milestone/Milestone.module.css";


function Tooltip() {
  const [pos, setPos] = useState([0,0]);
  const show = useAtomValue(isShowTooltipAtom);
  const creating = useAtomValue(toolAtom) !== TOOLS.SELECT;
  const editing = useAtomValue(editingAtom);
  const mouseTimestamp = useAtomValue(mouseTimestampAtom);

  const rowHover = !!document.querySelector(`.${CSS.escape(rowStylesheet.tasksCell)}:hover`);
  const taskCreatorHover = !!document.querySelector(`.${CSS.escape(taskCreatorStylesheet.wrapper)}:hover`);
  const taskHover = !!document.querySelector(`.${CSS.escape(taskStylesheet.task)}:hover`);
  const milestoneHover = !!document.querySelector(`.${CSS.escape(milestoneStylesheet.shapeWrapper)}:hover`);


  useEffect(() => {
    const onMouseMove = (e) => {
      setPos([e.clientX, e.clientY]);
    };
    document.addEventListener("pointermove", onMouseMove);
    return () => document.removeEventListener("pointermove", onMouseMove);
  })
  
  // Classes
  const classNames = [s.wrapper];
  if ((rowHover || taskCreatorHover) && !milestoneHover && !taskHover && !editing && show && creating) classNames.push(s.show)
  const className = classNames.join(" ");

  const style = {
    transform: `translate(${pos[0]}px, ${pos[1]}px)`
  }

  const date = vxDate.round(mouseTimestamp);
  const textDate = vxDate.format(date, "ddd, dd mmm yyyy");
  
  return (
    <div id={"tooltip"} className={className} style={style}>
      <div className={s.content}>
        <p>{textDate}</p>
      </div>
    </div>
  )
}

export default Tooltip;