import { useRef } from "react";
import { Helmet } from "react-helmet";
import { useAtom, useSetAtom } from "jotai";
import { redoAtom, undoAtom, titleAtom, historyAtom, importModalAtom } from "store";
import { Link } from "react-router-dom";
import { Button } from "components";
import Download from "./Download";
import Save from "./Save";
import User from "./User";
import { ReactComponent as undo } from "img/icon/undo.svg";
import { ReactComponent as redo } from "img/icon/redo.svg";
import { ReactComponent as upload } from "img/icon/import.svg";
import { ReactComponent as Favicon } from "img/favicon.svg";
import s from "./Header.module.css";

const isMac = /(Mac|iPhone|iPod|iPad)/i.test(navigator?.platform);
const meta = isMac ? "⌘" : "Ctrl";

export default function Header() {
  return (
    <header className={s.wrapper}>
      <Group spaceAfter>
        <MenuButton />
        <Title />
      </Group>
      <Group>
        <UndoButton />
        <RedoButton />
      </Group>
      <Group>
        <Import />
        <Download />
        <Save />
        <User />
      </Group>
    </header>
  )
}

function Group({children, spaceAfter}) {
  const className = [
    s.group,
    spaceAfter && s.spaceAfter
  ].filter(Boolean).join(" ");

  return (
    <div className={className}>
      {children}
    </div>
  )
}

function Title() {
  const ref = useRef();
  const [title, setTitle] = useAtom(titleAtom);
  const setHistory = useSetAtom(historyAtom);
  const onKeyDown = (e) => {
    e.stopPropagation();
    if (e.key === "Enter") {
      ref.current.blur();
    }
  }
  const onChange = (e) => {
    setTitle(e.target.value);
    setHistory("title-change");
  }
  return (
    <div className={s.titleWrapper}>
      <Helmet title={title} />
      <input ref={ref} className={s.input} type="text" value={title || ""} onChange={onChange} onKeyDown={onKeyDown} />
      <h1 className={s.title}>{title}</h1>
    </div>
  )
}

function MenuButton() {
  return (
    <Link className={s.menuButton} to="/">
      <Favicon />
    </Link>
  )
}

function UndoButton() {
  const [available, onClick] = useAtom(undoAtom);
  return (
    <Button className={s.button} label="Undo" icon={undo} iconOnly={true} disabled={!available} onClick={onClick} tooltip={"Undo"} hotkey={[meta,"Z"]} />
  )
}

function RedoButton() {
  const [available, onClick] = useAtom(redoAtom);
  return (
    <Button className={s.button} label="Redo" icon={redo} iconOnly={true} disabled={!available} onClick={onClick} tooltip={"Redo"} hotkey={["⇧", meta, "Z"]} />
  )
}

function Import() {
  const setImportModal = useSetAtom(importModalAtom);
  const openImportModal = () => setImportModal(true);

  return (
    <Button className={s.button} icon={upload} label={"Import"} onClick={openImportModal} />
  )
}