import { isEmpty, isEqual, isEqualWith, isPlainObject, round, partition } from "lodash";
import { v4 as uid } from "uuid";
import { GUEST_USER, DEFAULT_WORKDAYS, DEFAULT_CURRENCY_PLACEMENT, DEFAULT_CURRENCY_SIGN, DEFAULT_EXCEL_CONFIG, SELECTABLE } from "store";
import { fetchAuthSession, fetchUserAttributes, signOut } from "aws-amplify/auth";
import { generateClient } from "aws-amplify/api";
import { createTimeline } from "graphql/mutations";
import { uploadData } from "aws-amplify/storage";


export function devLog() {
  if (process.env.REACT_APP_ENV !== "dev") return;
  console.log(...arguments);
}
export function devError() {
  if (process.env.REACT_APP_ENV !== "dev") return;
  console.error(...arguments);
}

// MUTATIONS
const uidRegexp = /.{8}-.{4}-.{4}-.{4}-.{12}/;
export function setNewIds(obj) {
  if (typeof obj !== "object") return;
  if ("id" in obj) {
    obj.id = obj.id.replace(uidRegexp, uid());
  }
  Object.values(obj).forEach(setNewIds);
}


// FILTERING
export function uniqueValues(val, index, self) {
  return val && self.indexOf(val) === index;
}


// CLEAR REDUNDANT OVERRIDES
export function removeEqualProps(target, comparator) {
  const result = Object.fromEntries(
    Object.entries(target).reduce(
      (acc, [ key, value ]) => {
        const value2 = comparator?.[key];
        if ( isPlainObject(value) && !isColor(value) ) {
          const val = removeEqualProps(value, value2);
          if (val) {
            acc.push([ key, val ])
          }
        } else if (!isEqual( value, value2 )) {
          acc.push([ key, value ])
        }
        return acc;
      }, []
    )
  )
  return isEmpty(result) ? null : result;
}
export function removeEmptyProps(target) {
  const result = Object.fromEntries(
    Object.entries(target).reduce(
      (acc, [ key, value ]) => {
        if ( isPlainObject(value) ) {
          const val = removeEmptyProps(value);
          if (val) {
            acc.push([ key, val ])
          }
        } else {
          acc.push([ key, value ])
        }
        return acc;
      }, []
    )
  )
  return isEmpty(result) ? null : result;
}
export function isColor(val) {
  return isPlainObject(val) && ["h", "s", "v", "a"].every( key => typeof val[key] === "number");
}


// CHECK FOR MIXED PROPS
export function isEqualAll() {
  const args = [...arguments];
  if (args.length < 2) return true;
  return args.every(a => isEqualWith(a, args[0], compareColors));
}
export function isEqualColors(col1, col2) {
  return col1.h === col2.h && col1.s === col2.s && col1.v === col2.v;
}
function compareColors(obj, oth) {
  if (isColor(obj) && isColor(oth)) {
    return isEqualColors(obj, oth);
  }
}

// INSENSITIVE STRING COMPARISON
export function isEqualStrings(strA = "", strB = "") {
  return strA.localeCompare(
    strB,
    undefined,
    { sensitivity: "base" }
  ) === 0
}


// NUMBER FORMATTING
export function numFormat( val, precision = 0 ) {
  const rounded = round(val, precision);
  const string = rounded.toFixed(precision);
  const parts  = string.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}

export function currencyFormat(total, currency = "USD", props = { locale: "en-US", undividable: false }) {
  const {locale, undividable} = props;
  const divider = (["JPY", "KRW"].includes(currency) || undividable) ? 1 : 100;
  return (total / divider).toLocaleString(locale, {style: "currency", currency});
}

// AMPLIFY
export async function saveTemp() {
  const tempProject = sessionStorage.getItem("tempProject");
  if (!tempProject) return;
  sessionStorage.removeItem("tempProject");
  const content = JSON.parse(tempProject);
  const size = content.tasks.length + content.milestones.length;
  const title = content.title;
  delete content.title;
  const client = generateClient();
  const response = await client.graphql({
    query: createTimeline,
    variables: {
      input: { title, size }
    }
  });
  const timelineId = response.data.createTimeline.id;
  await uploadData({
    path: ({identityId}) => `private/${identityId}/timeline/${timelineId}.json`,
    data: JSON.stringify(content),
    options: { contentType: "application/json" }
  }).result;
  window.fbq('trackCustom', 'CreateTimeline', {
    content_name: title,
    status: true
  });
}

export async function getUser() {
  try {
    await fetchAuthSession();
    return await fetchUserAttributes();
  } catch (error) {
    if (error.name === "UserNotFoundException") {
      signOut();
    } else if (error.name === "NotAuthorizedException") {
      signOut();
    }
    return GUEST_USER;
  }
}

export function getExcelConfig(user) {
  try {
    return JSON.parse(user["custom:excelConfig"]);
  } catch {
    return DEFAULT_EXCEL_CONFIG
  }
}

export function getUserPrefs(user) {
  try {
    return JSON.parse(user["custom:preferences"]);
  } catch {
    return {
      workdays: DEFAULT_WORKDAYS,
      currencyPlacement: DEFAULT_CURRENCY_PLACEMENT,
      currencySign: DEFAULT_CURRENCY_SIGN,
    }
  }
}
export function isPaidUser(user) {
  return hasSubscription(user) || hasLifetime(user);
}
export function hasSubscription(user) {
  try {
    const subscription = JSON.parse(user["custom:premium"]);
    return subscription.status !== "canceled";
  } catch {
    return false;
  }
}
export function hasLifetime(user) {
  try {
    return JSON.parse(user["custom:lifetime"]);
  } catch {
    return false;
  }
}

// FILE DOWNLOAD
export async function writeFileToBrowser(exportName, data) {
  // STEP 1: Create element
  const eleLink = document.createElement('a')
  eleLink.setAttribute('style', 'display:none;')
  eleLink.dataset.interception = 'off' // @see https://docs.microsoft.com/en-us/sharepoint/dev/spfx/hyperlinking
  document.body.appendChild(eleLink)

  // STEP 2: Download file to browser
  // DESIGN: Use `createObjectURL()` to D/L files in client browsers (FYI: synchronously executed)
  if (window.URL.createObjectURL) {
    let url;
    if (data instanceof Blob) {
      url = window.URL.createObjectURL(data);
    } else {
      url = data;
    }
    eleLink.href = url
    eleLink.download = exportName
    eleLink.click()

    // Clean-up (NOTE: Add a slight delay before removing to avoid 'blob:null' error in Firefox Issue#81)
    setTimeout(() => {
      window.URL.revokeObjectURL(url)
      document.body.removeChild(eleLink)
    }, 100)
  }
}


// COOKIES
// export function setCookie(name, val) {
//   if (typeof document !== "undefined") {
//     const value = JSON.stringify(val);
//     const domain = process.env.GATSBY_DOMAIN;
//     const maxAge = 60 * 60 * 24 * 365;
//     document.cookie = `${name}=${value};domain=${domain};max-age=${maxAge}`;
//   }
// }
export function getCookie(name) {
  if (typeof document !== "undefined") {
    const cookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith(name + "="))
    ?.split("=")[1];
    if (cookie) {
      return JSON.parse(cookie)
    } else {
      return null;
    }
  }
}

export function getRowFirstChild(items) {
  // Sort
  const [milestones, tasks] = partition(
    items, { type: SELECTABLE.MILESTONE }
  );
  milestones.sort((a, b) => a.date - b.date);
  tasks.sort((a, b) => a.start - b.start);

  // Return the first
  if (tasks.length && milestones.length) {
    return (tasks[0].start <= milestones[0].date) ? tasks[0] : milestones[0]
  } else {
    return tasks.length ? tasks[0] : milestones[0];
  }
}