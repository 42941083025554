import { Select } from "components";
import s from "./FieldPicker.module.css";


export default function FieldPicker(props) {
  const classNames = { control: s.control };

  return (
    <Select
      className={s.select}
      classNames={classNames}
      {...props}
    />
  )
}
