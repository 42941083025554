
import { Link, useBlocker } from "react-router-dom";
import { useRive } from "@rive-app/react-canvas";
import { getUser, isPaidUser } from "utils";
import { ReactComponent as Arrow } from 'img/icon/go-to.svg';
import s from "./Success.module.css";
import { useEffect } from "react";

export default function Success({ img, isUpdate }) {
  const blocker = useBlocker(true);

  useEffect(() => {
    if (isUpdate) return;
    window.fbq('track', 'Subscribe');
  }, [isUpdate])
  
  useEffect(() => {
    if (blocker.state !== "blocked") return;
    const fetchUpdate = async () => {
      const user = await getUser();
      if (isPaidUser(user)) {
        blocker.proceed?.();
      } else {
        setTimeout(fetchUpdate, 3000);
      }
    }
    fetchUpdate();
  }, [blocker])

  const isBlocked = blocker.state === "blocked";
  const cn = [
    s.link,
    isBlocked && s.disabled
  ].filter(Boolean).join(" ");

  let title, description;
  if (isUpdate) {
    title = "Payment details successfully updated";
    description = "Your payment information has been updated. You're all set to continue making great plans without any interruptions.";
  } else {
    title = "Thanks for your purchase";
    description = "Your payment was successfully processed, and your order is complete. You're now all set to make great plans.";
  }

  return (
    <div className={s.main}>
      <div className={s.wrapper}>
        {img ? <Image buffer={img} /> : <div className={s.image} />}
        <h1 className={s.title}>{title}</h1>
        <p className={s.description}>{description}</p>
        <div className={s.linkWrapper}>
          <Link className={cn} to={isBlocked ? null : "/"}>
            <span>To workspace</span>
            <Arrow className={s.icon} />
          </Link>
          {isBlocked && <p className={s.linkStatus}>License activation...</p>}
        </div>
      </div>
    </div>
  )
}

function Image({buffer}) {
  const { RiveComponent } = useRive({
    buffer, autoplay: true,
  });
  return <RiveComponent className={s.image} />
}