import { useAtomValue, useSetAtom } from "jotai";
import { find } from "lodash";
import {
  headerAtom, footerAtom, selectedAtom, historyAtom,
  columnAtomsAtom, editHeadingAtom,
  tiersAtom, editTierAtom, removeTierAtom,
  TIER_OPTIONS, TIMELINE_SCALE_OPTIONS
} from "store";
import { useStyleEdit } from "utils";
import Header from "../../components/Header/Header";
import Section, { Icon } from "../../components/Section/Section";
import ColorPicker from "../../components/ColorPicker/ColorPicker";
import NumberInput from "../../components/NumberInput/NumberInput";
import SliderInput from "../../components/Slider/Slider";
import TextAlign from "../../components/TextAlign";
import TextStyle from "../../components/TextStyle";
import TextTransform from "../../components/TextTransform";
import FieldPicker from "../../components/FieldPicker/FieldPicker";
import FormatPicker from "../../components/FormatPicker/FormatPicker";
import { AddColumn, AddTier } from "../../components/AddItem/AddItem";
import Column from "../../components/ColumnLabelAppearance";
import TimelineConfig from "../Document/TimelineConfig";
import { ReactComponent as cross } from "img/icon/cross.svg";
import { ReactComponent as Spacing } from "img/icon/spacing.svg";
import { ReactComponent as LineWidht } from "img/icon/line-width.svg";
import { ReactComponent as Height } from "img/icon/height.svg";
import { ReactComponent as Roundness } from "img/icon/roundness.svg";


export default function Heading() {
  const selected = useAtomValue(selectedAtom);
  const columns = useAtomValue(columnAtomsAtom);
  const id = selected[0];
  const atom = id === "header" ? headerAtom : footerAtom;
  const title = id === "header" ? "Header" : "Footer";
  const styles = [useAtomValue(atom)];
  const [
    s, valueChange, toggleChange, colorChange, opacityChange
  ] = useStyleEdit({
    editAtom: editHeadingAtom,
    setter: (key, val) => ({ atom, [key]: val }),
    styles,
    suffix: id
  });

  return s.tiers?.value.length ? (
    <>
      <div>
        <Header title={title} />
        <TimelineConfig />
        <Section title={"Row"}>
          <div>
            <ColorPicker
              title={"Background"}
              onChange={colorChange("background")}
              id={`${id}-background`}
              color={s.background.value ?? { h: 0, s: 0, v: 0, a: 1 }}
              disabled={!s.backgroundShow.value}
              onToggle={toggleChange("backgroundShow")}
            />
            <NumberInput
              title={"Opacity"}
              onChange={opacityChange("background")}
              id={`${id}-background-alpha`}
              value={Math.floor(s.background.value.a * 100)}
              min={0}
              max={100}
              unit={"%"}
              disabled={!s.backgroundShow.value && !s.backgroundShow.mixed}
            />
          </div>
          <div>
            <SliderInput
              title={"Spacing"}
              onChange={valueChange("padding")}
              id={`${id}-padding`}
              value={s.padding.value ?? 0}
              min={0}
              sliderMax={32}
              max={160}
              icon={Spacing}
            />
          </div>
        </Section>
        <Section title="Bar" >
          <div>
            <ColorPicker
              title={"Fill"}
              onChange={colorChange("fill")}
              id={`${id}-fill`}
              color={s.fill.value ?? { h: 0, s: 0, v: 0, a: 1 }}
              disabled={!s.fillShow.value}
              onToggle={toggleChange("fillShow")}
            />
            <NumberInput
              title={"Opacity"}
              onChange={opacityChange("fill")}
              id={`${id}-fill-alpha`}
              value={Math.floor(s.fill.value.a * 100)}
              min={0}
              max={100}
              unit={"%"}
              disabled={!s.fillShow.value}
            />
          </div>
          <div>
            <ColorPicker
              title={"Border"}
              onChange={colorChange("borderColor")}
              id={`${id}-border-color`}
              color={s.borderColor.value ?? { h: 0, s: 0, v: 0, a: 1 }}
              disabled={!s.borderShow.value}
              onToggle={toggleChange("borderShow")}
            />
            <NumberInput
              title="Line width"
              id={`${id}-border-width`}
              value={s.borderWidth.value}
              min={0}
              max={20}
              step={0.5}
              onChange={valueChange("borderWidth")}
              icon={LineWidht}
              disabled={!s.borderShow.value}
            />
          </div>
          <div>
            <SliderInput
              title={"Roundness"}
              onChange={valueChange("borderRadius")}
              id={`${id}-border-radius`}
              value={s.borderRadius.value ?? 0}
              min={0}
              sliderMax={20}
              max={100}
              icon={Roundness}
            />
          </div>
        </Section>
      </div>
      <div>
        <Header title="Tiers" />
        {s.tiers.value.map(id => <Tier id={id} key={id} />)}
        <Section>
          <AddTier atom={atom} />
        </Section>
      </div>
      <div>
        <Header title="Columns" />
        {columns.map(column => (
          <Column
            key={`${column}`}
            atom={column}
            styles={styles}
            editAtom={editHeadingAtom}
            headingAtom={atom}
          />
        ))}
        <Section>
          <AddColumn />
        </Section>
      </div>
    </>
  ) : (
    <>
      <div>
        <Header title={title} />
        <TimelineConfig />
      </div>
      <div>
        <Header title="Tiers" />
        <Section>
          <AddTier atom={atom} />
        </Section>
      </div>
    </>
  )
}

function Tier({ id }) {
  const tiers = useAtomValue(tiersAtom);
  const styles = [find(tiers, { id })];
  const removeTier = useSetAtom(removeTierAtom);
  const setHistory = useSetAtom(historyAtom);
  const [
    s, valueChange, toggleChange, colorChange, opacityChange
  ] = useStyleEdit({
    editAtom: editTierAtom,
    styles,
    suffix: id,
  });

  const onClick = () => {
    removeTier(id);
    setHistory(`${id}-remove`);
  }

  const toolbar = <>
    <FieldPicker
      value={s.scale.value}
      options={TIMELINE_SCALE_OPTIONS}
      onChange={valueChange("scale")}
    />
    <FormatPicker
      value={s.format.value}
      field={`${s.field.value}-${s.scale.value}`}
      onChange={valueChange("format")}
    />
    <Icon icon={cross} onClick={onClick} />
  </>;

  return (
    <Section
      title={s.field.value}
      options={TIER_OPTIONS}
      onChange={valueChange("field")}
      toolbar={toolbar}
    >
      <div>
        <SliderInput
          title="Height"
          id={`${id}-height`}
          value={s.height.value}
          min={0}
          sliderMin={10}
          sliderMax={60}
          max={200}
          step={1}
          onChange={valueChange("height")}
          icon={Height}
        />
      </div>
      <div>
        <ColorPicker
          title={"Background"}
          onChange={colorChange("background")}
          id={`${id}-fill`}
          color={s.background.value ?? { h: 0, s: 0, v: 100, a: 1 }}
          disabled={!s.backgroundShow.value}
          onToggle={toggleChange("backgroundShow")}
        />
        <NumberInput
          title="Opacity"
          id={`${id}-fill-alpha`}
          value={Math.floor(s.background.value.a * 100)}
          min={0}
          max={100}
          onChange={opacityChange("background")}
          unit={"%"}
          disabled={!s.backgroundShow.value}
        />
      </div>
      <div>
        <ColorPicker
          title={"Separators"}
          onChange={colorChange("separatorColor")}
          id={`${id}-sr-fill`}
          color={s.separatorColor.value ?? { h: 0, s: 0, v: 100, a: 1 }}
          disabled={!s.separatorShow.value}
          onToggle={toggleChange("separatorShow")}
        />
        <NumberInput
          title="Line width"
          id={`${id}-sr-width`}
          value={s.separatorWidth.value}
          min={0}
          max={20}
          step={0.5}
          onChange={valueChange("separatorWidth")}
          icon={LineWidht}
          disabled={!s.separatorShow.value}
        />
      </div>
      <div>
        <ColorPicker
          title={"Font color"}
          onChange={colorChange("color")}
          id={`${id}-color`}
          color={s.color.value ?? { h: 0, s: 0, v: 0, a: 1 }}
          alwaysOn={true}
        />
        <NumberInput
          title={"Font size"}
          onChange={valueChange("fontSize")}
          id={`${id}-font-size`}
          value={s.fontSize.value ?? ""}
          min={6}
          max={60}
        />
      </div>
      <div>
        <TextAlign value={s.textAlign.value} onChange={valueChange("textAlign")} />
        <TextStyle
          bold={s.bold.value}
          italic={s.italic.value}
          underline={s.underline.value}
          toggleBold={toggleChange("bold")}
          toggleItalic={toggleChange("italic")}
          toggleUnderline={toggleChange("underline")}
        />
        <TextTransform value={s.textTransform.value} onChange={valueChange("textTransform")} />
      </div>
    </Section>
  )
}