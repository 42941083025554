import { useStyleEdit } from 'utils';
import Section from '../../components/Section/Section';
import ColorPicker from '../../components/ColorPicker/ColorPicker';
import NumberInput from '../../components/NumberInput/NumberInput';
import SliderInput from '../../components/Slider/Slider';
import { ReactComponent as Height } from "img/icon/height.svg";
import { ReactComponent as LineWidht } from "img/icon/line-width.svg";
import { ReactComponent as Roundness } from "img/icon/roundness.svg";

export default function BarAppearance({ styles, editAtom }) {
  const getter = (style) => style.bar;
  const setter = (key, val, { id }) => ({ id, bar: { [key]: val } });
  const [
    s, valueChange, toggleChange, colorChange, opacityChange
  ] = useStyleEdit({ editAtom, styles, getter, setter });

  return s ? (
    <Section
      title={"Tasks summary"}
      toggle={s.show?.value}
      toggleMixed={s.show?.mixed}
      onToggle={toggleChange("show")}
    >
      {(s.show.value || s.show.mixed) && <>
        <div>
          <ColorPicker
            title={"Fill"}
            id={"group-bar-color"}
            color={s.background.value ?? { h: 0, s: 0, v: 0, a: 1 }}
            disabled={!s.backgroundShow.mixed && !s.backgroundShow.value}
            mixed={s.background.mixed}
            mixedDisabled={s.backgroundShow.mixed}
            onChange={colorChange("background")}
            onToggle={toggleChange("backgroundShow")}
          />
          <NumberInput
            title={"Opacity"}
            id={"group-bar-color-alpha"}
            value={Math.floor(s.background.value.a * 100)}
            mixed={s.background.mixedAlpha}
            disabled={!s.backgroundShow.mixed && !s.backgroundShow.value}
            onChange={opacityChange("background")}
            min={0}
            max={100}
            unit={"%"}
          />
        </div>
        <div>
          <ColorPicker
            title={"Border"}
            id={"group-border"}
            color={s.borderColor.value ?? { h: 0, s: 0, v: 0, a: 1 }}
            mixed={s.borderColor.mixed}
            disabled={!s.borderShow.mixed && !s.borderShow.value}
            mixedDisabled={s.borderShow.mixed}
            onChange={colorChange("borderColor")}
            onToggle={toggleChange("borderShow")}
          />
          <NumberInput
            title={"Line width"}
            id={"group-line-width"}
            value={s.borderWidth.value ?? ""}
            mixed={s.borderWidth.mixed}
            disabled={!s.borderShow.mixed && !s.borderShow.value}
            onChange={valueChange("borderWidth")}
            min={0}
            max={10}
            step={0.5}
            icon={LineWidht}
          />
        </div>
        <div>
          <SliderInput
            title={"Height"}
            id={"group-height"}
            value={s.height.value ?? ""}
            mixed={s.height.mixed}
            onChange={valueChange("height")}
            min={1}
            sliderMax={40}
            max={160}
            icon={Height}
          />
        </div>
        <div>
          <SliderInput
            title={"Roundness"}
            id={"group-roundness"}
            value={s.borderRadius.value ?? ""}
            mixed={s.borderRadius.mixed}
            onChange={valueChange("borderRadius")}
            min={0}
            sliderMax={20}
            max={80}
            icon={Roundness}
          />
        </div>
        <div>
          <SliderInput
            title={"Gap opacity"}
            id={"group-gap-opacity"}
            value={s.gapOpacity.value ?? ""}
            mixed={s.gapOpacity.mixed}
            onChange={valueChange("gapOpacity")}
            min={0}
            max={100}
            unit="%"
          />
        </div>
      </>}
    </Section>
  ) : null
}