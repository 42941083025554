import { useAtomValue, useSetAtom } from "jotai";
import { map } from "lodash";
import { uniqueValues } from "utils";
import {
  availableWidthAtom, addColumnAtom, historyAtom, addTierAtom,
  TIER_OPTIONS, MIN_COLUMN_WIDTH, COLUMN_OPTIONS,
  createTaskLabelStyleAtom, editModeAtom, selectedAtom, mapAtom, taskStylesAtom,
  TASK_FIELD_OPTIONS, EDIT_MODES
  } from "store";
import { Select } from "components";
import { ReactComponent as plus } from "img/icon/plus.svg";


function AddItem(props) {
  return (
    <div>
      <Select
        controlShouldRenderValue={false}
        icon={plus}
        {...props}
      />
    </div>
  )
}

export function AddColumn() {
  const addColumn = useSetAtom(addColumnAtom);
  const setHistory = useSetAtom(historyAtom);
  const availableWidth = useAtomValue(availableWidthAtom);
  
  const onChange = (value) => {
    addColumn(value);
    setHistory();
  }
  const isDisabled = availableWidth < MIN_COLUMN_WIDTH;

  return (
    <AddItem
      isDisabled={isDisabled}
      options={COLUMN_OPTIONS}
      placeholder={isDisabled ? "Add column (not enough space)" : "Add column"}
      onChange={onChange}
    />
  )
}

export function AddTier({ atom }) {
  const addTier = useSetAtom(addTierAtom);
  const setHistory = useSetAtom(historyAtom);
  
  const onChange = (value) => {
    addTier({ atom, field: value });
    setHistory();
  }

  return (
    <AddItem
      options={TIER_OPTIONS}
      placeholder={"Add tier"}
      onChange={onChange}
    />
  )
}

export function AddLabel() {
  const selected = useAtomValue(selectedAtom);
  const itemsMap = useAtomValue(mapAtom);
  const editMode = useAtomValue(editModeAtom);
  const taskStyles = useAtomValue(taskStylesAtom);
  const createTaskLabelStyle = useSetAtom(createTaskLabelStyleAtom);
  const setHistory = useSetAtom(historyAtom);
  
  
  const onChange = (field) => {
    let styleIds;
    if (editMode === EDIT_MODES.GLOBAL) {
      styleIds = map(taskStyles, "id");
    } else {
      styleIds = selected
        .map(id => itemsMap.get(id).style)
        .filter(uniqueValues);
    }

    styleIds.forEach(id => createTaskLabelStyle({ id, field }));
    setHistory();
  }

  return (
    <AddItem
      options={TASK_FIELD_OPTIONS}
      placeholder={"Add content"}
      onChange={onChange}
    />
  )
}