import { useAtomValue, useSetAtom } from "jotai";
import {
  columnAtomsAtom, historyAtom, editColumnAtom, removeColumnAtom,
  FIELDS, COLUMN_OPTIONS, MIN_COLUMN_WIDTH, MAX_COLUMN_WIDTH
} from "store";
import Section      from "../../components/Section/Section";
import NumberInput  from "../../components/NumberInput/NumberInput";
import Select       from "components/Select";
import Input        from "../../components/Input/Input";
import FormatPicker from "../../components/FormatPicker/FormatPicker";
import { AddColumn } from "../../components/AddItem/AddItem";

import { ReactComponent as Cross } from "img/icon/cross.svg";
import { ReactComponent as Placeholder } from "img/icon/placeholder.svg";
import s from "./Style.module.css";

export default function ColumnsConfig() {
  const columns = useAtomValue( columnAtomsAtom );

  return(
    <Section title="Columns">
      <div className={s.wrapper}>
        <div className={s.th}>
          <div className={s.titleCell}>Column</div>
          <div className={s.widthCell}>Width</div>
          <div className={s.iconCell}>&nbsp;</div>
          <div className={s.iconCell}>&nbsp;</div>
        </div>
        {columns?.map( atom => <Column key={`${atom}`} atom={atom} />)}
      </div>
      <AddColumn />
    </Section>
  )
}

function Column({ atom }) {
  const {id, field, format, width} = useAtomValue(atom);
  const removeColumn = useSetAtom(removeColumnAtom);
  const editColumn = useSetAtom(editColumnAtom);
  const setHistory = useSetAtom(historyAtom);

  const onChange = (field, value) => {
    editColumn({ atom, [field]: value });
    setHistory(`${id}-${field}`);
  }
  const onFieldChange  = (val) => onChange("field", val);
  const onWidthChange  = (val) => onChange("width", val);
  const onFormatChange  = (val) => onChange("format", val);
  const onRemove = () => {
    removeColumn( atom );
    setHistory();
  };

  return (field === FIELDS.TASKS ? (
    <div className={s.row}>
      <div className={s.titleCell}>
        <Input
          value={ "Tasks" }
          disabled={true}
        />
      </div>
      <div className={s.widthCell}>         
        <Input
          value={ "Auto" }
          disabled={true}
        />
      </div>
      <div className={s.iconCell}>
        <FormatPicker value={format} field={field} onChange={onFormatChange} />
      </div>
      <div className={s.iconCell}>
        <button className={s.remove} disabled>
          <Placeholder className={s.icon} />
        </button>
      </div>
    </div>
  ) : (
    <div className={s.row}>
      <div className={s.titleCell}>
        <Select
          value={field}
          options={COLUMN_OPTIONS}
          onChange={onFieldChange}
        />
      </div>
      <div className={s.widthCell}>
        <NumberInput
          id={`${id}-width`}
          value={width}
          min={MIN_COLUMN_WIDTH}
          max={MAX_COLUMN_WIDTH}
          onChange={onWidthChange}
        />
      </div>
      <div className={s.iconCell}>
        <FormatPicker value={format} field={field} onChange={onFormatChange} />
      </div>
      <div className={s.iconCell}>
        <button className={s.remove} onClick={onRemove}>
          <Cross className={s.icon} />
        </button>
      </div>
    </div>
  ))
}