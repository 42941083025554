import { signOut } from "aws-amplify/auth";
import { useAtomValue, useSetAtom } from "jotai";
import { userAtom, tokensAtom, callToUpgradeModalAtom, GUEST_USER, isSubscribedUserAtom, isLifetimeUserAtom } from "store";
import { Link, useNavigate } from "react-router-dom";
import Select from "components/Select";
import { components } from "react-select";
import { ReactComponent as icon } from "img/icon/menu.svg";
import { ReactComponent as Right } from "img/icon/right.svg";
import s from "./User.module.css";


export default function User() {
  const user = useAtomValue(userAtom);
  const navigate = useNavigate();
  const tokens = useAtomValue(tokensAtom);
  const isSubscribed = useAtomValue(isSubscribedUserAtom);
  const isLifetime = useAtomValue(isLifetimeUserAtom);
  const upgrade = useSetAtom(callToUpgradeModalAtom);

  const handleClick = async (val) => {
    try {
      if (val === "user") {
        if (isSubscribed || isLifetime) {
          navigate("/settings/billing");
        } else {
          upgrade(true);
        }
      } else if (val === "signout") {
        await signOut();
        navigate("/login");
      } else if (val === "settings") {
        navigate("/settings");
      }
    } catch (err) {
      console.log(err);
    }
  }
  
  const options = [
    {
      label: "User",
      value: "user",
    }, {
      label: "Settings",
      value: "settings",
    }, {
      label: "Log out",
      value: "signout",
    }
  ];

  let plan, limit;
  if (isSubscribed) {
    plan = "Pro";
    limit = 150;
  } else if (isLifetime) {
    plan = "Lifetime";
    limit = 30;
  } else {
    plan = "Starter";
  };

  function Option(props) {
    if (props.value !== "user") {
      return (
        <components.Option {...props} />
      )
    } else if(isSubscribed || isLifetime) {
      return (
        <components.Option {...props} className={s.infoWrapper}>
          <div className={s.usernameWrapper}>
            <span className={s.username}>{user.name}</span>
            <span className={s.plan}>{plan}</span>
          </div>
          <div className={s.credits}>
            <div>{tokens} / {limit} AI Credits</div>
            <div className={s.progressWrapper}>
              <div className={s.progress} style={{ width: `${Math.round(tokens / limit * 100)}%` }} />
            </div>
          </div>
        </components.Option>
      )
    } else {
      return (
        <components.Option {...props} className={s.infoWrapper}>
          <div className={s.usernameWrapper}>
            <span className={s.username}>{user.name}</span>
            <span className={s.plan}>{plan}</span>
          </div>
          <div className={s.link}>
            <span>Upgrade to Pro</span>
            <Right />
          </div>
        </components.Option>
      )
    }
  }

  if (!user) return null;
  if (user === GUEST_USER) return (
    <Link className={s.button} to="/signup">Sign up</Link>
  )
  
  const classNames = { control: s.control, menuList: s.menuList };

  return (
    <Select
      classNames={classNames}
      options={options}
      onChange={handleClick}
      value={null}
      icon={icon}
      components={{ Option }}
      iconControl
    />
  )
}