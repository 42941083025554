import { useAtomValue } from "jotai";
import { minDateAtom, timeScaleAtom, SELECTABLE } from "store";
import s from "./FusedTasks.module.css";

export default function FusedTasks({ items = [], onClick, ...rest }) {

  const bars = items
    .filter( i => i.type === SELECTABLE.TASK )
    .sort( (a, b) => a.start - b.start )
    .reduce( (acc, task) => {
      const prev = acc.at(-1);
      if (!prev || task.start > prev.end) {
        acc.push({
          start: task.start,
          end: task.end,
        })
      } else if (task.end > prev.end) {
        prev.end = task.end;
      }
      return acc;
    }, []);

  if (!bars.length) return null;

  const {start} = bars.at(0)
  const {end} = bars.at(-1)

  return (
    <div className={s.wrapper} onClick={onClick} {...rest} >
      <Bar gaps start={start} end={end} />
      {bars.map(
        (el, index) => <Bar key={index} {...el} />
      )}
    </div>
  )
}

function Bar({ start, end, gaps }) {
  const minDate = useAtomValue(minDateAtom);
  const timeScale = useAtomValue(timeScaleAtom);
  
  const width = Math.abs(end - start) / timeScale;
  const deltaX = (Math.min(start, end) - minDate) / timeScale;
  const transform = `translate(${deltaX}px`;
  const style = { width, transform };

  const className = [s.bar, gaps && s.gaps].filter(Boolean).join(" ");

  return (
    <div className={className} style={style} />
  )

} 