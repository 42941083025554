import { useRef, useState } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  taskDragAtom,
  rowDragAtom,
  createTaskAtom,
  createMilestoneAtom,
  historyAtom,
  snappedDatesAtom,
  isShowTooltipAtom,
  selectedAtom,
  rowStylesAtom,
  rowStylesMapAtom,
  addSelectedAtom,
  editingAtom,
  mapAtom,
  moveRowAtom,
  groupsAtom,
  timeSnapshotAtom,
  columnAtomsAtom,
  ptAtom,
  borderWidthAtom,
  groupBorderWidthAtom,
  borderShowAtom,
  groupBorderShowAtom,
  toolAtom,
  lockedAtom,
  createRowAtom,
  TOOLS,
} from "store";
import merge from "lodash/merge";
import { useSetAtomLater, vxDate, useSnap, useTimeoutState } from "utils";
import SplitButton from "./SplitButton";
import Cell from "./Cell";
import s from "./Row.module.css";


export default function Row(props) {
  const {index, groupIndex, groupStyle, id, title, styleId, childItems, overrides} = props;
  const ref = useRef();
  const capture = useRef();
  const tasksWrapper = useRef();
  const startDate = useRef();
  const endDate = useRef();
  const dropTargets = useRef();
  
  const [startPos, setStartPos] = useState();
  const [deltaPos, setDeltaPos] = useState([0, 0]);
  const [direction, setDirection] = useState();
  const [hover, setHover] = useState();
  const [clickCooldown, setClickCooldown] = useTimeoutState(200);
  
  const [rowDrag, setRowDrag] = useAtom(rowDragAtom);
  const isLocked = useAtomValue(lockedAtom);
  const createRow = useSetAtom(createRowAtom);
  const rowStyles = useAtomValue(rowStylesAtom);
  const rowStylesMap = useAtomValue(rowStylesMapAtom);
  const columns = useAtomValue(columnAtomsAtom);
  const pt = useAtomValue(ptAtom);
  const borderWidth = useAtomValue(borderWidthAtom);
  const groupBorderWidth = useAtomValue(groupBorderWidthAtom);
  const borderShow = useAtomValue(borderShowAtom);
  const groupBorderShow = useAtomValue(groupBorderShowAtom);
  const getTimeSnapshot = useSetAtom(timeSnapshotAtom);
  const groups = useAtomValue(groupsAtom);
  const map = useAtomValue(mapAtom);
  const taskDrag = useAtomValue(taskDragAtom);
  const createTask = useSetAtom(createTaskAtom);
  const createMilestone = useSetAtom(createMilestoneAtom);
  const moveRow = useSetAtom(moveRowAtom);
  const setHistory = useSetAtom(historyAtom);
  const setShowTooltip = useSetAtom(isShowTooltipAtom);
  const [selected, setSelected] = useAtom(selectedAtom);
  const addSelected = useSetAtom(addSelectedAtom);
  const [isEditing, setEditing] = useAtom(editingAtom);
  const setSnappedDates = useSetAtomLater(snappedDatesAtom);
  const snap = useSnap();
  const tool = useAtomValue(toolAtom);
  const creating = [TOOLS.TASK, TOOLS.MILESTONE].includes(tool);

  const select = (e) => {
    e.stopPropagation();
    setEditing(false);
    if (e.shiftKey || e.metaKey) {
      addSelected(id);
    } else if (!selected.includes(id)) {
      setSelected(id);
    }
  }
  const onPointerDown = (e) => {
    if (isLocked) {
      if (!creating) {
        return;
      } else {
        setDirection("x");
      }
    } else {
      select(e);
      if (!creating || !tasksWrapper.current.contains(e.target)) {
        setDirection("y");
      }
    }

    e.target.setPointerCapture(e.pointerId);
    setStartPos([e.clientX, e.clientY]);
    endDate.current = startDate.current = snap(getTimeSnapshot()).val;
    capture.current = true;
    if (creating) {
      setSnappedDates(endDate.current);
    }
    setShowTooltip(false);
    setClickCooldown();
    calcDropTargets();
  }
  const onPointerUp = () => {
    if (!capture.current) return;
    applyChanges();
    resetChanges();
  }

  const onPointerMove = (e) => {
    if (isLocked && !capture.current) {
      const box = ref.current.getBoundingClientRect();
      const threshold = box.height * 0.25;
      if (e.clientY - box.top < threshold) {
        setHover(s.createAbove);
      } else if (e.clientY - box.top > box.height - threshold) {
        setHover(s.createBelow);
      } else {
        setHover(s.createOver);
      }
    }

    if (!capture.current) return;

    const distX = e.clientX - startPos[0];
    const distY = e.clientY - startPos[1];
    
    const dragAlnogX = direction ?
      (direction === "x") :
      (clickCooldown || Math.abs(distY) > 5) ?
      (Math.abs(distX) > Math.abs(distY)) : true;

    const x = dragAlnogX ? distX : 0;
    const y = !dragAlnogX ? distY : 0;

    if (!direction) {
      if (Math.abs(x) > 30) setDirection("x");
      if (Math.abs(y) > 30) setDirection("y");
    }

    setDeltaPos([x, y]);
    reorderRows(y);

    endDate.current = snap(getTimeSnapshot()).val;
    setSnappedDates( y || !creating ? null : endDate.current);
  }

  const resetChanges = () => {
    capture.current = null;
    setStartPos(null);
    setDeltaPos([0, 0]);
    setDirection(null);
    setRowDrag(null);
    setSnappedDates(null);
    setShowTooltip(true);
    setHover(null);
  }

  const applyChanges = () => {
    const {id, from, to} = rowDrag;
    if (from.groupId !== to.groupId || from.index !== to.index) {
      moveRow({
        id,
        index: to.index,
        group: to.groupId,
      })
      setHistory();
    } else if (direction !== "y") {
      if (tool === TOOLS.MILESTONE) {
        const endDate = snap(getTimeSnapshot()).val;
        const date = vxDate.round(endDate);
        const row = getRow();
        const {id} = createMilestone({ date, row }) || {};
        if (!id) return;
        setHistory();
        setSelected(id);
      } else if (((!clickCooldown && deltaPos[0]) || (direction === "x"))) {
        const snapStart = startDate.current;
        const snapEnd = snap(getTimeSnapshot()).val;
        const [start, end] = vxDate.sort(snapStart, snapEnd);
        const row = getRow();
        const {id} = createTask({ start, end, row }) || {};
        if (!id) return;
        setHistory();
        setSelected(id);
      }
    }
  };

  const getRow = () => {
    if (!isLocked) return id;
    switch (hover) {
      case s.createAbove:
        return createRow({ index: index, group: props.groupId }).id;
      case s.createBelow:
        return createRow({ index: index+1, group: props.groupId }).id;
      default:
        return id;
    }
  }

  const calcDropTargets = () => {
    const {top, height} = ref.current.getBoundingClientRect();

    dropTargets.current = groups.map( (group, groupIndex) => {
      if ( !group.unfolded ) return null;
      const { id } = group;
      const rect = document.getElementById(id).getBoundingClientRect();
      const children = group.children.map( id => {
        const { top, height } = document.getElementById(id).getBoundingClientRect();
        return { id, top, height };
      });

      const targets = children.filter(
        ({ id }) => id !== props.id
      ).map(
        ( rect, rowIndex ) => {
          let pos = rect.top + rect.height / 2 - top;
          if (
            (groupIndex === props.groupIndex && rowIndex >= index) || /* Rows in the same group which goes after carrent */
            (groupIndex > props.groupIndex) /* Rows in every following group */
          ) {
            pos -= height; /* Adjust coordintate like draggable row doesn't exist */
          }
          return pos;
        }
      );

      const topChildren = children.length ? ((children[0].top - rect.top) / 2) : 0
      let pos = rect.top + topChildren - top - groupBorderWidth * groupBorderShow * pt / 2;
      if (groupIndex > props.groupIndex) {
        pos -= height;
      }

      return { id, groupIndex, pos, targets };
    }).filter(Boolean);

    const isEdgeRow = (ref.current.parentElement.firstChild === ref.current) || (ref.current.parentElement.lastChild === ref.current);

    setRowDrag({
      id,
      delta: height + (isEdgeRow * borderWidth * borderShow * pt / 2),
      from: {
        index,
        groupIndex: props.groupIndex,
        groupId: props.groupId,
      },
      to: {
        index,
        groupIndex: props.groupIndex,
        groupId: props.groupId,
      }
    })
  }

  const reorderRows = (y) => {
    const group = (dropTargets.current.findLast(el => (el.pos <= y)) || dropTargets.current[0])
    const groupIndex = group.groupIndex;
    const groupId = group.id;
    const index = group.targets.findLastIndex(el => (el < y)) + 1;
    setRowDrag({ ...rowDrag, to: {
      index, groupIndex, groupId
    }});
  }

  // CLASSES
  const isSelected = !isEditing && selected.includes(id);
  const taskDragClass = taskDrag && taskDrag.row === id ? s[taskDrag.position]: null;
  const isDragTransitions = rowDrag && rowDrag.id !== id;
  const isDragging = (rowDrag && rowDrag.id === id) && (Math.abs(deltaPos[1]) > 5 || (!clickCooldown && direction === "y"));
  const isHovering = isLocked && creating && !capture.current;

  let className = [
    s.row, props.className, `override-${id}`, taskDragClass,
    styleId ?? rowStyles[0].id,
    isHovering          && hover,
    creating            && s.creating,
    taskDrag            && s.taskDrag,
    isDragging          && s.drag,
    isDragTransitions   && s.dragTransitions,
  ].filter(Boolean).join(" ");

  // STYLES
  const rowStyle = {};
  
  // Sorting
  if (rowDrag) {

    let translateY = 0;
    
    if ( rowDrag.id === id ) {
      translateY = deltaPos[1];
    } else {
      const {from, to, delta} = rowDrag;
      let index = props.index;
      if (from.groupIndex === groupIndex && from.index < index) {
        --index;
      }

      if ((
        from.groupIndex < groupIndex
      ) || (
        from.groupIndex === groupIndex && from.index <= index
      )) {
        translateY -= delta;
      }

      if ((
        to.groupIndex < groupIndex
      ) || (
        to.groupIndex === groupIndex && to.index <= index
      )) {
        translateY += delta;
      }
      
      // Add or remove borders for edge rows
      if (from.groupIndex === groupIndex) {
        if (from.index === 0 && (to.index > index || to.groupIndex !== groupIndex)) {
          className += ` ${s.newFirst}`;
        } else if (from.index > index && (to.index <= index || to.groupIndex !== groupIndex)) {
          className += ` ${s.newLast}`;
        }
      }
      if (to.groupIndex === groupIndex) {
        if (to.index === 0) {
          className += ` ${s.notFirst}`;
        } else if (to.index > index) {
          className += ` ${s.notLast}`;
        }
      }
    }
    rowStyle.transform = `translateY(${translateY}px)`;
  }

  // Label paddings
  const items = childItems.map((id) => map.get(id));

  // MISC
  const tempTask = ( startPos && !deltaPos[1] && direction !== "y" ) ? ( !isLocked || !hover ) ? s.createOver : hover : null;
  const isShowSplitButton = !taskDrag && (index !== 0 || props.groupId === "root");

  const style = merge({}, rowStylesMap[styleId], overrides);

  return (
    <>
    {(tempTask === s.createAbove) && (
      <TempRow start={startDate.current} end={endDate.current} groupStyle={groupStyle} />
    )}
    <div
      ref={ref}
      id={id}
      className={className}
      style={rowStyle}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      onLostPointerCapture={onPointerUp}
      onPointerMove={onPointerMove}
    >
      {columns.map( (atom, columnIndex) => (
        <Cell
          key={`${atom}`}
          columnIndex={columnIndex}
          atom={atom}
          rowId={id}
          groupStyle={!isDragging ? groupStyle : null}
          rowStyle={style}
          styleId={styleId}
          title={title}
          items={items}
          temp={(tempTask === s.createOver) && {start: startDate.current, end: endDate.current}}
          innerRef={tasksWrapper}
          selected={isSelected}
        />
      ))}
      {isShowSplitButton && <SplitButton id={id} /> }
      {isSelected && <div className={s.selectFrame} />}
    </div>
    {(tempTask === s.createBelow) && (
      <TempRow start={startDate.current} end={endDate.current} groupStyle={groupStyle} />
    )}
    </>
  )
}

function TempRow({ start, end, groupStyle }) {
  const rowStyles = useAtomValue(rowStylesAtom);
  const columns = useAtomValue(columnAtomsAtom);
  const className = [
    s.row, rowStyles[0].id,
  ].filter(Boolean).join(" ");
  return (
    <div className={className}>
      {columns.map( (atom, columnIndex) => (
        <Cell
          key={`${atom}`}
          columnIndex={columnIndex}
          atom={atom}
          rowStyle={rowStyles[0]}
          styleId={rowStyles[0].id}
          temp={{start, end}}
          groupStyle={groupStyle}
        />
      ))}
    </div>
  )
}