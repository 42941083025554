import { useState } from "react";
import { Helmet } from "react-helmet";
import { uploadData } from "aws-amplify/storage";
import { generateClient } from "aws-amplify/api";
import { createTimeline } from "graphql/mutations";
import { useLoaderData, useNavigate } from "react-router-dom";
import { templates } from "store/templates";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { copySampleDataAtom, isPaidUserAtom, callToUpgradeModalAtom } from "store";
import { getUser, getUserPrefs, uniqueValues } from "utils";
import { v4 as uid } from "uuid";
import { Toggle } from "components";
import s from "../Timelines/Timelines.module.css";


export default function Templates() {
  const data = useLoaderData();
  const [ busy, setBusy ] = useState(false);

  return (
    <div className={s.wrapper}>
      <Helmet title="Templates" />
      <div className={s.header}>
        <h1>Templates</h1>
        <div className={s.toolbar}>
          <SampleDataToggle />
        </div>
      </div>
      <ul className={s.cards}>
        {data.map((template) => (
          <li className={s.card} key={template.id}>
            <TemplateCard key={template.id} busy={busy} setBusy={setBusy} {...template} />
          </li>
        ))}
      </ul>
    </div>
  )
}

export function SampleDataToggle() {
  const [ isCopySample, setCopySample ] = useAtom(copySampleDataAtom);
  const hasSubscription = useAtomValue(isPaidUserAtom);
  const callToUpgrade = useSetAtom(callToUpgradeModalAtom);

  const copySampleChange = () => {
    if (!hasSubscription) {
      callToUpgrade(true);
    } else {
      setCopySample(!isCopySample);
    }
  }
  return (
    <Toggle
      title="Copy sample data"
      primary
      checked={isCopySample}
      onChange={copySampleChange}
    />
  )
}

export function TemplateCard({ id, title, busy, setBusy }) {
  const [isReady, setReady] = useState(false);
  const isCopySample = useAtomValue(copySampleDataAtom);
  const navigate = useNavigate();

  const onClick = async (e) => {
    if (busy) return;
    setBusy(true);
    const timelineId = await newTimeline({ templateId: id, title, isCopySample });
    if (timelineId) {
      return navigate(`/timeline/${timelineId}`);
    } else {
      setBusy(false);
      console.log("Interal Error")
    }
  }
  const onLoad = () => setReady(true);
  const thumbnailClasses = [s.thumbnail, !isReady && s.loading].filter(Boolean).join(" ");

  const className = [
    s.link,
    busy && s.busy
  ].filter(Boolean).join(" ");

  return (
    <button type="button" className={className} onClick={onClick}>
      <div className={thumbnailClasses}>
        <div className={s.loader} />
        <div className={s.imageWrapper}>
          <img className={s.image} src={`/templates/${id}.png`} alt="" loading="lazy" onLoad={onLoad} />
        </div>
      </div>
      <div className={s.desc}>
        <div className={s.meta}>
          <p className={s.name}>{title}</p>
        </div>
      </div>
    </button>
  )
}


export async function loader() {
  return templates;
}

export async function newTimeline(props) {
  try {
    const { templateId, title, isCopySample } = props ?? {
      templateId: "default",
      title: "Untitled",
      isCopySample: false
    }
    // Fetch user
    const user = await getUser();

    // Fetch template
    const data = await window.fetch(`/templates/${templateId}.json`);
    const str = await data.text();

    // Update IDs
    const idRegexp = /"id":"(.*?)"/g;
    const uidRegexp = /.{8}-.{4}-.{4}-.{4}-.{12}/;
    const ids = [...str.matchAll(idRegexp)]
      .map(val => val[1])
      .filter(val => val !== "root")
      .filter(uniqueValues);
    const result = ids.reduce((acc, id) => {
      const newId = id.replace(uidRegexp, uid());
      return acc.replaceAll(id, newId);
    }, str);
    const content = JSON.parse(result);

    // Rename
    if (title) {
      content.title = title;
    }

    // Strip content
    if (!isCopySample) {
      content.rows = [];
      content.tasks = [];
      content.milestones = [];
      content.team = [];
      content.groups = content.groups.filter(el => el.id === "root");
      content.groups[0].children = [];
    }

    // Apply preferences
    const preferences = getUserPrefs(user);
    Object.assign(content.document, preferences);
    
    // Create timeline
    const client = generateClient();
    const size = (content.tasks?.length || 0) + (content.milestones?.length || 0);
    const response = await client.graphql({
      query: createTimeline,
      variables: {
        input: { title, size }
      }
    });
    const timelineId = response.data.createTimeline.id;
    await uploadData({
      path: ({identityId}) => `private/${identityId}/timeline/${timelineId}.json`,
      data: JSON.stringify(content),
      options: { contentType: "application/json" }
    }).result;
    window.fbq('trackCustom', 'CreateTimeline', {
      content_name: title, status: true
    });

    return timelineId;

  } catch (err) {
    console.log(err);
    return null;
  }
}