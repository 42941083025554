import { useAtomValue } from "jotai"
import { ptAtom, toolAtom, taskStylesMapAtom, milestoneStylesMapAtom, cellIndentAtom, lastUsedTaskStyleAtom, lastUsedMilestoneStyleAtom, SELECTABLE, TOOLS, MILESTONE_LABEL_ALIGNMENT } from "store";
import merge from "lodash/merge";
import Task from "../Task/Task";
import Milestone from "../Milestone/Milestone";
import s from "./Row.module.css";

export default function RowTimeline({ id, items=[], temp }) {
  const taskStylesMap = useAtomValue(taskStylesMapAtom);
  const milestoneStylesMap = useAtomValue(milestoneStylesMapAtom);
  const lastTaskStyle = useAtomValue(lastUsedTaskStyleAtom);
  const lastMilestoneStyle = useAtomValue(lastUsedMilestoneStyleAtom);
  const cellIndent = useAtomValue(cellIndentAtom);
  const pt = useAtomValue(ptAtom);
  const tool = useAtomValue(toolAtom);

  const tasks = items
    .filter( i => i.type === SELECTABLE.TASK)
    .sort((a, b) => a.start - b.start);
  const milestones = items
    .filter( i => i.type === SELECTABLE.MILESTONE)
    .sort((a, b) => a.date - b.date);
  const tempTask = temp && tool === TOOLS.TASK;
  const tempMilestone = temp && tool === TOOLS.MILESTONE;

  const rowSize = items
  .concat(( tempTask ? [
    { 
      type: SELECTABLE.TASK,
      style: lastTaskStyle,
    }
  ] : tempMilestone ? [
    { 
      type: SELECTABLE.MILESTONE,
      style: lastMilestoneStyle,
    }
  ] : []))
  .reduce( (acc, el) => {
    if (el.type === SELECTABLE.TASK) {
      const style = {
        ...taskStylesMap[el.style],
        ...el.overrides,
        labels: taskStylesMap[el.style].labels.map(
          label => ({...label, ...el.overrides?.labels?.[label.id]})
        ),
      }
      const height = style.height;
      const above = style.labels.reduce((acc, label) => label.alignment.startsWith("above") && label.show ? Math.max(acc, label.fontSize) : acc , 0 ) * 1.5 + height / 2;
      const below = style.labels.reduce((acc, label) => label.alignment.startsWith("below") && label.show ? Math.max(acc, label.fontSize) : acc , 0 ) * 1.5 + height / 2;
      // TODO: Count side labels as a possible bigger height
      return {
        height: Math.max(acc.height, height),
        above: Math.max(acc.above, above),
        below: Math.max(acc.below, below)
      };
    } else if (el.type === SELECTABLE.MILESTONE) {
      const style = merge( {}, milestoneStylesMap[el.style], el.overrides );
      let height = style.size;
      let above = 0;
      let below = 0;
      let before = 0;
      let after = 0;
      [style.title, style.date].forEach( el => {
        if (!el.show) return;
        switch (el.textAlign) {
          case MILESTONE_LABEL_ALIGNMENT.ABOVE :
            above += el.fontSize * 1.1;
            break;
          case MILESTONE_LABEL_ALIGNMENT.BELOW :
            below += el.fontSize * 1.1;
            break;
          case MILESTONE_LABEL_ALIGNMENT.BEFORE :
            before += el.fontSize * 1.1;
            break;
          case MILESTONE_LABEL_ALIGNMENT.AFTER :
            after += el.fontSize * 1.1;
            break;
          default: break;
        }
      })
      if ([style.title, style.date].some( el => el.show && el.textAlign === MILESTONE_LABEL_ALIGNMENT.ABOVE )) {
        above += cellIndent;
      }
      if ([style.title, style.date].some( el => el.show && el.textAlign === MILESTONE_LABEL_ALIGNMENT.BELOW )) {
        below += cellIndent;
      }
      const delta = Math.max((Math.max(before, after) - height) / 2, 0);
      above = Math.max(above, delta) + height / 2 + style.baseline;
      below = Math.max(below, delta) + height / 2 - style.baseline;

      return {
        height: Math.max(acc.height, height),
        above: Math.max(acc.above, above),
        below: Math.max(acc.below, below),
      };
    }
    return acc;
  }, { above: 0, below: 0, height: 0 });

  const style = {
    "--top-padding": `${(rowSize.above - rowSize.height / 2) * pt}px`,
    "--bottom-padding": `${(rowSize.below - rowSize.height / 2) * pt}px`,
  }

  return (
    <div className={s.tasksWrapper} style={style}>
      {tasks.map(({style, ...task}) => (
        <Task
          key={task.id}
          row={id}
          styleId={style}
          {...task}
        />
      ))}
      {milestones.map(({style, ...milestone}) => (
        <Milestone
          key={milestone.id}
          row={id}
          styleId={style}
          {...milestone}
        />
      ))}
      {tempTask && (
        <Task
          start={temp.start}
          end={temp.end}
          temp={true}
        />
      )}
      {tempMilestone && (
        <Milestone
          date={temp.end}
          temp={true}
        />
      )}
    </div>
  )
}