import { LABEL_ALIGNMENT, MILESTONE_LABEL_ALIGNMENT } from "store";
import { ReactComponent as Left } from "img/icon/text-left-bold.svg";
import { ReactComponent as Center } from "img/icon/text-center-bold.svg";
import { ReactComponent as Right } from "img/icon/text-right-bold.svg";
import s from "./AlignmentPicker.module.css";


export default function AlignmentPicker({prefix, value, onChange, min}) {
  const onClick = () => {
    document.activeElement.blur();
  }
  const onKeyDown = (e) => {
    if (e.key !== "Escape") return;
    document.activeElement.blur();
  }
  const vals = min ? SORTED_VALS_MIN : SORTED_VALS;
  const className = [s.wrapper, min ? s.min : s.max].filter(Boolean).join(" ");

  const handleChange = (e) => onChange(e.target.value);
  return (
    <div className={s.field}>
      <p className={s.label}>Alignment</p>
      <div className={className} onChange={handleChange}>
        {vals.map( (val, index) => ( !val ? < div className={s.option} key={index} /> :
          <label className={s.option} key={val} onClick={onClick}>
            <input
              type="radio"
              checked={value === val}
              name={`${prefix}-alignment`}
              value={val}
              onChange={()=>{}}
              onKeyDown={onKeyDown}
            />
            <Icon index={index} min={min} />
          </label>
        ))}
      </div>
    </div>
  )
}

function Icon({index, min}) {
  const Component = min ? ICONS_MIN[index] : ICONS[index];
  return <Component className={s.icon} />
}

const SORTED_VALS = [
  null,
  LABEL_ALIGNMENT.ABOVE_LEFT,
  LABEL_ALIGNMENT.ABOVE_CENTER,
  LABEL_ALIGNMENT.ABOVE_RIGHT,
  null,
  LABEL_ALIGNMENT.OUTSIDE_LEFT,
  LABEL_ALIGNMENT.INSIDE_LEFT,
  LABEL_ALIGNMENT.INSIDE_CENTER,
  LABEL_ALIGNMENT.INSIDE_RIGHT,
  LABEL_ALIGNMENT.OUTSIDE_RIGHT,
  null,
  LABEL_ALIGNMENT.BELOW_LEFT,
  LABEL_ALIGNMENT.BELOW_CENTER,
  LABEL_ALIGNMENT.BELOW_RIGHT,
  null,
];
const ICONS = [
  null, Left, Center, Right, null,
  Right, Left, Center, Right, Left,
  null, Left, Center, Right, null,
];

const SORTED_VALS_MIN = [
  null,
  MILESTONE_LABEL_ALIGNMENT.ABOVE,
  null,
  MILESTONE_LABEL_ALIGNMENT.BEFORE,
  null,
  MILESTONE_LABEL_ALIGNMENT.AFTER,
  null,
  MILESTONE_LABEL_ALIGNMENT.BELOW,
  null,
];
const ICONS_MIN = [
  null, Center, null,
  Right, null, Left,
  null, Center, null,
];