import { Outlet, redirect, useLoaderData } from "react-router-dom";
import { Provider, createStore } from "jotai";
import { userAtom } from "store";
import { getUser } from "utils";
import Menu from "./Menu/Menu";
import ScrollToTop from "router/ScrollToTop";
import { UpgradeModal } from "pages/CallToUpgrade/CallToUpgrade";
import s from "./Workspace.module.css";
import { GUEST_USER } from "store";



export default function Workspace() {
  const { store } = useLoaderData();

  return (
    <Provider store={store}>
      <div className={s.wrapper}>
        <UpgradeModal />
        <ScrollToTop />
        <Menu />
        <Outlet />
      </div>
    </Provider>
  )
}

export async function loader() {
  const store = createStore();
  // Get user
  const user = await getUser();
  if (user === GUEST_USER) {
    return redirect("/login")
  }
  store.set(userAtom, user);
  return { store };
}