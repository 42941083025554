import { useAtomValue } from "jotai";
import { dragColumnsAtom } from "store";
import s from "./ColumnGuide.module.css";


export default function ColumnGuide() {
  const drag = useAtomValue(dragColumnsAtom);
  // if (!drag) return null;
  
  const style = {};
  if (drag) {
    style.width = drag.width;
    style.transform = `translateX(${drag.delta + drag.left}px )`;
  }
  const isVisible = !!drag?.delta;

  const className = [ s.wrapper, isVisible && s.show ].filter(Boolean).join(" ");
  return (
    <div className={className} style={style} />
  )
}