import {v4 as uid} from "uuid";
import { FIELDS, LABEL_ALIGNMENT, TIMELINE_UNITS, TIMELINE_FORMATS, GROUP_TYPES, DATE_FORMATS, MILESTONE_LABEL_ALIGNMENT, SHAPES } from "./constants";

const columns = [
  {
    id: uid(),
    field: FIELDS.TITLE,
    format: null,
    width: 180,
  }, {
    id: uid(),
    field: FIELDS.TASKS,
    format: null,
    width: null,
  }
];
const groupStyles = [
  {
    id: "group-" + uid(),
    name: "Primary",
    type: GROUP_TYPES.SWIMLANE,
    background: {h: 0, s: 0, v: 95, a: 1},
    backgroundShow: true,
    padding: 4,
    bar: {
      show: true,
      background: {h: 185, s: 74, v: 62, a: 1},
      backgroundShow: true,
      borderColor: {h: 0, s: 0, v: 0, a: 1},
      borderWidth: 1,
      borderShow: false,
      height: 32,
      borderRadius: 4,
      gapOpacity: 50,
    },
    columns: {
      [columns[0].id] : {
        show: true,
        color: {h: 228, s: 10, v: 21, a: 1},
        fontSize: 12,
        textAlign: "center",
        bold: true,
        italic: false,
        underline: false,
        textTransform: "none",
      },
    },
  }, {
    id: "group-" + uid(),
    name: "Secondary",
    type: GROUP_TYPES.SWIMLANE,
    background: {h: 0, s: 0, v: 95, a: 1},
    backgroundShow: true,
    padding: 4,
    bar: {
      show: true,
      background: {h: 350, s: 80, v: 80, a: 1},
      backgroundShow: true,
      borderColor: {h: 0, s: 0, v: 0, a: 1},
      borderWidth: 1,
      borderShow: false,
      height: 32,
      borderRadius: 4,
      gapOpacity: 50,
    },
    columns: {
      [columns[0].id] : {
        show: true,
        color: {h: 228, s: 10, v: 21, a: 1},
        fontSize: 12,
        textAlign: "center",
        bold: true,
        italic: false,
        underline: false,
        textTransform: "none",
      },
    },
  }
];
const rowStyles = [
  {
    id: "row-" + uid(),
    name: "Main",
    background: {h: 0, s: 0, v: 100, a: 1},
    backgroundShow: false,
    padding: 4,
    columns: {
      [columns[0].id] : {
        show: true,
        color: {h: 228, s: 10, v: 21, a: 1},
        fontSize: 12,
        textAlign: "center",
        bold: true,
        italic: false,
        underline: false,
        textTransform: "none",
      },
    }
  }, {
    id: "row-" + uid(),
    name: "Hightlight",
    background: {h: 214, s: 6, v: 97, a: 1},
    backgroundShow: true,
    padding: 4,
    columns: {
      [columns[0].id] : {
        show: true,
        color: {h: 228, s: 10, v: 21, a: 1},
        fontSize: 12,
        textAlign: "center",
        bold: true,
        italic: false,
        underline: false,
        textTransform: "none",
      },
    }
  }
];
const taskStyles = [
  {
    id: "task-" + uid(),
    name: "Style 1",
    height: 20,
    background: {h: 257, s: 60, v: 67, a: 1},
    backgroundShow: true,
    borderRadius: 4,
    borderColor: {h: 0, s: 0, v: 0, a: 1},
    borderWidth: 1,
    borderShow: false,
    labels: [
      {
        id: "label-" + uid(),
        field: FIELDS.TITLE,
        format: "",
        show: true,
        alignment: LABEL_ALIGNMENT.INSIDE_LEFT,
        color: {h: 0, s: 0, v: 100, a: 1},
        fontSize: 12,
        bold: false,
        italic: false,
        underline: false,
        textTransform: "none",
      }
    ],
  }, {
    id: "task-" + uid(),
    name: "Style 2",
    height: 20,
    background: {h: 185, s: 74, v: 62, a: 1},
    backgroundShow: true,
    borderRadius: 4,
    borderColor: {h: 0, s: 0, v: 0, a: 1},
    borderWidth: 1,
    borderShow: false,
    labels: [
      {
        id: "label-" + uid(),
        field: FIELDS.TITLE,
        format: "",
        show: true,
        alignment: LABEL_ALIGNMENT.INSIDE_LEFT,
        color: {h: 0, s: 0, v: 100, a: 1},
        fontSize: 12,
        bold: false,
        italic: false,
        underline: false,
        textTransform: "none",
      }
    ],
  }, {
    id: "task-" + uid(),
    name: "Style 3",
    height: 20,
    background: {h: 350, s: 80, v: 80, a: 1},
    backgroundShow: true,
    borderRadius: 4,
    borderColor: {h: 0, s: 0, v: 0, a: 1},
    borderWidth: 1,
    borderShow: false,
    labels: [
      {
        id: "label-" + uid(),
        field: FIELDS.TITLE,
        format: "",
        show: true,
        alignment: LABEL_ALIGNMENT.INSIDE_LEFT,
        color: {h: 0, s: 0, v: 100, a: 1},
        fontSize: 12,
        bold: false,
        italic: false,
        underline: false,
        textTransform: "none",
      }
    ],
  }
];
const milestoneStyles = [
  {
    id: "milestone-" + uid(),
    name: "Style 1",
    shape: SHAPES.FLAG,
    background: {h: 257, s: 60, v: 67, a: 1},
    backgroundShow: true,
    borderColor: {h: 0, s: 0, v: 0, a: 1},
    borderWidth: 1,
    borderShow: true,
    size: 18,
    baseline: 0,
    title: {
      show: true,
      color: {h: 228, s: 10, v: 21, a: 1},
      fontSize: 12,
      textAlign: MILESTONE_LABEL_ALIGNMENT.AFTER,
      bold: true,
      italic: false,
      underline: false,
      textTransform: "none",
    },
    date: {
      format: DATE_FORMATS[0],
      show: true,
      color: {h: 228, s: 10, v: 21, a: 0.7},
      fontSize: 10,
      textAlign: MILESTONE_LABEL_ALIGNMENT.AFTER,
      bold: false,
      italic: false,
      underline: false,
      textTransform: "none",
    },
  }, {
    id: "milestone-" + uid(),
    name: "Style 2",
    shape: SHAPES.FLAG,
    background: {h: 185, s: 74, v: 62, a: 1},
    backgroundShow: true,
    borderColor: {h: 0, s: 0, v: 0, a: 1},
    borderWidth: 1,
    borderShow: true,
    size: 18,
    baseline: 0,
    title: {
      show: true,
      color: {h: 228, s: 10, v: 21, a: 1},
      fontSize: 12,
      textAlign: MILESTONE_LABEL_ALIGNMENT.AFTER,
      bold: true,
      italic: false,
      underline: false,
      textTransform: "none",
    },
    date: {
      format: DATE_FORMATS[0],
      show: true,
      color: {h: 228, s: 10, v: 21, a: 0.7},
      fontSize: 10,
      textAlign: MILESTONE_LABEL_ALIGNMENT.AFTER,
      bold: false,
      italic: false,
      underline: false,
      textTransform: "none",
    },
  }, {
    id: "milestone-" + uid(),
    name: "Style 3",
    shape: SHAPES.FLAG,
    background: {h: 350, s: 80, v: 80, a: 1},
    backgroundShow: true,
    borderColor: {h: 0, s: 0, v: 0, a: 1},
    borderWidth: 1,
    borderShow: true,
    size: 18,
    baseline: 0,
    title: {
      show: true,
      color: {h: 228, s: 10, v: 21, a: 1},
      fontSize: 12,
      textAlign: MILESTONE_LABEL_ALIGNMENT.AFTER,
      bold: true,
      italic: false,
      underline: false,
      textTransform: "none",
    },
    date: {
      format: DATE_FORMATS[0],
      show: true,
      color: {h: 228, s: 10, v: 21, a: 0.7},
      fontSize: 10,
      textAlign: MILESTONE_LABEL_ALIGNMENT.AFTER,
      bold: false,
      italic: false,
      underline: false,
      textTransform: "none",
    },
  }
];
const tiers = [
  {
    id: `tier-${uid()}`,
    field: FIELDS.TIMELINE,
    format: TIMELINE_FORMATS[0],
    scale: TIMELINE_UNITS.AUTO,
  
    color: {h: 0, s: 0, v: 0, a: 1},
    fontSize: 12,
    textAlign: "left",
    bold: true,
    italic: false,
    underline: false,
    textTransform: "none",
  
    background: { h: 0, s: 0, v: 100, a: 1 },
    backgroundShow: false,
    height: 24,
    
    separatorColor: { h: 0, s: 0, v: 80, a: 1 },
    separatorWidth: 1,
    separatorShow: true,
  }, {
    id: `tier-${uid()}`,
    field: FIELDS.TIMELINE,
    format: TIMELINE_FORMATS[0],
    scale: TIMELINE_UNITS.YEARS,
  
    color: {h: 0, s: 0, v: 0, a: 1},
    fontSize: 12,
    textAlign: "left",
    bold: true,
    italic: false,
    underline: false,
    textTransform: "none",
  
    background: { h: 0, s: 0, v: 100, a: 1 },
    backgroundShow: false,
    height: 24,
    
    separatorColor: { h: 0, s: 0, v: 80, a: 1 },
    separatorWidth: 1,
    separatorShow: true,
  }, {
    id: `tier-${uid()}`,
    field: FIELDS.COST,
    format: null,
    scale: TIMELINE_UNITS.AUTO,
  
    color: {h: 0, s: 0, v: 0, a: 1},
    fontSize: 12,
    textAlign: "left",
    bold: true,
    italic: false,
    underline: false,
    textTransform: "none",
  
    background: { h: 0, s: 0, v: 100, a: 1 },
    backgroundShow: false,
    height: 24,
    
    separatorColor: { h: 0, s: 0, v: 80, a: 1 },
    separatorWidth: 1,
    separatorShow: true,
  }
];
const header = {
  title: "",
  padding: 8,
  background: { h: 0, s: 0, v: 100, a: 1 },
  backgroundShow: false,
  borderRadius: 0,
  fill: { h: 0, s: 0, v: 95, a: 1 },
  fillShow: true,
  borderColor: { h: 0, s: 0, v: 85, a: 1 },
  borderWidth: 1,
  borderShow: true,

  columns: {
    [columns[0].id] : {
      show: false,
      color: {h: 0, s: 0, v: 0, a: 1},
      fontSize: 12,
      textAlign: "center",
      bold: true,
      italic: false,
      underline: false,
      textTransform: "none",
    },
  },

  tiers: [ tiers[1].id, tiers[0].id ],
};
const footer = {
  title: "",
  padding: 8,
  background: { h: 0, s: 0, v: 100, a: 1 },
  backgroundShow: false,
  borderRadius: 0,
  fill: { h: 0, s: 0, v: 95, a: 1 },
  fillShow: true,
  borderColor: { h: 0, s: 0, v: 85, a: 1 },
  borderWidth: 1,
  borderShow: true,

  columns: {
    [columns[0].id] : {
      show: true,
      color: {h: 0, s: 0, v: 0, a: 1},
      fontSize: 12,
      textAlign: "center",
      bold: true,
      italic: false,
      underline: false,
      textTransform: "none",
    },
  },

  tiers: [ tiers[2].id ],
};

const daySize = 1000 * 60 * 60 * 24;
const startDate = Math.floor(Date.now() / daySize) * daySize;
const document = {
  title: "Untitled timeline",
  indent: 5,
  _start: startDate,
  _end: startDate + (6 * 30 * daySize),
  autoDate: true,
  roundTimeline: TIMELINE_UNITS.MONTHS,
  pagePadding: [15, 15],
  background: {h: 0, s: 0, v: 100, a: 1},
  backgroundShow: false,
  fontFamily: "Arial",
  borderWidth: 1,
  borderColor: {h: 0, s: 0, v: 82, a: 1},
  borderShow: true,
  groupBorderWidth: 1,
  groupBorderColor: {h: 0, s: 0, v: 70, a: 1},
  groupBorderShow: true,
};

export const blankTimeline = {
  groups: [{
    id: "root",
    type: "group",
    style: groupStyles[0].id,
    unfolded: true,
    children: [],
  }],
  columns,
  rows: [],
  tasks: [],
  milestones: [],
  team: [],
  groupStyles,
  rowStyles,
  taskStyles,
  milestoneStyles,
  tiers,
  header,
  footer,
  document,
}