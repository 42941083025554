import { atom } from "jotai";
import { rowsAtom, tasksAtom, groupsAtom, groupStylesAtom, rowStylesAtom, taskStylesAtom, teamAtom, documentAtom, lastUsedTaskStyleAtom, lastUsedMilestoneStyleAtom, columnsAtom, SAVE_STATUS, footerAtom, headerAtom, tiersAtom, milestonesAtom, milestoneStylesAtom, titleAtom } from ".";

const historyLimitAtom = atom(25);

const historyTrackAtoms = [ teamAtom, groupsAtom, rowsAtom, tasksAtom, milestonesAtom, groupStylesAtom, rowStylesAtom, taskStylesAtom, milestoneStylesAtom, columnsAtom, headerAtom, footerAtom, tiersAtom, documentAtom, lastUsedTaskStyleAtom, lastUsedMilestoneStyleAtom, titleAtom ];

export const historyAtom = atom(
  (get) => get(_historyAtom),
  (get, set, stackId) => {
    const history = [...get(_historyAtom)];
    const limit = get(historyLimitAtom);
    const isStackExpired = (Date.now() - get(_historyDateAtom)) > 1000;
    const historyShift = stackId && !isStackExpired && get(_historyStackAtom) === stackId ? 0 : 1;
    const historyPos = get(historyPosAtom) + historyShift;
    history.splice(historyPos, Infinity, historyTrackAtoms.map( atom => get(atom)) );
    while (history.length > limit) {
      history.shift();
    }
    const pos = Math.min(historyPos, limit - 1);
    set(_historyAtom, history);
    set(_historyPosAtom, pos);
    set(_historyStackAtom, stackId);
    set(_historyDateAtom, Date.now());
    set(saveStateAtom, SAVE_STATUS.REQUIRED);
  }
);
const _historyAtom = atom([]);
const _historyStackAtom = atom(null);
const _historyDateAtom = atom(Date.now());

const snapshotStructure = {
  team: teamAtom,
  groups: groupsAtom,
  rows: rowsAtom,
  tasks: tasksAtom,
  milestones: milestonesAtom,
  groupStyles: groupStylesAtom,
  rowStyles: rowStylesAtom,
  taskStyles: taskStylesAtom,
  milestoneStyles: milestoneStylesAtom,
  columns: columnsAtom,
  header: headerAtom,
  footer: footerAtom,
  tiers: tiersAtom,
  document: documentAtom,
}
export const snapshotAtom = atom(null,
  (get) => {
    const result = {};
    Object.entries(snapshotStructure).forEach(([key, value]) => {
      result[key] = get(value);
    });
    return result;
  }
)
export const setProjectAtom = atom(null,
  (get, set, proj) => {
    Object.entries(snapshotStructure).forEach(([key, value]) => {
      if (proj[key] === undefined) return;
      set(value, proj[key]);
    });
    set(resetHistoryAtom);
    set(_saveStateAtom, SAVE_STATUS.SAVED);
    set(lastUsedTaskStyleAtom, null);
    set(lastUsedMilestoneStyleAtom, null);
  }
)

export const resetHistoryAtom = atom(null,
  (get, set) => {
    set(_historyAtom, []);
    set(_historyStackAtom, null);
    set(_historyDateAtom, Date.now());
    set(_historyPosAtom, -1);
    set(historyAtom);
  }
)

const historyPosAtom = atom(
  (get) => get(_historyPosAtom),
  (get, set, pos) => {
    set(_historyPosAtom, pos);
    const state = get(historyAtom)[pos];
    historyTrackAtoms.forEach((atom, index) => {
      set( atom, state[index])
    })
    set(saveStateAtom, SAVE_STATUS.REQUIRED);
  }
);
const _historyPosAtom = atom(-1);


const historyLengthAtom = atom((get) => get(_historyAtom).length);

export const undoAtom = atom(
  (get) => get(historyPosAtom) > 0,
  (get, set, _) => {
    set(historyPosAtom, Math.max(get(historyPosAtom) - 1, 0));
    set(_historyStackAtom, null);
  })

export const redoAtom = atom(
  (get) => get(historyPosAtom) < (get(historyLengthAtom) - 1),
  (get, set, _) => {
    set(historyPosAtom, Math.min(get(historyPosAtom) + 1, get(historyLengthAtom) - 1));
    set(_historyStackAtom, null);
  })

const _saveStateAtom = atom(SAVE_STATUS.SAVED);
export const saveStateAtom = atom(
  (get) => get(_saveStateAtom),
  (get, set, val) => {
    const current = get(_saveStateAtom);
    if (val === SAVE_STATUS.SAVED && current !== SAVE_STATUS.SAVING) {
      return;
    } else {
      set(_saveStateAtom, val);
    }
  }
);
export const syncSaveStateAtom = atom( null,
  (get) => get(saveStateAtom)
)