import { useAtomValue } from 'jotai';
import { selectedTypeAtom, SELECTABLE } from 'store';

import Group from './sections/Group/Group';
import Row from './sections/Row/Row';
import Task from './sections/Task/Task';
import Milestone from './sections/Milestone/Milestone';
import Document from './sections/Document/Document';
import Heading from './sections/Heading/Heading';

import s from "./Aside.module.css";


export default function Aside() {
  const type = useAtomValue(selectedTypeAtom);
  const components = {
    [SELECTABLE.GROUP]     : Group,
    [SELECTABLE.ROW]       : Row,
    [SELECTABLE.TASK]      : Task,
    [SELECTABLE.MILESTONE] : Milestone,
    [SELECTABLE.HEADER]    : Heading,
    [SELECTABLE.FOOTER]    : Heading,
  }
  const Component = components[type] || Document;
  return (
    <aside className={s.aside}>
      <div className={s.contentWrapper}>
        <Component />
      </div>
    </aside>
  )
}