/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTimeline = /* GraphQL */ `
  query GetTimeline($id: ID!) {
    getTimeline(id: $id) {
      id
      title
      content
      size
      updatedAt
      owner
      createdAt
      __typename
    }
  }
`;
export const timelinesByOwner = /* GraphQL */ `
  query TimelinesByOwner(
    $owner: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    timelinesByOwner(
      owner: $owner
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        content
        size
        updatedAt
        owner
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const transactionsByOwner = /* GraphQL */ `
  query TransactionsByOwner(
    $owner: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByOwner(
      owner: $owner
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        currency
        total
        type
        card
        paymentMethodChange
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
