import { Segmented } from "components";
import { ReactComponent as textLeft } from "img/icon/text-left.svg";
import { ReactComponent as textCenter } from "img/icon/text-center.svg";
import { ReactComponent as textRight } from "img/icon/text-right.svg";

export default function TextAlign({ value, onChange }) {
  return (
    <Segmented
    value={value}
    onChange={onChange}
    secondary
    options={[
      {
        label: "Left",
        value: "left",
        icon: textLeft,
      }, {
        label: "Center",
        value: "center",
        icon: textCenter,
      }, {
        label: "Right",
        value: "right",
        icon: textRight,
      },
    ]}
  />
  )
}