import { useCallback, useEffect } from "react";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { splitAtom } from "jotai/utils";
import { vxDate } from "utils";
import { createMilestoneAtom, createRowAtom, createTaskAtom, DAY_LENGHT, groupFindOrCreateAtom, historyAtom, IMPORT_COLUMN_OPTIONS, IMPORTABLE, importModalAtom, mapWorkgroupToTeamAtom, SELECTABLE } from "store";
import Papa from "papaparse";
import ExcelJS from "exceljs";
import dateFormat from "dateformat";
import { useDropzone } from "react-dropzone";
import { Modal, Select } from "components";
import { ModalButton, ModalFooter } from "components/Modal";
import { ReactComponent as Excel } from "img/excel.svg";
import { ReactComponent as Checkmark } from "img/icon/check.svg";
import { ReactComponent as Halfcheck } from "img/icon/halfcheck.svg";
import s from "./ImportModal.module.css";


const importAtom = atom([]);
const importAtomsAtom = splitAtom(importAtom);
const columnsMapAtom = atom([]);


export default function ImportModal() {
  const [isOpen, setOpen] = useAtom(importModalAtom);
  const [data, setData] = useAtom(importAtom);

  useEffect(() => {
    if (!isOpen) return;
    setData([]);
  }, [isOpen, setData])

  const closeModal = () => setOpen(false);

  const isUpload = !data?.length;

  return (
    <Modal
      title="Import data"
      isOpen={isOpen}
      close={closeModal}
      lightClose={false}
      fullscreen
      closeButton
    >
      {isUpload ? (
        <Upload />
      ) : (
        <Preview />
      )}
    </Modal>
  )
}

function Upload() {
  const setData = useSetAtom(importAtom);
  const setColumnsMap = useSetAtom(columnsMapAtom);

  const onDrop = useCallback((acceptedFiles) => {
    const onUpload = async (file) => {
      let data;
      if (file.type === "application/vnd.ms-excel" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        data = await readExcel(file);
      } else if (file.type === "text/csv") {
        data = await readCsv(file);
      } else {
        throw new Error("Unsupported file format. Please upload an Excel or CSV file.");
      }

      // Normalize
      const maxLength = Math.max(...data.map(row => row.length));
      const normalized = data.map(row => [...row, ...Array(maxLength - row.length).fill("")]);

      // Format
      const formatted = normalized.map(row => ({
        checked: true,
        data: row
      }));

      // ColumnsMap
      const columnsMap = Array(maxLength).fill(null);

      // Set
      setData(formatted);
      setColumnsMap(columnsMap);
    }
    onUpload(acceptedFiles[0] || null);
  }, [setData, setColumnsMap]);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragActive,
    isDragReject
  } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "text/csv": [".csv"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    }
  });

  const className = [
    s.upload,
    isFocused && s.focused,
    isDragActive && s.active,
    isDragReject && s.reject,
  ].filter(Boolean).join(" ");

  return (
    <div {...getRootProps({ className })}>
      <input {...getInputProps()} />
      <Excel className={s.icon} />
      <p className={s.description}>Drop excel or csv file here to import or...</p>
      <p className={s.button}>Browse files</p>
    </div>
  )
}

function Preview() {
  const setOpen = useSetAtom(importModalAtom);
  const [data, setData] = useAtom(importAtom);
  const columnsMap = useAtomValue(columnsMapAtom);
  const atoms = useAtomValue(importAtomsAtom);
  const mapWorkgroupToTeam = useSetAtom(mapWorkgroupToTeamAtom);
  const groupFindOrCreate = useSetAtom(groupFindOrCreateAtom);
  const createRow = useSetAtom(createRowAtom);
  const createTask = useSetAtom(createTaskAtom);
  const createMilestone = useSetAtom(createMilestoneAtom);
  const setHistory = useSetAtom(historyAtom);

  const clear = () => {
    setData([]);
  }

  const create = (props) => {
    // Group
    const group = groupFindOrCreate({ title: props.group }).id;
    const row = createRow({ group }).id;

    // Create
    if (props.type === SELECTABLE.MILESTONE) {
      const { title, date } = props;
      createMilestone({
        title, date, row
      });
    } else if (props.type === SELECTABLE.TASK) {
      const { title } = props;
      let [start, end] = vxDate.sort(props.start, props.end);
      end += DAY_LENGHT;
      const workgroup = mapWorkgroupToTeam(props.assignee);

      createTask({
        title, start, end, row, workgroup
      });

    } else {
      console.warn("Unknown type");
    }
  }

  const onSubmit = () => {
    const mapped = mapData(data, columnsMap);
    mapped.forEach(create);
    setHistory();
    setOpen(false);
  }

  const checkedRowsAmount = data.filter(row => row.checked).length;

  let checkedRowsString;
  if (!checkedRowsAmount) {
    checkedRowsString = `No items to import`;
  } else if (checkedRowsAmount === 1) {
    checkedRowsString = `1 item to import`;
  } else {
    checkedRowsString = `${checkedRowsAmount} items to import`;
  }

  const isMapped = columnsMap.includes(IMPORTABLE.START) || columnsMap.includes(IMPORTABLE.END);
  const isDisabled = !checkedRowsAmount || !isMapped;

  return (
    <>
      <div className={s.toolbarWrapper}>
        <p>Map the columns from your file to the importable attributes.</p>
      </div>
      <div className={s.previewWrapper}>
        <div className={s.previewTable}>
          <HeadingRow />
          {atoms.map((row, id) => (
            <Row atom={row} key={id} />
          ))}
        </div>
      </div>
      <ModalFooter>
        <ModalButton onClick={clear}>Back</ModalButton>
        <div style={{ flexGrow: 1 }} />
        <p className={s.checkedRowsComment}>{checkedRowsString}</p>
        <ModalButton primary disabled={isDisabled} onClick={onSubmit}>Import</ModalButton>
      </ModalFooter>
    </>
  )
}

function Row({ atom }) {
  const [row, setRow] = useAtom(atom);
  const onChange = () => setRow({
    ...row,
    checked: !row.checked
  })
  const className = [
    s.row,
    !row.checked && s.disabled
  ].filter(Boolean).join(" ");

  return (
    <label className={className}>
      <div className={s.cell}>
        <div className={s.checkboxWrapper}>
          <input type="checkbox" checked={row.checked} onChange={onChange} />
          {row.checked && <Checkmark className={s.checkmark} />}
        </div>
      </div>
      {row.data.map((cell, id) => (
        <div className={s.cell} key={id}>
          <div className={s.value}>
            {cellValue(cell)}
          </div>
        </div>
      ))}
    </label>
  )
}

function HeadingRow() {
  const [data, setData] = useAtom(importAtom);
  const [columnsMap, setColumnsMap] = useAtom(columnsMapAtom);

  // Mapping
  const onSelect = (index) => {
    return (value) => {
      const newMap = columnsMap.map((colValue, colIndex) => {
        if (colIndex === index) return value;
        if (colValue === value) return null;
        return colValue;
      });
      setColumnsMap(newMap);
    }
  }

  // Checkbox
  const checkedRowsAmount = data.filter(row => row.checked).length;
  const checked = checkedRowsAmount === data.length;
  const halfchecked = !checked && checkedRowsAmount > 0;
  const onCheck = () => {
    const value = !(checked || halfchecked);
    const newData = data.map(row => ({
      ...row,
      checked: value
    }))
    setData(newData);
  }

  // Styles
  const getStyles = (value) => ({
    control: (styles, { menuIsOpen }) => ({
      ...styles,
      color: !!value ? "var(--black)" : "var(--unactive)",
      fontWeight: !!value ? "600" : "regular",
      ":hover": {
        backgroundColor: "var(--active)",
      },
      outline: menuIsOpen ? "var(--focus-outline)" : "none",
    }),
    option: (styles, { data }) => ({
      ...styles,
      color: !data.value ? "var(--unactive)" : "var(--black)"
    })
  });

  return (
    <div className={s.headingRow}>
      <label className={s.cell}>
        <div className={s.checkboxWrapper}>
          <input type="checkbox" checked={checked || halfchecked} onChange={onCheck} />
          {checked ? (
            <Checkmark className={s.checkmark} />
          ) : halfchecked ? (
            <Halfcheck className={s.checkmark} />
          ) : null
          }
        </div>
      </label>
      {columnsMap.map((value, index) => (
        <div className={s.cell} key={index} >
          <Select
            className={s.columnSelect}
            options={IMPORT_COLUMN_OPTIONS}
            onChange={onSelect(index)}
            value={value}
            classNames={{
              control: [s.selectControl, value && s.mapped].filter(Boolean).join(" "),
              menuList: s.menuList
            }}
            styles={getStyles(value)}
          />
        </div>
      ))}
    </div>
  )
}

const cellValue = (val) => {
  if (!val) {
    return null;
  } else if (typeof val === "object") {
    let obj = val.result ?? val.text ?? val;
    if (obj instanceof Date) {
      return dateFormat(obj, "dd mmm yyyy", true);
    } else {
      return `${obj}`;
    }
  } else {
    return `${val}`;
  }
}


// READERS
const readExcel = async (file) => {
  const workbook = new ExcelJS.Workbook();
  await workbook.xlsx.load(await file.arrayBuffer());

  const sheet = workbook.worksheets[0]; // First sheet
  const data = [];

  sheet.eachRow((row) => {
    data.push(row.values.slice(1)); // Remove first element (empty)
  });

  return data;
};

const readCsv = (file) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      complete: (results) => resolve(results.data),
      error: (err) => reject(err),
    });
  });
};


const mapData = (data, map) => {
  const groupIndex = map.indexOf(IMPORTABLE.GROUP);
  const titleIndex = map.indexOf(IMPORTABLE.TITLE);
  const startDateIndex = map.indexOf(IMPORTABLE.START);
  const endDateIndex = map.indexOf(IMPORTABLE.END);
  const assigneeNameIndex = map.indexOf(IMPORTABLE.ASSIGNEE_NAME);
  const assigneeRoleIndex = map.indexOf(IMPORTABLE.ASSIGNEE_ROLE);
  const assigneeRateIndex = map.indexOf(IMPORTABLE.ASSIGNEE_RATE);
  const assigneeAllocationIndex = map.indexOf(IMPORTABLE.ASSIGNEE_ALLOCATION);

  const mapped = data
    // Only selected for import 
    .filter(row => row.checked)
    // Only data itself
    .map(row => row.data)
    // Formatting
    .map(row => {
      const group = formatString(row[groupIndex]);
      const title = formatString(row[titleIndex]);
      const start = formatDate(row[startDateIndex]);
      const end = formatDate(row[endDateIndex]);
      let assignee = {
        name: formatString(row[assigneeNameIndex]),
        role: formatString(row[assigneeRoleIndex]),
        rate: formatNumber(row[assigneeRateIndex]),
        allocation: assigneeAllocationIndex === -1 ? 1 : formatNumber(row[assigneeAllocationIndex])
      }

      // Invalid
      if (!start && !end) return { invalid: true };

      // Milestone
      if (!start || !end || start === end) return {
        type: SELECTABLE.MILESTONE,
        group,
        title,
        date: start || end
      };

      // Task
      const id = `${group}-${title}-${start}-${end}`.toLowerCase();
      return {
        type: SELECTABLE.TASK,
        id,
        group,
        title,
        start,
        end,
        assignee: [assignee]
      }
    })
    // Filter out those with invalid dates
    .filter(row => !row.invalid)
    // Merge identical tasks
    .reduce((acc, row) => {
      if (row.type === SELECTABLE.MILESTONE) {
        acc.push(row);
        return acc;
      }
      const target = acc.find(el => el.id === row.id);
      if (target) {
        target.assignee.push(...row.assignee);
      } else {
        acc.push(row);
      }
      return acc;
    }, []);

  return mapped;
}

function formatDate(val) {
  try {
    if (!val) {
      throw new Error("No date");
    }
    const preview = cellValue(val);
    const isoDate = dateFormat(preview, "yyyy-mm-dd'Z'");
    return (new Date(isoDate)).getTime();
  } catch {
    console.warn("Invalid date")
    return null;
  }
}
function formatNumber(val) {
  const preview = cellValue(val);
  return parseFloat(preview) || 0;
}
function formatString(val) {
  return cellValue(val) || "";
}