import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  historyAtom, roundTimelineAtom, autoDateAtom,
  minDateAtom, maxDateAtom, taskMinDateAtom, taskMaxDateAtom, 
  TIMELINE_ROUND_SHORT_OPTIONS
} from "store";

import { Segmented }  from "components";
import Section        from "../../components/Section/Section";
import DatePicker     from "../../components/DatePicker/DatePicker";
import Toggle         from "../../components/Toggle/Toggle";


export default function TimelineConfig() {
  const [roundTimeline, setRoundTimeline] = useAtom(roundTimelineAtom);
  const [minDate, setMinDate] = useAtom(minDateAtom);
  const [maxDate, setMaxDate] = useAtom(maxDateAtom);
  const [autoDate, setAutoDate] = useAtom(autoDateAtom);
  const taskMinDate = useAtomValue(taskMinDateAtom);
  const taskMaxDate = useAtomValue(taskMaxDateAtom);
  const setHistory = useSetAtom(historyAtom);

  const onAutoChange = () => {
    setAutoDate( !autoDate );
    setHistory(`timeline-auto-date`)
  }
  const startOnChange = (val) => {
    setAutoDate( false );
    setMinDate( val );
    setHistory(`timelie-start`);
  };
  const endOnChange = (val) => {
    setAutoDate( false );
    setMaxDate( val );
    setHistory(`timelie-end`);
  };

  const onRoundChange = (val) => {
    setRoundTimeline( val );
    setHistory(`round-timeline`);
  }

  return (
    <>
      <Section title="Date range">
        <div>
          <Segmented
            label="Rounding"
            value={roundTimeline}
            onChange={onRoundChange}
            secondary
            showLabels
            options={TIMELINE_ROUND_SHORT_OPTIONS}
          />
        </div>
        <div>
          <DatePicker
            title="Start"
            value={minDate}
            maxDate={taskMinDate}
            onChange={startOnChange}
            format={roundTimeline}
          />
          <DatePicker
            title="End"
            value={maxDate}
            minDate={taskMaxDate}
            isLast={true}
            onChange={endOnChange}
            format={roundTimeline}
          />
        </div>
        <div>
          <Toggle title="Auto dates" onChange={onAutoChange} checked={autoDate} />
        </div>
      </Section>
    </>
  )
}