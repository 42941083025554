import { useState } from "react";
import { useSetAtom } from "jotai";
import { userAtom } from "store";
import { fetchAuthSession, fetchUserAttributes } from "aws-amplify/auth";
import { patch } from "aws-amplify/api";
import { Button, Input } from "components";
import s from "./General.module.css";


export default function NameInput({ value, setMessage }) {
  const setUser = useSetAtom(userAtom);
  const [name, setName] = useState(value);
  const [isBusy, setBusy] = useState(false);

  const onChange = (e) => {
    setName(e.target.value);
    setBusy(false);
  }
  const onSubmit = async (e) => {
    e.preventDefault();
    setBusy(true);
    try {
      const { idToken, accessToken } = (await fetchAuthSession()).tokens;
      await patch({
        apiName: "user",
        path: "/name",
        options: {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
          body: {
            name: name.trim(),
            token: accessToken.toString(),
          }
        }
      }).response;
      setMessage({ message: "Name changed" })
      const newUser = await fetchUserAttributes();
      setUser(newUser);
    } catch (error) {
      console.log(error.name);
    }
    setBusy(false);
  }

  const disabled = isBusy || name === value;

  const label = isBusy ? "Saving..." : "Save";

  return (
    <form className={s.row} onSubmit={onSubmit}>
      <Input
        className={s.input}
        id="user-name"
        label="Name"
        type="text"
        name="name"
        value={name}
        onChange={onChange}
        autoComplete="name"
        required
      />
      <Button className={s.button} label={label} disabled={disabled} />
    </form>
  )
}