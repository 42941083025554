import { useEffect, useRef, useState } from "react";
import { useAtomValue } from "jotai";
import { snappingAtom, snapDatesAtom, snapThresholdAtom } from "store";



export function useToggle(initial) {
  const [val, setVal] = useState(initial || false);
  const on = () => setVal(true);
  const off = () => setVal(false);
  return [val, on, off, setVal];
}

export function useTimeoutState(timeout = 2000) {
  const [temp, setTemp] = useState(null);
  const timer = useRef(null);
  const set = () => {
    clear();
    timer.current = setTimeout(clear, timeout)
    setTemp(true);
  }
  const clear = () => {
    timer.current && clearTimeout(timer.current);
    timer.current = null;
    setTemp(false);
  }
  return [temp, set, clear];
}

export function useDebounceCallback(callback, timeout = 500) {
  const timer = useRef(null);

  const debounce = (delay = timeout) => {
    clear();
    timer.current = setTimeout(trigger, delay);
  }
  const trigger = () => {
    clear();
    callback();
  }
  const clear = () => {
    timer.current && clearTimeout(timer.current);
    timer.current = null;
  }
  return [debounce, trigger, clear];
}

export function useSnap() {
  const snapThreshold = useAtomValue(snapThresholdAtom);
  const snapping = useAtomValue(snappingAtom);
  const snapDates = useAtomValue(snapDatesAtom);
  const [dates, setDates] = useState([]);

  useEffect(() => {
    if (arguments.length === 0) return;
    const p = [...snapDates];
    [...arguments].forEach((el => p.splice(p.indexOf(el), 1)));
    setDates(p);
  }, [snapDates])

  const check = (val) => {
    if (!snapping) return {
      val: val,
      delta: 0,
      snapped: false,
    }
    const p = (arguments.length === 0) ? snapDates : dates;
    const distances = p.map(p => Math.abs(p - val));
    const index = distances.indexOf(Math.min(...distances));
    const isSnapped = (distances[index] <= snapThreshold);
    const result = isSnapped ? p[index] : val;
    return {
      val: result,
      delta: result - val,
      snapped: isSnapped,
    }
  }
  return check;
}