import { useRive, useStateMachineInput } from "@rive-app/react-canvas";
import s from "./Button.module.css";

export default function Button ({
  rive, riveState, iconOnly, active, className, secondary, listButton, hotkey, tooltip,
  el:El = "button",
  label = "Label",
  icon:Icon = "",
  tooltipAlign = "center",
  reverseTooltip,
  ...props
}) {
  const buttonClassName = [
    s.button,
    className,
    secondary && s.secondary,
    listButton && s.listButton,
    active && s.active
  ].filter(Boolean).join(" ");

  const labelClassName = [
    s.label,
    iconOnly && s.hidden
  ].filter(Boolean).join(" ");
  
  return (
    <El className={buttonClassName} {...props}>
      {Icon && <Icon draggable={false} className={s.icon} />}
      {rive && <RiveIcon className={s.icon} src={rive} state={riveState} />}
      <span className={labelClassName}>{label}</span>
      {tooltip && (
        <Tooltip
          hotkey={hotkey}
          align={tooltipAlign}
          reverse={reverseTooltip}
        >
          {tooltip}
        </Tooltip>
      )}
    </El>
  )
}

export function Tooltip({children, hotkey, align, reverse, className}) {
  const cn = [
    s.tooltipWrapper,
    className,
    alignClasses[align],
    reverse && s.reverseTooltip
  ].filter(Boolean).join(" ");

  return (
    <>
      <div className={s.hitTarget}/>
      <div className={cn}>
        <span className={s.tooltip}>{children}</span>
        {hotkey && <span className={s.hotkey}>{
          hotkey.map(k => <span key={k} className={s.key}>{k}</span>)
        }</span>}
      </div>
    </>
  )
}

function RiveIcon({className, src, state}) {
  const { rive, RiveComponent } = useRive({
    src: src,
    stateMachines: "default",
    autoplay: true,
  });
  const stateInput = useStateMachineInput(rive, "default", "state", 2);
  if (stateInput) {
    stateInput.value = state;
  }
  return <RiveComponent className={className} />
}

const alignClasses = {
  left: s.tooltipLeft,
  right: s.tooltipRight,
  center: s.tooltipCenter,
}