import { useAtomValue, useSetAtom } from "jotai";
import { useEffect, useRef } from "react";
import { historyAtom, updateTeamMemberAtom, currencySignAtom } from "store";
import NumberInput from "../../../components/NumberInput/NumberInput";
import Input from "../../../components/Input/Input";
import s from "./MemberModal.module.css";


export default function MemberModal({ value, onClose }) {
  const sign = useAtomValue(currencySignAtom);
  const updateTeamMember = useSetAtom(updateTeamMemberAtom);
  const setHistory = useSetAtom(historyAtom);
  const ref = useRef();

  const onChange = (prop, val) => {
    if (value[prop] === val) return;
    updateTeamMember({...value, [prop]: val});
    setHistory(`worker-update-${prop}-${value.id}`);
  };
  const roleChange = (val) => onChange("role", val);
  const nameChange = (val) => onChange("name", val);
  const rateChange = (val) => onChange("rate", val);

  useEffect( () => {
    const handler = (e) => {
      if ( ref.current.contains(e.target) ) {
        document.addEventListener( "click", handler, { once: true, capture: true } );
      } else {
        e.preventDefault();
        e.stopPropagation();
        onClose(value);
      }
    };
    if (value) {
      document.addEventListener( "click", handler, { once: true, capture: true } );
    }
    return () => document.removeEventListener( "click", handler, true );
  }, [ value, onClose ]);


  const onKeyDown = (e) => {
    if ( e.key === "Escape" || e.key === "Enter" ) {
      onClose(value);
    }
  }

  return value ? (
    <div className={s.modal} ref={ref} onKeyDown={onKeyDown} tabIndex={0}>
      <div className={s.row}>
        <div>
          <Input
            title="Role"
            value={value.role}
            onChange={roleChange}
            id="member-role"
            autoFocus
          />
        </div>
        <div>
          <NumberInput
            title={"Rate/d"}
            id={"member-rate"}
            value={value.rate}
            min={0}
            step={10}
            unit={sign}
            onChange={rateChange}
            instant
          />
        </div>
      </div>
      <div>
        <Input
          title="Name"
          value={value.name}
          onChange={nameChange}
          id="member-name"
        />
      </div>
      <p className={s.disclaimer}>
        The member will be updated across the whole document.
      </p>
    </div>
  ) : null;
}