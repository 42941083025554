import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { clamp, has } from "lodash";
import { EDIT_MODES, FIELDS, PAGE_WIDTH, TOOLS, TIMELINE_UNITS, DEFAULT_WORKDAYS, DEFAULT_CURRENCY_SIGN, DEFAULT_CURRENCY_PLACEMENT } from ".";
import { availableWidthAtom, columnsAtom, timelineColumnIndexAtom, timelineColumnWidthAtom } from "./columns";
import { rowsAtom, composedRowsAtom } from "./rows";
import { milestonesAtom } from "./milestones";
import { groupsAtom } from "./groups";
import { dateRangeAtom, taskMaxDateAtom, taskMinDateAtom, tasksAtom, _maxDateAtom, _minDateAtom, maxDateAtom, minDateAtom } from "./tasks";


export const toolAtom = atom(TOOLS.TASK);
export const editModeAtom = atomWithStorage("editMode", EDIT_MODES.STYLE);
export const optionKeyAtom = atom(false);
export const tokensAtom = atom(0);

export const mapAtom = atom(get => {
  const result = new Map();
  [].concat(
    get(milestonesAtom),
    get(tasksAtom),
    get(composedRowsAtom),
    get(groupsAtom)
  ).forEach(el => result.set(el.id, el));
  return result;
});

export const parentsMapAtom = atom(get => {
  return [].concat(
    get(rowsAtom),
    get(groupsAtom)
  ).reduce(
    (acc, el) => {
      el.children.forEach(
        (id) => acc[id] = el
      );
      return acc;
    }, {});
})

// Page style
const _pagePaddingAtom = atom([15, 15]);
export const pagePaddingAtom = atom(
  (get) => get(_pagePaddingAtom),
  (get, set, upd) => {
    const prev = get(_pagePaddingAtom);
    const availableSpace = get(availableWidthAtom);

    const isBothSides = (upd[0] !== prev[0]) && (upd[1] !== prev[1]);
    const availableForSide = isBothSides ? Math.floor(availableSpace / 2) : availableSpace;

    const delta = [];
    delta[0] = Math.min(upd[0] - prev[0], availableForSide);
    delta[1] = availableSpace - delta[0];

    const result = [      
      clamp( upd[0], 0, prev[0] + delta[0]),
      clamp( upd[1], 0, prev[1] + delta[1])
    ];
    set(_pagePaddingAtom, result);
  }
);
const _timelinePaddingAtom = atom([0,0]);
export const timelinePaddingAtom = atom(
  (get) => {
    let [L, R] = get(_timelinePaddingAtom);
    const width = get(timelineColumnWidthAtom);
    const maxSum = width - 100;
    const sum = L + R;
    if (sum > maxSum) {
      L = Math.round(L / sum * maxSum);
      R = maxSum - L;
    }
    return [L, R];
  },
  (get, set, upd) => {
    const [currentL, currentR] = get(_timelinePaddingAtom);
    const width = get(timelineColumnWidthAtom);
    let [updL, updR] = upd;
    updL = Math.max(updL, 0);
    updR = Math.max(updR, 0);
    updL = Math.round(updL);
    updR = Math.round(updR);
    const deltaL = updL - currentL;
    const deltaR = updR - currentR;
    const maxSum = width - 100;
    const sum = updL + updR;
    if (sum > maxSum) {
      if (deltaL && deltaR) {
        updL = Math.round(updL / sum * maxSum);
        updR = maxSum - updL;
      } else if (deltaL) {
        updL = maxSum - updR;
      } else {
        updR = maxSum - updL;
      }
    }
    set(_timelinePaddingAtom, [updL, updR]);
  }
)
export const cellIndentAtom = atom(5);
export const backgroundAtom = atom({h: 0, s: 0, v: 100, a: 1});
export const backgroundShowAtom = atom(true);
export const pageFontAtom = atom("Arial");
export const borderColorAtom = atom({h: 0, s: 0, v: 82, a: 1});
export const borderWidthAtom = atom(1);
export const borderShowAtom = atom(true);
export const groupBorderColorAtom = atom({h: 0, s: 0, v: 82, a: 1});
export const groupBorderWidthAtom = atom(1);
export const groupBorderShowAtom = atom(true);
export const titleAtom = atom("");
export const workdaysAtom = atom(DEFAULT_WORKDAYS);
export const currencySignAtom = atom(DEFAULT_CURRENCY_SIGN);
export const currencyPlacementAtom = atom(DEFAULT_CURRENCY_PLACEMENT);
export const preferencesAtom = atom((get) => ({
  workdays: get(workdaysAtom),
  currencySign: get(currencySignAtom),
  currencyPlacement: get(currencyPlacementAtom),
}))
const _autoDateAtom = atom(true);
export const autoDateAtom = atom(
  (get) => get(_autoDateAtom),
  (get, set, val) => {
    const prev = get(_autoDateAtom);
    const tasks = get(tasksAtom);
    if (prev === val) {
      return;
    } if (tasks?.length) {
      set(minDateAtom, get(taskMinDateAtom));
      set(maxDateAtom, get(taskMaxDateAtom));
    }
    set(_autoDateAtom, val);
  }
);
export const roundTimelineAtom = atom(TIMELINE_UNITS.MONTHS);

const documentStructure = {
  indent: cellIndentAtom,
  pagePadding: pagePaddingAtom,
  timelinePadding: timelinePaddingAtom,
  background: backgroundAtom,
  backgroundShow: backgroundShowAtom,
  fontFamily: pageFontAtom,
  borderWidth: borderWidthAtom,
  borderColor: borderColorAtom,
  borderShow: borderShowAtom,
  groupBorderWidth: groupBorderWidthAtom,
  groupBorderColor: groupBorderColorAtom,
  groupBorderShow: groupBorderShowAtom,
  workdays: workdaysAtom,
  currencyPlacement: currencyPlacementAtom,
  currencySign: currencySignAtom,
  autoDate: _autoDateAtom,
  roundTimeline: roundTimelineAtom,
  start: minDateAtom,
  end: maxDateAtom,
  _start: _minDateAtom,
  _end: _maxDateAtom
};

export const documentAtom = atom(
  get => {
    return Object.fromEntries(
      Object.entries(documentStructure).map(
        ([key, value]) => [key, get(value)]
      )
    )
  },
  (_, set, doc) => {
    Object.entries(documentStructure).forEach(([key, value]) => {
      if (!has(doc, key)) return;
      set(value, doc[key]);
    });
  }
)

// Workarea
export const workareaWidthAtom = atom(1);
export const mouseToWorkareaAtom = atom(null);
export const ptAtom = atom( get => get(workareaWidthAtom) / PAGE_WIDTH );

// How much time in a single pixel
export const timeScaleAtom = atom(get => {
  const pt = get(ptAtom);
  const dateRange = get(dateRangeAtom);
  const pagePadding = get(pagePaddingAtom);
  const timelinePadding = get(timelinePaddingAtom);
  const columns = get(columnsAtom);
  const outterArea = columns
  .filter( col => col.field !== FIELDS.TASKS )
  .map( el => el.width )
  .concat( pagePadding )
  .concat( timelinePadding )
  .reduce( (acc, val) => acc + val, 0 );

  const pixelRange = (PAGE_WIDTH - outterArea) * pt;
  return dateRange / pixelRange;
})

// Indent of the Min Date
export const timelineIndentAtom = atom(get => {
  const pt = get(ptAtom);
  const [tlPaddingLeft] = get(timelinePaddingAtom);
  const [paddingLeft] = get(pagePaddingAtom);
  const columns = get(columnsAtom);
  const timelineIndex = get(timelineColumnIndexAtom);
  const startPos = columns
  .slice(0, timelineIndex)
  .map( el => el.width )
  .concat( paddingLeft )
  .concat( tlPaddingLeft )
  .reduce( (acc, w) => acc + w, 0);
  return startPos * pt;
})

// What's the date under the pointer
export const mouseTimestampAtom = atom( get => {
  const x = get(mouseToWorkareaAtom)?.[0];
  if (!x) return null;
  const start = get(minDateAtom);
  const timeScale = get(timeScaleAtom);  
  const indent = get(timelineIndentAtom);
  return start + timeScale * (x - indent);
})

export const timeSnapshotAtom = atom(null, (get) => get(mouseTimestampAtom))

export const editingAtom = atom(false);


const _lockedAtom = atomWithStorage("lockedTasks", false);

export const lockedAtom = atom(
  (get) => {
    return !!(get(_lockedAtom) ^ get(optionKeyAtom));
  },
  (get, set, val) => {
    set(_lockedAtom,
      !!(val ^ get(optionKeyAtom))
    )
  }
)

export const importModalAtom = atom(false);

export const teamModalAtom = atom(false);