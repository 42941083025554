import { useAtom, useSetAtom } from "jotai";
import { historyAtom, backgroundAtom, backgroundShowAtom, pageFontAtom, borderWidthAtom, borderColorAtom, borderShowAtom, groupBorderWidthAtom, groupBorderColorAtom, groupBorderShowAtom, FONT_OPTIONS } from "store";

import ColorPicker from "../../components/ColorPicker/ColorPicker";
import NumberInput from "../../components/NumberInput/NumberInput";
import Section from "../../components/Section/Section";
import Select from "components/Select";
import { ReactComponent as LineWidth } from "img/icon/line-width.svg";


export default function DocumentConfig() {
  const [background, setBackground] = useAtom(backgroundAtom);
  const [pageFont, setPageFont] = useAtom(pageFontAtom);
  const [borderWidth, setBorderWidth] = useAtom(borderWidthAtom);
  const [borderColor, setBorderColor] = useAtom(borderColorAtom);
  const [groupBorderWidth, setGroupBorderWidth] = useAtom(groupBorderWidthAtom);
  const [groupBorderColor, setGroupBorderColor] = useAtom(groupBorderColorAtom);
  const [backgroundShow, setBackgroundShow] = useAtom(backgroundShowAtom);
  const [borderShow, setBorderShow] = useAtom(borderShowAtom);
  const [groupBorderShow, setGroupBorderShow] = useAtom(groupBorderShowAtom);
  const setHistory = useSetAtom(historyAtom);

  const onPageFontChange = (val) => {
    if (val === pageFont) return;
    setPageFont(val);
    setHistory(`page-font-change`);
  }
  const onBackgroundChange = ({ hsva }) => {
    if (hsva === background) return;
    setBackground(hsva);
    setHistory(`page-fill-change`);
  }
  const onPageAlphaChange = (val) => {
    if (val / 100 === background.a) return;
    setBackground({ ...background, a: val / 100 });
    setHistory(`page-fill-alpha-change`);
  }
  const onBorderColorChange = ({ hsva }) => {
    if (hsva === borderColor) return;
    setBorderColor(hsva);
    setHistory(`page-border-color-change`);
  }
  const onBorderWidthChange = (val) => {
    if (val === borderWidth) return;
    setBorderWidth(val);
    setHistory(`page-border-width-change`);
  }
  const onGroupBorderColorChange = ({ hsva }) => {
    if (hsva === groupBorderColor) return;
    setGroupBorderColor(hsva);
    setHistory(`page-group-border-color-change`);
  }
  const onGroupBorderWidthChange = (val) => {
    if (val === groupBorderWidth) return;
    setGroupBorderWidth(val);
    setHistory(`page-group-border-width-change`);
  }

  const toggleBackground = () => {
    setBackgroundShow(!backgroundShow);
    setHistory("toggle-page-fill");
  }
  const toggleBorder = () => {
    setBorderShow(!borderShow);
    setHistory("toggle-page-border");
  }
  const toggleGroupBorder = () => {
    setGroupBorderShow(!groupBorderShow);
    setHistory("toggle-page-group-border");
  }

  return (
    <Section title="Appearance">
      <div>
        <Select
          title="Font"
          id={"document-font"}
          value={pageFont}
          options={FONT_OPTIONS}
          onChange={onPageFontChange}
        />
      </div>
      <div>
        <ColorPicker
          title={"Page fill"}
          id={"document-fill"}
          color={background}
          onChange={onBackgroundChange}
          onToggle={toggleBackground}
          disabled={!backgroundShow}
        />
        <NumberInput
          title="Opacity"
          id={"document-fill-alpha"}
          value={Math.floor(background.a * 100)}
          min={0}
          max={100}
          unit="%"
          onChange={onPageAlphaChange}
          disabled={!backgroundShow}
        />
      </div>
      <div>
        <ColorPicker
          title={"Row borders"}
          id={"document-border-color"}
          color={borderColor}
          onChange={onBorderColorChange}
          onToggle={toggleBorder}
          disabled={!borderShow}
        />
        <NumberInput
          title="Line width"
          id={"document-border-width"}
          value={borderWidth}
          min={0}
          max={20}
          step={0.5}
          onChange={onBorderWidthChange}
          icon={LineWidth}
          disabled={!borderShow}
        />
      </div>
      <div>
        <ColorPicker
          title={"Group borders"}
          id={"document-group-border-color"}
          color={groupBorderColor}
          onChange={onGroupBorderColorChange}
          onToggle={toggleGroupBorder}
          disabled={!groupBorderShow}
        />
        <NumberInput
          title="Line width"
          id={"document-group-border-width"}
          value={groupBorderWidth}
          min={0}
          max={20}
          step={0.5}
          onChange={onGroupBorderWidthChange}
          icon={LineWidth}
          disabled={!groupBorderShow}
        />
      </div>
    </Section>
  )
}