import { useAtomValue, useSetAtom } from "jotai";
import { historyAtom, editColumnAtom, editColumnAlignAtom, removeColumnAtom, COLUMN_OPTIONS, FIELDS } from "store";
import { useStyleEdit } from "utils";

import Section, { Icon } from './Section/Section';
import ColorPicker from './ColorPicker/ColorPicker';
import NumberInput from "./NumberInput/NumberInput";
import TextAlign from "./TextAlign";
import TextStyle from "./TextStyle";
import TextTransform from "./TextTransform";
import FormatPicker from "./FormatPicker/FormatPicker";
import { ReactComponent as cross } from "img/icon/cross.svg";


export default function LabelAppearance({ atom, styles, editAtom, headingAtom }) {
  const labelProps = useAtomValue(atom);
  const editColumn = useSetAtom(editColumnAtom);
  const editColumnAlign = useSetAtom(editColumnAlignAtom);
  const removeColumn = useSetAtom(removeColumnAtom);
  const setHistory = useSetAtom(historyAtom);

  const { id, field, format } = labelProps;
  const getter = (style) => style.columns?.[id];
  const setter = headingAtom ? ((key, val) => ({
    atom: headingAtom,
    columns: { [id]: { [key]: val } }
  })) : ((key, val, style) => ({
    id: style.id,
    columns: { [id]: { [key]: val } }
  }));
  const [
    s, valueChange, toggleChange, colorChange,
  ] = useStyleEdit({ editAtom, styles, suffix: headingAtom, getter, setter });
  
  // Tasks column doesn't have label, ergo doesn't have controls
  if (field === FIELDS.TASKS) return null;

  const onTextAlign = (textAlign) => {
    editColumnAlign({ columnId: id, textAlign });
    setHistory(`textAlign-change-${id}`);
  }
  const onField = (val) => onColumnChange('field', val);
  const onFormat = (val) => onColumnChange('format', val);
  const onColumnChange = (prop, val) => {
    if (val === labelProps[prop]) return;
    editColumn({ atom, [prop]: val });
    setHistory(`${prop}-change-${id}`);
  }
  const onRemove = () => {
    removeColumn(atom);
    setHistory(`column-remove-${id}`);
  }

  const toolbar = <>
    <FormatPicker value={format} field={field} onChange={onFormat} />
    <Icon icon={cross} onClick={onRemove} />
  </>;

  return s ? (
    <Section
      title={field}
      options={COLUMN_OPTIONS}
      onChange={onField}
      toggle={s.show.value}
      toggleMixed={s.show.mixed}
      onToggle={toggleChange("show")}
      toolbar={toolbar}
    >
      {(s.show.value || s.show.mixed) && (
        <>
          <div>
            <ColorPicker
              title={"Color"}
              onChange={colorChange("color")}
              id={`${id}-color`}
              color={s.color.value ?? { h: 0, s: 0, v: 0, a: 1 }}
              mixed={s.color.mixed}
            />
            <NumberInput
              title={"Font size"}
              onChange={valueChange("fontSize")}
              id={`${id}-font-size`}
              value={s.fontSize.value ?? ""}
              mixed={s.fontSize.mixed}
              min={6}
              max={60}
            />
          </div>
          <div>
            <TextAlign value={s.textAlign.value} onChange={onTextAlign} />
            <TextStyle
              bold={s.bold.value && !s.bold.mixed}
              italic={s.italic.value && !s.italic.mixed}
              underline={s.underline.value && !s.underline.mixed}
              toggleBold={toggleChange("bold")}
              toggleItalic={toggleChange("italic")}
              toggleUnderline={toggleChange("underline")}
            />
            <TextTransform
              value={s.textTransform.mixed ? null : s.textTransform.value}
              onChange={valueChange("textTransform")}
            />
          </div>
        </>
      )}
    </Section>
  ) : null
}