import { atom } from "jotai";
import { v4 as uid } from "uuid";
import { find } from "lodash";
import { deleteTaskAtom, mapAtom, groupsAtom, rowStylesAtom, deleteGroupAtom, deleteMilestoneAtom, SELECTABLE, updateMilestoneAtom, updateTaskAtom, milestonesAtom, tasksAtom } from ".";
import { getRowFirstChild } from "utils";


export const rowsAtom = atom([]);

export const composedRowsAtom = atom(
  (get) => {
    const rows = get(rowsAtom);
    const map = get(rowChildrenMapAtom);
    return rows.map(
      row => ({...row, title: getRowDisplayTitle(row, map)})
    )
  }
);

export const rowChildrenMapAtom = atom(get => {
  const result = new Map();
  [].concat(
    get(milestonesAtom),
    get(tasksAtom),
  ).forEach(el => result.set(el.id, el));
  return result;
});

function getFirstChild(row, map) {
  const items = row.children.map(id => map.get(id));
  return getRowFirstChild(items);
}

function getRowDisplayTitle(row, map) {
  if (!row.isLinkedTitle) {
    return row.title;
  } else {
    return getFirstChild(row, map)?.title
  }
}

// Row manipulations
export const createRowAtom = atom(null, (get, set, props = {}) => {
  const defaultProps = {
    id: uid(),
    type: "row",
    title: "",
    isLinkedTitle: true,
    style: get(rowStylesAtom)[0].id,
    children: [],
  }
  const { index, group, ...providedProps } = props;
  const result = {...defaultProps, ...providedProps};
  set(rowsAtom, [...get(rowsAtom), result]);
  set(attachRowAtom, { id: result.id, index, group });
  return result;
})

export const updateRowAtom = atom(null, (get, set, props) => {
  // Get original row
  const rows = get(rowsAtom);
  const target = find(rows, { id: props.id });
  if (!target || target.type !== "row") return false;

  // Compose new row
  const row = {...target, ...props};

  // Update first child title
  if (row.isLinkedTitle && "title" in props) {
    row.title = target.title;
    const firstChild = getFirstChild(row, get(rowChildrenMapAtom));
    const updAtom = (firstChild.type === SELECTABLE.MILESTONE) ? updateMilestoneAtom : updateTaskAtom;
    set(updAtom, { id: firstChild.id, title: props.title });
  }

  // Update row title when change title linking
  if ("isLinkedTitle" in props) {
    if (props.isLinkedTitle) {
      row.title = "";
    } else {
      const firstChild = getFirstChild(row, get(rowChildrenMapAtom));
      row.title = firstChild.title;
    }
  }

  // Apply results
  const newRows = rows.map( el => el === target ? row : el );
  set(rowsAtom, newRows);
  return row;
})

export const moveRowAtom = atom(null, (get, set, props) => {
  set(detachRowAtom, props.id);
  set(attachRowAtom, props);
})

export const deleteRowAtom = atom(null, (get, set, id) => {
  const rows = get(rowsAtom);
  const target = find(rows, { id });
  if (!target || target.type !== "row") return false;
  if (target.children.length) {
    target.children.forEach(el => {
      set(deleteTaskAtom, el)
      set(deleteMilestoneAtom, el)
    });
  } else {
    set(detachRowAtom, id);
    set(rowsAtom,rows.filter( el => el.id !== id ));
  }
  return true;
})

export const cleanupRowsAtom = atom(null, (get, set) => {
  const rows = get(rowsAtom);
  rows.forEach(({ id, children }) => {
    if (!children.length) set(deleteRowAtom, id);
  })
})

export const detachRowAtom = atom(null, (get, set, id) => {
  const groups = get(groupsAtom);
  const target = groups.find( el => el.children.includes(id) );
  if (!target) return false;

  const group = { ...target };
  group.children = target.children.filter(el => el !== id);

  const newGroups = groups.map( el => el === target ? group : el );
  set(groupsAtom, newGroups);

  if ( !group.children.length ) {
    set( deleteGroupAtom, group.id );
  }

  return true;
})

export const attachRowAtom = atom(null, (get, set, props) => {
  // Find target
  const target = props.group
    ? get(mapAtom).get(props.group)
    : get(groupsAtom).at(-1);

  // Make sure it's a group
  if (!target || target.type !== "group") return false;

  // Add new child
  const index = props.index ?? target.children.length;
  const group = { ...target };
  group.children = [...target.children];
  group.children.splice(index, 0, props.id);

  // Make sure it's visible
  group.unfolded = true;

  // Apply changes
  const upd = get(groupsAtom).map( el => el === target ? group : el);
  set(groupsAtom, upd);
})

// Drag'n'drop states
export const rowDragAtom = atom(null);