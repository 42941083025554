export const templates = [
  {
    id: "powerpoint",
    title: "PowerPoint",
  }, {
    id: "corporate",
    title: "Corporate",
  }, {
    id: "green",
    title: "Green",
  }, {
    id: "citrus",
    title: "Citrus",
  }, {
    id: "watermelon",
    title: "Watermelon",
  }, {
    id: "eggplant",
    title: "Eggplant",
  }, {
    id: "minimalistic",
    title: "Minimalistic",
  }, {
    id: "candy",
    title: "Candy",
  }, {
    id: "development_roadmap",
    title: "Development Roadmap",
  }, {
    id: "project_overview",
    title: "Project Overview",
  }, {
    id: "development_and_release",
    title: "Development & Release",
  }, {
    id: "roadmap",
    title: "Roadmap",
  }, {
    id: "powerpoint_night",
    title: "PowerPoint [Night]",
  }, {
    id: "corporate_night",
    title: "Corporate [Night]",
  }, {
    id: "green_night",
    title: "Green [Night]",
  }, {
    id: "citrus_night",
    title: "Citrus [Night]",
  }, {
    id: "watermelon_night",
    title: "Watermelon [Night]",
  }, {
    id: "eggplant_night",
    title: "Eggplant [Night]",
  }, {
    id: "minimalistic_night",
    title: "Minimalistic [Night]",
  }, {
    id: "candy_night",
    title: "Candy [Night]",
  }, {
    id: "development_roadmap_night",
    title: "Development Roadmap [Night]",
  }, {
    id: "project_overview_night",
    title: "Project Overview [Night]",
  }, {
    id: "development_and_release_night",
    title: "Development & Release [Night]",
  }, {
    id: "roadmap_night",
    title: "Roadmap [Night]",
  },
]

export const featuredTemplates = templates.slice(0, 6);