import { useRef, useState } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { teamAtom, tasksAtom, updateTeamMemberAtom, historyAtom, selectedAtom, removeTeamMemberAtom, createTeamMemberAtom, changeTeamMemberIndexAtom, teamModalAtom } from "store";
import { Modal } from "components";
import { ModalButton, ModalFooter } from "components/Modal";
import Input from "pages/Project/Aside/components/Input/Input";
import NumberInput from "pages/Project/Aside/components/NumberInput/NumberInput";

import {ReactComponent as Plus} from "img/icon/plus.svg";
import {ReactComponent as Cross} from "img/icon/cross.svg";
import {ReactComponent as Grab} from "img/icon/grab.svg";
import s from "./TeamModal.module.css";


export default function TeamModal() {
  const [isOpen, setOpen] = useAtom(teamModalAtom);
  const closeModal = () => setOpen(false);

  return (
    <Modal title="Project team" isOpen={isOpen} close={closeModal} wide>
      <TeamTable close={closeModal} />
      <ModalFooter>
        <ModalButton onClick={closeModal}>Close</ModalButton>
      </ModalFooter>
    </Modal>
  )
}

function TeamTable({close}) {
  const [ drag, setDrag ] = useState();
  const ref = useRef();
  const dropTargets = useRef();
  const team = useAtomValue(teamAtom);
  const createTeamMember = useSetAtom(createTeamMemberAtom);
  const changeIndex = useSetAtom(changeTeamMemberIndexAtom);
  const setHistory = useSetAtom(historyAtom);
  const onClick = () => {
    createTeamMember();
  }

  const onDragStart = ( id ) => {
    const nodes = ref.current.getElementsByClassName(s.memberRow);
    const items = [...nodes].map( i => ({
      id: i.id,
      rect: i.getBoundingClientRect(),
    }) );
    const index = items.findIndex( i => i.id === id );
    const target = items[index].rect;

    dropTargets.current = items.filter( i => i.id !== id ).map( (item, i) => {
      const { top, height } = item.rect;
      const delta = (i >= index) ? target.height : 0;
      const pos = top + height / 2 - target.top - delta;
      return pos;
    }, []);

    setDrag({ from: index, to: index, delta: target.height, id });
  }
  const onDrag = ( delta ) => {
    if ( !dropTargets.current || !drag ) return;
    const index = dropTargets.current.findLastIndex( el => el < delta ) + 1;
    if ( index !== drag.to ) {
      setDrag({ ...drag, to: index });
    }
  }
  const onDragEnd = () => {
    setDrag( null );
    const { from, to, id } = drag;
    if ( from === to ) return;
    changeIndex({ id, index: to });
    setHistory(`team-sort`);
  }

  const calcDelta = (index) => {
    if ( !drag || index === drag.from ) {
      return 0;
    } else if ( index < drag.from && index >= drag.to ) {
      return drag.delta;
    } else if ( index > drag.from && index <= drag.to ) {
      return -drag.delta;
    }
  } 

  return (
    <div className={s.tableWrapper} ref={ref}>
      <div className={`${s.tableHeader} ${s.row}`}>
        <div>&nbsp;</div>
        <div>Role</div>
        <div>Name</div>
        <div>Rate/d</div>
        <div>Assigned</div>
        <div>&nbsp;</div>
      </div>
      {team.map( (member, index) => (
        <MemberRow
          key={member.id}
          close={close}
          {...member}
          onDragStart={onDragStart}
          onDrag={onDrag}
          onDragEnd={onDragEnd}
          delta={calcDelta(index)}
          sorting={!!drag}
        />
      ))}
      <div className={s.row}>
        <button className={s.addNew} onClick={onClick}><Plus className={s.buttonIcon} /><span>New member</span></button>
      </div>
    </div>
  )
}

function MemberRow({ close, delta, onDragStart, onDragEnd, onDrag, sorting, ...props }) {
  const [start, setStart] = useState(null);
  const [distance, setDistance] = useState(0);
  const captured = useRef();

  const { id, role, name, rate } = props;
  const updateTeamMember = useSetAtom(updateTeamMemberAtom);
  const setSelected = useSetAtom(selectedAtom);
  const removeTeamMember = useSetAtom(removeTeamMemberAtom);
  const setHistory = useSetAtom(historyAtom);
  const allTasks = useAtomValue(tasksAtom);
  const tasks = allTasks.filter( task => task.workgroup.some( member => member.id === id ));

  const onChange = (prop, val) => {
    if (props[prop] === val) return;
    updateTeamMember({...props, [prop]: val});
    setHistory(`worker-update-${prop}-${id}`);
  };
  const roleChange = (val) => onChange("role", val);
  const nameChange = (val) => onChange("name", val);
  const rateChange = (val) => onChange("rate", val);

  const selectTasks = () => {
    const selected = tasks.map( task => task.id );
    setSelected(selected);
    close();
  }
  const onRemove = () => {
    removeTeamMember(id);
    setHistory();
  }

  const onPointerDown = (e) => {
    e.stopPropagation();
    e.currentTarget.setPointerCapture(e.pointerId);
    captured.current = true;
    setStart(e.clientY);
    onDragStart(id);
  };
  const onPointerUp = (e) => {
    if (!captured.current) return;
    e.preventDefault();
    e.stopPropagation();
    setStart(null);
    setDistance(0);
    onDragEnd();
    captured.current = false;
  }

  const onPointerMove = (e) => {
    if (!captured.current) return;
    const distance = e.clientY - start;
    setDistance( distance );
    onDrag( distance );
  }

  const style = delta ? {
    transform: `translateY(${delta}px)`,
  } : distance ? {
    transform: `translateY(${distance}px)`,
  } : {};

  const className = [
    s.row, s.memberRow,
    sorting && s.dragTransitions,
    start && s.drag
  ].filter(Boolean).join(" ");

  return (
    <div
      id={id}
      className={className}
      style={style}
    >
      <div
        className={s.grab}
        onPointerDown={onPointerDown}
        onPointerMove={onPointerMove}
        onPointerUp={onPointerUp}
        onLostPointerCapture={onPointerUp}
      >
        <Grab className={s.grabIcon} />
      </div>
      <div>
        <Input
          id={`${id}-role`}
          value={role}
          onChange={roleChange}
          autoFocus={true}
        />
      </div>
      <div>
        <Input
          id={`${id}-name`}
          value={name}
          onChange={nameChange}
        />
        </div>
      <div>
        <NumberInput
          id={`${id}-rate`}
          value={rate}
          onChange={rateChange}
          suffix="$"
        />
      </div>
      <div>
        {tasks.length ? (
          <button tabIndex={0} className={s.link} onClick={selectTasks}>{tasks.length > 1 ? `${tasks.length} tasks` : `${tasks.length} task`}</button>
        ) : (
          <p className={`${s.link} ${s.disabled}`}>No tasks</p>
        )}
      </div>
      <div className={s.remove}>
        <button className={s.removeButton} onClick={onRemove}><Cross className={s.buttonIcon} /></button>
      </div>
    </div>
  )
}