import { useAtomValue } from "jotai";
import { groupStylesMapAtom, columnAtomsAtom, updateGroupAtom, editGroupStyleAtom, overrideGroupAtom, SELECTABLE } from "store";
import { useGetStyles, useStyleEdit } from "utils";
import { AddColumn } from "../../components/AddItem/AddItem";
import LvlSwitch from "../../components/LvlSwitch/LvlSwitch";
import StylesList from "../../components/StylesList/StylesList";
import Section from "../../components/Section/Section";
import Input from "../../components/Input/Input";
import EditMode from "../../components/EditMode/EditMode";
import Toggle from "../../components/Toggle/Toggle";
import Column from "../../components/ColumnLabelAppearance";
import GroupAppearance from "./GroupAppearance";
import BarAppearance from "./BarAppearance";


export default function Group() {
  const columns = useAtomValue(columnAtomsAtom);
  const [items, styles, isOverriding] = useGetStyles(groupStylesMapAtom);
  const editAtom = isOverriding ? overrideGroupAtom : editGroupStyleAtom;

  return (
    <>
      <div>
        <LvlSwitch />
        <MainDetails items={items} />
        <StylesList title="Style" type={SELECTABLE.GROUP} />
      </div>
      <div>
        <EditMode />
        <GroupAppearance
          styles={styles}
          editAtom={editAtom}
        />
        <BarAppearance
          styles={styles}
          editAtom={editAtom}
        />
        {columns.map((atom) => (
          <Column
            key={`${atom}`}
            atom={atom}
            styles={styles}
            editAtom={editAtom}
          />
        ))}
        <Section>
          <AddColumn />
        </Section>
      </div>
    </>
  )
}

function MainDetails({ items }) {
  const [
    s, valueChange, toggleChange
  ] = useStyleEdit({
    editAtom: updateGroupAtom,
    styles: items,
  });

  return (
    <Section title="Details">
      <div>
        <Input
          title="Title"
          id="group-data-title"
          value={s.title.value}
          mixed={s.title.mixed}
          onChange={valueChange("title")}
        />
      </div>
      <div>
        <Toggle
          title="Fold group"
          checked={!s.unfolded.value}
          mixed={s.unfolded.mixed}
          onChange={toggleChange("unfolded")}
        />
      </div>
    </Section>
  )
}