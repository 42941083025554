import { useEffect, useState } from "react";
import { useToggle } from "utils";
import { useSetAtom } from "jotai";
import { userAtom } from "store";
import { updateUserAttribute, fetchUserAttributes, fetchAuthSession } from "aws-amplify/auth";
import { patch } from "aws-amplify/api";
import { Button, Input, Modal, ModalMessage } from "components";
import { ModalButton, ModalFooter } from "components/Modal";
import s from "./General.module.css";


export default function EmailInput({ value, setMessage }) {
  const [email, setEmail] = useState(value);
  const [modal, openModal, closeModal] = useToggle(false);
  const [isBusy, setBusy] = useState(false);

  const handleEmail = (e) => setEmail(e.target.value);
  const emailSubmit = (e) => {
    e.preventDefault();
    if (isBusy) return;
    setBusy(true);
    updateUserAttribute({
      userAttribute: {
        attributeKey: 'email',
        value: email.trim(),
      }
    })
      .then(openModal)
      .catch(console.log)
      .finally(() => setBusy(false))
  }

  const disabled = isBusy || email === value || modal;

  const label = isBusy ? "Saving..." : "Save";

  return (
    <>
      <EmailModal isOpen={modal} close={closeModal} email={email.trim()} setMessage={setMessage} />
      <form className={s.row} onSubmit={emailSubmit}>
        <Input
          className={s.input}
          id="user-email"
          label="Email"
          type="email"
          name="email"
          autoComplete="off"
          value={email}
          onChange={handleEmail}
          required
        />
        <Button className={s.button} label={label} disabled={disabled} />
      </form>
    </>
  )
}

function EmailModal({ isOpen, close, email, setMessage }) {
  const setUser = useSetAtom(userAtom);
  const [code, setCode] = useState("");
  const [error, setError] = useState();
  const [cooling, setCooling] = useState(0);
  const [isBusy, setBusy] = useState(false);

  useEffect(() => {
    setError(null);
    setCode("");
  }, [isOpen]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const code = data.get("code").trim();

    if (!code) {
      setError("NoCode");
      return;
    } else if (code.length !== 6) {
      setError("WrongCode");
      return;
    }

    setBusy(true);

    try {
      const { idToken, accessToken } = (await fetchAuthSession()).tokens;
      await patch({
        apiName: "user",
        path: "/email",
        options: {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
          body: {
            email: email,
            code: code,
            token: accessToken.toString(),
          }
        }
      }).response;
      setMessage({ message: "Email changed" })
      const newUser = await fetchUserAttributes();
      setUser(newUser);
      close();
    } catch (error) {
      console.log(error);
      setError(error.name);
    }
    setBusy(false);
  }

  useEffect(() => {
    if (cooling > 0) setTimeout(() => setCooling(cooling - 1), 1000);
  }, [cooling])

  const onChange = (e) => setCode(e.target.value);
  const onFocus = (e) => e.target.select();
  const handleResend = () => {
    setCooling(60);
    updateUserAttribute({
      userAttribute: {
        attributeKey: 'email',
        value: email,
      }
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      title="Change Email Address"
      subtitle="We have sent a confirmation code to the email address you'd provided. Please enter the code below to proceed."
      lightClose={false}
    >
      <form onSubmit={onSubmit}>
        <Input
          className={s.modalInput}
          id="confirmation-code"
          label="Confirmation code"
          type={"text"}
          name={"code"}
          value={code}
          autoComplete={"one-time-code"}
          onChange={onChange}
          onFocus={onFocus}
          error={error}
          autoFocus
          required
        />
        {cooling > 0 ? (
          <p className={s.resend}>The code was sent. No message still? Try again in {cooling} seconds</p>
        ) : (
          <button type="button" className={`${s.resend} ${s.resendButton}`} onClick={handleResend}>Resend confirmation code</button>
        )}
        <ModalMessage value={error} options={options} />
        <ModalFooter>
          <ModalButton disabled={isBusy} onClick={close}>Cancel</ModalButton>
          <ModalButton disabled={isBusy}>Confirm Email</ModalButton>
        </ModalFooter>
      </form>
    </Modal>
  )
}

const options = {
  NoCode: "No code provided",
  WrongCode: "Invalid verification code provided, please try again.",
  CodeMismatchException: "Invalid verification code provided, please try again.",
  AliasExistsException: "An account with the given email already exists.",
  default: "We are sorry, but it seems there is a problem on our side. Please try again later.",
}