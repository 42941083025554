import s from './ModalMessage.module.css';


export default function ModalMessage({ value, options, type="error" }) {
  if (!value) return null;

  const msg = options[value] || options.default;
  let Message = () => null;
  if ( typeof(msg) === "function" ) {
    Message = msg;
  } else if ( typeof(msg) === "string" ) {
    Message = () => <p>{ msg }</p>
  }

  const className = [
    s.wrapper,
    type === "error" && s.error,
    type === "success" && s.success,
    type === "info" && s.info,
  ].filter(Boolean).join(" ");

  return (
    <div className={className}>
      <Message />
    </div>
  )
}