import { useLayoutEffect, useRef, useState } from "react";
import { ReactComponent as Icon } from "img/icon/success.svg";
import s from "./General.module.css";

export default function Notification({ value, onTimeout }) {
  const timeout = useRef();
  const [key, setKey] = useState(null);

  useLayoutEffect(() => {
    if (!value) return;
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    setKey(Date.now());
    timeout.current = setTimeout(() => {
      onTimeout();
    }, 5000)
  }, [ value, onTimeout ])

  return value ? (
    <div className={s.notification} key={key}>
      <Icon />
      {value.message}
    </div>
  ) : null
}