import { Toggle } from "components";
import FieldPicker from "../FieldPicker/FieldPicker";
import s from"./Section.module.css";

export default function Section({ title, options, onChange, children, main, actions, toolbar, className, onToggle, toggle, toggleMixed }) {

  const onKeyDown = (e) => {
    e.stopPropagation();
  }
  const cn = [
    s.wrapper,
    className,
    main && s.main
  ].filter(Boolean).join(" ");

  return (
    <div className={cn} onKeyDown={onKeyDown}>
      <div className={s.header}>
        <div className={s.title}>
          {onToggle && <Toggle checked={toggle} mixed={toggleMixed} onChange={onToggle} />}
          <h2 className={s.titleValue}>
            {options ? (
              <FieldPicker
                value={title}
                options={options}
                onChange={onChange}
              />
            ) : title}
          </h2>
        </div>
        {actions && (
          <div className={s.toolbar}>
            {actions.map( el => (
              <Icon key={el.icon} icon={el.icon} onClick={el.onClick} />
            ))}
          </div>
        )}
        {toolbar && (
          <div className={s.toolbar}>
            {toolbar}
          </div>
        )}
      </div>
      <div className={s.content}>
        {children}
      </div>
    </div>
  )
}

export function Icon({ icon: Icon, onClick, ...rest}) {
  return (
    <button className={s.button} onClick={onClick} {...rest}>
      <Icon className={s.icon} draggable={false} />
    </button>
  )
}