import { atom } from "jotai";
import { v4 as uid } from "uuid";
import { cloneDeep, difference, groupBy, intersection, keyBy, pull } from "lodash";
import { DAY_LENGHT, format } from "utils/date";
import { devError, devLog, vxDate } from "utils";
import { maxDateAtom, minDateAtom, tasksAtom } from "./tasks";
import { teamAtom } from "./team";
import { milestonesAtom } from "./milestones";
import { groupsAtom } from "./groups";
import { rowsAtom } from "./rows";
import { allSelectedAtom } from "./select";
import { mapAtom, parentsMapAtom } from "store";
import { groupStylesAtom, milestoneStylesAtom, rowStylesAtom, taskStylesAtom } from "./styles";

const idMapAtom = atom();
const placementAtom = atom();
const originalAtom = atom();

export const gptRequestTypeAtom = atom();

export const gptCancelRequestAtom = atom(
  null, (get, set) => {
    const { team, tasks, milestones, groups, rows } = get(originalAtom);

    set(gptRequestTypeAtom, null);
     // Apply
     set(teamAtom, team);
     set(tasksAtom, tasks);
     set(milestonesAtom, milestones);
     set(rowsAtom, rows);
     set(groupsAtom, groups);
  }
);

export const gptGetSnapshotAtom = atom(
  null, (get, set) => {
    const team = get(teamAtom);
    const tasks = get(tasksAtom);
    const milestones = get(milestonesAtom);
    const rows = get(rowsAtom);
    const groups = get(groupsAtom);
    const map = get(mapAtom);
    const selected = get(allSelectedAtom);
    const parentsMap = get(parentsMapAtom);

    const original = cloneDeep({
      team, tasks, milestones, rows, groups
    });

    const items = [];
    const idMap = {};
    const placement = {};
    
    // Employees
    team.forEach((employee, index) => {
      const id = `employee-${index}000`;
      idMap[id] = employee.id;
      idMap[employee.id] = id;
      placement[employee.id] = { index: (index+1) * 1000 };
      items.push({
        type: "employee",
        id: id,
        name: employee.name,
        role: employee.role,
        rate: employee.rate,
        index: (index+1) * 1000,
      });
    })

    // Groups
    groups.forEach((group, index) => {
      if (group.id === "root") {
        placement.root = {
          index: -1000
        }
        return;
      }
      const id = `group-${index}000`;
      idMap[id] = group.id;
      idMap[group.id] = id;
      placement[group.id] = { index: (index+1) * 1000 };
      const dates = group.children
        .map((id) => map.get(id).children).flat() /* Row ids to children ids */
        .map((id) => map.get(id)) /* Tasks and milestnes */
        .map((item) => [item.start, item.end - DAY_LENGHT, item.date]).flat() /* All possible dates */
        .filter(Boolean); /* Remove empty values */
      items.push({
        type: "group",
        id: id,
        title: group.title,
        index: (index+1) * 1000,
        children: [...group.children],
        start: format(Math.min(...dates), "yyyy-mm-dd"),
        end: format(Math.max(...dates), "yyyy-mm-dd"),
        selected: selected.includes(group.id),
      });
    })

    // Items placement
    groups.forEach(
      (group) => {
        group.children.map(
          (id) => map.get(id).children
        )
          .flat()
          .forEach((itemId, index) => {
            placement[itemId] = {
              group: group.id,
              index: (index+1) * 1000
            }
          });
      }
    );

    // Tasks
    tasks.forEach((task, index) => {
      const id = `task-${index}000`;
      idMap[id] = task.id;
      idMap[task.id] = id;
      items.push({
        type: "task",
        id: id,
        title: task.title || parentsMap[task.id]?.title,
        start: format(task.start, "yyyy-mm-dd"),
        end: format(task.end, "yyyy-mm-dd", true),
        assignee: task.workgroup.map(
          (i) => ({
            id: idMap[i.id],
            allocation: i.allocation
          })
        ),
        group: idMap[placement[task.id].group] || null,
        index: placement[task.id].index,
        selected: selected.includes(task.id)
      });
    })

    // Milestones
    milestones.forEach((milestone, index) => {
      const id = `milestone-${index}000`;
      idMap[id] = milestone.id;
      idMap[milestone.id] = id;
      items.push({
        type: "milestone",
        id: id,
        title: milestone.title || parentsMap[milestone.id]?.title,
        start: format(milestone.date, "yyyy-mm-dd"),
        group: idMap[placement[milestone.id].group] || null,
        index: placement[milestone.id].index,
        selected: selected.includes(milestone.id)
      });
    });

    items.forEach((item) => {
      if (item.children) {
        item.children = item.children
        .map(id => map.get(id).children).flat()
        .map(id => idMap[id])
      }
    });

    set(idMapAtom, idMap);
    set(placementAtom, placement);
    set(originalAtom, original);

    const selectedMapped = selected.map(id => idMap[id]);

    return {
      minDate: format(get(minDateAtom), "yyyy-mm-dd"),
      maxDate: format(get(maxDateAtom), "yyyy-mm-dd", true),
      workingDays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
      selected: selectedMapped,
      items,
    }
  }
)

export const gptSetSnapshotAtom = atom(
  null, (get, set, upd) => {
    const type = get(gptRequestTypeAtom);
    const idMap = cloneDeep(get(idMapAtom));
    const placement = cloneDeep(get(placementAtom));
    let { team, tasks, milestones, groups, rows } = cloneDeep(get(originalAtom));
    const taskStyle = get(taskStylesAtom)[0].id;
    const milestoneStyle = get(milestoneStylesAtom)[0].id;
    const rowStyle = get(rowStylesAtom)[0].id;
    const groupStyle = get(groupStylesAtom)[0].id;


    // Parents map
    const parentsMap = [...rows, ...groups].reduce((acc, el) => {
      el.children.forEach(id => acc[id] = el);
      return acc;
    }, {});

    // Employees, Tasks, Milestones map
    const employeesMap = keyBy(team, "id");
    const tasksMap = keyBy(tasks, "id");
    const milestonesMap = keyBy(milestones, "id");
    const rowsMap = keyBy(rows, "id");
    const groupsMap = keyBy(groups, "id");

    // Get assignies
    function getAssignies(assignies) {
      return assignies.map(i => ({
        id: idMap[cleanId(i.id)],
        allocation: type === "assign" && !i.reason ? 100 : i.allocation
      })).filter(i => !!i.id && !!i.allocation);
    }

    // Create row
    function createRow(children, title = "") {
      const childrenArray = Array.isArray(children) ? [...children] : [children];
      const row = {
        id: uid(),
        type: "row",
        style: rowStyle,
        isLinkedTitle: !title,
        title: title,
        children: childrenArray
      };
      rows.push(row);
      rowsMap[row.id] = row;
      childrenArray.forEach(id => {
        parentsMap[id] = row;
      })
      return row;
    }

    // Break row
    function breakRow(row, pulledItems) {
      // Split children
      const leftItems = difference(row.children, pulledItems);

      // Create new rows
      createRow(leftItems);
      const newRow = createRow(pulledItems);

      // Delete original row
      pull(rows, row);
      delete rowsMap[row.id];
      delete parentsMap[row.id];

      return newRow;
    }

    // Get group child items to rows
    function getGroupChildren(groupId) {
      const children = [];
      const queue = [];

      // Get children
      const childItems = Object.entries(placement)
        .map(([id, value]) => ({ id, ...value }))
        .filter(el => el.group === groupId)
        .sort((a, b) => a.index - b.index)
        .map(el => el.id)
      ;

      childItems.sort((a, b) => {
        const indexA = sortArray.indexOf(a);
        const indexB = sortArray.indexOf(b);
        if (indexA === -1 || indexB === -1) return 0;
        return indexA - indexB;
      })

      childItems.forEach((id) => {
        // Get related row
        const row = parentsMap[id];
        const queuingRow = queue.length ? parentsMap[queue[0]] : null;

        // If queue with new item doesn't match row children, place the queue on a new row
        if (queuingRow && queuingRow.children[queue.length] !== id) {
          const newRow = breakRow(queuingRow, queue);
          children.push(newRow.id);
          queue.length = 0;
        }

        // If new item still doesn't match row children, place it on a new row and go to the next item
        if (row.children[queue.length] !== id) {
          const newRow = breakRow(row, [id]);
          children.push(newRow.id);
          queue.length = 0;
          return;
        }

        // Push the item in the queue
        queue.push(id);
        
        // The row children and queue are match at this point. If reached the end of the children array, the row can be placed in the group
        if (row.children.length === queue.length) {
          children.push(row.id);
          // detachRow(row.id);
          queue.length = 0;
        }
      });

      return children;
    }

    // Group actions by item type
    const actions = groupBy(upd.edits, 'type');

    // Edit employees
    actions.employee?.forEach(
      (i) => {
        switch (i.action) {
          case "remove": {
            const id = idMap[cleanId(i.id)];
            const employee = employeesMap[id];
            devLog("Remove employee: ", id);
            if (!employee) {
              devError("Employee not found");
              break;
            }

            // Remove employee
            pull(team, employee);

            // Remove ids
            delete idMap[id];
            delete idMap[cleanId(i.id)];
            delete employeesMap[id];
            delete placement[id];

            break;
          }
          case "create": {
            devLog("Create employee: ", i);
            // Throw error if already exists
            if (idMap[i.id]) {
              devError("Can't create employee: ID duplication");
              break;
            }
            
            // Create Employee
            const id = uid();
            const employee = {
              id: id,
              name: i.name,
              role: i.role,
              rate: i.rate,
            }
            team.push(employee);

            // Write ids
            idMap[i.id] = id;
            idMap[id] = i.id;
            employeesMap[id] = employee;
            placement[id] = { index: i.index };
            break;
          }
          case "edit": {
            const id = idMap[cleanId(i.id)];
            const employee = employeesMap[id];
            devLog("Edit employee: ", id);
            if (!employee) {
              devError("Employee not found!");
              break;
            }

            // Edit employee
            Object.assign(
              employee, 
              {
                name: i.name,
                role: i.role,
                rate: i.rate,
              }
            );
            placement[id].index = i.index;
            break;
          }
          default: {
            devError(`Unknow action: ${i.action}`, i);
            return;
          }
        }
      }
    )

    // Edit groups
    actions.group?.forEach(
      (i) => {
        switch (i.action) {
          case "remove": {
            const id = idMap[cleanId(i.id)];
            const group = groupsMap[id];
            devLog("Remove group: ", id);
            if (!group) {
              devError("Group not found");
              break;
            }

            // Remove group
            pull(groups, group);

            // Remove ids
            delete idMap[id];
            delete idMap[cleanId(i.id)];
            delete groupsMap[id];
            delete placement[id];

            break;
          }
          case "create": {
            // Throw error if already exists
            devLog("Create group: ", i);
            if (idMap[i.id]) {
              devError("Can't create group: ID duplication");
              break;
            }

            // Create Group
            const id = uid();
            const group = {
              id: id,
              type: "group",
              title: i.title,
              style: groupStyle,
              unfolded: true,
              children: []
            };
            groups.push(group);

            // Write ids
            idMap[i.id] = id;
            idMap[id] = i.id;
            groupsMap[id] = group;
            placement[id] = { index: i.index };
            break;
          }
          case "edit": {
            const id = idMap[cleanId(i.id)];
            const group = groupsMap[id];
            if (!group) {
              devError("Group not found!");
              break;
            }
            devLog("Edit group: ", id);

            // Update group
            Object.assign(
              group,
              {
                title: i.title,
              }
            );
            placement[id].index = i.index;
            break;
          }
          default: {
            devError(`Unknow action: ${i.action}`, i);
            return;
          }
        }
      }
    )

    // Edit tasks
    actions.task?.forEach(
      (i) => {
        switch (i.action) {
          case "remove": {
            const id = idMap[cleanId(i.id)];
            const task = tasksMap[id];
            devLog("Remove task: ", id);
            if (!task) {
              devError("Task not found");
              break;
            }

            // Remove task
            pull(tasks, task);

            // Remove ids
            delete idMap[id];
            delete idMap[cleanId(i.id)];
            delete parentsMap[id];
            delete tasksMap[id];
            delete placement[id];
            break;
          }
          case "create": {
            // Throw error if already exists
            devLog("Create task: ", i.id);
            if (idMap[i.id]) {
              devError("Can't create task: ID duplication");
              break;
            }

            // Parse dates
            const parsedStart = Date.parse(i.start);
            const parsedEnd = Date.parse(i.end) + vxDate.DAY_LENGHT;

            // Exit if invalid dates
            if (isNaN(parsedStart) || isNaN(parsedEnd)) {
              devError("Invalid dates: ", i);
              break;
            }
            const [start, end] = vxDate.sort(
              parsedStart, parsedEnd
            );

            // Create Task
            const id = uid();
            const task = {
              id: id,
              type: "task",
              title: i.title,
              style: taskStyle,
              start: start,
              end: end,
              workgroup: getAssignies(i.assignee),
            };
            tasks.push(task);

            // Create Row
            createRow(id);

            // Write ids
            idMap[i.id] = id;
            idMap[id] = i.id;
            tasksMap[id] = task;
            placement[id] = {
              group: idMap[i.group] || "root",
              index: i.index
            };
            break;
          }
          case "edit": {
            const id = idMap[cleanId(i.id)];
            const task = tasksMap[id];
            devLog("Edit task: ", id);
            if (!task) {
              devError("Task not found!");
              break;
            }

            // Parse dates
            const parsedStart = Date.parse(i.start);
            const parsedEnd = Date.parse(i.end) + vxDate.DAY_LENGHT;

            // Exit if invalid dates
            if (isNaN(parsedStart) || isNaN(parsedEnd)) {
              devError("Invalid dates: ", i);
              break;
            }
            const [start, end] = vxDate.sort(
              parsedStart, parsedEnd
            );

            // Update row title
            const row = parentsMap[id];
            if (row?.title === task.title || (!task.title && row?.children.length === 1)) {
              row.title = i.title;
            }

            // Update task
            Object.assign(
              task,
              {
                title: i.title,
                start: start,
                end: end,
                workgroup: getAssignies(i.assignee)
              }
            );
            placement[id] = {
              group: idMap[i.group] || "root",
              index: i.index,
            };
            break;
          }
          default: {
            devError(`Unknow action: ${i.action}`, i);
            return;
          }
        }
      }
    )

    // Edit milestones
    actions.milestone?.forEach(
      (i) => {
        switch (i.action) {
          case "remove": {
            const id = idMap[cleanId(i.id)];
            const milestone = milestonesMap[id];
            devLog("Remove milestone: ", id);
            if (!milestone) {
              devError("Milestone not found");
              break;
            }

            // Remove milestone
            pull(milestones, milestone);
          
            // Remove ids
            delete idMap[id];
            delete idMap[cleanId(i.id)];
            delete parentsMap[id];
            delete milestonesMap[id];
            delete placement[id];
            break;
          }
          case "create": {
            // Throw error if already exists
            devLog("Create milestone: ", i.id);
            if (idMap[i.id]) {
              devError("Can't create milestone: ID duplication");
              break;
            }

            // Parse date
            const date = Date.parse(i.start);

            // Exit if invalid date
            if (isNaN(date)) {
              devError("Invalid date: ", i);
              break;
            }
            
            // Create Milestone
            const id = uid();
            const milestone = {
              id: id,
              type: "milestone",
              title: i.title,
              date: date,
              style: milestoneStyle,
            };
            milestones.push(milestone);
            
            // Create Row
            createRow(id);

            // Write ids
            idMap[i.id] = id;
            idMap[id] = i.id;
            milestonesMap[id] = milestone;
            placement[id] = {
              group: idMap[i.group] || "root",
              index: i.index
            };
            break;
          }
          case "edit": {
            const id = idMap[cleanId(i.id)];
            const milestone = milestonesMap[id];
            devLog("Edit milestone: ", id);
            if (!milestone) {
              devError("Milestone not found!");
              break;
            }

            // Parse date
            const date = Date.parse(i.start);

            // Exit if invalid date
            if (isNaN(date)) {
              devError("Invalid date: ", i);
              break;
            }

            // Update row title
            const row = parentsMap[id];
            if (row?.title === milestone.title || (!milestone.title && row?.children.length === 1)) {
              row.title = i.title;
            }

            // Update milestone
            Object.assign(
              milestone,
              {
                title: i.title,
                date: date,
              }
            );
            placement[id] = {
              group: idMap[i.group] || "root",
              index: i.index,
            };
            break;
          }
          default: {
            devError(`Unknow action: ${i.action}`, i);
            return;
          }
        }
      }
    )

    // Sort array
    const sortArray = upd.sorting?.map(id => idMap[id]) || [];

    // Calculate groups children
    groups.forEach(group => {
      const children = getGroupChildren(group.id);
      group.children = children;
      children.forEach(rowId => parentsMap[rowId] = group);
    })

    // Remove empty groups
    groups = groups.reduce((acc, group) => {
      if (group.children.length || group.id === "root") {
        acc.push(group);
      } else {
        delete groupsMap[group.id];
        delete placement[group.id];
      }
      return acc;
    }, [])

    // Remove dead references to employees
    tasks.forEach(task => {
      task.workgroup = task.workgroup.filter(
        assignee => !!employeesMap[assignee.id]
      )
    })

    // Remove dead references to tasks and milestones
    const itemIds = [...Object.keys(tasksMap), ...Object.keys(milestonesMap)];
    rows.forEach(row => {
      row.children = intersection(row.children, itemIds);
    })

    // Remove empty rows
    rows = rows.filter(row => !!row.children.length);

    // Put abandoned rows into root
    const rowIds = groups.map(group => group.children).flat();
    const abandonedRows = rows.filter(
      row => !rowIds.includes(row.id)
    ).map(
      row => row.id
    )
    groupsMap.root.children.push(...abandonedRows);

    // Remove unused tasks and milestones
    const rowsChildren = rows.map(row => row.children).flat();
    tasks = tasks.filter(task => rowsChildren.includes(task.id));
    milestones = milestones.filter(milestone => rowsChildren.includes(milestone.id));

    // Sort items
    team.sort((a, b) => placement[a.id].index - placement[b.id].index);
    groups.sort((a, b) => placement[a.id].index - placement[b.id].index);

    function sortItems(a, b) {
      const indexA = sortArray.indexOf(a.id);
      const indexB = sortArray.indexOf(b.id);
      if (indexA === -1 || indexB === -1) return 0;
      return indexA - indexB;
    }
    team.sort(sortItems);
    groups.sort(sortItems);

    // Apply
    set(teamAtom, team);
    set(tasksAtom, tasks);
    set(milestonesAtom, milestones);
    set(rowsAtom, rows);
    set(groupsAtom, groups);
  }
)

function cleanId(str) {
  return str.replace(/},{$/, '');
}