import { useRef, useState } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { minDateAtom, timeScaleAtom, milestoneStylesMapAtom, selectedAtom, addSelectedAtom, editingAtom, updateMilestoneAtom, moveMilestoneAtom, historyAtom, lastUsedMilestoneStyleAtom, isShowTempTooltipAtom, snappedDatesAtom, lockedAtom, MILESTONE_LABEL_ALIGNMENT, SHAPES } from "store";
import { useSetAtomLater, useSnap, vxDate } from "utils";
import merge from "lodash/merge";
import useDragAndDrop from "../Task/dragHook";
import Tooltip from "./Tooltip";
import TitleInput from "../TitleInput/TitleInput";

import { ReactComponent as Flag } from "img/icon/milestone/flag.svg";
import { ReactComponent as Pin } from "img/icon/milestone/pin.svg";
import { ReactComponent as Diamond } from "img/icon/milestone/diamond.svg";
import { ReactComponent as Star } from "img/icon/milestone/star.svg";
import { ReactComponent as Star8 } from "img/icon/milestone/star8.svg";
import { ReactComponent as Up } from "img/icon/milestone/up.svg";
import { ReactComponent as Down } from "img/icon/milestone/down.svg";
import s from "./Milestone.module.css";


export default function Milestone(props) {
  const { id, temp, title, date, styleId, overrides } = props;
  const milestoneStyles = useAtomValue(milestoneStylesMapAtom);
  const minDate = useAtomValue(minDateAtom);
  const timeScale = useAtomValue(timeScaleAtom);
  const lastStyle = useAtomValue(lastUsedMilestoneStyleAtom);
  const timeoutTooltip = useAtomValue(isShowTempTooltipAtom);
  const [selected, setSelected] = useAtom(selectedAtom);
  const addSelected = useSetAtom(addSelectedAtom);
  const setContentEditing = useSetAtom(editingAtom);
  const updateMilestone = useSetAtom(updateMilestoneAtom);
  const moveMilestone = useSetAtom(moveMilestoneAtom);
  const setHistory = useSetAtom(historyAtom);
  const setSnappedDates = useSetAtomLater(snappedDatesAtom);
  const isLocked = useAtomValue(lockedAtom);

  const ref = useRef();
  const capture = useRef(false);
  const [deltaX, setDeltaX] = useState(0);
  const [deltaY, setDeltaY] = useState(0);
  const [isEditing, setEditing] = useState(false);
  const [startPos, setStartPos] = useState(null);
  const [direction, setDirection] = useState(null);
  const getTargetRow = useDragAndDrop(ref, isEditing, deltaY);
  const snap = useSnap(date);


  // Handlers
  const select = (e) => {
    e.stopPropagation();
    setContentEditing(false);
    if (e.shiftKey || e.metaKey) {
      addSelected(id);
    } else if (!selected.includes(id)) {
      setSelected(id);
    }
  }

  const onDoubleClick = () => {
    setSelected(id);
    setContentEditing(true);
  }

  const onPointerDown = (e) => {
    select(e);
    capture.current = true;
    e.stopPropagation();
    e.currentTarget.setPointerCapture(e.pointerId);
    setStartPos([e.clientX, e.clientY]);
    setDirection(null);
    setEditing(true);
  };
  
  const onPointerUp = (e) => {
    if (!capture.current) return;
    capture.current = false;
    e.preventDefault();
    e.stopPropagation();
    applyChanges();
    resetChanges();
  }

  const onPointerMove = (e) => {
    if (!capture.current) return;
    e.preventDefault();
    e.stopPropagation();
    const {shiftKey} = e;

    const distX = e.clientX - startPos[0];
    const distY = e.clientY - startPos[1];
    const dragAlnogX = Math.abs(distX) > Math.abs(distY);

    const x = ((!direction && dragAlnogX) || direction === "x" || shiftKey) ? distX : 0;
    const y = ((!direction && !dragAlnogX) || direction === "y" || shiftKey) ? distY : 0;

    if (Math.abs(x) > 50) setDirection("x");
    if (Math.abs(y) > 50) setDirection("y");

    onChange(x, y);
  }


  // Methods
  const resetChanges = () => {
    setStartPos(null);
    setDeltaX(0);
    setDeltaY(0);
    setEditing(false);
    setSnappedDates(null);
  }

  const onChange = (deltaX, deltaY) => {
    setDeltaX(deltaX);
    setDeltaY(deltaY);
  }

  const applyChanges = () => {
    const newDate = date + deltaX * timeScale;
    const isChanged = newDate !== date;
    if (isChanged) {
      const snappedDate = vxDate.round(snap(newDate).val);
      updateMilestone({ id, date: snappedDate });
    }
    
    const row = getTargetRow();
    const isRowChanged = row && row !== props.row;
    if ( isRowChanged ) moveMilestone({ id, row });
    
    if ( isChanged || isRowChanged ) {
      setHistory();
    };
  };

  const changeTitle = (val) => {
    updateMilestone({id, title: val});
    setHistory(`title-edit-${id}`);
  }


  // Calculations

  const actualStyle = merge({}, milestoneStyles[styleId ?? lastStyle], overrides);
  const titleAlign = actualStyle.title?.textAlign;
  const dateAlign = actualStyle.date?.textAlign;
  const titleShow = actualStyle.title?.show;
  const dateShow = actualStyle.date?.show;
  const Icon = icons[actualStyle.shape] || Flag;

  const delta = deltaX * timeScale;
  const snappedDate = snap(date + delta);
  const adjDate = delta ? snappedDate.val : date;
  const actualDate = vxDate.round(adjDate);
  const dateVal = vxDate.format(actualDate, actualStyle.date?.format);

  if (capture.current) {
    setSnappedDates(delta && snappedDate.snapped ? snappedDate.val : null);
  }


  // Style
  const translateX = (adjDate - minDate) / timeScale;
  const transform = `translate(${translateX}px, ${deltaY}px)`;
  const style = { transform };
  
  const isFlag = actualStyle.shape === SHAPES.FLAG;
  const isSelected = selected.includes(id);
  const noTransitions = !ref.current || isEditing || temp;
  const immediateTooltip = (timeoutTooltip && isSelected) || deltaX;
  const disabled = isLocked && !temp;
  const className = [
    s.wrapper,
    styleId ?? lastStyle,
    overrides        && `override-${id}`,
    isFlag           && s.flag,
    isSelected       && s.selected,
    isEditing        && s.editing,
    temp             && s.temp,
    noTransitions    && s.noTransitions,
    immediateTooltip && s.immediateTooltip,
    disabled         && s.disabled,
  ].filter(Boolean).join(" ");

  const labels = (temp || (!titleShow && !dateShow)) ? null :
  (titleAlign === dateAlign) ? (
    <div className={[s.label, ALIGNMENT[titleAlign]].join(" ")}>
        {titleShow && <TitleInput className={s.title} onChange={changeTitle} id={id} value={title} />}
        {dateShow && <div className={s.date}>{dateVal}</div>}
    </div>
  ) : (
    <>
    {titleShow && (
      <div className={[s.label, ALIGNMENT[titleAlign]].join(" ")}>
        <TitleInput className={s.title} onChange={changeTitle} id={id} value={title} />
      </div>
    )}
    {dateShow && (
      <div className={[s.label, ALIGNMENT[dateAlign]].join(" ")}>
          <span className={s.date}>{dateVal}</span>
      </div>
    )}
    </>
  );

  const isShowTooltip = !!(
    temp ||
    (timeoutTooltip && isSelected) ||
    (isEditing && (!deltaY || deltaX))
  );
  
  return (
    <div id={id} style={style} className={className}>
      <div
        ref={ref}
        className={s.shapeWrapper}
        onDoubleClick={onDoubleClick}
        onPointerDown={onPointerDown}
        onPointerUp={onPointerUp}
        onLostPointerCapture={onPointerUp}
        onPointerMove={onPointerMove}
      >
        <Icon />
        {labels}
      </div>
      {isShowTooltip && <Tooltip date={actualDate} />}
    </div>
  )
}


const ALIGNMENT = {
  [MILESTONE_LABEL_ALIGNMENT.ABOVE]: s.above,
  [MILESTONE_LABEL_ALIGNMENT.BELOW]: s.below,
  [MILESTONE_LABEL_ALIGNMENT.BEFORE]: s.before,
  [MILESTONE_LABEL_ALIGNMENT.AFTER]: s.after,
}

const icons = {
  [SHAPES.FLAG]: Flag,
  [SHAPES.PIN]: Pin,
  [SHAPES.DIAMOND]: Diamond,
  [SHAPES.STAR]: Star,
  [SHAPES.STAR8]: Star8,
  [SHAPES.ARROW_DOWN]: Down,
  [SHAPES.ARROW_UP]: Up,
}