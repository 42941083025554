import { Segmented } from "components";
import { ReactComponent as sentencecase } from "img/icon/sentence-case.svg";
import { ReactComponent as uppercase } from "img/icon/uppercase.svg";

export default function TextTransform({ value, onChange }) {

  return (
    <Segmented
      value={value}
      onChange={onChange}
      secondary
      options={[
        {
          label: "None",
          value: "none",
          icon: sentencecase,
        }, {
          label: "Uppercase",
          value: "uppercase",
          icon: uppercase,
        }
      ]}
    />
  )
}