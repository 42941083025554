import { Suspense, useLayoutEffect } from 'react';
import { Await, defer, useLoaderData, useNavigate } from 'react-router-dom';
import { RouteAsyncError } from 'pages/RouteError/RouteError';
import { fetchAuthSession } from 'aws-amplify/auth';
import { generateClient, get } from 'aws-amplify/api';
import { downloadData, uploadData } from 'aws-amplify/storage';
import { getTimeline } from 'graphql/queries';
import { updateTimeline } from 'graphql/mutations';
import { Provider, createStore } from 'jotai';
import { setProjectAtom, titleAtom, userAtom, tokensAtom, GUEST_USER, FREE_ELEMENTS_LIMIT } from 'store';
import { getUser, isPaidUser } from 'utils';
import Styles from './Styles';
import Hotkeys from './Hotkeys';
import Aside from './Aside/Aside';
import Header from './Header/Header';
import Planner from './Planner/Planner';
import { UpgradeModal } from 'pages/CallToUpgrade/CallToUpgrade';
import Gpt from './Toolbar/Gpt';
import ImportModal from './ImportModal/ImportModal';
import TeamModal from './TeamModal/TeamModal';
import Tools from './Toolbar/Tools';
import s from "./Project.module.css";


export default function Project() {
  const data = useLoaderData();
  
  return (
    <Suspense fallback={<Fallback />}>
      <Await resolve={data.main} errorElement={<RouteAsyncError />}>
        {(data) => <Page data={data} />}
      </Await>
    </Suspense>
  )
}

function Fallback() {
  return (
    <div className={s.loader}>
      <h1>Loading timeline data</h1>
      <div className={s.loadBar}>
        <div className={s.loadBarInner} />
      </div>
    </div>
  )
}

function Page({ data }) {
  const navigate = useNavigate();
  useLayoutEffect(() => {
    if (data.isRedirect) {
      navigate(data.path, { replace: true });
    }
  }, [data, navigate])
  return (
    <Provider store={data.store}>
      <div className={s.wrapper}>
        <UpgradeModal />
        <Styles />
        <Hotkeys />
        <Header />
        <Aside />
        <Planner />
        <div className={s.toolbar}>
          <Gpt />
          <Tools />
        </div>
        <ImportModal />
        <TeamModal />
      </div>
    </Provider>
  )
}

export async function loader({ params }) {
  return defer({ main: loadData(params) });
}

async function loadData(params) {
  const { timelineId } = params;
  const store = createStore();

  // Get user
  const user = await getUser();
  store.set(userAtom, user);

  if (user === GUEST_USER) {
    return {
      isRedirect: true,
      path: "/login"
    };
  } else {
    // Load project
    const client = generateClient();
    const response = await client.graphql({
      query: getTimeline,
      variables: { id: timelineId }
    });
    const timeline = response.data.getTimeline;
    if (!timeline) {
      throw new Response(
        `Error: User ${user.sub} don't have access to timeline ${timelineId}`,
        { status: 403, statusText: "Access denied" }
      );
    }

    // LEGACY HANDLER START
    if (timeline.content) {
      // Upload content from DB to Storage
      await uploadData({
        path: ({identityId}) => `private/${identityId}/timeline/${timelineId}.json`,
        data: timeline.content,
        options: { contentType: "application/json" }
      }).result;
      // Remove content from DB
      await client.graphql({
        query: updateTimeline,
        variables: {
          input: {
            id: timelineId,
            content: null,
            updatedAt: timeline.updatedAt
          }
        }
      });
    }
    // LEGACY HANDLER END

    // Download content
    const isPaid = isPaidUser(user);
    const tokens = isPaid ? await getTokens() : 0;
    const contentResult = await downloadData({
      path: ({identityId}) => `private/${identityId}/timeline/${timelineId}.json`,
    }).result;
    const projectText = await contentResult.body.text();
    const project = JSON.parse(projectText);
    const elementsCount = (project.tasks?.length || 0) + (project.milestones?.length || 0);
    if (!isPaid && elementsCount > FREE_ELEMENTS_LIMIT) {
      return {
        isRedirect: true,
        path: "/upgrade"
      };
    } else {
      store.set(titleAtom, timeline.title);
      store.set(tokensAtom, tokens);
      store.set(setProjectAtom, project);
    }
  }
  return { store };
}

async function getTokens() {
  try {
    const { idToken } = (await fetchAuthSession({ forceRefresh: true })).tokens;
    const response = await get({
      apiName: "AiTokens",
      path: "/tokens",
      options: {
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      }
    }).response;
    const result = await response.body.json()
    return(result.tokens);
  } catch {
    return(0)
  }
}