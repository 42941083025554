import { useAtom, useSetAtom } from "jotai";
import { editingAtom, updateRowAtom, historyAtom, selectedAtom } from "store";
import TitleInput from "../TitleInput/TitleInput";
import s from "./RowTitle.module.css";


export default function RowTitle({id, title, selected}) {
  const [editing, setEditing] = useAtom(editingAtom);
  const setUpdateRow = useSetAtom(updateRowAtom);
  const setHistory = useSetAtom(historyAtom);
  const setSelected = useSetAtom(selectedAtom);

  const onDoubleClick = (e) => {
    e.stopPropagation();
    setSelected(id);
    setEditing(true);
  };

  const onChange = (val) => {
    setUpdateRow({id, title: val});
    setHistory(`title-edit-${id}`);
  }

  const className = [
    s.wrapper,
    !editing && s.placeholder,
    selected && !title && s.selected,
  ].filter(Boolean).join(" ");

  return (
    <div
      className={className}
      onDoubleClick={onDoubleClick}
    >
      <TitleInput id={id} value={title} onChange={onChange} />
    </div>
  )
}