import dateFormat from "dateformat";
import { DEFAULT_WORKDAYS } from "store";

export const DAY_LENGHT = 24 * 60 * 60 * 1000;

export function round(d) {
  return Math.round(d / DAY_LENGHT) * DAY_LENGHT;
}

export function start(date1, date2) {
  return round(Math.min(date1, date2));
}

export function end(date1, date2) {
  return Math.max(
    round(Math.max(date1, date2)),
    start(date1, date2) + DAY_LENGHT
  );
}

export function sort(date1, date2) {
  return [
    start(date1, date2),
    end(date1, date2)
  ];
}

export function format(timestamp, format, isLast) {
  if (!timestamp || !isFinite(timestamp)) return "";
  const d = isLast ? timestamp - DAY_LENGHT : timestamp;
  return dateFormat(d, format ?? "ddd, dd mmm yy", true);
}

export function workdays(date1, date2, schedule) {
  const delta = Math.round(Math.abs(date2 - date1) / DAY_LENGHT);
  const weeks = Math.floor(delta / 7);
  const partialWeek = delta % 7;
  const startDate = new Date(start(date1, date2));
  const startDay = startDate.getDay();
  const workdays = schedule ?? DEFAULT_WORKDAYS;
  let result = weeks * workdays.filter(Boolean).length;
  for (let i = 0; i < partialWeek; i++) {
    const index = (i + startDay) % 7;
    if (workdays[index]) result += 1;
  }
  return result;
}