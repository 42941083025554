import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { uploadData, downloadData, copy, remove } from "aws-amplify/storage";
import { generateClient, get } from "aws-amplify/api";
import { timelinesByOwner } from "graphql/queries";
import { createTimeline, deleteTimeline, updateTimeline } from "graphql/mutations";
import { useInView } from "react-intersection-observer";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { splitAtom } from "jotai/utils";
import { getUser } from "utils";
import { userAtom, callToUpgradeModalAtom, isPaidUserAtom, GUEST_USER, FREE_ELEMENTS_LIMIT } from "store";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { Button, Input, Modal, Select } from "components";
import { ModalButton, ModalFooter } from "components/Modal";
import { featuredTemplates } from "store/templates";
import { SampleDataToggle, TemplateCard, newTimeline } from "../Templates/Templates";
import { timelineDownload } from "pages/Project/Header/Download";
import { ReactComponent as more } from "img/icon/more.svg";
import { ReactComponent as plus } from "img/icon/plus.svg";
import { ReactComponent as Lock } from "img/icon/lock.svg";
import { ReactComponent as Arrow } from "img/workspace-empty-arrow.svg";
import s from "./Timelines.module.css";

TimeAgo.locale(en);
const timeFormat = new TimeAgo();

const timelinesAtom = atom([]);
const syncedAtom = atom(false);
const timelineAtomsAtom = splitAtom(timelinesAtom, (el) => el.id);


export default function Timelines() {
  const user = useAtomValue(userAtom);
  const timelines = useAtomValue(timelineAtomsAtom);
  const setTimelines = useSetAtom(timelinesAtom);
  const [isSynced, setSynced] = useAtom(syncedAtom);
  const [modal, setModal] = useState(null);
  const [busy, setBusy] = useState(false);
  const navigate = useNavigate();

  // Fetch timelines
  useEffect(() => {
    if (!user) return;
    if (user === GUEST_USER) {
      setSynced(true);
      return;
    }
    const client = generateClient();
    async function fetchTimelines(nextToken) {
      const variables = {
        owner: `${user.sub}::${user.sub}`,
        sortDirection: "DESC",
        nextToken
      };
      try {
        const res = await client.graphql({ query: timelinesByOwner, variables });
        const { items, nextToken: token } = res.data.timelinesByOwner;
        const result = [...items];
        if (token) {
          const nextPage = await fetchTimelines(token);
          result.push(...nextPage);
        }
        return result;
      } catch (err) {
        console.log(err);
        return [];
      }
    }
    fetchTimelines()
      .then((items) => Promise.all(items.map(updateLegacy))) /* Remove later */
      .then((items) => {
        setTimelines(items);
        setSynced(true);
      });
  }, [user, setTimelines, setSynced]);

  // Create blank timeline
  async function onClick() {
    if (busy) return;
    setBusy(true);
    const timelineId = await newTimeline();
    if (timelineId) {
      return navigate(`/timeline/${timelineId}`);
    } else {
      setBusy(false);
      console.log("Interal Error")
    }
  }

  // Modals
  const closeModal = () => setModal(null);
  const modalProps = { modal, closeModal };

  return (
    <div className={s.wrapper}>
      <Helmet title="My timelines" />
      <DeleteModal {...modalProps} />
      <RenameModal {...modalProps} />
      <div className={s.header}>
        <h1>My timelines</h1>
        <div className={s.toolbar}>
          {!isSynced && <div className={s.sync}>Syncing...</div>}
          <Button disabled={busy} className={s.button} label="New timeline" icon={plus} onClick={onClick} />
        </div>
      </div>
      {timelines && timelines.length ? (
        <ul className={s.cards}>
          {timelines.map(atom => (
            <li className={s.card} key={atom}>
              <TimelineLink atom={atom} busy={busy} setModal={setModal} />
            </li>
          ))}
        </ul>
      ) : isSynced ? (
        <Empty busy={busy} setBusy={setBusy} />
      ) : (
        <p>Loading your timelines...</p>
      )}
    </div>
  )
}

function generateThumbnail(id) {
  return get({
    apiName: "thumbnailTimeline",
    path: `/thumbnail/${id}`
  }).response;
}
async function fetchThumbnail(id, updatedAt) {
  const response = await downloadData({
    path: ({identityId}) => `private/${identityId}/thumbnail/${id}.png`
  }).result;
  const timelineUpdatedAt = new Date(updatedAt);
  const stale = response.lastModified < timelineUpdatedAt;
  const blob = await response.body.blob();
  const url = URL.createObjectURL(blob);
  return { url, stale };
}


function TimelineLink({ atom, setModal, busy }) {
  const [timelines, setTimelines] = useAtom(timelinesAtom);
  const timeline = useAtomValue(atom);
  const { id, title, updatedAt, size } = timeline;
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "50% 0%",
  });
  const [image, setImage] = useState();
  const callToUpgrade = useSetAtom(callToUpgradeModalAtom);
  const hasSubscription = useAtomValue(isPaidUserAtom);
  const navigate = useNavigate();
  const isLocked = !hasSubscription && (size > FREE_ELEMENTS_LIMIT);
  const updated = useMemo(() => timeFormat.format(new Date(updatedAt)), [updatedAt]);

  // Load image
  useEffect(() => {
    if (!inView || image) return;
    const url = fetchThumbnail(id, updatedAt)
      .then(result => {
        setImage(result.url);
        if (result.stale) {
          throw new Error();
        }
        return result.url;
      })
      .catch(async () => {
        await generateThumbnail(id);
        const result = await fetchThumbnail(id, updatedAt);
        setImage(result.url);
        return result.url;
      })

    return () => {
      if (!url) return;
      URL.revokeObjectURL(url);
    }
  }, [ id, updatedAt, image, inView ]);

  const dontPropagate = (e) => {
    e.stopPropagation();
    e.preventDefault();
  }
  const onLockClick = () => callToUpgrade(true);
  const onClick = (e) => {
    if (isLocked || busy) {
      e.preventDefault();
    }
  }

  const classNames = [
    s.link,
    isLocked && s.locked,
    busy && s.busy,
  ].filter(Boolean).join(" ");
  const options = [
    {
      label: "Download .pptx",
      value: "download_pptx",
      isDisabled: isLocked,
    }, {
      label: "Download .svg",
      value: "download_svg",
      isDisabled: isLocked,
    }, {
      label: "Download .png",
      value: "download_png",
      isDisabled: isLocked,
    }, {
      label: "Download .xlsx",
      value: "download_xlsx",
      isDisabled: !hasSubscription,
    }, {
      label: "Duplicate",
      value: "duplicate",
      isDisabled: isLocked,
    }, {
      label: "Rename",
      value: "rename",
      isDisabled: isLocked,
    }, {
      label: "Delete",
      value: "delete",
    }
  ];

  const onChange = async ( action ) => {
    const user = await getUser();
    if (user === GUEST_USER) {
      navigate("/login");
      return;
    }
    if (action.startsWith("download")) {
      const format = action.split("_")[1];
      download({
        id, title, format
      });
    } else if (action === "duplicate") {
      const res = await duplicate({ id, title, size });
      setTimelines([res, ...timelines]);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      setModal({ action, atom });
    }
  }

  const thumbnailClasses = [s.thumbnail, !image && s.loading].filter(Boolean).join(" ");
  const El = isLocked ? "div" : Link;

  return (
    <El ref={ref} className={classNames} onClick={onClick} to={isLocked ? null : `/timeline/${id}`}>
      <div className={thumbnailClasses}>
        <div className={s.loader} />
        <div className={s.imageWrapper}>
          <img className={s.image} src={image} alt="" />
        </div>
      </div>
      <div className={s.desc}>
        <div className={s.meta}>
          <span className={s.name}>{title}</span>
          <span className={s.updated}>{updated}</span>
        </div>
        {isLocked &&
          <button type="button" className={s.lock} onClick={onLockClick} >
            <Lock className={s.lockIcon} />
          </button>
          }
        <div onClick={dontPropagate}>
          <Select
            className={s.action}
            classNames={{control: s.control}}
            options={options}
            onChange={onChange}
            value={null}
            icon={more}
            menuPlacement={"top"}
            rightMenu
            iconControl
          />
        </div>
      </div>
    </El>
  )
}

const modalPlaceholderAtom = atom();
function RenameModal({ modal, closeModal }) {
  const [busy, setBusy] = useState(false);
  const [timeline, setTimeline] = useAtom(modal?.atom || modalPlaceholderAtom);
  const navigate = useNavigate();
  const isOpen = modal?.action === "rename";

  const onSubmit = async (e) => {
    e.preventDefault();
    if (busy) return;
    try {
      setBusy(true);

      // Authorization check
      const user = await getUser();
      if (user === GUEST_USER) {
        throw new Error("401");
      }

      // Rename
      const data = new FormData(e.target);
      const name = data.get('name');
      const client = generateClient();
      const res = await client.graphql({
        query: updateTimeline,
        variables: {
          input: {
            id: timeline.id,
            title: name
          }
        }
      });
      setTimeline(res.data.updateTimeline);
      closeModal();
    } catch (err) {
      if (err.message === "401") {
        navigate("/login");
      }
      console.log(err);
    } finally {
      setBusy(false);
    }
  }

  const onFocus = (e) => e.target.select();

  return (
    <Modal
      isOpen={isOpen}
      title="Rename timeline"
      close={closeModal}
    >
      <form className={s.modalForm} onSubmit={onSubmit}>
        <Input
          className={s.modalInput}
          defaultValue={timeline?.title}
          onFocus={onFocus}
          type={"text"}
          name={"name"}
          autoComplete={"off"}
        />
        <ModalFooter>
          <ModalButton disabled={busy} onClick={closeModal}>Cancel</ModalButton>
          <ModalButton disabled={busy}>Rename</ModalButton>
        </ModalFooter>
      </form>
    </Modal>
  )
}

function DeleteModal({ modal, closeModal }) {
  const [busy, setBusy] = useState(false);
  const dispatch = useSetAtom(timelineAtomsAtom);
  const timeline = useAtomValue(modal?.atom || modalPlaceholderAtom);
  const navigate = useNavigate();
  const isOpen = modal?.action === "delete";

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (busy) return;
    try {
      setBusy(true);
      
      // Authorization check
      const user = await getUser();
      if (user === GUEST_USER) {
        new Error("401");
      }
      
      // Removal
      const id = timeline.id;
      const client = generateClient();
      await client.graphql({
        query: deleteTimeline,
        variables: {
          input: { id }
        }
      });
      dispatch({ type: "remove", atom: modal.atom });
      try {
        remove({
          path: ({identityId}) => `private/${identityId}/timeline/${id}.json`
        });
        remove({
          path: ({identityId}) => `private/${identityId}/thumbnail/${id}.png`
        });
      } catch (err) {
        console.log("Storage error:", err);
      }
      closeModal();
    } catch (err) {
      if (err.message === "401") {
        navigate("/login");
      }
      console.log(err);
    } finally {
      setBusy(false);
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      title="Delete timeline"
      subtitle="You'll not be able to undo this. Please confirm."
      close={closeModal}
      tabIndex={-1}
    >
      <ModalFooter>
        <ModalButton disabled={busy} onClick={closeModal}>Cancel</ModalButton>
        <ModalButton disabled={busy} onClick={handleSubmit} alert>Delete</ModalButton>
      </ModalFooter>
    </Modal>
  )
}

async function download({ id, title, format }) {
  try {
    timelineDownload({ id, title, format });
  } catch(err) {
    console.log(err);
  }
}

async function duplicate({ id, title, size }) {
  const client = generateClient();

  // Duplicate metadata
  const result = (await client.graphql({
    query: createTimeline,
    variables: {
      input: {
        title: `${title} copy`,
        size
      }
    }
  })).data.createTimeline;

  // Duplicate content
  await copy({
    source: {
      path: ({identityId}) => `private/${identityId}/timeline/${id}.json`
    },
    destination: {
      path: ({identityId}) => `private/${identityId}/timeline/${result.id}.json`
    },
  });

  // Duplicate thumbnail
  try {    
    await copy({
      source: {
        path: ({identityId}) => `private/${identityId}/thumbnail/${id}.png`
      },
      destination: {
        path: ({identityId}) => `private/${identityId}/thumbnail/${result.id}.png`
      },
    });
  } catch(err) {
    console.log(err);
  }

  return result;
}

function Empty({ busy, setBusy }) {
  const isGuest = useAtomValue(userAtom) === GUEST_USER;

  const hasTemp = sessionStorage.getItem("tempProject");
  return (
    <>
      {(isGuest && hasTemp) ? (
        <div className={s.keepup}>
          <Link className={s.keepupLink} to="/timeline/temp">Continue working on your timeline</Link>
          <p className={s.or}>or</p>
          <p><Link to="/signup">Sign up</Link> to save the progress</p>
        </div>
      ) : (
        <div className={s.empty}>
          <Arrow className={s.arrow} />
          <p>No timelines yet.</p>
        </div>
      )}
      <div className={s.featured}>
        <div className={s.header}>
          <h2>Start with a template</h2>
          <div className={s.toolbar}>
            <SampleDataToggle />
          </div>
        </div>
        <ul className={s.cards}>
          {featuredTemplates.map((template, index) => (
            <li className={s.card} key={template.id}>
              <TemplateCard key={index} busy={busy} setBusy={setBusy} {...template} />
            </li>
          ))}
        </ul>
        <Link to="/templates" className={s.showMore}>More templates</Link>
      </div>
    </>
  )
}


// Remove later
async function updateLegacy(item) {
  if (!item.content) {
    return item;
  }
  // Write to cloud
  await uploadData({
    path: ({identityId}) => `private/${identityId}/timeline/${item.id}.json`,
    data: item.content,
    options: { contentType: "application/json" }
  }).result;

  // Calc size
  const content = JSON.parse(item.content);
  const size = (content.tasks?.length || 0) + (content.milestones?.length || 0);

  // Update
  const client = generateClient();
  const response = await client.graphql({
    query: updateTimeline,
    variables: {
      input: {
        id: item.id,
        updatedAt: item.updatedAt,
        size: size,
        content: null
      }
    }
  });
  return response.data.updateTimeline;
}