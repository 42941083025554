import { useAtomValue } from "jotai";
import { teamAtom, preferencesAtom } from "store";
import { fieldValue } from "utils";
import s from "./RowTitle.module.css";


export default function RowLabel({field, format, items=[], ...rest}) {
  const team = useAtomValue( teamAtom );
  const preferences = useAtomValue( preferencesAtom );

  const classes = [s.wrapper, s.value];
  const className = classes.join(" ");

  return items.length ? (
    <div className={className} {...rest}>
      {fieldValue(items, field, format, team, preferences)}
    </div>
  ) : null;
}