import { signOut } from "aws-amplify/auth";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { hasLifetime, hasSubscription } from "utils";
import { useAtomValue } from "jotai";
import { userAtom } from "store";
import { Button } from "components";
import s from "./Menu.module.css";

import { ReactComponent as Logo } from "img/logo.svg";
import { ReactComponent as TimelineIcon } from "img/icon/timeline.svg";
import { ReactComponent as StyleIcon } from "img/icon/style.svg";
import { ReactComponent as UserIcon } from "img/icon/user.svg";
import { ReactComponent as SignOutIcon } from "img/icon/sign-out.svg";


export default function Menu() {
  const user = useAtomValue(userAtom)
  const navigate = useNavigate();

  async function handleSignOut() {
    try {
      await signOut();
      navigate("/login")
    } catch (error) {
      console.log( error );
    }
  }

  const offerSubscription = !hasSubscription(user);

  let desc;
  if (hasLifetime(user)) {
    desc = "Get access to the first-in-class AI Assistant";
  } else {
    desc = "Make bigger timelines with unlimited elements"
  }

  return (
    <aside className={s.wrapper}>
      <Link to="/" className={s.logo}>
        <Logo />
      </Link>
      <div className={s.section}>
        <h2 className={s.title}>Workspace</h2>
        <IconLink icon={TimelineIcon} to="/" end>My timelines</IconLink>
        <IconLink icon={StyleIcon} to="/templates">Templates</IconLink>
      </div>
      {offerSubscription && (
        <div className={s.subscribe}>
          <p className={s.subscribeTitle}>Upgrade to Pro</p>
          <p className={s.subscribeDesc}>{desc}</p>
          <Button el={Link} to="/upgrade" className={s.subscribeButton} label="Upgrade" />
        </div>
      )}
      <div className={s.user}>
        <IconLink icon={UserIcon} to="/settings">{user.name}</IconLink>
        <IconLink icon={SignOutIcon} onClick={handleSignOut} warning>Log out</IconLink>
      </div>
      <div className={s.legal}>
        <a target="_blank" rel="noreferrer" href="https://deltaplan.io/privacy/">Privacy</a>
        <div className={s.sr} />
        <a target="_blank" rel="noreferrer" href="https://deltaplan.io/terms/">Terms</a>
        <div className={s.sr} />
        <a target="_blank" rel="noreferrer" href="https://deltaplan.io/refunds/">Refunds</a>
      </div>
    </aside>
  )
}

function IconLink({ to, icon: Icon, end, children, warning, onClick }) {
  const linkStyle = ({ isActive }) => [
    s.link,
    isActive && s.active,
    warning && s.warning
  ].filter(Boolean).join(" ");

  return onClick ? (
    <button className={linkStyle({})} onClick={onClick} type="button">
      <Icon className={s.icon} />
      {children}
    </button>
  ) : (
    <NavLink className={linkStyle} to={to} end={end}>
      <Icon className={s.icon} />
      <span>{children}</span>
    </NavLink>
  )
}