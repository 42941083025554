import { useEffect, useMemo, useState } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { historyAtom } from "store";
import { Modal } from "components";
import { ModalButton, ModalFooter } from "components/Modal";
import StyleItem from "./StyleItem";
import s from "./StylesList.module.css";


export default function RemoveStyleModal({ atoms, id, closeModal }) {
  const [ selected, setSelected ] = useState();
  const [ savedId, saveId ] = useState(id);
  const styles = useAtomValue(atoms.styles);
  const items = useAtomValue(atoms.items);
  const removeStyle = useSetAtom(atoms.removeStyle);
  const setHistory = useSetAtom(historyAtom);

  const options = useMemo(() => (
    styles.filter(
      style => style.id !== savedId
    )
  ), [savedId, styles]);

  const counter = useMemo(() => (
    items.reduce(
      (acc, el) => (el.id !== "root" && el.style === savedId) ? acc + 1 : acc
    , 0)
  ), [savedId, items]);

  const remove = () => {
    removeStyle({ id, to: selected });
    setHistory( `${id}-style-remove` );
    closeModal();
  };

  useEffect(() => {
    if (!id) return;
    saveId(id);
  }, [id]);

  useEffect(() => {
    if (!id || !options.length) {
      setSelected(null);
    } else {
      setSelected(options[0].id);
    }
  }, [ id, options ])

  useEffect(() => {
    if (!id || !savedId || counter) return;
    remove();
  })

  
  const onSubmit = (e) => {
    e.preventDefault();
    remove();
  }
  const onChange = (e) => {
    setSelected( e.target.value );
  }

  const actionMessage = options.length > 1 ? "Choose a replacement for it." : "It will be replaced by:";
  const label = counter > 1 ? "items" : "item";
  const warningMessage = `The style is used by ${counter} ${label}.`;

  return (
    <Modal isOpen={!!(id && counter)} title="Delete style" subtitle={`${warningMessage} ${actionMessage}`} close={closeModal}>
      <form className={s.modalForm} onSubmit={onSubmit}>
        <ul className={s.list}>
          {options.map(({id, name}, index) => (
            <StyleItem
              key={id}
              atoms={atoms}
              checked={selected === id}
              prefix={"replace"}
              isHotkey={false}
              isMenu={false}
              sortable={false}
              {...{id, name, index, onChange}}
            />
          ))}
        </ul>
        <ModalFooter>
          <ModalButton onClick={closeModal}>Cancel</ModalButton>
          <ModalButton alert>Replace and delete</ModalButton>
        </ModalFooter>
      </form>
    </Modal>
  )
}