import { useEffect, useState } from "react"
import { Helmet } from "react-helmet";
import { autoSignIn, confirmSignUp, resendSignUpCode } from "aws-amplify/auth";
import { saveTemp } from "utils";
import { Input, ModalMessage } from "components";
import { ModalButton, FormFooter } from "components/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import { Wrapper } from "./SignIn";

import s from "./authorization.module.css";


export default function VerifyEmail() {
  const locationState = useLocation().state;
  const [ cooling, setCooling ] = useState(0);
  const [ sending, setSending ] = useState(false);
  const [ error, setError ] = useState();
  const navigate = useNavigate();


  // Redirect if page opened directly
  useEffect(() => {
    if (!locationState?.email) navigate("/signup", {replace: true, state: locationState});
  }, [locationState, navigate])

  const onSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const code = data.get('code');
    const email = locationState.email;
    const confirmation = "Your account is ready for work.";
    const state = { ...locationState, confirmation };

    // Simple data validation
    if (!email) {
      navigate("/signup");
      return;
    } else if (!code) {
      setError("NoCode");
      return;
    }

    // Submit code
    setSending(true);
    confirmSignUp({ username: email, confirmationCode: code })
    .then(() => {
      autoSignIn()
      .then(() => {
        saveTemp()
        .then(() => navigate("/"))
      })
      .catch(() => {
        navigate("/login", { state });
      })
    })
    .catch(error => {
      if (error.name === "NotAuthorizedException") {
        // Redirect to signin if already confirmed
        navigate("/login", { state });
      } else {
        // Show error
        setError(error.name);
      }
    })
    .finally(() => setSending(false))
  }

  // Resend code
  const handleResend = () => {
    resendSignUpCode({username: locationState.email});
    setCooling(60);
  }

  // Resend code cooling timer
  useEffect(() => {
    if (cooling > 0) setTimeout(() => setCooling( cooling - 1 ), 1000);
  }, [cooling] )

  const goBack = () => {
    navigate(locationState?.from || "/signup", {state: locationState});
  }

  return (
    <Wrapper>
      <Helmet title="Verify email" />
      <h1 className={s.title}>Check your email</h1>
      <p>We have sent a confirmation code to the email address you provided. Please enter the code below to proceed.</p>
      <p className={s.infoBox}><b>Important:</b> If you don't see the email in your inbox within a few minutes, please check your spam or junk folder.</p>
      <form onSubmit={onSubmit}>
        <Input
          id="confirmation-code"
          label="Confirmation code"
          name="code"
          type="text"
          autoComplete="one-time-code"
          autoFocus
          required
        />
        {cooling > 0 ? (
          <p className={s.resend}>The code was sent. No message still? Try again in {cooling} seconds</p>
        ) : (
          <button type="button" className={`${s.resend} ${s.resendButton}`} onClick={handleResend}>Resend confirmation code</button>
        )}
        <ModalMessage value={error} options={options} />
        <FormFooter>
          <ModalButton onClick={goBack}>Back</ModalButton>
          <ModalButton disabled={sending} >Confirm</ModalButton>
        </FormFooter>
      </form>
    </Wrapper>
  )
}

const options = {
  NoCode: "No code provided",
  CodeMismatchException: "Invalid verification code provided, please try again.",
  ExpiredCodeException: "For security reasons, this code is no longer relevant. Please get a new one",
  default: "We are sorry, but it seems there is a problem on our side. Please try again later.",
};