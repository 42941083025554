import { useAtomValue } from "jotai";
import { snappedGuidesAtom } from "store";
import s from "./SnapGuide.module.css";

function SnapGuide() {
  const guides = useAtomValue(snappedGuidesAtom);
  return (<>{guides.map( x =>
    <div key={x} className={s.guide} style={{transform: `translateX(${x}px)`}} />
  )} </>)
}

export default SnapGuide;