import {v4 as uid} from "uuid";
import { atom } from "jotai";
import { FIELDS, TIMELINE_FORMATS, TIMELINE_UNITS } from "./constants";
import { columnsAtom } from "./columns";
import { getTierFormat } from "utils";
import { cloneDeep, find, merge, reject, without, } from "lodash";


export const tiersAtom = atom([]);
export const headerAtom = atom({});
export const footerAtom = atom({});

export const editHeadingAtom = atom(null,
  (get, set, {atom, ...props}) => {
    const target = get(atom);
    const result = cloneDeep(target);
    merge(result, props);
    set(atom, result);
  }
);


// COLUMNS
export const addHeadingColumnAtom = atom(null,
  (get, set, id) => {
    const prevId = get(columnsAtom).filter(
      c => c.field !== FIELDS.TASKS && c.id !== id
    ).at(-1)?.id;
    [ headerAtom, footerAtom ].forEach( (atom) => {
      const defaultColumn = {
        show: false,
        color: {h: 0, s: 0, v: 0, a: 1},
        fontSize: 12,
        textAlign: "left",
        bold: true,
        italic: false,
        underline: false,
        textTransform: "none",
      };
      const target = cloneDeep(get(atom));
      target.columns[id] = {...target.columns[prevId] || defaultColumn}
      set(atom, target);
    });
  }
  );

export const removeHeadingColumnAtom = atom(null,
  (get, set, id) => {
    [ headerAtom, footerAtom ].forEach( (atom) => {
      const target = cloneDeep(get(atom));
      delete target.columns[id];
      set(atom, target);
    });
  }
);



// TIERS
export const editTierAtom = atom(null,
  (get, set, {id, ...upd}) => {
    const tiers = get(tiersAtom);
    const current = find(tiers, { id });
    const target = cloneDeep(current);
    const updated = merge(target, upd);
    const result = tiers.map( el => el === current ? updated : el);
    set(tiersAtom, result);
  }
)

export const addTierAtom = atom(null,
  (get, set, { atom, field }) => {
    const tiers = get(tiersAtom);
    const target = cloneDeep(get(atom));
    const targetTiers = target.tiers;
    const ref = find(tiers, { id: targetTiers.at(-1) }) || defaultTier;
    const newTier = {
      ...ref,
      id: `tier-${uid()}`,
      field: field,
      format: getTierFormat(field, ref.format),
    };
    target.tiers.push(newTier.id);
    set(tiersAtom, [...tiers, newTier]);
    set(atom, target);
  }
)

export const removeTierAtom = atom(null,
  (get, set, id) => {
    set(tiersAtom, reject(get(tiersAtom), { id }));

    const header = cloneDeep(get(headerAtom));
    header.tiers = without(header.tiers, id);
    set( headerAtom, header);
    
    const footer = cloneDeep(get(footerAtom));
    footer.tiers = without(footer.tiers, id);
    set( footerAtom, footer);
  }
)

const defaultTier = {
  field: FIELDS.TIMELINE,
  format: TIMELINE_FORMATS[0],
  scale: TIMELINE_UNITS.AUTO,

  color: {h: 0, s: 0, v: 0, a: 1},
  fontSize: 12,
  textAlign: "left",
  bold: true,
  italic: false,
  underline: false,
  textTransform: "none",

  background: { h: 0, s: 0, v: 95, a: 1 },
  backgroundShow: false,
  height: 24,
  
  separatorColor: { h: 0, s: 0, v: 80, a: 1 },
  separatorWidth: 1,
  separatorShow: true,
}