import { vxDate } from "utils";
import s from "./Milestone.module.css";

export default function Tooltip({date}) {
  const value = vxDate.format(date, "ddd, dd mmm yyyy");
  return (
    <div className={s.tooltip}>
      {value}
    </div>
  )
}