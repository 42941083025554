import { useEffect, useRef } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { createRowAtom, taskDragAtom, groupsAtom } from "store";

export default function useDragAndDrop(ref, isDragging, deltaY) {
  const dropTargets = useRef();
  const groups = useAtomValue(groupsAtom);
  const [taskDrag, setTaskDrag] = useAtom(taskDragAtom);
  const createRow = useSetAtom(createRowAtom);

  useEffect(() => {

    // Reset state
    if ( !isDragging ) {
      setTaskDrag(null);
      dropTargets.current = null;
      return;
    }

    // Precalculate drop targets
    if ( !dropTargets.current && ( Math.abs(deltaY) > 10 )) {
      const interlineThreshold = 8;

      let coord = -Infinity;
      dropTargets.current = groups.reduce( (acc, {children, id:group}) => (
        acc.concat( children
          .reduce( ( acc, row, index, array ) => {
            const el = document.getElementById(row);
            if (!el) return acc;
            const { top, bottom } = el.getBoundingClientRect();
            acc.push(
              {
                position: "dropAbove",
                coord, group, row, index,
              }, {
                position: "dropOver",
                coord: top + interlineThreshold,
                group, row, index,
              }
            );
            coord = bottom - interlineThreshold;

            if ( index === array.length-1 ) {
              acc.push({
                position: "dropBelow",
                coord, group, row,
                index: index + 1,
              });
              coord = bottom + interlineThreshold;
            }
            
            return acc;
          }, [])
        )
      ), [] );
    }

    // Find current target
    if ( !dropTargets.current || !ref.current ) return;
    const rect = ref.current.getBoundingClientRect();
    const origin = rect.top + rect.height / 2;
    const result = dropTargets.current.findLast( el => el.coord < origin );
    setTaskDrag(result);
    
  }, [ref, groups, isDragging, deltaY, setTaskDrag]);

  const getTargetRow = () => {
    if ( !taskDrag ) return false;
    const { group, index, row, position } = taskDrag;
    if ( position === "dropOver") {
      return row;
    } else {
      return createRow({ index, group }).id;
    }
  }
  return getTargetRow;
}