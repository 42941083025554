import * as vxDate from "./date";
import { clamp, find, maxBy, minBy, partition, round, sumBy } from "lodash";
import { FIELDS, DATE_FORMATS, DURATION_FORMATS, ASSIGNEE_FORMATS, SELECTABLE, DAY_LENGHT } from "store";
import { numFormat } from "./functions";


export function fieldValue(items = [], field, rawFormat, team = [], preferences) {
  const format = getFormat(field, rawFormat);
  const [milestones, tasks] = partition(
    Array.isArray(items) ? items : [items],
    { type: SELECTABLE.MILESTONE }
  );

  const { workdays, currencyPlacement: placement, currencySign: sign } = preferences;

  let value;
  switch (field) {
    case FIELDS.START: {
      const start = minDate(tasks, milestones);
      value = vxDate.format(start, format);
      break;
    }
    case FIELDS.END: {
      const end = maxDate(tasks, milestones);
      value = vxDate.format(end, format, true);
      break;
    }
    case FIELDS.RANGE: {
      const start = minDate(tasks, milestones);
      const end = maxDate(tasks, milestones);
      value = `${vxDate.format(start, format)} – ${vxDate.format(end, format, true)}`;
      break;
    }
    case FIELDS.DURATION: {
      const start = minDate(tasks, milestones);
      const end = maxDate(tasks, milestones);
      const days = vxDate.workdays(start, end, workdays);
      value = `${days} ${format}`
      break;
    }
    case FIELDS.COST: {
      const cost = sumBy(tasks, (t) => (
        taskCost(t.start, t.end, t.workgroup, team, workdays)
      ));
      const prefix = placement === "prefix" ? sign : "";
      const suffix = placement === "suffix" ? sign : "";
      value = `${prefix}${numFormat(cost)}${suffix}`;
      break;
    }
    case FIELDS.FTE: {
      const rangeStart = minDate(tasks);
      const rangeEnd = maxDate(tasks);
      value = rangeFTE(rangeStart, rangeEnd, tasks, workdays);
      break;
    }
    case FIELDS.ASSIGNEE: {
      value = tasksAssignee(tasks, team, format, workdays);
      break;
    }
    default: break;
  }

  return String(value);
}

function minDate(tasks, milestones = []) {
  const minTask = minBy(tasks, "start")?.start ?? Infinity;
  const minMilestone = minBy(milestones, "date")?.date ?? Infinity;
  return vxDate.round(
    Math.min(minTask, minMilestone)
  );
}
function maxDate(tasks, milestones = []) {
  const maxTask = maxBy(tasks, "end")?.end ?? 0;
  const maxMilestone = (maxBy(milestones, "date")?.date ?? 0) + DAY_LENGHT;
  return vxDate.round(
    Math.max(maxTask, maxMilestone)
  );
}

export function taskCost(start, end, workgroup, team, schedule) {
  const workdays = vxDate.workdays(start, end, schedule);
  const cost = sumBy(workgroup, ({ id, allocation }) => {
    const member = find(team, { id });
    const rate = member?.rate || 0;
    return workdays * rate * allocation / 100;
  });
  return cost;
}

export function rangeFTE(rangeStart, rangeEnd, tasks, schedule) {
  const rangeWorkdays = vxDate.workdays(rangeStart, rangeEnd, schedule);
  if ( !rangeWorkdays ) {
    return 0;
  }
  const manDays = sumBy(tasks, (t) => {
    const start = clamp(t.start, rangeStart, rangeEnd);
    const end = clamp(t.end, rangeStart, rangeEnd);
    const workdays = vxDate.workdays(start, end, schedule);
    return sumBy(t.workgroup, "allocation") / 100 * workdays;
  });
  return round(manDays / rangeWorkdays, 2);
}

function tasksAssignee(tasks, team, format, schedule) {
  const members = team.reduce((acc, member) => {
    acc[member.id] = { ...member, worked: 0 };
    return acc;
  }, {});

  tasks.forEach((task) => {
    const workdays = vxDate.workdays(task.start, task.end, schedule);
    task.workgroup.forEach( member => {
      members[member.id].worked += member.allocation * workdays;
    })
  }, members);

  const assigneeArray = Object.values(members)
  .filter( member => member.worked > 0 )
  .sort( (a, b) => b.worked - a.worked );

  const assignee = assigneeArray[0];
  let result = "";
  if (assignee) {
    switch (format) {
      case ASSIGNEE_FORMATS.AUTO: {
        result = assignee.role || assignee.name;
        break;
      }
      case ASSIGNEE_FORMATS.ROLE: {
        result = assignee.role;
        break;
      }
      case ASSIGNEE_FORMATS.NAME: {
        result = assignee.name;
        break;
      }
      default: break;
    }
    if (assigneeArray.length > 1) {
      result += ` +${assigneeArray.length - 1}`;
    }
  }

  return result;
}


export function getFormat( field, prevFormat ) {
  const isDateField = [FIELDS.START, FIELDS.END, FIELDS.RANGE].includes(field);
  const isDurationField = field === FIELDS.DURATION;
  const isAssigneeField = field === FIELDS.ASSIGNEE;

  if ( isDateField ) {
    return DATE_FORMATS.find( el => el === prevFormat) ?? DATE_FORMATS[0];
  } else if ( isDurationField ) {
    return DURATION_FORMATS.find( el => el === prevFormat) ?? DURATION_FORMATS[0];
  } else if ( isAssigneeField ) {
    return Object.values(ASSIGNEE_FORMATS).find( el => el === prevFormat) ?? ASSIGNEE_FORMATS.AUTO;
  } else {
    return "";
  }
}
