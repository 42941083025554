
export const subscription = {
  annual: {
    priceId: process.env.REACT_APP_PRICE_ANNUAL,
    quantity: 1,
  },
  monthly: {
    priceId: process.env.REACT_APP_PRICE_MONTHLY,
    quantity: 1,
  },
  lifetime: {
    priceId: process.env.REACT_APP_PRICE_LIFETIME,
    quantity: 1,
  }
};

export const countries = [
  { value: "AX", label: "Åland Islands" },
  { value: "AL", label: "Albania" },
  { value: "DZ", label: "Algeria" },
  { value: "AS", label: "American Samoa" },
  { value: "AD", label: "Andorra" },
  { value: "AO", label: "Angola" },
  { value: "AI", label: "Anguilla" },
  { value: "AG", label: "Antigua and Barbuda" },
  { value: "AR", label: "Argentina" },
  { value: "AM", label: "Armenia" },
  { value: "AW", label: "Aruba" },
  { value: "AU", label: "Australia" },
  { value: "AT", label: "Austria" },
  { value: "AZ", label: "Azerbaijan" },
  { value: "BS", label: "Bahamas" },
  { value: "BH", label: "Bahrain" },
  { value: "BD", label: "Bangladesh" },
  { value: "BB", label: "Barbados" },
  { value: "BE", label: "Belgium" },
  { value: "BZ", label: "Belize" },
  { value: "BJ", label: "Benin" },
  { value: "BM", label: "Bermuda" },
  { value: "BT", label: "Bhutan" },
  { value: "BO", label: "Bolivia" },
  { value: "BA", label: "Bosnia and Herzegovina" },
  { value: "BW", label: "Botswana" },
  { value: "BV", label: "Bouvet Island" },
  { value: "BR", label: "Brazil" },
  { value: "IO", label: "British Indian Ocean Territory" },
  { value: "VG", label: "British Virgin Islands" },
  { value: "BN", label: "Brunei" },
  { value: "BG", label: "Bulgaria" },
  { value: "BF", label: "Burkina Faso" },
  { value: "BI", label: "Burundi" },
  { value: "KH", label: "Cambodia" },
  { value: "CM", label: "Cameroon" },
  { value: "CA", label: "Canada" },
  { value: "CV", label: "Cape Verde" },
  { value: "BQ", label: "Caribbean Netherlands (Bonaire, Sint Eustatius, Saba)" },
  { value: "KY", label: "Cayman Islands" },
  { value: "TD", label: "Chad" },
  { value: "CL", label: "Chile" },
  { value: "CN", label: "China" },
  { value: "CX", label: "Christmas Island" },
  { value: "CC", label: "Cocos Islands" },
  { value: "CO", label: "Colombia" },
  { value: "KM", label: "Comoros" },
  { value: "CK", label: "Cook Islands" },
  { value: "CR", label: "Costa Rica" },
  { value: "HR", label: "Croatia" },
  { value: "CW", label: "Curaçao" },
  { value: "CY", label: "Cyprus" },
  { value: "CZ", label: "Czechia (Czech Republic)" },
  { value: "CI", label: "Côte d'Ivoire (Ivory Coast)" },
  { value: "DK", label: "Denmark" },
  { value: "DJ", label: "Djibouti" },
  { value: "DM", label: "Dominica" },
  { value: "DO", label: "Dominican Republic" },
  { value: "EC", label: "Ecuador" },
  { value: "EG", label: "Egypt" },
  { value: "SV", label: "El Salvador" },
  { value: "GQ", label: "Equatorial Guinea" },
  { value: "ER", label: "Eritrea" },
  { value: "EE", label: "Estonia" },
  { value: "ET", label: "Ethiopia" },
  { value: "FK", label: "Falkland Islands" },
  { value: "FO", label: "Faroe Islands" },
  { value: "FJ", label: "Fiji" },
  { value: "FI", label: "Finland" },
  { value: "FR", label: "France" },
  { value: "GF", label: "French Guiana" },
  { value: "PF", label: "French Polynesia" },
  { value: "TF", label: "French Southern and Antarctic Lands" },
  { value: "GA", label: "Gabon" },
  { value: "GM", label: "Gambia" },
  { value: "GE", label: "Georgia" },
  { value: "DE", label: "Germany" },
  { value: "GH", label: "Ghana" },
  { value: "GI", label: "Gibraltar" },
  { value: "GR", label: "Greece" },
  { value: "GL", label: "Greenland" },
  { value: "GD", label: "Grenada" },
  { value: "GP", label: "Guadeloupe" },
  { value: "GU", label: "Guam" },
  { value: "GT", label: "Guatemala" },
  { value: "GG", label: "Guernsey" },
  { value: "GN", label: "Guinea" },
  { value: "GW", label: "Guinea-Bissau" },
  { value: "GY", label: "Guyana" },
  { value: "HM", label: "Heard Island and McDonald Islands" },
  { value: "VA", label: "Holy See/ Vatican City" },
  { value: "HN", label: "Honduras" },
  { value: "HK", label: "Hong Kong" },
  { value: "HU", label: "Hungary" },
  { value: "IS", label: "Iceland" },
  { value: "IN", label: "India" },
  { value: "ID", label: "Indonesia" },
  { value: "IQ", label: "Iraq" },
  { value: "IE", label: "Ireland" },
  { value: "IM", label: "Isle of Man" },
  { value: "IL", label: "Israel" },
  { value: "IT", label: "Italy" },
  { value: "JM", label: "Jamaica" },
  { value: "JP", label: "Japan" },
  { value: "JE", label: "Jersey" },
  { value: "JO", label: "Jordan" },
  { value: "KZ", label: "Kazakhstan" },
  { value: "KE", label: "Kenya" },
  { value: "KI", label: "Kiribati" },
  { value: "XK", label: "Kosovo" }, /* Error */
  { value: "KW", label: "Kuwait" },
  { value: "KG", label: "Kyrgyzstan" },
  { value: "LA", label: "Lao People's Democratic Republic (Laos)" },
  { value: "LV", label: "Latvia" },
  { value: "LB", label: "Lebanon" },
  { value: "LS", label: "Lesotho" },
  { value: "LR", label: "Liberia" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Lithuania" },
  { value: "LU", label: "Luxembourg" },
  { value: "MO", label: "Macao" },
  { value: "MK", label: "Macedonia" },
  { value: "MG", label: "Madagascar" },
  { value: "MW", label: "Malawi" },
  { value: "MY", label: "Malaysia" },
  { value: "MV", label: "Maldives" },
  { value: "MT", label: "Malta" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MQ", label: "Martinique" },
  { value: "MR", label: "Mauritania" },
  { value: "MU", label: "Mauritius" },
  { value: "YT", label: "Mayotte" },
  { value: "MX", label: "Mexico" },
  { value: "FM", label: "Micronesia" },
  { value: "MD", label: "Moldova" },
  { value: "MC", label: "Monaco" },
  { value: "MN", label: "Mongolia" },
  { value: "ME", label: "Montenegro" },
  { value: "MS", label: "Montserrat" },
  { value: "MA", label: "Morocco" },
  { value: "MZ", label: "Mozambique" },
  { value: "NA", label: "Namibia" },
  { value: "NR", label: "Nauru" },
  { value: "NP", label: "Nepal" },
  { value: "NL", label: "Netherlands" },
  { value: "NC", label: "New Caledonia" },
  { value: "NZ", label: "New Zealand" },
  { value: "NE", label: "Niger" },
  { value: "NG", label: "Nigeria" },
  { value: "NU", label: "Niue" },
  { value: "NF", label: "Norfolk Island" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "NO", label: "Norway" },
  { value: "OM", label: "Oman" },
  { value: "PK", label: "Pakistan" },
  { value: "PW", label: "Palau" },
  { value: "PS", label: "Palestinian territories" },
  { value: "PA", label: "Panama" },
  { value: "PG", label: "Papua New Guinea" },
  { value: "PY", label: "Paraguay" },
  { value: "PE", label: "Peru" },
  { value: "PH", label: "Philippines" },
  { value: "PN", label: "Pitcairn" },
  { value: "PL", label: "Poland" },
  { value: "PT", label: "Portugal" },
  { value: "PR", label: "Puerto Rico" },
  { value: "QA", label: "Qatar" },
  { value: "CG", label: "Republic of Congo" },
  { value: "RS", label: "Republic of Serbia" },
  { value: "RE", label: "Reunion" },
  { value: "RO", label: "Romania" },
  { value: "RW", label: "Rwanda" },
  { value: "BL", label: "Saint Barthélemy" }, /* Error */
  { value: "SH", label: "Saint Helena" },
  { value: "KN", label: "Saint Kitts and Nevis" },
  { value: "LC", label: "Saint Lucia" },
  { value: "MF", label: "Saint Martin" },
  { value: "PM", label: "Saint Pierre and Miquelon" },
  { value: "VC", label: "Saint Vincent and the Grenadines" },
  { value: "WS", label: "Samoa" },
  { value: "SM", label: "San Marino" },
  { value: "SA", label: "Saudi Arabia" },
  { value: "SN", label: "Senegal" },
  { value: "SC", label: "Seychelles" },
  { value: "SL", label: "Sierra Leone" },
  { value: "SG", label: "Singapore" },
  { value: "SX", label: "Sint Maarten" }, /* Error */
  { value: "SK", label: "Slovakia" },
  { value: "SI", label: "Slovenia" },
  { value: "SB", label: "Solomon Islands" },
  { value: "ZA", label: "South Africa" },
  { value: "GS", label: "South Georgia and the South Sandwich Islands" },
  { value: "KR", label: "South Korea" },
  { value: "ES", label: "Spain" },
  { value: "LK", label: "Sri Lanka" },
  { value: "SR", label: "Suriname" },
  { value: "SJ", label: "Svalbard and Jan Mayen" },
  { value: "SZ", label: "Swaziland" },
  { value: "SE", label: "Sweden" },
  { value: "CH", label: "Switzerland" },
  { value: "ST", label: "São Tomé and Príncipe" },
  { value: "TW", label: "Taiwan" },
  { value: "TJ", label: "Tajikistan" },
  { value: "TZ", label: "Tanzania" },
  { value: "TH", label: "Thailand" },
  { value: "TL", label: "Timor-Leste" },
  { value: "TG", label: "Togo" },
  { value: "TK", label: "Tokelau" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trinidad and Tobago" },
  { value: "TN", label: "Tunisia" },
  { value: "TR", label: "Turkey" },
  { value: "TM", label: "Turkmenistan" },
  { value: "TC", label: "Turks and Caicos Islands" },
  { value: "TV", label: "Tuvalu" },
  { value: "VI", label: "U.S. Virgin Islands" },
  { value: "UG", label: "Uganda" },
  { value: "UA", label: "Ukraine" },
  { value: "AE", label: "United Arab Emirates" },
  { value: "GB", label: "United Kingdom" },
  { value: "US", label: "United States" },
  { value: "UM", label: "United States Minor Outlying Islands" },
  { value: "UY", label: "Uruguay" },
  { value: "UZ", label: "Uzbekistan" },
  { value: "VU", label: "Vanuatu" },
  { value: "VN", label: "Vietnam" },
  { value: "WF", label: "Wallis and Futuna" },
  { value: "EH", label: "Western Sahara" },
  { value: "ZM", label: "Zambia" }
];

export const regions = [
  { value: "ABU DHABI", label: "Abu Dhabi" },
  { value: "DUBAI", label: "Dubai" },
  { value: "SHARJAH", label: "Sharjah" },
  { value: "AJMAN", label: "Ajman" },
  { value: "UMM AL QUWAIN", label: "Umm Al Quwain" },
  { value: "RAS AL KHAIMAH", label: "Ras Al Khaimah" },
  { value: "FUJAIRAH", label: "Fujairah" }
];

export const zipRegexMap = {
  AU: /^\d{4}$/,
  CA: /^[A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d$/,
  FR: /^\d{5}$/,
  DE: /^\d{5}$/,
  IN: /^\d{6}$/,
  IT: /^\d{5}$/,
  NL: /^\d{4}\s?[A-Za-z]{2}$/,
  ES: /^\d{5}$/,
  GB: /^[A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2}$/,
  US: /^\d{5}(-\d{4})?$/,
};

export const vatRegexMap = {
  AM: /^\d{8}$/,
  AU: /^(AU)?\d{11}$/,
  BS: /^\d{9}$/,
  BH: /^\d{15}$/,
  CA: /^(?:\d{10}TQ\d{4}|\d{7}|\d{9}[A-Z]{6})$/,
  CL: /^\d{2}\.\d{3}\.\d{3}-\d$/,
  CO: /^\d{10}$/,
  GE: /^(?:\d{9}|\d{11})$/,
  GH: /^(GHA)?\d{9}-\d$/,
  IS: /^(?:\d{5}|\d{6})$/,
  IN: /^(?:\d{2}[A-Z]{5}\d{4}[A-Z]\d{3}|\d{11}C)$/,
  ID: /^\d{15}$/,
  IM: /^(GB)?(?:\d{9}|\d{12}|GD\d{3}|HA5\d{2}|8888\d{3}GD|88885\d{2}HA)$/,
  JP: /^\d{13}$/,
  KZ: /^\d{12}$/,
  KE: /^[A-Z]\d{9}[A-Z]$/,
  LI: /^\d{5}$/,
  MY: /^\d{15}$/,
  MX: /^(?:[A-Z]{3}\d{6}[A-Z]{3}|[A-Z]{4}\d{6}[A-Z]{3})$/,
  MD: /^(?:\d{7}|\d{13})$/,
  NZ: /^(NZ)?\d{8,9}$/,
  NG: /^\d{8}-\d{4}$/,
  NO: /^\d{9}(?:MVA)?$/,
  OM: /^OM\d{10}$/,
  SA: /^\d{15}$/,
  SG: /^(?:[A-Z]\d{8}[A-Z]|\d{8}[A-Z]|\d{9}[A-Z]|\d{2}-\d{7}-\d)$/,
  ZA: /^(?:4\d{9}|3\d{10})$/,
  KR: /^\d{10}$/,
  SZ: /^(CHE)?\d{3}\.\d{3}\.\d{3} (?:MWST|TVA|IVA)$/,
  TW: /^\d{8}$/,
  TZ: /^\d{2}-\d{6}-[A-Z]$/,
  TH: /^\d{13}$/,
  TR: /^\d{10}$/,
  UG: /^\d{10}$/,
  AE: /^\d{15}$/,
  GB: /^(GB)?(?:\d{9}|\d{12}|GD\d{3}|HA5\d{2}|8888\d{3}GD|88885\d{2}HA)$/,
  UZ: /^(UZB)?\d{9}$/,
  VN: /^(?:\d{10}|\d{13})$/,
  AT: /^(AT)?U\d{8}$/,
  BE: /^(BE)?\d{9,10}$/,
  BG: /^(BG)?\d{9,10}$/,
  HR: /^(HR)?\d{11}$/,
  CY: /^(CY)?\d{8}[A-Z]$/,
  CZ: /^(CZ)?\d{8,10}$/,
  DK: /^(DK)?\d{8}$/,
  EE: /^(EE)?\d{9}$/,
  FI: /^(FI)?\d{8}$/,
  FR: /^(FR)?\d{9}$/,
  DE: /^(DE)?\d{9}$/,
  GR: /^(EL)?\d{9}$/,
  HU: /^(HU)?\d{8}$/,
  IE: /^(IE)?(?:\d{7}[A-Z]|\d{7}[A-Z]{2}W?|0[A-Z]\d{5}[A-Z]W?)$/,
  IT: /^(IT)?\d{11}$/,
  LV: /^(LV)?\d{11}$/,
  LT: /^(LT)?(?:\d{9}|\d{12})$/,
  LU: /^(LU)?\d{8}$/,
  MT: /^(MT)?\d{8}$/,
  NL: /^(NL)?\d{9}B\d{2}$/,
  PL: /^(PL)?\d{10}$/,
  PT: /^(PT)?\d{9}$/,
  RO: /^(RO)?\d{2,10}$/,
  RS: /^\d{9}$/,
  SK: /^(SK)?\d{10}$/,
  SI: /^(SI)?\d{8}$/,
  ES: /^(ES)?[A-Z]\d{7}[A-Z]$/,
  SE: /^(SE)?\d{12}$/
};

export function taxNumberName(countryCode) {
  switch (countryCode) {
    case "AU":
      return "ABN";
    case "IN":
    case "SG":
    case "NZ":
    case "CA":
      return "GST Number";
    case "JP":
      return "Company Number";
    // case "US":
    //   return "Tax Number";
    default:
      return "VAT Number";
  }
}
// gn = function(e) {
//   switch (e) {
//   case "AU":
//       return "ABN";
//   case "IN":
//   case "SG":
//   case "NZ":
//   case "CA":
//       return "GST Number";
//   case "JP":
//       return "Company Number";
//   default:
//       return "VAT number";
//   }
// }