import { useSetAtom } from "jotai";
import { splitGroupAtom, editingAtom, historyAtom, selectedAtom } from "store";
import s from "./Row.module.css";

import { ReactComponent as Icon } from "img/icon/devide.svg";


export default function SplitButton({id}) {
  const splitGroup = useSetAtom(splitGroupAtom);
  const setSelected = useSetAtom(selectedAtom);
  const setEditing = useSetAtom(editingAtom);
  const setHistory = useSetAtom(historyAtom);
  
  const onClick = (e) => {
    e.stopPropagation();
    const result = splitGroup(id);
    setHistory();
    setSelected(result.id);
    setEditing(true);
  }
  const onPointerDown = (e) => {
    e.stopPropagation();
  }

  return (
    <div className={s.splitWrapper}>
      <button className={s.splitButton} onPointerDown={onPointerDown} onClick={onClick} type="button" tabIndex={-1}>
        <Icon className={s.splitIcon} />
        <div className={s.splitPlaceholder} />
      </button>
      <div className={s.splitGuide}/>
    </div>
  )
}