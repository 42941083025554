import { ReactComponent as Check } from "img/icon/check.svg";
import s from "./Checkbox.module.css";


export default function Checkbox ({ children, id, onChange, value, disabled, secondary }) {
  const className = [
    s.wrapper, 
    secondary && s.secondary
  ].filter(Boolean).join(" ");
  return (
    <label className={className} htmlFor={id}>
      <input className={s.checkbox} type="checkbox" id={id} checked={value} onChange={onChange} disabled={disabled} />
      <div className={s.checkboxDummy}>
        <Check className={s.icon} />
      </div>
      <p className={s.label}>
        { children }
      </p>
    </label>
  )
}