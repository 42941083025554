import { atom } from "jotai";
import { v4 as uid } from "uuid";
import { keyBy, merge, isEqual, cloneDeep, find, findIndex, without, reject, isUndefined, mergeWith, cloneDeepWith, concat, map, reduce, filter } from "lodash";
import { getFormat, removeEmptyProps, removeEqualProps, setNewIds } from "utils";
import { FIELDS, LABEL_ALIGNMENT } from "./constants";
import { selectedAtom } from "./select";
import { mapAtom } from "./planner";
import { columnsAtom } from "./columns";

import { tasksAtom, updateTaskAtom } from "./tasks";
import { rowsAtom, updateRowAtom } from "./rows";
import { groupsAtom, updateGroupAtom } from "./groups";
import { milestonesAtom, updateMilestoneAtom } from "./milestones";


export const defaultTaskStyle = {
  borderColor: { a: 1, s: 0, v: 0, h: 0 },
  borderRadius: 0,
  background: { a: 1, s: 0, v: 0, h: 0 },
  borderWidth: 1,
  name: "Task",
  id: "task-00000000-0000-0000-0000-000000000000",
  borderShow: false,
  backgroundShow: true,
  height: 20,
  labels: []
}

export const defaultMilestoneStyle = {
  id: "milestone-00000000-0000-0000-0000-000000000000",
  name: "Milestone",
  borderColor: { a: 1, s: 0, v: 0, h: 0 },
  shape: "flag",
  size: 20,
  background: { a: 1, s: 0, v: 0, h: 0 },
  borderWidth: 1,
  baseline: 0,
  borderShow: false,
  backgroundShow: true,
  date: {
    color: { a: 1, s: 0, v: 0, h: 0 },
    textAlign: "after",
    underline: false,
    format: "dd mmm",
    show: true,
    fontSize: 10,
    bold: false,
    italic: false,
    textTransform: "none"
  },
  title: {
    color: { a: 1, s: 0, v: 0, h: 0 },
    textAlign: "after",
    underline: false,
    show: true,
    fontSize: 12,
    bold: false,
    italic: false,
    textTransform: "none"
  },
}

export const defaultRowStyle = {
  name: "Row",
  id: "row-00000000-0000-0000-0000-000000000000",
  backgroundShow: true,
  background: { a: 1, s: 0, v: 100, h: 0 },
  padding: 8,
  columns: {},
}

export const defaultGroupStyle = {
  name: "Main",
  id: "group-00000000-0000-0000-0000-000000000000",
  type: "heading",
  background: { a: 1, s: 0, v: 100, h: 0 },
  backgroundShow: true,
  padding: 8,
  bar: {
    borderColor: { a: 1, s: 0, v: 0, h: 0 },
    borderRadius: 0,
    background: { a: 1, s: 0, v: 0, h: 0 },
    borderWidth: 1,
    show: true,
    gapOpacity: 50,
    borderShow: false,
    backgroundShow: true,
    height: 20
  },
  columns: {},
}


export const [
  taskStylesAtom,
  taskStylesMapAtom,
  createTaskStyleAtom,
  editTaskStyleAtom,
  removeTaskStyleAtom,
  overrideTaskAtom,
  clearTaskOverridesAtom,
  applyTaskOverridesAtom
] = styleAtoms(tasksAtom, updateTaskAtom, defaultTaskStyle);

export const [
  createTaskLabelStyleAtom,
  removeTaskLabelStyleAtom,
  taskStylesLabelsMapAtom,
  labelsOrderAtom
] = labelStyleAtoms(tasksAtom, taskStylesAtom, taskStylesMapAtom);

export const [
  rowStylesAtom,
  rowStylesMapAtom,
  createRowStyleAtom,
  editRowStyleAtom,
  removeRowStyleAtom,
  overrideRowAtom,
  clearRowOverridesAtom,
  applyRowOverridesAtom
] = styleAtoms(rowsAtom, updateRowAtom, defaultRowStyle);

export const [
  addRowColumnStyleAtom,
  removeRowColumnStyleAtom
] = columnStyleAtoms(rowsAtom, rowStylesAtom, editRowStyleAtom);

export const [
  groupStylesAtom,
  groupStylesMapAtom,
  createGroupStyleAtom,
  editGroupStyleAtom,
  removeGroupStyleAtom,
  overrideGroupAtom,
  clearGroupOverridesAtom,
  applyGroupOverridesAtom
] = styleAtoms(groupsAtom, updateGroupAtom, defaultGroupStyle);

export const [
  addGroupColumnStyleAtom,
  removeGroupColumnStyleAtom
] = columnStyleAtoms(groupsAtom, groupStylesAtom, editGroupStyleAtom);

export const [
  milestoneStylesAtom,
  milestoneStylesMapAtom,
  createMilestoneStyleAtom,
  editMilestoneStyleAtom,
  removeMilestoneStyleAtom,
  overrideMilestoneAtom,
  clearMilestoneOverridesAtom,
  applyMilestoneOverridesAtom
] = styleAtoms(milestonesAtom, updateMilestoneAtom, defaultMilestoneStyle);



function styleAtoms(itemsAtom, updateItemAtom, defaultStyle) {
  const stylesAtom = atom([defaultStyle]);
  const stylesMapAtom = atom(
    get => keyBy(get(stylesAtom), "id")
  );

  // Styles
  const createStyleAtom = atom(null,
    (get, set) => {
      const map = get(mapAtom);
      const selected = get(selectedAtom);
      const target = map.get(selected[0]);
      const styles = get(stylesAtom);
      const source = get(stylesMapAtom)[target.style] || styles[0];
  
      // Copy style
      const style = cloneDeep(source);
      mergeWith(style, target.overrides, mergeLabels);
      setNewIds(style);
      const newStyles = [...styles, style];
      set(stylesAtom, newStyles);

      // Apply new style and clear overrides
      selected.forEach((id) => {
        const item = map.get(id);
        if (item.style === target.style && isEqual(item.overrides, target.overrides)) {
          set(updateItemAtom, { id, style: style.id });
          set(clearOverridesAtom, id);
        }
      });
      return style;
    }
  )
  
  const editStyleAtom = atom(null,
    (get, set, { id, index, ...props }) => {
      const styles = get(stylesAtom);
      const target = find(styles, { id });

      const style = cloneDeep(target);
      mergeWith(style, props, mergeLabels);
      const result = styles.map((el) => el === target ? style : el);

      if (!isUndefined(index)) {
        const from = findIndex(result, { id });
        result.splice(from, 1);
        result.splice(index, 0, style);
      }
      set(stylesAtom, result);
    }
  )
  
  const removeStyleAtom = atom(null,
    (get, set, upd) => {
      const styles = get(stylesAtom);
      const newStyles = reject(styles, { id: upd.id });
      const toStyle = upd.to || newStyles[0].id;

      filter(get(itemsAtom), { style: upd.id }).forEach(
        ({ id }) => set(updateItemAtom, { id, style: toStyle })
      );
      set(stylesAtom, newStyles);
    }
  )

  // Overrides
  const overrideAtom = atom(null,
    (get, set, props) => {
      const { id, ...newProps } = props;
      const target = get(mapAtom).get(id);
      const style = cloneDeepWith(
        get(stylesMapAtom)[target.style], keyLabels
      );
  
      const currentOverrides = cloneDeep(target.overrides) || {};
      // Remove dead labels overrides
      if (currentOverrides.labels) {
        Object.keys(currentOverrides.labels)
          .filter(key => !style.labels[key])
          .forEach(key => delete currentOverrides.labels[key]);
      }
      // Compose new overrides object
      const merged = merge(currentOverrides, newProps);
      const overrides = removeEqualProps(merged, style);
  
      // Apply overrides
      if (overrides) {
        set(updateItemAtom, { id, overrides });
      } else {
        set(clearOverridesAtom, id);
      }
      return overrides;
    }
  )
  
  const clearOverridesAtom = atom(null,
    (get, set, id) => {
      const target = get(mapAtom).get(id);
      const { overrides, ...newTarget } = target;
      const items = get(itemsAtom).map(item => item === target ? newTarget : item);
      set(itemsAtom, items);
    }
  )
  
  const applyOverridesAtom = atom(null,
    (get, set, id) => {
      const target = get(mapAtom).get(id);
      if (!target?.overrides) return;
      set(editStyleAtom, {
        id: target.style,
        ...target.overrides
      });
      set(clearOverridesAtom, id);
    }
  )

  return [
    stylesAtom,
    stylesMapAtom,
    createStyleAtom,
    editStyleAtom,
    removeStyleAtom,
    overrideAtom,
    clearOverridesAtom,
    applyOverridesAtom
  ]
}

function columnStyleAtoms(itemsAtom, stylesAtom, editStyleAtom) {
  const addColumnStyleAtom = atom(null,
    (get, set, id) => {
      // Find reference styles
      const refColumns = get(columnsAtom).filter(
        c => c.field !== FIELDS.TASKS && c.id !== id
      );
      const prevColumn = refColumns?.at(-1)?.id || null;

      // Compose and apply styles
      get(stylesAtom).forEach(targetStyle => {
        const defaultStyle = {
          show: true,
          color: { h: 0, s: 0, v: 0, a: 1 },
          fontSize: 12,
          textAlign: "left",
          bold: false,
          italic: false,
          underline: false,
          textTransform: "none",
        }
        const prevStyle = targetStyle.columns[prevColumn];
        const columns = { ...targetStyle.columns };
        columns[id] = { ...defaultStyle, ...prevStyle };
        set(editStyleAtom, { id: targetStyle.id, columns });
      })
    }
  )

  const removeColumnStyleAtom = atom(null,
    (get, set, id) => {
      // Update styles
      const styles = get(stylesAtom).map((style) => {
        const newStyle = cloneDeep(style);
        delete newStyle.columns[id];
        return newStyle;
      })
      set(stylesAtom, styles);

      // Clean up overrides
      const items = get(itemsAtom).map((target) => {
        if (!target.overrides?.columns?.[id]) {
          return target;
        } else {
          const newTarget = cloneDeep(target);
          delete newTarget.overrides.columns[id];
          return removeEmptyProps(newTarget);
        }
      });
      set(itemsAtom, items);
    }
  )

  return [
    addColumnStyleAtom,
    removeColumnStyleAtom
  ]
}

function labelStyleAtoms(itemsAtom, stylesAtom, stylesMapAtom) {
  const addLabelStyleAtom = atom(null,
    (get, set, upd) => {
      const { id, field } = upd;
      const styles = get(stylesAtom);
      const target = find(styles, { id });
      const targetLabels = target.labels;
      const prevLabel = targetLabels.at(-1) || {};
      const defaultLabel = {
        color: { h: 0, s: 0, v: 0, a: 1 },
        fontSize: 12,
        bold: false,
        italic: false,
        underline: false,
        textTransform: "none",
      }
      const label = {
        ...defaultLabel,
        ...prevLabel,
        id: "label-" + uid(),
        show: true,
        field,
        format: getFormat(field, prevLabel.format),
        alignment: getLabelAlignment(targetLabels)
      };
  
      const style = cloneDeep(target);
      style.labels.push(label);
      const result = styles.map(el => el === target ? style : el);
      set(stylesAtom, result);
    }
  )
  
  const removeLabelStyleAtom = atom(null,
    (get, set, id) => {
      // Update styles
      const styles = get(stylesAtom).map((style) => {
        const newStyle = cloneDeep(style);
        newStyle.labels = reject(style.labels, { id });
        return newStyle;
      })
      set(stylesAtom, styles);
  
      // Clean up overrides
      const items = get(itemsAtom).map((target) => {
        if (!target.overrides?.labels?.[id]) {
          return target;
        } else {
          const newTarget = cloneDeep(target);
          delete newTarget.overrides.labels[id];
          return removeEmptyProps(newTarget);
        }
      });
      set(itemsAtom, items);
    }
  )

  const stylesLabelsMap = atom((get) => (
    reduce(get(stylesMapAtom), (result, style, key) => {
      result[key] = {
        ...style,
        labels: keyBy(style.labels, "id")
      };
      return result;
    }, {})
  ))

  const labelsOrder = atom((get) => (
    concat( get(stylesAtom).map(s => map(s.labels, "id")) )
  ))
  return [
    addLabelStyleAtom,
    removeLabelStyleAtom,
    stylesLabelsMap,
    labelsOrder
  ]
}

function mergeLabels(objValue, srcValue, key) {
  if (key === "labels") {
    return objValue.map(label => (
      merge(label, srcValue[label.id])
    ))
  }
}

function keyLabels(value, key) {
  if (key === "labels") {
    return keyBy(value, "id");
  }
}

function getLabelAlignment(labels) {
  const alignmentOrder = [
    LABEL_ALIGNMENT.INSIDE_LEFT,
    LABEL_ALIGNMENT.INSIDE_RIGHT,
    LABEL_ALIGNMENT.OUTSIDE_LEFT,
    LABEL_ALIGNMENT.OUTSIDE_RIGHT,
    LABEL_ALIGNMENT.ABOVE_LEFT,
    LABEL_ALIGNMENT.ABOVE_RIGHT,
    LABEL_ALIGNMENT.BELOW_LEFT,
    LABEL_ALIGNMENT.BELOW_RIGHT,
    LABEL_ALIGNMENT.INSIDE_CENTER,
    LABEL_ALIGNMENT.ABOVE_CENTER,
    LABEL_ALIGNMENT.BELOW_CENTER,
  ];
  const alignments = labels.map(el => el.alignment);
  const next = without(alignmentOrder, ...alignments)[0];
  return next ?? alignmentOrder[0];
}