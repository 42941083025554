import { FIELDS } from 'store';
import { useStyleEdit } from 'utils';
import Section from '../../components/Section/Section';
import ColorPicker from '../../components/ColorPicker/ColorPicker';
import NumberInput from '../../components/NumberInput/NumberInput';
import TextTransform from '../../components/TextTransform';
import TextStyle from '../../components/TextStyle';
import AlignmentPicker from '../../components/AlignmentPicker/AlignmentPicker';
import FormatPicker from '../../components/FormatPicker/FormatPicker';
import s from "./Milestone.module.css";


export default function DateAppearance({ styles, editAtom }) {
  const getter = (style) => style.date;
  const setter = (key, val, { id }) => ({ id, date: { [key]: val } });
  const [
    p, valueChange, toggleChange, colorChange
  ] = useStyleEdit({ editAtom, styles, getter, setter });

  const toolbar = <FormatPicker value={p.format.mixed || p.format.value} field={FIELDS.START} onChange={valueChange("format")} />;

  return (
    <Section
      title="Date"
      toggle={p.show.value}
      toggleMixed={p.show.mixed}
      onToggle={toggleChange("show")}
      toolbar={toolbar}
    >
      {(p.show.value || p.show.mixed) && (
        <div className={s.wrapper}>
          <div className={s.row}>
            <ColorPicker
              title={"Color"}
              id={`ms-date-color`}
              color={p.color.value ?? { h: 0, s: 0, v: 0, a: 1 }}
              mixed={p.color.mixed}
              onChange={colorChange("color")}
            />
            <NumberInput
              title={"Font size"}
              id={`ms-date-font-size`}
              value={p.fontSize.value ?? ""}
              mixed={p.fontSize.mixed}
              onChange={valueChange("fontSize")}
              min={6}
              max={60}
            />
          </div>
          <div className={s.row}>
            <TextStyle
              bold={p.bold.value && !p.bold.mixed}
              italic={p.italic.value && !p.italic.mixed}
              underline={p.underline.value && !p.underline.mixed}
              toggleBold={toggleChange("bold")}
              toggleItalic={toggleChange("italic")}
              toggleUnderline={toggleChange("underline")}
            />
            <TextTransform
              value={p.textTransform.mixed ? null : p.textTransform.value}
              onChange={valueChange("textTransform")}
            />
          </div>
          <div className={s.alignment}>
            <AlignmentPicker
              value={p.textAlign.mixed ? null : p.textAlign.value}
              onChange={valueChange("textAlign")}
              prefix={`ms-date-alignment`}
              min
            />
          </div>
        </div>
      )}
    </Section>
  )
}