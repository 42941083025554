import { useEffect } from "react";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import { getUser, useDebounceCallback } from "utils";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { generateClient } from "aws-amplify/api";
import { uploadData } from "aws-amplify/storage";
import { updateTimeline } from "graphql/mutations";
import { userAtom, snapshotAtom, titleAtom, saveStateAtom, SAVE_STATUS, GUEST_USER } from "store";
import { Button } from "components";
import riveSaveIcon from "img/icon/save.riv";
import s from "./Header.module.css";


export default function Save() {
  const user = useAtomValue(userAtom);
  const title = useAtomValue(titleAtom);
  const getData = useSetAtom( snapshotAtom );
  const [saveState, setSaveState] = useAtom(saveStateAtom);
  const { timelineId } = useParams();
  const blocker = useBlocker(saveState !== SAVE_STATUS.SAVED);
  const navigate = useNavigate();

  const disabled = [SAVE_STATUS.SAVING, SAVE_STATUS.SAVED].includes(saveState);

  const saveExternally = async () => {
    if (disabled) return;
    try {
      setSaveState(SAVE_STATUS.SAVING);
      const user = await getUser();
      if (user === GUEST_USER) {
        setSaveState(SAVE_STATUS.SAVED);
        navigate("/login");
        return;
      }
      const content = getData();
      const size = content.tasks.length + content.milestones.length;
      const client = generateClient();
      const dataUpdate = uploadData({
        path: ({identityId}) => `private/${identityId}/timeline/${timelineId}.json`,
        data: JSON.stringify(content),
        options: { contentType: "application/json" }
      }).result;
      const metaUpdate = client.graphql({
        query: updateTimeline,
        variables: {
          input: {
            id: timelineId,
            title: title,
            size: size,
          }
        }
      });
      await Promise.all([dataUpdate, metaUpdate]);
      setSaveState(SAVE_STATUS.SAVED);
    } catch(err) {
      console.log(err);
      setSaveState(SAVE_STATUS.REQUIRED);
    }
  }
  const saveLocally = () => {
    if (disabled) return;
    setSaveState(SAVE_STATUS.SAVING);
    const content = getData();
    content.title = title;
    sessionStorage.setItem("tempProject", JSON.stringify(content));
    setSaveState(SAVE_STATUS.SAVED);
    blocker.proceed?.();
  }
  const temp = user === GUEST_USER;
  const save = temp ? saveLocally : saveExternally
  const [ debounceSave, saveNow ] = useDebounceCallback( save, temp ? 100 : 8000 );

  useEffect(() => {
    if (saveState === SAVE_STATUS.SAVED) {
      blocker.proceed?.();
    }
  }, [saveState, blocker])
  // Schedule save on change
  useEffect(() => {
    if ( saveState !== SAVE_STATUS.REQUIRED ) return;
    setSaveState(SAVE_STATUS.QUEUED)
    debounceSave();
    // console.log("Queued");
  }, [ saveState, debounceSave, setSaveState ] );

  // Prevent external navigation / refresh / close without saving
  useEffect(() => {
    window.onbeforeunload = (e) => {
      if ( saveState !== SAVE_STATUS.SAVED ) {
        e.preventDefault();
        saveNow();
        return true;
      };
    };
    return () => window.onbeforeunload = undefined;
  }, [ saveState, saveNow ]);

  // Prevent internal navigation without saving
  useEffect( () => {
    if ( blocker.state === "blocked" ) {
      saveNow();
    }
  }, [ blocker, saveNow ]);


  return temp ? null : (
    <Button className={s.button} label={labels[saveState]} iconOnly rive={riveSaveIcon} riveState={ states[saveState] } tooltip={labels[saveState]} onClick={saveNow} />
  )
}


const labels = {
  [SAVE_STATUS.REQUIRED]: "Save",
  [SAVE_STATUS.QUEUED]: "Save",
  [SAVE_STATUS.SAVING]: "Saving...",
  [SAVE_STATUS.SAVED]: "Saved",
}

const states = {
  [SAVE_STATUS.REQUIRED]: 0,
  [SAVE_STATUS.QUEUED]: 0,
  [SAVE_STATUS.SAVING]: 1,
  [SAVE_STATUS.SAVED]: 2,
}