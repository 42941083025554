import { useAtom } from "jotai";
import { editModeAtom, EDIT_MODES } from "store";
import Header from "../Header/Header";
import { Segmented } from "components";
import { ReactComponent as EditGlobal } from "img/icon/edit-global.svg";
import { ReactComponent as EditStyle } from "img/icon/edit-style.svg";
import { ReactComponent as EditElement } from "img/icon/edit-element.svg";


const modeLabels = {
  [EDIT_MODES.GLOBAL]: "Global editing",
  [EDIT_MODES.STYLE]: "Style editing",
  [EDIT_MODES.ELEMENT]: "Element editing",
}
export default function EditMode() {
  const [editMode, setEditMode] = useAtom(editModeAtom);
  return (
    <Header title={modeLabels[editMode]} >
      <Segmented
        value={editMode}
        onChange={setEditMode}
        options={[
          {
            label: "Element",
            value: EDIT_MODES.ELEMENT,
            icon: EditElement,
          }, {
            label: "Style",
            value: EDIT_MODES.STYLE,
            icon: EditStyle,
          }, {
            label: "Global",
            value: EDIT_MODES.GLOBAL,
            icon: EditGlobal,
          }
        ]}
      />
    </Header>
  )
}