import { useRef, useState } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { createRowAtom, createTaskAtom, createMilestoneAtom, editingAtom, historyAtom, isShowTooltipAtom, selectedAtom, snappedDatesAtom, timeSnapshotAtom, rowStylesAtom, toolAtom, timelineColumnIndexAtom, TOOLS } from "store";
import { vxDate, useSetAtomLater, useSnap } from "utils";
import Task from "../Task/Task";
import Milestone from "../Milestone/Milestone";

import s from "./TaskCreator.module.css";


function TaskCreator() {
  const [startDate, setStartDate] = useState(null);
  const [key, setKey] = useState(0);
  const [, setX] = useState(0);
  const getTimeSnapshot = useSetAtom(timeSnapshotAtom);
  const createRow = useSetAtom(createRowAtom);
  const createTask = useSetAtom(createTaskAtom);
  const createMilestone = useSetAtom(createMilestoneAtom);
  const setHistory = useSetAtom(historyAtom);
  const setShowTooltip = useSetAtom(isShowTooltipAtom);
  const setSnappedDates = useSetAtomLater(snappedDatesAtom);
  const setSelected = useSetAtom(selectedAtom);
  const setEditing = useSetAtom(editingAtom);
  const timelineIndex = useAtomValue(timelineColumnIndexAtom);
  const styleId = useAtomValue(rowStylesAtom)[0]?.id;
  const tool = useAtomValue(toolAtom);
  const snap = useSnap();

  const isDisabled = ![TOOLS.TASK, TOOLS.MILESTONE].includes(tool);
  const justCreated = useRef(false);
  const capture = useRef(false);

  const onClick = (e) => {
    e.stopPropagation();
    if (justCreated.current) {
      justCreated.current = false;
      return;
    } else {
      setEditing(false);
      setSelected(null);
    }
  }
  
  const onPointerDown = (e) => {
    if (isDisabled) return;
    capture.current = true;
    e.target.setPointerCapture(e.pointerId);
    const {val} = snap(getTimeSnapshot());
    setStartDate(val);
    setSnappedDates(val);
    setShowTooltip(false);
  }

  const onPointerMove = (e) => {
    if (!capture.current) return;
    setX(e.clientX);
  }

  const onPointerUp = () => {
    if (!capture.current) return;
    applyChanges();
    resetChanges();
  }

  const resetChanges = () => {
    capture.current = null;
    setStartDate(null);
    setSnappedDates(null);
    setShowTooltip(true);
  }
  const applyChanges = () => {
    let id;
    const endDate = snap(getTimeSnapshot()).val;
    if (tool === TOOLS.TASK) {
      if (startDate === endDate) return;
      const [start, end] = vxDate.sort(startDate, endDate);
      const row = createRow()?.id;
      id = createTask({ start, end, row })?.id;
    } else if (tool === TOOLS.MILESTONE) {
      const date = vxDate.round(endDate);
      const row = createRow().id;
      id = createMilestone({ date, row })?.id;
    }
    if (!id) return;
    setSelected(id);
    setHistory();
    justCreated.current = true;
    setKey(key+1);
  };

  const endDate = snap(getTimeSnapshot()).val;
  if (startDate) { setSnappedDates(endDate); }

  const className = [
    s.wrapper,
    key && s.animate,
    isDisabled && s.disable,
    styleId,
  ].filter(Boolean).join(" ");

  return (
    <div
      className={className}
      onClick={onClick}
      onPointerDown={onPointerDown}
      onPointerMove={onPointerMove}
      onPointerUp={onPointerUp}
      onLostPointerCapture={onPointerUp}
    >      
      <div className={s.tile} />
      <div key={key} className={s.row} style={{gridColumn: `${timelineIndex + 1}/${timelineIndex + 2}`}} >
        {startDate && tool === TOOLS.TASK &&  (
          <Task
            start={startDate}
            end={endDate}
            temp={true}
          />
        )}
        {startDate && tool === TOOLS.MILESTONE &&  (
          <Milestone
            date={endDate}
            temp={true}
          />
        )}
      </div>
    </div>
  )
}

export default TaskCreator;