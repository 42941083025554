import { atom } from "jotai";
import { mapAtom, SELECTABLE, groupsAtom, parentsMapAtom } from ".";
import { uniqueValues } from "utils";


const _selectedAtom = atom([]);
const _selectedTypeAtom = atom(SELECTABLE.DOCUMENT);

export const allSelectedAtom = atom(
  (get) => {
    const map = get(mapAtom);
    const targetType = get(_selectedTypeAtom);
    const selected = get(selectedAtom);

    if (
      targetType === SELECTABLE.HEADER ||
      targetType === SELECTABLE.FOOTER ||
      targetType === SELECTABLE.DOCUMENT
    ) {
      return [];
    }

    function drillDown(id) {
      const children = map.get(id).children;
      const result = [id];
      if (children) {
        result.push(
          ...children.map(child => drillDown(child)).flat()
        )
      }
      return children ? [children, children.map(child => drillDown(child))].flat() : [];
    }
    // function lookUp(id) {
    //   const parent = parents[id]?.id;
    //   return parent ? [parent, ...lookUp(parent)] : [];
    // }
    if (targetType === SELECTABLE.ROW) {
      return selected.map(id => [...drillDown(id)]).flat(Infinity).filter(uniqueValues);
    } else {
      return selected;
    }
    // return selected.map(id => [id, ...lookUp(id), ...drillDown(id)]).flat(Infinity).filter(uniqueValues);
  }
)

export const selectedAtom = atom(
  (get) => {
    const map = get(mapAtom);
    const selected = get(_selectedAtom);
    const parents = get(parentsMapAtom);
    const targetType = get(_selectedTypeAtom);

    if (
      targetType === SELECTABLE.HEADER ||
      targetType === SELECTABLE.FOOTER ||
      targetType === SELECTABLE.DOCUMENT
    ) {
      return selected;
    }

    const typeMapping = { [SELECTABLE.GROUP]: 2, [SELECTABLE.ROW]: 1, [SELECTABLE.TASK]: 0, [SELECTABLE.MILESTONE]: 0 };

    function convertSelection( toLvl, ids ) {
      return ids.reduce( (acc, id) => {
        const el = map.get(id);
        if (!el) return acc;
        const elementType = el.type;
        const lvl = typeMapping[elementType];
        if ( lvl > toLvl ) {
          const children = el.children;
          acc.push(
            ...convertSelection(toLvl, children)
          );
        } else if ( lvl < toLvl ) {
          const parent = parents[id]?.id;
          acc.push(
            ...convertSelection(toLvl, [parent])
          );
        } else if (elementType === targetType ) {
          acc.push(id);
        }
        return acc;
      }, [] );
    }

    return convertSelection( typeMapping[targetType], selected ).filter( id => id !== "root" );
  },
  (get, set, upd) => {
    if ( !upd || !upd.length ) {
      set(selectedTypeAtom, SELECTABLE.DOCUMENT);
      set(_selectedAtom, []);
      return;
    }

    const selected = Array.isArray(upd) ? upd : [upd];
    
    if (selected[0] === SELECTABLE.HEADER || selected[0] === SELECTABLE.FOOTER) {
      set(selectedTypeAtom, selected[0]);
      set(_selectedAtom, selected);
      return;
    }
    
    const lvlMapping = [ SELECTABLE.MILESTONE, SELECTABLE.TASK, SELECTABLE.ROW, SELECTABLE.GROUP ];
    const map = get(mapAtom);
    const lvl = selected.reduce( (acc, id) => Math.max( lvlMapping.indexOf(map.get(id).type), acc ), 0 );
    set(selectedTypeAtom, lvlMapping[lvl]);
    set(_selectedAtom, selected);
  }
)

export const selectedTypeAtom = atom(
  (get) => {
    const selected = get(selectedAtom);
    if (!selected || !selected.length) {
      return SELECTABLE.DOCUMENT;
    } else {
      return get(_selectedTypeAtom);
    }
  },
  (get, set, upd) => {
    set(_selectedTypeAtom, upd);
  }
)

const selectableAtom = atom( get => {
  const map = get(mapAtom);
  const type = get(selectedTypeAtom);
  switch ( type ) {
    case SELECTABLE.GROUP :
      return get(groupsAtom).map(
        el => el.id
      ).filter(
        el => el !== "root"
      );
    case SELECTABLE.ROW :
      return (
        get(groupsAtom).reduce(
          (acc, el) => acc.concat(el.children), []
        )
      );
    case SELECTABLE.TASK :
    case SELECTABLE.MILESTONE :
      return (
        get(groupsAtom).reduce(
          (acc, el) => acc.concat(el.children), []
        ).map(
          rowId => map.get(rowId).children
        ).reduce(
          (acc, children) => acc.concat(
            [...children].sort(
              (a, b) => ((map.get(a).start ?? map.get(a).date) - (map.get(b).start ?? map.get(b).date))
            )
          ), []
        )
      );
    default : return null;
  }
})

export const selectNextAtom = atom( null,
  (get, set) => {
    const selected = get(selectedAtom);
    const selectable = get(selectableAtom);
    if (!selectable) {
      return;
    } else if (selected.length === 0) {
      set(selectedAtom, selectable[0]);
    } else if (selected.length > 1) {
      set(selectedAtom, selected[0]);
    } else {
      const index = selectable.indexOf(selected[0]);
      const newIndex = (index + 1) % selectable.length;
      set(selectedAtom, selectable[newIndex]);
    }
  }
)
export const selectPrevAtom = atom( null,
  (get, set) => {
    const selected = get(selectedAtom);
    const selectable = get(selectableAtom);
    if (!selectable) {
      return;
    } else if (selected.length === 0) {
      set(selectedAtom, selectable[0]);
    } else if (selected.length > 1) {
      set(selectedAtom, selected.at(-1));
    } else {
      const index = Math.max(selectable.indexOf(selected[0]), 0);
      const newIndex = (index - 1 + selectable.length) % selectable.length;
      set(selectedAtom, selectable[newIndex]);
    }
  }
)

export const addSelectedAtom = atom( null, 
  (get, set, val) => {
    const type = get(selectedTypeAtom);
    const map = get(mapAtom);
    const selected = get(selectedAtom);
    const result = [...selected];
    const elements = Array.isArray(val) ? val : [val];
    elements.forEach(id => {
      if (map.get(id).type !== type) return;
      const index = selected.indexOf(id);
      if (index === -1) {
        result.push(id);
      } else {
        result.splice(index, 1);
      }
    })
    set(_selectedAtom, result);
  }
)