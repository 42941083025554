import { useCallback, useState } from "react";
import { fetchUserAttributes, updateUserAttribute } from "aws-amplify/auth";
import { useAtom, useAtomValue } from "jotai";
import { userAtom } from "store";
import { Helmet } from "react-helmet";
import { Checkbox } from "components";
import EmailInput from "./EmailInput";
import NameInput from "./NameInput";
import Toast from "./Toast";
import PasswordInput from "./PasswordInput";
import s from "./General.module.css";


export default function General() {
  const user = useAtomValue(userAtom);
  const [message, setMessage] = useState(null);
  const clearMessage = useCallback(() => setMessage(null), []);


  return (
    <>
      <Helmet title="Settings" />
      <Toast value={message} onTimeout={clearMessage} />
      <div className={s.section}>
        <h1>Personal</h1>
        <NameInput user={user} value={user.name} setMessage={setMessage} />
        <EmailInput user={user} value={user.email} setMessage={setMessage} />
        <PasswordInput user={user} email={user.email} setMessage={setMessage} />
      </div>
      <div className={s.section}>
        <h1>Notifications</h1>
        <SubscriptionInput />
      </div>
    </>
  )
}

function SubscriptionInput() {
  const [user, setUser] = useAtom(userAtom);
  const [busy, setBusy] = useState(false);
  const value = JSON.parse(user["custom:subscription"] ?? "true");

  const onChange = () => {
    if (busy) return;
    setBusy(true);
    updateUserAttribute({
      userAttribute: {
        attributeKey: "custom:subscription",
        value: JSON.stringify(!value)
      }
    })
      .then(fetchUserAttributes)
      .then(setUser)
      .catch(console.log)
      .finally(() => setBusy(false));
  }
  return (
    <Checkbox
      value={value}
      onChange={onChange}
      disabled={busy}
    >
      Send me occasional emails with tips and updates
    </Checkbox>
  )
}