import { clamp, sumBy } from "lodash";
import { FIELDS, TIMELINE_UNITS, TIMELINE_FORMATS } from "store";
import { numFormat, rangeFTE, taskCost, vxDate } from "utils";

export function getTimelineUnits(minDate, maxDate, timeScale, props) {
  const result = [];

  const step = getStep(props.scale, minDate, maxDate);
  const getValue = formatter(props.field, props.format, step, props.tasks, props.team, props.preferences);

  const unitStart = new Date(minDate);
  unitStart.setUTCMonth(0, 1);

  const unitEnd = new Date(minDate);
  unitEnd.setUTCMonth(step, 1);

  while (unitStart < maxDate) {
    const start = Math.max(unitStart, minDate);
    const end = Math.min(unitEnd, maxDate);

    const key = unitStart.valueOf();
    const x = (start - minDate) / timeScale;
    const width = (end - start) / timeScale;
    const value = getValue(unitStart, unitEnd);

    if (width > 0) {
      result.push({ key, x, width, value });
    }

    unitStart.setUTCMonth(unitStart.getUTCMonth() + step);
    unitEnd.setUTCMonth(unitEnd.getUTCMonth() + step);
  }

  return result;
}

function getStep(scale, minDate, maxDate) {
  switch (scale) {
    case TIMELINE_UNITS.YEARS:
      return 12;
    case TIMELINE_UNITS.QUARTERS:
      return 3;
    case TIMELINE_UNITS.MONTHS:
      return 1;
    default:
      const yearSize = 1000 * 60 * 60 * 24 * 365;
      const delta = maxDate - minDate;
      if (delta < yearSize) {
        return 1;
      } else if (delta < yearSize * 3) {
        return 3;
      } else {
        return 12;
      }
  }
}

function formatter(field, rawFormat, step, tasks, team, preferences) {
  const format = getTierFormat(FIELDS.TIMELINE, rawFormat);
  switch (field) {
    case FIELDS.TIMELINE:
      return (start) => String(rangeName(start, step, format));
    case FIELDS.COST:
      return (start, end) => String(rangeCost(start, end, tasks, team, preferences));
    case FIELDS.FTE:
      return (start, end) => String(rangeFTE(start, end, tasks, preferences.workdays));
    default:
      return () => "";
  }
}

function rangeName(date, step, format) {
  switch (step) {
    case 12:
      return date.getUTCFullYear();
    case 3:
      return `Q${date.getUTCMonth() / 3 + 1}`;
    case 1:
      return vxDate.format(date, format);
    default:
      return "Error";
  }
}

function rangeCost(startDate, endDate, tasks = [], team = [], preferences) {
  const cost = sumBy(tasks, (t) => {
    const start = clamp(t.start, startDate, endDate);
    const end = clamp(t.end, startDate, endDate);
    return taskCost(start, end, t.workgroup, team, preferences.workdays);
  });
  const placement = preferences.currencyPlacement;
  const sign = preferences.currencySign;
  const prefix = placement === "prefix" ? sign : "";
  const suffix = placement === "suffix" ? sign : "";
  return `${prefix}${numFormat(cost)}${suffix}`;
}

export function getTierFormat(field, prevFormat) {
  if (field === FIELDS.TIMELINE) {
    return TIMELINE_FORMATS.find(el => el === prevFormat) || TIMELINE_FORMATS[0];
  } else {
    return "";
  }
}