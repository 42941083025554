import { useState } from "react"
import { Helmet } from "react-helmet";
import { signIn } from "aws-amplify/auth";
import { getUser, saveTemp } from "utils";
import { EMAIL_REGEXP, GUEST_USER } from "store";
import { Link, redirect, useLocation, useNavigate } from "react-router-dom";
import { Button, Input, ModalMessage } from "components";
import { ReactComponent as Logo } from "img/logo.svg";

import s from "./authorization.module.css";


export default function SignIn() {
  const locationState = useLocation().state;
  const navigate = useNavigate();
  const [ sending, setSending ] = useState(false);
  const [ email, setEmail ] = useState(locationState?.email ?? "");
  const [ password, setPassword ] = useState("");
  const [ error, _setError ] = useState("");
  const [ confirmation, setConfirmation ] = useState(locationState?.confirmation ?? "");
  const state = { name: locationState?.name, email, from: "/login" };

  const setError = (val) => {
    setConfirmation(null);
    _setError(val);
  }

  const handleEmail = e => {
    if ( EMAIL_ERRORS.includes(error)) {
      setError(null);
    }
    setEmail(e.target.value);
  }
  const handlePassword = e => {
    if ( PASSWORD_ERRORS.includes(error)) {
      setError(null);
    }
    setPassword(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (sending) return;
    if (!email) {
      setError("NoEmail");
      return;
    } else if ( !email.match(EMAIL_REGEXP)) {
      setError("NotEmail");
      return;
    } else if (!password) {
      setError("NoPassword");
      return;
    } else if (password.length < 6) {
      setError("ShortPassword");
      return;
    }
    setSending(true);
    signIn({ username: email, password })
    .then( response => {
      if (response?.nextStep?.signInStep === "CONFIRM_SIGN_UP") {
        navigate("/verify-email", { state });
      } else {
        saveTemp()
        .then(() => navigate("/"))
      }
    })
    .catch( error => {
      console.log(error);
      setError(error.name)
    })
    .finally(() => setSending(false))
  }

  const options = {
    NoEmail : "Email is required.",
    NotEmail : "Email seems to be incorrect. Please check for typos.",
    NoPassword : "Password is required.",
    ShortPassword : "Password has to be at least 6 characters long.",
    UserNotFoundException : () => <p>User not found. You probably want to <Link to="/signup" className={s.link} state={state}>create account</Link>.</p>,
    NotAuthorizedException : () => <p>Incorrect password. Try again or <Link to="/reset-password" className={s.link} state={state}>reset password</Link>.</p>,
    TooManyRequestsException : "Sorry, we have received too many requests from your IP address, please wait a few minutes before trying again.",
    default : "We are sorry, but it seems there is a problem on our side. Please try again later."
  }

  return (
    <Wrapper>
      <Helmet title="Log in" />
      <h1 className={s.title}>Log in to Deltaplan</h1>
      { confirmation && <div className={s.success}><p>{confirmation}</p></div>}
      <form className={s.form} onSubmit={handleSubmit}>
        <div>
          <Input
            id={"sign-in-email"}
            label={"Email"}
            type={"email"}
            value={email}
            autoComplete={"email"}
            required
            onChange={handleEmail}
          />
        </div>
        <div>
          <Input
            id={"sign-in-password"}
            label={"Password"}
            type={"password"}
            value={password}
            autoComplete={"current-password"}
            required
            minLength={6}
            onChange={handlePassword}
          />
        </div>
        <ModalMessage value={error} options={options} />
        <Button className={s.submit} disabled={sending} label="Log in" />
      </form>
      <div className={s.footer}>
        <p className={s.footerRow}><Link to="/reset-password" className={s.link} state={state}>Reset password</Link></p>
        <p className={s.footerRow}>New to Deltaplan? <Link to="/signup" className={s.link} state={state}>Create an account</Link></p>
      </div>
    </Wrapper>
  )
}

const PASSWORD_ERRORS = [ "NoPassword", "ShortPassword", "NotAuthorizedException" ];
const EMAIL_ERRORS = [ "NoEmail", "NotEmail", "UserNotFoundException" ];

export function Wrapper({ children }) {
  return (
    <div className={s.pageWrapper}>
      <div className={s.poster} />
      <div className={s.main}>
        <div className={s.wrapper}>
          <a href="https://deltaplan.io" className={s.logoWrapper}>
            <Logo className={s.logo} />
          </a>
          {children}
        </div>
      </div>
    </div>
  )
}


export async function loader() {
  const user = await getUser();
  if (user !== GUEST_USER) {
    return redirect("/");
  } else {
    return null;
  }
}