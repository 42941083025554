import { useSetAtom } from "jotai";
import { selectedAtom } from "store";
import { Button }   from "components";
import Section      from "../../components/Section/Section";

import { ReactComponent as Arrow } from "img/icon/go-to.svg";

export default function TiersConfig() {
  const setSelected = useSetAtom(selectedAtom);
  const onHeaderClick = () => setSelected("header");
  const onFooterClick = () => setSelected("footer");
  return (
    <Section title={"Scales"}>
      <Button listButton label={"Header"} icon={Arrow} onClick={onHeaderClick} />
      <Button listButton label={"Footer"} icon={Arrow} onClick={onFooterClick} />
    </Section>
  )
}