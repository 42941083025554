import { useState, useEffect, useLayoutEffect, useRef, useCallback } from "react";
import { useRive, useStateMachineInput } from "@rive-app/react-canvas";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { fetchUserAttributes, updateUserAttribute } from "aws-amplify/auth";
import { snapshotAtom, titleAtom, isPaidUserAtom, callToUpgradeModalAtom, userAtom, FREE_ELEMENTS_LIMIT } from "store";
import { Button, Checkbox, CheckboxGroup, Modal, Segmented } from "components";
import { ModalButton, ModalFooter } from "components/Modal";
import { post } from 'aws-amplify/api';
import { getExcelConfig, writeFileToBrowser } from "utils";
import { isEqual } from "lodash";
import icons_url from "img/icon/file_types.riv";
import { ReactComponent as download } from "img/icon/download.svg";
import { ReactComponent as Adjust } from "img/icon/adjust.svg";
// import { getPpt, getSvg, getPng} from "./Download/paintTimeline";
// import getJson from "./Download/getJson";

import s from "./Download.module.css";


export default function Download() {
  const [modal, setModal] = useState(null);
  const [lastModal, setLastModal] = useState(null);
  const [icon, setIcon] = useState(false);

  useLayoutEffect(() => {
    if (!modal) return;
    setLastModal(modal);
  }, [modal]);

  useEffect(() => {
    fetch(icons_url)
      .then(res => res.arrayBuffer())
      .then(setIcon)
      .catch(console.log)
  }, []);

  const openDownloadModal = () => setModal("download");
  const openExcelModal = () => setModal("excel");
  const closeModal = () => setModal(null);

  const title = lastModal === "download" ? "Download timeline" : "Excel configuration";
  return (
    <>
      <Button className={s.button} icon={download} label={"Download"} onClick={openDownloadModal} />
      <Modal title={title} isOpen={modal} close={closeModal} className={s.modal}>
        {lastModal === "download" ? (
          <DownloadModal icon={icon} close={closeModal} excelConfig={openExcelModal} />
        ) : (
          <ExcelConfigModal close={closeModal} back={openDownloadModal} />
        )}
      </Modal>
    </>
  )
}


function DownloadModal({ icon, close, excelConfig }) {
  // const getData = useSetAtom(snapshotAtom);
  // const json = () => {
  //   const data = getData();
  //   getJson(data);
  // }
  const sharedProps = { icon, close };

  return (
    <>
      <div className={s.wrapper}>
        <Option {...sharedProps} label={"PowerPoint"} format={"pptx"} />
        <Option {...sharedProps} label={"SVG"} format={"svg"} />
        <Option {...sharedProps} label={"PNG"} format={"png"} />
        <Option {...sharedProps} label={"Excel"} format={"xlsx"} pro config={excelConfig} />
      </div>
      <ModalFooter>
        {/* <ModalButton onClick={json}>Get JSON</ModalButton> */}
        <ModalButton onClick={close}>Close</ModalButton>
      </ModalFooter>
    </>
  );
}

function ExcelConfigModal({ back, close }) {
  const title = useAtomValue(titleAtom);
  const [busy, setBusy] = useState(false);
  const [user, setUser] = useAtom(userAtom);
  const [config, setConfig] = useState(getExcelConfig(user));
  const timeout = useRef();
  const callToUpgrade = useSetAtom(callToUpgradeModalAtom);
  const getData = useSetAtom(snapshotAtom);

  const saveConfig = useCallback(async () => {
    clearTimeout(timeout.current);
    const currentConfig = getExcelConfig(user);
    if (!timeout.current || isEqual(config, currentConfig)) return;

    try {
      await updateUserAttribute({
        userAttribute: {
          attributeKey: "custom:excelConfig",
          value: JSON.stringify(config)
        }
      });
      const newUser = await fetchUserAttributes();
      setUser(newUser);
      timeout.current = null;
    } catch (error) {
      console.log(error);
    }
  }, [config, user, setUser])

  useEffect(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(saveConfig, 1000);
  }, [saveConfig]);


  const onTypesChange = (val) => {
    setConfig(
      { ...config, types: val }
    )
  }
  const onSeparateSheetsChange = () => {
    setConfig(
      { ...config, separateSheets: !config.separateSheets }
    )
  }
  const onHeadingRowChange = () => {
    setConfig(
      { ...config, headingRow: !config.headingRow }
    )
  }
  const onFieldChange = (val) => {
    setConfig(
      { ...config, fields: val.map(op => (op.disabled ? op.checked : op.value) && op.name).filter(Boolean) }
    );
  }

  const milestoneFields = ["type", "group", "row", "title", "startDate"];
  const fieldsOptions = [
    { label: "Type", name: "type" },
    { label: "Group", name: "group" },
    { label: "Row", name: "row" },
    { label: "Title", name: "title" },
    { label: "Start date", name: "startDate" },
    { label: "End date", name: "endDate" },
    { label: "Duration", name: "duration" },
    { label: "Assignee", name: "assignee" },
    { label: "Rate", name: "rate" },
    { label: "Allocation", name: "allocation" },
    { label: "Cost", name: "cost" },
  ].map(op => {
    const disabled = config.types === "milestones" && !milestoneFields.includes(op.name);
    const checked = config.fields.includes(op.name);
    return {
      ...op,
      value: !disabled && checked,
      checked: checked,
      disabled: disabled,
    }
  });

  const download = async () => {
    if (busy) return;
    setBusy(true);
    try {
      await saveConfig();
      const data = getData();
      await timelineDownload({ data, title, format: "xlsx" });
      close();
    } catch (error) {
      console.log(error);
      setBusy(false);
      if (error._response.statusCode === 403) {
        callToUpgrade(true);
        close();
      }
    }
  }

  return (
    <>
      <form className={s.configWrapper}>
        <Row label="Elements">
          <Segmented value={config.types} onChange={onTypesChange} showLabels options={[
            { label: "All", value: "both" },
            { label: "Tasks", value: "tasks" },
            { label: "Milestones", value: "milestones" }
          ]} />
        </Row>
        <Row label="Format">
          <Checkbox
            value={config.types === "both" && config.separateSheets}
            disabled={config.types !== "both"}
            onChange={onSeparateSheetsChange}
          >
            Separate sheets
          </Checkbox>
          <Checkbox
            value={config.headingRow}
            onChange={onHeadingRowChange}
          >
            Heading row
          </Checkbox>
        </Row>
        <Row label="Columns">
          <CheckboxGroup noFrame columns={2} onChange={onFieldChange} options={fieldsOptions} />
        </Row>
      </form>
      <ModalFooter>
        <ModalButton disabled={busy} onClick={back}>Back</ModalButton>
        <ModalButton disabled={busy} onClick={download} primary>Download</ModalButton>
      </ModalFooter>
    </>
  )
}

function Row({label, children}) {
  return (
    <div className={s.configRow}>
      <div className={s.configLabel}>{label}</div>
      <div className={s.configFields}>{children}</div>
    </div>
  )
}

function Option({ format, label, close, disabled, pro, icon, config }) {
  const title = useAtomValue(titleAtom);
  const isPaid = useAtomValue(isPaidUserAtom);
  const getData = useSetAtom(snapshotAtom);
  const callToUpgrade = useSetAtom(callToUpgradeModalAtom);
  const [busy, setBusy] = useState(false);

  const subscriptionValidate = (data) => {
    const elementsCount = (data.tasks?.length || 0) + (data.milestones?.length || 0);
    if (!isPaid && (elementsCount > FREE_ELEMENTS_LIMIT || format === "xlsx")) {
      close();
      callToUpgrade(true);
      return false;
    } else {
      return true;
    }
  }

  const handleClick = async () => {
    if (disabled || busy) return;
    
    const data = getData();
    if (!subscriptionValidate(data)) return;

    setBusy(true);
    // const localMode = true;
    
    // if (localMode) {
    //   const mime = {
    //     pptx: "application/zip",
    //     svg: "image/svg",
    //     png: "image/png",
    //     xlsx: "application/zip",
    //   }
    //   const painters = {
    //     pptx: getPpt,
    //     svg: getSvg,
    //     png: getPng,
    //     xlsx: getPpt,
    //   }
    //   const base64 = await painters[format](data);
    //   fetch('data:application/octet-stream;base64,' + base64)
    //     .then(res => res.blob())
    //     .then(res => writeFileToBrowser(`${data.document.title}.${format}`, res))
    //     .catch(console.log)
    //     .finally(() => {
    //       setBusy(false);
    //       close();
    //     });
    // } else {
    timelineDownload({ data, format, title })
      .catch(err => {
        if (err._response.statusCode === 403) {
          callToUpgrade(true);
        }
      })
      .finally(() => {
        setBusy(false);
        close();
      });
    // }
  }

  const cn = [s.option, busy && s.busy].filter(Boolean).join(" ");

  const subscriptionRequired = pro && !isPaid;

  return (
    <div className={s.optionWrapper}>
      <button className={cn} disabled={disabled} onClick={handleClick}>
        <div className={s.icon}>
          {icon && <Icon icon={icon} format={format} active={busy} />}
        </div>
        <p className={s.label}>
          {label}
          {disabled ? (
            <span className={s.tag}>Soon</span>
          ) : subscriptionRequired ? (
            <span className={s.pro}>Pro</span>
          ) : null}
        </p>
      </button>
      {config && !subscriptionRequired && (
        <button className={s.configButton} onClick={config}>
          <Adjust className={s.configIcon} />
          Config
        </button>
      )}
    </div>
  )
}

function Icon({ active, icon, format }) {
  const { RiveComponent, rive } = useRive({
    buffer: icon,
    artboard: format,
    stateMachines: "main",
    autoplay: true,
  });
  const stateInput = useStateMachineInput(rive, "main", "active");

  useEffect(() => {
    if (!stateInput) return;
    stateInput.value = active;
  }, [ active, stateInput ])

  return <RiveComponent />
}

export function postProcessing(blob, format) {
  if (format !== "svg") return blob;
  return blob.text().then(
    text => {
      const container = document.createElement("div");
      container.innerHTML = text;
      document.body.append(container);
      [...container.querySelectorAll("text")].forEach(
        el => {
          const y1 = el.getBBox().y;
          el.removeAttribute("alignment-baseline");
          const y2 = el.getBBox().y;
          const delta = y2 - y1;
          const y = el.getAttribute("y") - delta;
          el.setAttribute("y", y);
        }
      )
      const result = container.innerHTML;
      document.body.removeChild(container);
      return new Blob([result], {type: 'image/svg'})
    }
  )
}

export function timelineDownload({ id, data, format, title }) {
  const mime = {
    pptx: "application/zip",
    svg: "image/svg",
    png: "image/png",
    xlsx: "application/zip",
  }

  window.fbq('trackCustom', 'DownloadTimeline', {
    content_name: format,
  });

  return post({
    apiName: 'exportTimeline',
    path: '/export',
    options: {
      body: { id, data, format },
      headers: {
        Accept: mime[format]
      }
    }
  }).response
    .then(res => res.body.blob())
    .then(blob => postProcessing(blob, format))
    .then(res => writeFileToBrowser(`${title}.${format}`, res))
}