import { useState } from "react";
import { Helmet } from "react-helmet";
import { confirmResetPassword, resetPassword, signIn, signOut } from "aws-amplify/auth";
import { saveTemp } from "utils";
import { EMAIL_REGEXP } from "store";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Input, ModalMessage } from "components";
import { ModalButton, FormFooter } from "components/Modal";
import { Wrapper } from "./SignIn";

import s from "./authorization.module.css";


export default function ResetPassword() {
  const navigate = useNavigate();
  const locationState = useLocation().state;
  const [ email, setEmail ] = useState( locationState?.email || "" );
  const [ sent, setSent ] = useState(false);
  const state = { ...useLocation().state, email };

  const goBack = () => navigate("/login", {state});

  return (
    <Wrapper>
      <Helmet title="Reset password" />
      { !sent ? (
        <Initiate email={email} setEmail={setEmail} goBack={goBack} onSubmit={setSent} />
        ) : (
        <Confirm email={email} goBack={goBack} />
      )}
    </Wrapper>
  )
}

function Initiate({ email, setEmail, goBack, onSubmit }) {
  const navigate = useNavigate();
  const [ sending, setSending ] = useState( "" );
  const [ error, setError ] = useState( "" );
  const state = { ...useLocation().state, email };

  const handleEmail = e => {
    setEmail(e.target.value);
    setError(null);
  }

  const handleSubmit = e => {
    e.preventDefault();
    if (sending) return;
    const validationError = validateInput(email);
    if (validationError) {
      setError(validationError);
      return;
    }
    setSending(true);
    resetPassword({ username: email })
      .then(() => onSubmit(true))
      .catch(error => {
        if (error.code === "InvalidParameterException") {
          navigate("/verify-email", { state });
          return;
        }
        setError(error.name);
      })
      .finally(() => setSending(false))
  }

  function validateInput(email) {
    if (!email) {
      return "NoEmail";
    } else if ( !email.match( EMAIL_REGEXP )) {
      return "NotEmail";
    } else {
      return null;
    }
  }

  const errorMessages = {
    NoEmail: "Email is required.",
    NotEmail: "Email seems to be incorrect. Please check for typos.",
    UserNotFoundException: () => <p>User not found. You should probably <Link to="/signup" className={s.link} state={state}>create account</Link>.</p>,
    LimitExceededException: "We are registering too many attempts to reset your account password. The request is declined for security reasons. Please try again in 30 minutes.",
    TooManyRequestsException: "Sorry, we have received too many requests from your IP address, please wait a few minutes before trying again.",
    default: "We are sorry, but it seems there is a problem on our side. Please try again later.",
  }

  return (
    <>
      <h1 className={s.title}>Reset password</h1>
      <p>Fill in your registration email, and we'll send you a link to regain access to the account.</p>
      <form className={s.form} onSubmit={handleSubmit}>
        <div>
          <Input
            id={"recover-password"}
            label={"Email"}
            type={"email"}
            value={email}
            autoComplete={"username"}
            required
            onChange={handleEmail}
          />
        </div>
        <ModalMessage value={error} options={errorMessages} />
        <FormFooter>
          <ModalButton onClick={goBack}>Back to log in</ModalButton>
          <ModalButton disabled={sending}>Reset password</ModalButton>
        </FormFooter>
      </form>
    </>
  )
}


function Confirm({ email, goBack }) {
  const navigate = useNavigate();
  const [ sending, setSending ] = useState( "" );
  const [ error, setError ] = useState( "" );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (sending) return;
    const data = new FormData(e.target);
    const confirmationCode = data.get("code").trim();
    const newPassword = data.get("password");
    const validationError = validateInput(confirmationCode, newPassword);
    if (validationError) {
      setError(validationError);
      return;
    }

    try {
      setSending(true);
      await confirmResetPassword({
        username: email,
        confirmationCode, newPassword
      });

      await signIn({
        username: email,
        password: newPassword
      }); /* Sign in */
      await signOut({ global: true }); /* Invoke global signout */
      await new Promise((resolve) => setTimeout(resolve, 2000)); /* Wait for Chrome */
      
      await signIn({
        username: email,
        password: newPassword
      }); /* Get new tokens */
      
      await saveTemp();
      navigate("/");
    } catch (error) {
      setError(error.name);
    } finally {
      setSending(false);
    }
  }
  
  function validateInput(code, password) {
    if (!code) {
      return "NoCode";
    } else if (!password) {
      return "NoPassword";
    } else if (password.length < 6) {
      return "ShortPassword";
    } else {
      return null;
    }
  }
  const confirmationErrors = {
    NoCode: "Code is required.",
    NoPassword: "Password is required.",
    ShortPassword: "Password has to be at least 6 characters long.",
    InvalidPasswordException: "Password has to be at least 6 characters long.",
    CodeMismatchException: "Wrong security code. Please check for typos.",
    LimitExceededException: "We are registering too many attempts to reset your account password. The request is declined for security reasons. Please try again in 30 minutes.",
    TooManyRequestsException: "Sorry, we have received too many requests from your IP address, please wait a few minutes before trying again.",
    default: "We are sorry, but it seems there is a problem on our side. Please try again later."
  }

  return (
    <>
      <h1 className={s.title}>Check you email</h1>
      <p>We sent you a temporary security code to put below. Then you can set your brand new password.</p>
      <p className={s.infoBox}><b>Important:</b> If you don't see the email in your inbox within a few minutes, please check your spam or junk folder.</p>
      <form className={s.form} onSubmit={handleSubmit}>
        <div>
          <Input
            id="recover-code"
            label="Security code"
            name="code"
            type="text"
            autoComplete="one-time-code"
            required
          />
        </div>
        <div>
          <Input
            id="recover-password"
            label="New password"
            name="password"
            type="password"
            autoComplete="new-password"
            required
          />
        </div>
        <ModalMessage value={error} options={confirmationErrors} />
        <FormFooter>
          <ModalButton onClick={goBack}>Back to log in</ModalButton>
          <ModalButton disabled={sending}>Confirm</ModalButton>
        </FormFooter>
      </form>
    </>
  )
}