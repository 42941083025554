import { useRef, useState } from "react";
import { useAtom, useSetAtom } from "jotai";
import { editingAtom, selectedAtom, addSelectedAtom } from "store";

const Bar = ({className, children, onChange, onChangeStart, onChangeEnd, id, ...rest}) => {
  const [startPos, setStartPos] = useState(null);
  const [direction, setDirection] = useState(null);
  const [selected, setSelected] = useAtom(selectedAtom);
  const addSelected = useSetAtom(addSelectedAtom);
  const setContentEditing = useSetAtom(editingAtom);
  const capture = useRef(false);

  const onDoubleClick = (e) => {
    e.stopPropagation();
    setSelected(id);
    setContentEditing(true);
  }

  const select = (e) => {
    e.stopPropagation();
    setContentEditing(false);
    if (e.shiftKey || e.metaKey) {
      addSelected(id);
    } else if (!selected.includes(id)) {
      setSelected(id);
    }
  }

  const onPointerDown = (e) => {
    select(e);
    
    capture.current = true;
    e.stopPropagation();
    e.currentTarget.setPointerCapture(e.pointerId);
    setStartPos([e.clientX, e.clientY]);
    setDirection(null);
    onChangeStart();
  };
  
  const onPointerUp = (e) => {
    if (!capture.current) return;
    capture.current = false;
    e.preventDefault();
    e.stopPropagation();
    setStartPos(null);
    onChangeEnd();
  }

  const onPointerMove = (e) => {
    if (!capture.current) return;
    e.preventDefault();
    e.stopPropagation();
    const {shiftKey} = e;

    const distX = e.clientX - startPos[0];
    const distY = e.clientY - startPos[1];
    const dragAlnogX = Math.abs(distX) > Math.abs(distY);

    const x = ((!direction && dragAlnogX) || direction === "x" || shiftKey) ? distX : 0;
    const y = ((!direction && !dragAlnogX) || direction === "y" || shiftKey) ? distY : 0;

    if (Math.abs(x) > 50) setDirection("x");
    if (Math.abs(y) > 50) setDirection("y");

    onChange(x, y);
  }

  return (
    <div
      className={className}
      onDoubleClick={onDoubleClick}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      onPointerMove={onPointerMove}
      onLostPointerCapture={onPointerUp}
      {...rest}
    >
      {children}
    </div>
  )
}


export default Bar;