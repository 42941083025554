import { GROUP_TYPES } from 'store';
import { useStyleEdit } from "utils";
import Section from '../../components/Section/Section';
import ColorPicker from '../../components/ColorPicker/ColorPicker';
import NumberInput from '../../components/NumberInput/NumberInput';
import SliderInput from '../../components/Slider/Slider';
import { Segmented } from 'components';
import { ReactComponent as Spacing } from "img/icon/spacing.svg";

export default function GroupAppearance({styles, editAtom}) {
  const [
    p, valueChange, toggleChange, colorChange, opacityChange
  ] = useStyleEdit({ editAtom, styles });

  return p ? (
    <Section title="Appearance">
      <div>
        <Segmented
          secondary
          showLabels={true}
          onChange={valueChange("type")}
          value={p.type.mixed || p.type.value}
          label={"Type"}
          options={[
            {
              label: "Heading",
              value: GROUP_TYPES.HEADING,
            }, {
              label: "Swimlane",
              value: GROUP_TYPES.SWIMLANE,
            },
          ]}
        />
      </div>
      <div>
        <ColorPicker
          title={"Background"}
          id={"group-fill"}
          color={p.background.value ?? {h: 0, s: 0, v: 0, a: 1}}
          mixed={p.background.mixed}
          disabled={!p.backgroundShow.mixed && !p.backgroundShow.value}
          mixedDisabled={p.backgroundShow.mixed}
          onChange={colorChange("background")}
          onToggle={toggleChange("backgroundShow")}
        />
        <NumberInput
          title={"Opacity"}
          id={"group-fill-alpha"}
          value={ Math.floor(p.background.value.a * 100) }
          disabled={!p.backgroundShow.mixed && !p.backgroundShow.value}
          mixed={p.background.mixedAlpha}
          onChange={opacityChange("background")}
          min={0}
          max={100}
          unit={"%"}
        />
      </div>
      <div>
        <SliderInput
          title={"Spacing"}
          onChange={valueChange("padding")}
          id={"group-padding-slider"}
          value={p.padding.value ?? ""}
          mixed={p.padding.mixed}
          min={0}
          sliderMax={32}
          max={160}
          icon={Spacing}
        />
      </div>
    </Section>
  ): null
}