import s from "./Segmented.module.css";

function Segmented( props ) {
  const {value, options, onChange, optionClassName, showLabels, secondary, label, disabled} = props;

  const className = [
    s.wrapper, props.className,
    showLabels  && s.showLabels,
    secondary   && s.secondary
  ].filter(Boolean).join(" ");

  return (
    <div className={s.inputWrapper}>
      {label && <div className={s.inputLabel}>{label}</div>}
      <div className={className}>
        {options.map((el, id) => (
          <SegmentedOption
            key={id}
            active={el.value === value}
            className={optionClassName}
            onChange={onChange}
            {...el}
            disabled={disabled || el.disabled}
          />
        ))}
      </div>
    </div>
  )
}

const SegmentedOption = ({label, icon: Icon, active, value, disabled, onChange, className, hotkey}) => {
  const onClick = (e) => {
    onChange(value);
    e.currentTarget.blur();
  }
  const cn = [
    s.button,
    className,
    active && s.active 
  ].filter(Boolean).join(" ");

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={cn}
      type="button"
    >
      {Icon && <Icon className={s.icon} />}
      <span className={s.label}>{label}</span>
      {hotkey && (
        <div className={s.tooltipWrapper}>
          <span className={s.tooltip}>{label}</span>
          <span className={s.hotkey}>{
            hotkey.map( k => <span key={k} className={s.key}>{k}</span>)
          }</span>
        </div>
      )}
    </button>
  )
}

export default Segmented;