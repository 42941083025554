import { atom } from "jotai";
import { find } from "lodash";
import { TIMELINE_UNITS, DAY_LENGHT } from "./constants";
import { taskStylesAtom, overrideTaskAtom, taskStylesMapAtom, newItemsAllowedAtom, callToUpgradeModalAtom } from ".";
import { autoDateAtom, mapAtom, roundTimelineAtom } from "./planner";
import { rowsAtom, deleteRowAtom, cleanupRowsAtom } from "./rows";
import { milestonesAtom } from "./milestones";
import { v4 as uid } from "uuid";


export const tasksAtom = atom([]);

const _lastUsedTaskStyleAtom = atom();
export const lastUsedTaskStyleAtom = atom(
  ( get ) => {
    const id = get(_lastUsedTaskStyleAtom);
    if (get(taskStylesMapAtom)[id]) {
      return id;
    } else {
      return get(taskStylesAtom)[0].id;
    }
  },
  ( get, set, upd ) => {
    set(_lastUsedTaskStyleAtom, upd);
  }
);

// Task manipulations
export const createTaskAtom = atom(null, (get, set, props = {}) => {
  if (!get(newItemsAllowedAtom)) {
    set(cleanupRowsAtom);
    set(callToUpgradeModalAtom, true);
    return;
  }
  const defaultProps = {
    id: uid(),
    type: "task",
    title: "",
    style: get(lastUsedTaskStyleAtom),
    workgroup: [],
  }
  const { row, ...providedProps } = props;
  const task = {...defaultProps, ...providedProps};
  set(tasksAtom, [...get(tasksAtom), task]);
  set(attachTaskAtom, { id: task.id, row });
  return task;
})

export const updateTaskAtom = atom(null, (get, set, props) => {
  const target = get(mapAtom).get(props.id);
  if (!target || target.type !== "task") return false;

  const task = {...target, ...props};
  const newTasks = get(tasksAtom).map( el => el === target ? task : el );
  set(tasksAtom, newTasks);

  if (props.style) {
    set( lastUsedTaskStyleAtom, props.style );
    set( replaceLabelOverrides, {
      id: props.id,
      from: target.style,
      to: props.style
    })
  }
  return task;
})

const replaceLabelOverrides = atom(null, (get, set, props) => {
  const {id, from, to} = props;
  const target = get(mapAtom).get(id);
  if (!target?.overrides?.labels) return;
  
  const styles = get(taskStylesMapAtom);
  const fromStyle = styles[from];
  const toStyle = styles[to];
  if (!fromStyle || !toStyle) return;

  const labelsMap = {};
  fromStyle.labels.forEach( originalLabel => {
    const match = toStyle.labels.find(
      newLabel => (
        (newLabel.field === originalLabel.field) && 
        (!Object.values(labelsMap).includes(newLabel.id))
      )
    );
    labelsMap[originalLabel.id] = match?.id;
  })

  const labels = {};
  Object.entries(target.overrides.labels).forEach(([id, value]) => {
    const newId = labelsMap[id];
    if (!newId) return;
    labels[newId] = value;
  })
  set(overrideTaskAtom, { id, labels })
})

export const deleteTaskAtom = atom(null, (get, set, id) => {
  set(detachTaskAtom, id);
  set(tasksAtom, get(tasksAtom).filter( el => el.id !== id ));
})

export const detachTaskAtom = atom(null, (get, set, id) => {
  const rows = get(rowsAtom);
  const target = rows.find( el => el.children.includes(id));
  if (!target) return false;

  const row = { ...target };
  row.children = target.children.filter(el => el !== id);

  set(rowsAtom, rows.map( el => el === target ? row : el ));
  if (!row.children.length) set(deleteRowAtom, target.id);
  return true;
})

export const attachTaskAtom = atom(null, (get, set, props) => {
  const rows = get(rowsAtom);
  const target = find(rows, {id: props.row});
  if (!target) return false;
  const row = { ...target };
  row.children = [ ...target.children, props.id ];

  const newRows = rows.map( el => el === target ? row : el );
  set(rowsAtom, newRows);
})

export const moveTaskAtom = atom(null, ( get, set, props) => {
  set(detachTaskAtom, props.id);
  set(attachTaskAtom, props)
})

export const _minDateAtom = atom(Date.now());
// Calculate the timeline boundaries
export const minDateAtom = atom(
  (get) => {
    const round = get(roundTimelineAtom);
    const tasks = get(tasksAtom);
    const milestones = get(milestonesAtom);
    const autoDate = get(autoDateAtom);
    const taskMinDate = get(taskMinDateAtom);
    const targetMinDate = get(_minDateAtom);
    const val =
      (tasks.length + milestones.length) > 3 && autoDate ?
      taskMinDate :
      Math.min(targetMinDate, taskMinDate)
    ;
    const d = new Date(val);
    d.setUTCHours(0,0,0,0);
    switch (round) {
      case TIMELINE_UNITS.YEARS :
        d.setUTCMonth(0, 1);
        break;
      case TIMELINE_UNITS.QUARTERS :
        d.setUTCMonth(Math.floor(d.getUTCMonth() / 3) * 3, 1);
        break;
      case TIMELINE_UNITS.MONTHS :
        d.setUTCDate(1);
        break;
      default:
        break;
    }
    return d.valueOf();
  },
  (get, set, val) => {
    set(_minDateAtom, val);
  }
);

export const _maxDateAtom = atom(Date.now() + (6 * 30 * 24 * 60 * 60 * 1000));
export const maxDateAtom = atom(
  (get) => {
    const round = get(roundTimelineAtom);
    const tasks = get(tasksAtom);
    const milestones = get(milestonesAtom);
    const autoDate = get(autoDateAtom);
    const taskMaxDate = get(taskMaxDateAtom);
    const targetMaxDate = get(_maxDateAtom);
    const val = 
      (tasks.length + milestones.length) > 3 && autoDate ?
      taskMaxDate :
      Math.max(targetMaxDate, taskMaxDate)
    ;
    const d = new Date(val);
    d.setUTCHours(-24,0,0,0);
    switch (round) {
      case TIMELINE_UNITS.YEARS :
        d.setUTCMonth(12, 0);
        break;
      case TIMELINE_UNITS.QUARTERS:
        d.setUTCMonth(Math.floor(d.getUTCMonth() / 3) * 3 + 3, 1);
        break;
      case TIMELINE_UNITS.MONTHS :
        d.setUTCMonth(d.getUTCMonth() + 1, 1);
        break;
      default:
        d.setUTCHours(24);
        break;
    }
    return d.valueOf();
  },
  (get, set, val) => {
    set(_maxDateAtom, val);
  }
);

export const taskMinDateAtom = atom(
  get => {
    const tasks = get(tasksAtom);
    const milestones = get(milestonesAtom);
    if (!tasks?.length && !milestones?.length) {
      return get(_minDateAtom);
    } else {
      const tasksMin = tasks.reduce((acc, c) => Math.min(c.start, acc), Infinity);
      const milestonesMin = milestones.reduce((acc, c) => Math.min(c.date, acc), Infinity);
      return Math.min(tasksMin, milestonesMin);
    }
  }
);
export const taskMaxDateAtom = atom(
  get => {
    const tasks = get(tasksAtom);
    const milestones = get(milestonesAtom);
    if (!tasks?.length && !milestones?.length) {
      return get(_maxDateAtom);
    } else {
      const tasksMax = tasks.reduce((acc, c) => Math.max(c.end, acc), -Infinity);
      const milestonesMax = milestones.reduce((acc, c) => Math.max(c.date, acc), -Infinity) + DAY_LENGHT;
      return Math.max(tasksMax, milestonesMax);
    }
  }
);

export const dateRangeAtom = atom( (get) => get(maxDateAtom) - get(minDateAtom) );

export const taskDragAtom = atom();