import DocumentConfig from "./DocumentConfig";
import ColumnsConfig  from "./ColumnsConfig";
import TiersConfig    from "./TiersConfig";
import Preferences    from "./Preferences";
import Header         from "../../components/Header/Header";


export default function Document() {
  return (
    <>
      <Header title="Document" />
      <TiersConfig />
      <ColumnsConfig />
      <DocumentConfig />
      <Preferences />
    </>
  )
}