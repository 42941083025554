import { useAtomValue } from "jotai";
import { taskStylesLabelsMapAtom, labelsOrderAtom, overrideTaskAtom, editTaskStyleAtom, SELECTABLE } from "store";
import { forEach, some } from "lodash";
import { useGetStyles } from "utils";
import LvlSwitch from "../../components/LvlSwitch/LvlSwitch";
import Section from "../../components/Section/Section";
import StylesList from "../../components/StylesList/StylesList";
import EditMode from "../../components/EditMode/EditMode";
import { AddLabel } from "../../components/AddItem/AddItem";
import MainData from "./MainData";
import BarAppearance from "./BarAppearance";
import LabelAppearance from "./LabelAppearance";


export default function Task() {
  const labelsOrder = useAtomValue(labelsOrderAtom);
  const [items, styles, isOverriding] = useGetStyles(taskStylesLabelsMapAtom);
  const editAtom = isOverriding ? overrideTaskAtom : editTaskStyleAtom;

  const labels = styles
    .reduce((result, { id, labels }) => {
      forEach(labels, ({ id: labelId, ...label }) => {
        const style = { ...label, id, labelId };
        const targetSlot = result.find(slot => (
          (slot.field === label.field) && (!some(slot.styles, { id }))
        ));
        if (targetSlot) {
          targetSlot.styles.push(style);
        } else {
          result.push({
            key: labelId,
            field: label.field,
            styles: [style]
          })
        }
      })
      return result;
    }, [])
    .sort((a, b) => (
      labelsOrder.indexOf(a.key) - labelsOrder.indexOf(b.key)
    ));

  return (
    <>
      <div>
        <LvlSwitch />
        <MainData items={items} />
        <StylesList title="Style" type={SELECTABLE.TASK} />
      </div>
      <div>
        <EditMode />
        <BarAppearance
          styles={styles}
          editAtom={editAtom}
        />
        {labels.map(({ key, styles }) => (
          <LabelAppearance
            key={key}
            styles={styles}
            editAtom={editAtom}
          />
        ))}
        <Section>
          <AddLabel />
        </Section>
      </div>
    </>
  )
}