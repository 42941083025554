import { useAtomValue } from "jotai"
import { dragColumnsAtom, FIELDS } from "store";
import FusedTasks from "./FusedTasks";
import Title from "./Title";
import RowLabel from "../Row/RowLabel";
import s from "./Group.module.css";


export default function Cell({ atom, columnId, groupId, styleId, style, title, items, selected, swimlane }) {
  const column = useAtomValue( atom );
  const drag = useAtomValue(dragColumnsAtom);

  const isTimeline = column.field === FIELDS.TASKS;
  const show = isTimeline ? style.bar?.show : style.columns?.[column.id].show;

  if ( swimlane && (!show || isTimeline)) return null;

  const dragTarget = drag && drag.from === columnId;
  const cellStyle = {gridColumn: `${columnId + 1} / ${columnId + 2}`};
  if (drag) {
    if (dragTarget) {
      cellStyle.transform = `translateX(${drag.delta}px)`;
    } else if (drag.from < columnId && drag.to >= columnId) {
      cellStyle.transform = `translateX(${-drag.width}px)`;
    } else if (drag.from > columnId && drag.to <= columnId) {
      cellStyle.transform = `translateX(${drag.width}px)`;
    }
  }
  const className = [
    `${styleId}-${column.id}`,
    ` override-${groupId}-${column.id}`,
    s.cell,
    dragTarget &&           s.dragCell,
    drag && !dragTarget &&  s.cellTransition,
  ].filter(Boolean).join(" ");


  let props, Component;
  switch (column.field) {
    case FIELDS.TASKS :
      props = { items };
      Component = FusedTasks;
      break;
    case FIELDS.TITLE :
      props = { id: groupId, title, selected };
      Component = Title;
      break;
    default :
      props = { items, ...column };
      Component = RowLabel;
  }

  return (
    <div
      style={cellStyle}
      className={className}
    >
      {show && <Component {...props} />}
    </div>
  )
}