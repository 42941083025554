import s from './Input.module.css';

export default function Input({ id, label, className, inputClass, ...rest }) {
  const wrapperClassName = [
    s.wrapper, className
  ].filter(Boolean).join(" ");
  const inputClassName = [
    s.input, inputClass, rest.disabled && s.disabled
  ].filter(Boolean).join(" ");
  return (
    <div className={wrapperClassName}>
      {label && <label className={s.label} htmlFor={id}>{ label }</label>}
      <input className={inputClassName} id={id} {...rest} />
    </div>
  )
}