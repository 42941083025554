import { useCallback, useEffect, useRef } from "react";
import { useAtom, useAtomValue } from "jotai";
import { hasLifetime, hasSubscription, isPaidUser } from "utils";
import { callToUpgradeModalAtom, userAtom } from "store";
import { Helmet } from "react-helmet";
import { Modal } from "components";
import { Link } from "react-router-dom";
import { ReactComponent as Check } from "img/icon/milestone/check.svg";
import { ReactComponent as Minus } from "img/icon/minus.svg";
import { ReactComponent as Starter } from "img/icon/starter.svg";
import { ReactComponent as Pro } from "img/icon/pro.svg";
import { ReactComponent as Lifetime } from "img/icon/lifetime.svg";
import s from "./CallToUpgrade.module.css";


export function UpgradeModal() {
  const [isOpen, setOpen] = useAtom(callToUpgradeModalAtom);
  const close = useCallback(() => setOpen(false), [setOpen]);
  const ref = useRef();

  useEffect(() => {
    ref.current = close;
    return () => setOpen(false);
  }, [setOpen, close])
  useEffect(() => {
    return () => ref.current();
  }, [])

  return (
    <Modal
      className={s.wrapper}
      dialogClassName={s.dialog}
      isOpen={isOpen}
      close={close}
      lightClose
      wide
      closeButton
    >
      <Content image />
    </Modal>
  )
}

export default function Upgrade() {
  return (
    <div className={s.pageWrapper}>
      <Helmet title="Upgrade to Pro"/>
      <Content />
    </div>
  )
}

function Content({ image }) {
  const user = useAtomValue(userAtom);
  return (
    <>
      {image && <div className={s.image} /> }
      <div className={s.content}>
        <div className={s.header}>
          <h1>Upgrade to Pro</h1>
          <p>Create professional timelines without any limitations or constraints.</p>
        </div>
        <div className={s.plans}>
          <div className={s.plan}>
            <div className={s.nameWrapper}>
              <Starter className={s.planIcon} />
              <div className={s.name}>Starter</div>
            </div>
            <div className={s.price}><span className={s.priceValue}>$0</span>/mo</div>
            <div className={s.priceComment}>&nbsp;</div>
            {isPaidUser(user) ? (
              <div className={`${s.button} ${s.disabled}`}>Available</div>
            ) : (
              <div className={`${s.button} ${s.disabled}`}>Current Plan</div>
            )}
            <div className={s.cardFeatures}>
              <Feature>Unlimited timelines</Feature>
              <Feature>10 elements per timeline</Feature>
              <Feature>Full functional timeline editor</Feature>
              <Feature>Export to PowerPoint, SVG, PNG</Feature>
            </div>
          </div>
          <div className={s.plan}>
            <div className={s.nameWrapper}>
              <Pro className={s.planIcon} />
              <div className={s.name}>Pro</div>
            </div>
            <div className={s.price}><span className={s.priceValue}>$4,99</span>/mo</div>
            <div className={s.priceComment}>billed annually or $6,99/mo</div>
            {hasSubscription(user) ? (
              <div className={`${s.button} ${s.disabled}`}>Current Plan</div>
            ) : (
              <Link to={"/checkout"} className={s.button}>Upgrade</Link>
            )}
            <div className={s.cardFeatures}>
              <Feature>Everything in Starter and...</Feature>
              <Feature>Unlimited elements</Feature>
              <Feature>150 AI credits/mo.</Feature>
              <Feature>Export to Excel</Feature>
            </div>
          </div>
          <div className={s.plan}>
            <div className={s.nameWrapper}>
              <Lifetime className={s.planIcon} />
              <div className={s.name}>Lifetime</div>
            </div>
            <div className={s.price}><span className={s.priceValue}>$119,99</span></div>
            <div className={s.priceComment}>&nbsp;</div>
            {hasLifetime(user) ? (
              <div className={`${s.button} ${s.disabled}`}>Purchased</div>
            ) : (
              <Link to={"/checkout/lifetime"} className={s.button}>Buy now</Link>
            )}
            <div className={s.cardFeatures}>
              <Feature>One-off payment</Feature>
              <Feature>Lifetime upgrades</Feature>
              <Feature>30 AI credits/mo.</Feature>
              <Feature>Export to Excel</Feature>
            </div>
          </div>
        </div>
        <div className={s.note}>
          Prices are in USD and may be subject to taxes.
        </div>
        <div className={s.features}>
          <div className={`${s.row} ${s.th}`}>
            <div>Key features</div>
            <div>Starter</div>
            <div>Pro</div>
            <div>Lifetime</div>
          </div>
          {features.map(({ name, starter, pro, lifetime }) => (
            <div className={s.row} key={name}>
              <div>{name}</div>
              <div><Value val={starter} /></div>
              <div><Value val={pro} /></div>
              <div><Value val={lifetime} /></div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

function Value({ val }) {
  if (val === true) {
    return <Check className={`${s.icon} ${s.plus}`} />
  } else if (val === false) {
    return <Minus className={`${s.icon} ${s.minus}`} />
  } else {
    return val;
  }
}

function Feature({ children }) {
  return (
    <div className={s.feature}>
      <Check className={s.featureIcon} />
      <span>{children}</span>
    </div>
  )
}


const features = [
  {
    name: "Timelines",
    starter: "Unlimited",
    pro: "Unlimited",
    lifetime: "Unlimited",
  }, {
    name: "Elements per timeline",
    starter: "10",
    pro: "Unlimited",
    lifetime: "Unlimited",
  }, {
    name: "AI Assistant",
    starter: false,
    pro: "150 Credits/mo.",
    lifetime: "30 Credits/mo.",
  }, {
    name: "Templates library",
    starter: true,
    pro: true,
    lifetime: true,
  }, {
    name: "Sample template data",
    starter: false,
    pro: true,
    lifetime: true,
  }, {
    name: "PowerPoint export",
    starter: true,
    pro: true,
    lifetime: true,
  }, {
    name: "SVG export",
    starter: true,
    pro: true,
    lifetime: true,
  }, {
    name: "PNG export",
    starter: true,
    pro: true,
    lifetime: true,
  }, {
    name: "Excel export",
    starter: false,
    pro: true,
    lifetime: true,
  }, {
    name: "Timescale design",
    starter: true,
    pro: true,
    lifetime: true,
  }, {
    name: "Shared styles",
    starter: true,
    pro: true,
    lifetime: true,
  }, {
    name: "Project teams",
    starter: true,
    pro: true,
    lifetime: true,
  }, {
    name: "Resource estimates",
    starter: true,
    pro: true,
    lifetime: true,
  }, {
    name: "Data columns",
    starter: true,
    pro: true,
    lifetime: true,
  }
]