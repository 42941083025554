import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { fieldValue, getTimelineUnits, hsvaToHexCss } from "utils";
import { justifyContent } from "pages/Project/Styles";
import { Helmet } from "react-helmet";
import { ptAtom, columnAtomsAtom, teamAtom, tasksAtom, dragColumnsAtom, tiersAtom, minDateAtom, maxDateAtom, timeScaleAtom, selectedAtom, editingAtom, historyAtom, editHeadingAtom, preferencesAtom, FIELDS, milestonesAtom } from "store";
import s from "./Heading.module.css";
import TitleInput from "../TitleInput/TitleInput";


export default function Heading({ atom, id }) {
  const [ selected, setSelected ] = useAtom(selectedAtom);
  const [ editing, setEditing ] = useAtom(editingAtom);
  const props = useAtomValue(atom);
  const columns = useAtomValue(columnAtomsAtom);

  const onPointerDown = (e) => {
    e.stopPropagation();
    setSelected([id]);
    setEditing(false);
  }

  const isSelected = !editing && (selected[0] === id);

  const className = [
    id, s.header, isSelected && s.selected
  ].filter(Boolean).join(" ");

  return props.tiers?.length ? (
    <>
    <HeaderStyle id={id} {...props} />
    <div className={className} onPointerDown={onPointerDown}>
      {columns.map((colAtom, index) => (
        <Cell
          key={`${colAtom}`}
          atom={colAtom}
          parentAtom={atom}
          parentId={id}
          index={index}
          className={id}
          tiers={props.tiers}
          columns={props.columns}
          selected={isSelected}
        />
      ))}
    </div>
    </>
  ) : <div className={`${s.emptyHeading} ${isSelected ? s.selected : ""}`} />;
}

function Cell({ atom, index, tiers, className: cn, columns, parentAtom, parentId, selected }) {
  const { field, id, format } = useAtomValue(atom);
  const drag = useAtomValue(dragColumnsAtom);

  const style = {};

  const dragTarget = drag?.from === index;
  if (drag) {
    if (dragTarget) {
      style.transform = `translateX(${drag.delta}px)`;
    } else if (drag.from < index && drag.to >= index) {
      style.transform = `translateX(${-drag.width}px)`;
    } else if (drag.from > index && drag.to <= index) {
      style.transform = `translateX(${drag.width}px)`;
    }
  }

  const className = [
    `${cn}-${id}`,
    s.cell,
    dragTarget &&           s.dragCell,
    drag && !dragTarget &&  s.transition,
  ].filter(Boolean).join(" ");

  return <div style={style} className={className}>
    {field === FIELDS.TASKS ? (
      <div className={s.tiersWrapper}>
        {tiers.map( tierId => <Tier id={tierId} key={tierId} />)}
      </div>
    ) : columns[id].show === false ? (
      null
    ) : field === FIELDS.TITLE ? (
      <TitleCell atom={parentAtom} id={parentId} selected={selected} />
    ) : (
      <LabelCell field={field} format={format} />
    )}
  </div>
}

function Tier({ id }) {
  const tiers = useAtomValue(tiersAtom);
  const { field, scale, format, ...styles } = tiers.find( el => el.id === id);

  const minDate = useAtomValue(minDateAtom);
  const maxDate = useAtomValue(maxDateAtom);
  const timeScale = useAtomValue(timeScaleAtom);
  const tasks = useAtomValue(tasksAtom);
  const team = useAtomValue(teamAtom);
  const preferences = useAtomValue(preferencesAtom);

  const units = getTimelineUnits(minDate, maxDate, timeScale, {scale, format, field, tasks, team, preferences});

  return (
    <>
    <TierStyle id={id} {...styles} />
    <div className={`${id} ${s.tier}`}>
      {units.map(({key, x, width, value}) => (
        <span
          key={key}
          className={s.marker}
          style={{
            transform: `translateX(${x}px)`,
            width: `${width}px`,
          }}
        >
          {value}
        </span>
      ))}
    </div>
    </>
  )
}

function LabelCell({ field, format }) {
  const preferences = useAtomValue( preferencesAtom );
  const team = useAtomValue( teamAtom );
  const tasks = useAtomValue( tasksAtom ) || [];
  const milestones = useAtomValue( milestonesAtom ) || [];

  return (
    <div className={s.label}>{fieldValue([...tasks, ...milestones], field, format, team, preferences)}</div>
  )
}

function TitleCell({ atom, id, selected }) {
  const [editing, setEditing] = useAtom(editingAtom);
  const title = useAtomValue(atom).title || "";
  const editHeading = useSetAtom(editHeadingAtom);
  const setHistory = useSetAtom(historyAtom);

  const onDoubleClick = (e) => {
    e.stopPropagation();
    setEditing(true);
  };

  const onChange = (title) => {
    editHeading({ atom, title });
    setHistory(`column-edit-${atom}`);
  }

  const className = [
    s.input,
    !editing && s.placeholder,
    selected && !title && s.activePlaceholder
  ].filter(Boolean).join(" ");

  return (
    <div
      className={className}
      onDoubleClick={onDoubleClick}
    >
      <TitleInput id={id} value={title} onChange={onChange} />
    </div>
  )
}


function HeaderStyle( s ) {
  const pt = useAtomValue(ptAtom);
  const tiers = useAtomValue(tiersAtom);
  const minHeight = s.tiers.map(
    id => tiers.find(el => el.id === id)
  ).reduce(
    (acc, val) => Math.min(acc, val.height), Infinity
  );
  return (
    <Helmet>
      <style>{`
      .${s.id} {
        --background: ${hsvaToHexCss(s.background, s.backgroundShow)};
        --fill: ${hsvaToHexCss(s.fill || { h:0, s:0, v:0, a:0 }, s.fillShow)};
        --border-color: ${hsvaToHexCss(s.borderColor, s.borderShow)};
        --border-radius: ${Math.min(s.borderRadius, minHeight) * pt}px;
        --border-width: ${s.borderWidth * s.borderShow * pt}px;
        --padding: ${s.padding * pt}px;
      }
      ${Object.keys(s.columns || {}).map((key) => 
        `.${s.id}-${key} {
          --color: ${hsvaToHexCss(s.columns[key].color)};
          --font-size: ${s.columns[key].fontSize * pt}px;
          --text-align: ${s.columns[key].textAlign};
          --justify-content: ${justifyContent[s.columns[key].textAlign]};
          --font-weight: ${s.columns[key].bold ? 600 : 400};
          --font-style: ${s.columns[key].italic ? "italic" : "none"};
          --text-decoration: ${s.columns[key].underline ? "underline" : "none"};
          --text-transform: ${s.columns[key].textTransform};
        }`).join('\n')
      }`}</style>
    </Helmet>
  )
}
function TierStyle( s ) {
  const pt = useAtomValue(ptAtom);
  return (
    <Helmet>
      <style>{`
      .${s.id} {
        --background: ${hsvaToHexCss(s.background, s.backgroundShow)};
        --height: ${s.height * pt}px;
        --sr-color: ${hsvaToHexCss(s.separatorColor, s.separatorShow)};
        --sr-width: ${s.separatorWidth * s.separatorShow * pt}px;
        --color: ${hsvaToHexCss(s.color)};
        --font-size: ${s.fontSize * pt}px;
        --text-align: ${s.textAlign};
        --justify-content: ${justifyContent[s.textAlign]};
        --font-weight: ${s.bold ? 600 : 400};
        --font-style: ${s.italic ? "italic" : "none"};
        --text-decoration: ${s.underline ? "underline" : "none"};
        --text-transform: ${s.textTransform};
      }
      `}</style>
    </Helmet>
  )
}