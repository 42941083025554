import { useAtomValue, useSetAtom } from "jotai";
import { teamAtom, updateTaskAtom, historyAtom, preferencesAtom, FIELDS } from "store";
import { fieldValue } from "utils";
import TitleInput from "../TitleInput/TitleInput";
import s from "./Label.module.css";

export default function Label( {className, title: value, field, ...rest} ) {
  const setUpdateTask = useSetAtom(updateTaskAtom);
  const setHistory = useSetAtom(historyAtom);
  if (!field) return null;

  const cn = [s.wrapper, className].filter(Boolean).join(" ");

  const isEditable = field === FIELDS.TITLE;
  const props = { value, field, ...rest}

  const onChange = (val) => {
    setUpdateTask({id: rest.id, title: val});
    setHistory(`title-edit-${rest.id}`);
  }

  return (
    <div className={cn}>
      { isEditable ? <TitleInput onChange={onChange} { ...props } /> : <SimpleLabel {...props} /> }
    </div>
  )
}

function SimpleLabel({ startDate, endDate, workgroup=[], field, format }) {
  const team = useAtomValue( teamAtom );
  const preferences = useAtomValue( preferencesAtom );

  const task = {
    start: startDate,
    end: endDate,
    workgroup
  }

  return (
    <div className={s.value}>
      {fieldValue( task, field, format, team, preferences )}
    </div>
  )
}